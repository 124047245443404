import { c as _c } from "react-compiler-runtime";
import { HHBox, HHGrid } from '@hinge-health/react-component-library';
import { ReactComponent as FreshDeskIcon } from '../components/assets/images/freshdeskfigma.svg';
import { ReactComponent as HelpScoutIcon } from '../components/assets/images/helpscoutfigma.svg';
import { QUICK_LINKS } from '../constants/strings/coach';
import { generateKey } from '../utils/app-utils';
import { QuickLinkIcon } from './quick-link-icon';
const quickLinks = [{
  text: QUICK_LINKS.HELPSCOUT,
  icon: <HelpScoutIcon />,
  url: 'https://secure.helpscout.net/members/login/'
}, {
  text: QUICK_LINKS.FRESHDESK,
  icon: <FreshDeskIcon />,
  url: 'https://hingehealth.freshdesk.com/support/tickets/new'
}];
export const QuickLinks = t0 => {
  const $ = _c(5);
  const {
    setShowQuickLinksPopover
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      width: 177,
      height: 97,
      borderRadius: 1
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] !== setShowQuickLinksPopover) {
    t2 = quickLinks.map((quickLink, id) => <HHGrid item={true} xs={6} key={generateKey(quickLink.text, id)}><HHBox sx={{
        paddingTop: 2
      }}><QuickLinkIcon url={quickLink.url} icon={quickLink.icon} text={quickLink.text} setShowQuickLinksPopover={setShowQuickLinksPopover} /></HHBox></HHGrid>);
    $[1] = setShowQuickLinksPopover;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== t2) {
    t3 = <HHBox data-testid="quick-links-container" sx={t1}><HHGrid container={true} spacing={2}>{t2}</HHGrid></HHBox>;
    $[3] = t2;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  return t3;
};