import { c as _c } from "react-compiler-runtime";
import { gql, LazyQueryHookOptions, LazyQueryResultTuple, MutationFunctionOptions, MutationTuple, OperationVariables, useLazyQuery, useMutation } from '@apollo/client';
import { UserPathway } from '../../../types';
import { EducationDataType, EngagementStreakData, EnsoData } from '../components/activity/types';
import { EnrollmentStatusQueryType, GetStateRegulations, GetUserName, UpdateEnrollmentStatusType, UserAqInfoQuery } from '../components/profile/types';
export const GET_USER_INFO = gql`
  query UserInfo($uuid: ID!) {
    user(uuid: $uuid) {
      id
      firstName
      lastName
      gender
      email
      phoneNumber
      addressOne
      addressTwo
      city
      stateOfResidence
      postalCode
      dateOfBirth
      timezone
      uuid
      tagList
      contactMethod
      allowsOffshore
      pathways {
        uuid
        id
        indication
        isActive
        level
        stage
        type
        startsAt
      }
      applicationPathway {
        program
        indication
        screeningId
      }
    }
  }
`;
export const GET_LAST_ET_SESSION = gql`
  query GetLastEtSession($uuid: ID!) {
    user(uuid: $uuid) {
      lastEtSessionAt
    }
  }
`;
export const GET_ENSO_TWO_WEEKS_USAGE = gql`
  query GetEnsoTwoWeeksUsage($userUuid: ID!) {
    getEnsoTwoWeeksUsage(GetEnsoTwoWeeksUsageInput: { userUuid: $userUuid }) {
      lastSessionDate
      sessionsLastWeek
      sessionsThisWeek
    }
  }
`;
export type UserRecentActivity = {
  __typename?: 'Query';
  user: {
    pathways?: Partial<UserPathway>[];
    lastEtSessionAt?: string | null;
  };
};
export const useGetEnsoTwoWeeksUsageData = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useLazyQuery(GET_ENSO_TWO_WEEKS_USAGE, options);
};
export const GET_USER_EDUCATION_CONTEXT = gql`
  query UserEduContext($uuid: ID!) {
    userEduContext(uuid: $uuid) {
      lastClosedAtArticle {
        closedAt
        contentUrl
        id
        name
        slug
      }
      nextArticle {
        closedAt
        contentUrl
        id
        name
        slug
      }
    }
  }
`;
export const useGetUserEducationContext = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useLazyQuery(GET_USER_EDUCATION_CONTEXT, options);
};
export const GET_USER_ENGAGEMENT_STREAK = gql`
  query UserEngagementStreak($uuid: ID!) {
    userEngagementStreak(uuid: $uuid) {
      currentStreak
    }
  }
`;
export const useGetUserEngagementStreak = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useLazyQuery(GET_USER_ENGAGEMENT_STREAK, options);
};
export const GET_USER_AQ_INFO = gql`
  query UserAQInfo($uuid: ID!) {
    userAQInfo(uuid: $uuid) {
      id
      firstName
      lastName
      email
      phoneNumber
      addressOne
      addressTwo
      city
      stateOfResidence
      postalCode
      dateOfBirth
      timezone
      tagList
      gender
      height
      weight
      bmi
      occupation
      client {
        name
      }
      uuid
      bio
      coachingQuestions {
        foreseeBarriers
        imagineFree
        keyReasons
      }
      firstEverEtSessionAttempted
      screener {
        painDetails {
          avoidExercise
          avoidedExercises
          description
          name
        }
        surgeryDetails {
          isPerisurgicalMember
          name
          followUps {
            futureSurgery
            upcomingSurgeries
          }
          impact {
            futureSurgery
            surgeryChanceNextYear
            upcomingSurgeries
          }
          impactUnification {
            upcomingSurgery {
              checkbox
              date
              indication
              surgeryType
            }
          }
          perisurgicalDetails {
            surgeryDate
            surgeryType
          }
        }
      }
    }
  }
`;
export const GET_STATE_REGULATIONS = gql`
  query GetStateRegulations($stateOfResidence: String!) {
    getStateRegulations(stateOfResidence: $stateOfResidence) {
      daAllowedForNoInjury
      hcpDetailsReqdIfNotReferred
      informHcpAfterFirstVisitDays
      referOnlyNoProgress
      rxReqdFor30DayPlusReturn
      rxReqdForDiagnosedChronic
      signedNoticeCoverageLimits
      signedNoticePtLimits
      state
      timeLimitDays
      visitLimit
    }
  }
`;
export const useGetUserAqInfo = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useLazyQuery(GET_USER_AQ_INFO, options);
};
export const useGetStateRegulations = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useLazyQuery(GET_STATE_REGULATIONS, options);
};
export const GET_USER_BALANCE_ASSESSMENTS_QUERY = gql`
  query getUserBalanceAssessments($uuid: ID!) {
    user(uuid: $uuid) {
      id
      uuid
      balanceAssessments {
        currentAssessment {
          sitToStand {
            score
            category
            date
          }
          fiveStageTest {
            score
            date
          }
        }
        previousAssessment {
          sitToStand {
            score
            category
            date
          }
          fiveStageTest {
            score
            date
          }
        }
      }
    }
  }
`;
export const EDIT_USER_PREFERRED_NAME_MUTATION = gql`
  mutation EditUserPreferredName($editUserInput: EditUserInput!, $uuid: ID!) {
    editUserPreferredName(editUserInput: $editUserInput, uuid: $uuid)
  }
`;
export const useEditPreferredName = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useMutation(EDIT_USER_PREFERRED_NAME_MUTATION, options);
};
export const GET_USER_NAME_QUERY = gql`
  query GetUserName($uuid: ID!, $id: ID!) {
    getUserName(uuid: $uuid, id: $id) {
      firstNameLegal
      firstNamePreferred
      id
      lastName
    }
  }
`;
export const useGetUserName = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useLazyQuery(GET_USER_NAME_QUERY, options);
};
export const UPDATE_ENROLLMENT_STATUS_MUTATION = gql`
  mutation UpdateEnrollmentStatus(
    $updateEnrollmentStatusInput: UpdateEnrollmentStatusInput!
  ) {
    updateEnrollmentStatus(
      updateEnrollmentStatusInput: $updateEnrollmentStatusInput
    ) {
      id
      status
      userUuid
      createdAt
    }
  }
`;
export const useUpdateEnrollmentStatus = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useMutation(UPDATE_ENROLLMENT_STATUS_MUTATION, options);
};
export const GET_ENROLLMENT_STATUS_QUERY = gql`
  query GetEnrollmentStatus($userUuid: ID!) {
    getEnrollmentStatus(uuid: $userUuid) {
      id
      status
      createdAt
      userUuid
    }
  }
`;
export const useGetEnrollmentStatus = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useLazyQuery(GET_ENROLLMENT_STATUS_QUERY, options);
};
export const EDIT_USER_INFO = gql`
  mutation editUserInfo($editUserInput: EditUserInput!, $uuid: ID!) {
    editUserInfo(editUserInput: $editUserInput, uuid: $uuid)
  }
`;
export const useEditUserInfo = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useMutation(EDIT_USER_INFO, options);
};