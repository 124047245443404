import { HHBox, HHCircularProgress, HHDivider, HHSnackbarAlert, HHTable, HHTableContainer, HHTypography } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { PtDashboardFilter } from '../../../constants/pt-dashboard';
import { ON, SPLIT_TREATMENTS } from '../../../constants/splits';
import { DEFAULT_COACH_DASHBOARD_TAB_NAME, LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from '../../../constants/strings/coach-dashboard-default';
import { COACH_LAYOUT } from '../../../constants/strings/coach-layout';
import { OS_NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/os-layout';
import { NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/pt-layout';
import { COACH_WORKFLOWS_TABLE, HEALTH_LOG_NOTES_LOAD_ERROR, TASKS_BEING_UPDATED, TASKS_LOAD_ERROR, UNREAD_MESSAGES_LOAD_ERROR } from '../../../constants/strings/workflows-table';
import { useShellContext } from '../../../contexts/shell.context';
import { DashboardType } from '../../../custom-types';
import { useBulkProgressTimeout } from '../../../hooks/use-bulk-progress-timeout';
import { useGetDashboardWorkflows } from '../../../hooks/use-get-dashboard-workflows';
import { bulkWorkflowHLNProgress, bulkWorkflowUnreadProgress, selectedCoachDashboardFilters, selectedDashboardType, selectedOsDashboardFilters, selectedPtDashboardFilters, selectedUnreadType, shouldRefetchWorkflows, showTimeoutAlert, timeoutIdAtom, totalAllTypesCount, totalAllTypesCountLowPriority, totalModularIamCount, totalModularIamCountLowPriority, totalUnreadCount, totalUnreadCountLowPriority, totalWorkflowCount, videoTabchanged, workflowListLoading } from '../../../jotai/atoms';
import { coachCallFilters, CoachDashboardFilters, coachVideoVisitFilters } from '../../../types/coach';
import { OsDashboardFilters } from '../../../types/os';
import { healthLogFilters, houseCallFilters, PtDashboardFilters, unreadMessageFilters, videoVisitFilters } from '../../../types/pt';
import { CoachCallsTabs } from '../coach-calls/coach-calls-tabs';
import { CoachVideoVisitTabs } from '../coach-video-visit-tabs';
import { HealthLogNotesTabs } from '../health-log-notes/health-log-notes-tabs';
import { PtHealthLogNotesTabs } from '../health-log-notes/pt-health-log-notes-tabs';
import { OSUnreadMessagesTabs } from '../unread-messages/os-unread-messages-tabs';
import { PtUnreadMessagesTabs } from '../unread-messages/pt-unread-messages-tabs';
import { UnreadMessagesTabs } from '../unread-messages/unread-messages-tabs';
import { VideoVisitTabs } from '../video-visit-tabs';
import { WorkflowTableTabs } from '../workflow-table-tabs';
import { getHouseCallTabsProps } from './pt-workflows-table-configs';
import { TaskUpdating } from './task-updating';
import WorkflowsPagination from './workflows-pagination';
import { WorkflowsTableContainer } from './workflows-table-container';
import { WorkflowsTableError } from './workflows-table-error';
import { workflowsTableStyles } from './workflows-table-styles';
export const PAGE_SIZE = 10;
export interface WorkflowsTableProps<T extends CoachDashboardFilters | PtDashboardFilters | OsDashboardFilters> {
  counts: Record<T, number>;
}
export const WorkflowsTable = <T extends CoachDashboardFilters | PtDashboardFilters | OsDashboardFilters,>(props: WorkflowsTableProps<T>): JSX.Element => {
  const {
    counts
  } = props;
  const dashboardType = useAtomValue(selectedDashboardType);
  const [selectedCoachDashboardFilter, setSelectedCoachDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [selectedPtDashboardFilter, setSelectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsDashboardFilter, setSelectedOsDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const [workflowCounts, setWorkflowCounts] = useState(0);
  const [allTypesCountTotal] = useAtom(totalAllTypesCount);
  const [messageCountTotal] = useAtom(totalUnreadCount);
  const [iamMessageCountTotal] = useAtom(totalModularIamCount);
  const [selectedUnreadFilter] = useAtom(selectedUnreadType);
  const {
    workflows,
    error,
    loading: workflowLoading
  } = useGetDashboardWorkflows(dashboardType);
  const showPagination = Boolean(workflowCounts);
  const [workflowListLoadingState, setWorkflowListLoading] = useAtom(workflowListLoading);
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const handleSnackbar = (): void => setOpenSnackbar(false);
  const ptLabel = (selectedPtDashboardFilter.filterLabel.split(':')[0].trim() as PtDashboardFilters);
  const osLabel = (selectedOsDashboardFilter.filterLabel.split(':')[0].trim() as OsDashboardFilters);
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const [videoTabchangedChanged, setvideoTabchangedchanged] = useAtom(videoTabchanged);
  const unreadMessagesDualTabTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.UNREAD_MESSAGE_ML, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const healthLogNotesSplitFlag = splitClient?.getTreatment(SPLIT_TREATMENTS.HEALTH_LOG_NOTES, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const ptHealthLogNotesDualTabFlag = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_HLN_ML, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const ptUnreadMessagesDualTabFlag = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_UNREAD_MESSAGES_ML, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  useEffect(() => {
    setWorkflowListLoading(workflowLoading && !error);
  }, [setWorkflowListLoading, workflowLoading, error, videoTabchangedChanged]);
  const [bulkHLNProgress, setBulkHLNProgress] = useAtom(bulkWorkflowHLNProgress);
  const [bulkUnreadProgress, setUnreadProgress] = useAtom(bulkWorkflowUnreadProgress);
  const [showTimeOutErr] = useAtom(showTimeoutAlert);
  const setTotalCount = useSetAtom(totalWorkflowCount);
  const [totalAllTypesLowPriority] = useAtom(totalAllTypesCountLowPriority);
  const [totalmodularIAMLowPriority] = useAtom(totalModularIamCountLowPriority);
  const [totalUnreadLowPriority] = useAtom(totalUnreadCountLowPriority);
  const [, setShouldRefetchWorkflows] = useAtom(shouldRefetchWorkflows);
  const [timeoutId] = useAtom(timeoutIdAtom);
  const lowPriorityNotesSplitFlag = unreadMessagesDualTabTreatment || healthLogNotesSplitFlag || ptHealthLogNotesDualTabFlag || ptUnreadMessagesDualTabFlag;
  const lowPriorityStyles = {
    marginTop: '1rem'
  };
  const unreadProgress = sessionStorage.getItem('unreadProgress');
  const hlnProgress = sessionStorage.getItem('hlnProgress');
  const videoTabchangedEvent = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [, setSelectedDashboardFilter] = useAtom(selectedPtDashboardFilters);
  window.addEventListener('beforeunload', () => {
    sessionStorage.removeItem('unreadProgress');
    sessionStorage.removeItem('hlnProgress');
  });
  useEffect(() => {
    if (unreadProgress === 'true') {
      setUnreadProgress(true);
    }
    if (hlnProgress === 'true') {
      setBulkHLNProgress(true);
    }
  }, [unreadProgress, hlnProgress, setUnreadProgress, setBulkHLNProgress]);
  useEffect(() => {
    if (dashboardType === DashboardType.Coach) {
      if ([CoachDashboardFilters.MemberHealthLog, CoachDashboardFilters.MemberHealthLogNoResponse].includes(selectedCoachDashboardFilter.filterLabel)) {
        if (selectedCoachDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME) {
          setTotalCount(counts[(CoachDashboardFilters.MemberHealthLogNoResponse as T)]);
          return setWorkflowCounts(counts[(CoachDashboardFilters.MemberHealthLogNoResponse as T)]);
        }
        return setWorkflowCounts(counts[(CoachDashboardFilters.MemberHealthLog as T)]);
      } else if ([CoachDashboardFilters.UnreadMessages, CoachDashboardFilters.UnreadMessagesNoResponse].includes(selectedCoachDashboardFilter.filterLabel)) {
        if (selectedCoachDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME) {
          setTotalCount(counts[(CoachDashboardFilters.UnreadMessagesNoResponse as T)]);
          return setWorkflowCounts(counts[(CoachDashboardFilters.UnreadMessagesNoResponse as T)]);
        }
        return setWorkflowCounts(counts[(CoachDashboardFilters.UnreadMessages as T)]);
      }
      return setWorkflowCounts(counts[(selectedCoachDashboardFilter.filterLabel as T)]);
    }
  }, [dashboardType, counts, selectedCoachDashboardFilter.filterLabel, selectedCoachDashboardFilter.filterTabName, setTotalCount, workflows]);
  useEffect(() => {
    if (counts[(PtDashboardFilters.VideoVisitUpcoming as T)] == 0 && counts[(PtDashboardFilters.VideoVisitIncomplete as T)] > 0 && !videoTabchangedChanged && !timeoutId) {
      videoTabchangedEvent.current = setTimeout(() => {
        setSelectedDashboardFilter(PtDashboardFilter[PtDashboardFilters.VideoVisitIncomplete]);
      }, 300);
      return () => {
        if (videoTabchangedEvent.current) {
          clearTimeout(videoTabchangedEvent.current);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counts]);
  useEffect(() => {
    if (timeoutId) {
      if (videoTabchangedEvent.current) {
        clearTimeout(videoTabchangedEvent.current);
      }
      setvideoTabchangedchanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutId]);
  useEffect(() => {
    if (dashboardType === DashboardType.PhysicalTherapist) {
      if ([PtDashboardFilters.HealthLogNotes, PtDashboardFilters.HealthLogNoResponse].includes(selectedPtDashboardFilter.filterLabel)) {
        if (selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME) {
          setTotalCount(counts[(PtDashboardFilters.HealthLogNoResponse as T)]);
          return setWorkflowCounts(counts[(PtDashboardFilters.HealthLogNoResponse as T)]);
        }
        return setWorkflowCounts(counts[(PtDashboardFilters.HealthLogNotes as T)]);
      } else if ([PtDashboardFilters.UnreadMessage, PtDashboardFilters.UnreadMessageNoResponse].includes(selectedPtDashboardFilter.filterLabel)) {
        if (selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME) {
          setTotalCount(counts[(ptLabel as T)]);
          let workflowCountToSet = counts[(ptLabel as T)];
          switch (selectedUnreadFilter) {
            case 'HLN':
              workflowCountToSet = totalmodularIAMLowPriority;
              break;
            case 'Messages':
              workflowCountToSet = totalUnreadLowPriority;
              break;
            case 'All Types':
              workflowCountToSet = totalAllTypesLowPriority;
              break;
            default:
              workflowCountToSet = counts[(ptLabel as T)];
              break;
          }
          return setWorkflowCounts(workflowCountToSet);
        } else {
          setTotalCount(counts[(ptLabel as T)]);
          let workflowCountToSet_0 = counts[(ptLabel as T)];
          switch (selectedUnreadFilter) {
            case 'HLN':
              workflowCountToSet_0 = iamMessageCountTotal;
              break;
            case 'Messages':
              workflowCountToSet_0 = messageCountTotal;
              break;
            case 'All Types':
              workflowCountToSet_0 = allTypesCountTotal;
              break;
            default:
              workflowCountToSet_0 = counts[(ptLabel as T)];
              break;
          }
          return setWorkflowCounts(workflowCountToSet_0);
        }

        //   return setWorkflowCounts(counts[PtDashboardFilters.UnreadMessage as T]);
      } else if (selectedPtDashboardFilter.filterTabName !== DEFAULT_COACH_DASHBOARD_TAB_NAME) {
        setWorkflowCounts(counts[(selectedPtDashboardFilter.filterLabel as T)]);
        setTotalCount(counts[(selectedPtDashboardFilter.filterLabel as T)]);
      } else {
        setWorkflowCounts(counts[(ptLabel as T)]);
        setTotalCount(counts[(ptLabel as T)]);
      }
    }
  }, [dashboardType, counts, ptLabel, selectedPtDashboardFilter.filterLabel, selectedPtDashboardFilter.filterTabName, setTotalCount, workflows, selectedUnreadFilter, iamMessageCountTotal, messageCountTotal, allTypesCountTotal, totalmodularIAMLowPriority, totalUnreadLowPriority, totalAllTypesLowPriority]);
  useEffect(() => {
    if (dashboardType === DashboardType.OnboardingSpecialist) {
      if ([OsDashboardFilters.UnreadMessage, OsDashboardFilters.UnreadMessageNoResponse].includes(selectedOsDashboardFilter.filterLabel)) {
        if (selectedOsDashboardFilter.filterTabName === OS_NO_RESPONSE_PREDICTED_TAB_NAME) {
          setTotalCount(counts[(OsDashboardFilters.UnreadMessageNoResponse as T)]);
          return setWorkflowCounts(counts[(OsDashboardFilters.UnreadMessageNoResponse as T)]);
        }
        return setWorkflowCounts(counts[(OsDashboardFilters.UnreadMessage as T)]);
      }
      return setWorkflowCounts(counts[(selectedOsDashboardFilter.filterLabel as T)]);
    }
  }, [dashboardType, counts, selectedOsDashboardFilter.filterLabel, selectedOsDashboardFilter.filterTabName, setTotalCount, workflows]);
  useBulkProgressTimeout(bulkHLNProgress, lowPriorityNotesSplitFlag, setShouldRefetchWorkflows, setBulkHLNProgress);
  useBulkProgressTimeout(bulkUnreadProgress, lowPriorityNotesSplitFlag, setShouldRefetchWorkflows, setUnreadProgress);
  let messageSnackbar = TASKS_LOAD_ERROR;
  if (selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.MemberHealthLog) {
    messageSnackbar = HEALTH_LOG_NOTES_LOAD_ERROR;
  }
  if (selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.UnreadMessages) {
    messageSnackbar = UNREAD_MESSAGES_LOAD_ERROR;
  }
  if (bulkHLNProgress && (healthLogNotesSplitFlag || ptHealthLogNotesDualTabFlag) || bulkUnreadProgress && (unreadMessagesDualTabTreatment || ptUnreadMessagesDualTabFlag)) {
    messageSnackbar = TASKS_BEING_UPDATED;
  }
  const handleOnPageChange = (_event: unknown, newPage: number): void => {
    let setFilter;
    if (dashboardType === DashboardType.Coach) {
      setFilter = setSelectedCoachDashboardFilter;
    } else if (dashboardType === DashboardType.PhysicalTherapist) {
      setFilter = setSelectedPtDashboardFilter;
    } else {
      setFilter = setSelectedOsDashboardFilter;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFilter((prevFilter: any) => ({
      ...prevFilter,
      filterPageNumber: newPage
    }));
    setvideoTabchangedchanged(true);
  };
  let pageNumber;
  if (dashboardType === DashboardType.Coach) {
    pageNumber = selectedCoachDashboardFilter.filterPageNumber;
  } else if (dashboardType === DashboardType.PhysicalTherapist) {
    pageNumber = selectedPtDashboardFilter.filterPageNumber;
  } else {
    pageNumber = selectedOsDashboardFilter.filterPageNumber;
  }
  const WorkflowsTableBody = (): JSX.Element => {
    if (error) {
      return <React.Fragment>
          <WorkflowsTableError />
          <HHSnackbarAlert severity="error" message={dashboardType === DashboardType.Coach ? messageSnackbar : TASKS_LOAD_ERROR} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={openSnackbar} onClose={handleSnackbar} />
        </React.Fragment>;
    } else if (workflowListLoadingState) {
      return <HHBox sx={workflowsTableStyles.spinnerStyle}>
          <HHCircularProgress data-testid="hh-circular-progress" />
        </HHBox>;
    } else {
      return <HHTableContainer>
          <HHTable sx={[CoachDashboardFilters.MemberHealthLog, CoachDashboardFilters.UnreadMessages].includes(selectedCoachDashboardFilter.filterLabel) || [PtDashboardFilters.HealthLogNotes, PtDashboardFilters.UnreadMessage].includes(selectedPtDashboardFilter.filterLabel) || [OsDashboardFilters.UnreadMessage].includes(selectedOsDashboardFilter.filterLabel) ? lowPriorityStyles : {}}>
            <WorkflowsTableContainer workflows={workflows ?? []} />
            {showPagination && <WorkflowsPagination workflowCounts={workflowCounts} pageNumber={pageNumber} onPageChange={handleOnPageChange} />}
          </HHTable>
        </HHTableContainer>;
    }
  };
  const generateWorkflowBody = (): JSX.Element => {
    if (dashboardType === DashboardType.Coach) {
      if (selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.MemberHealthLog) {
        return <HHBox>
            <HealthLogNotesTabs tableContent={<WorkflowsTableBody />} />
            {bulkHLNProgress && !showTimeOutErr && <TaskUpdating />}
          </HHBox>;
      }
      if (coachCallFilters.includes(selectedCoachDashboardFilter.filterLabel)) {
        return <HHBox>
            <CoachCallsTabs tableContent={<WorkflowsTableBody />} />
          </HHBox>;
      }
      if (selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.UnreadMessages && unreadMessagesDualTabTreatment) {
        return <HHBox>
            <UnreadMessagesTabs tableContent={<WorkflowsTableBody />} />
            {bulkUnreadProgress && !showTimeOutErr && <TaskUpdating />}
          </HHBox>;
      }
      if (coachVideoVisitFilters.includes(selectedCoachDashboardFilter.filterLabel)) {
        return <>
            <HHBox sx={workflowsTableStyles.titleContainer}>
              <HHTypography hhVariant="variant-bypass" sx={workflowsTableStyles.titleTypography}>
                {ptLabel}
              </HHTypography>
            </HHBox>
            <HHBox sx={workflowsTableStyles.bodyContainerTabs}>
              <HHDivider />
              <CoachVideoVisitTabs tableContent={<WorkflowsTableBody />} />
            </HHBox>
          </>;
      }
    }
    if (dashboardType === DashboardType.PhysicalTherapist) {
      if (videoVisitFilters.includes(selectedPtDashboardFilter.filterLabel)) {
        return <>
            <HHBox sx={workflowsTableStyles.titleContainer}>
              <HHTypography hhVariant="variant-bypass" sx={workflowsTableStyles.titleTypography}>
                {ptLabel}
              </HHTypography>
            </HHBox>
            <HHBox sx={workflowsTableStyles.bodyContainerTabs}>
              <HHDivider />
              <VideoVisitTabs tableContent={<WorkflowsTableBody />} />
            </HHBox>
          </>;
      }
      if (houseCallFilters.includes(selectedPtDashboardFilter.filterLabel)) {
        const tabsProps = getHouseCallTabsProps(<WorkflowsTableBody />);
        return <>
            <HHBox sx={workflowsTableStyles.titleContainer}>
              <HHTypography hhVariant="variant-bypass" sx={workflowsTableStyles.titleTypography}>
                {ptLabel}
              </HHTypography>
            </HHBox>
            <HHBox sx={workflowsTableStyles.bodyContainer}>
              <HHDivider />
              <WorkflowTableTabs {...tabsProps} />
            </HHBox>
          </>;
      }
      if (healthLogFilters.includes(selectedPtDashboardFilter.filterLabel) && ptHealthLogNotesDualTabFlag) {
        return <>
            <HHBox sx={workflowsTableStyles.bodyContainerTabs}>
              <PtHealthLogNotesTabs tableContent={<WorkflowsTableBody />} />
              {bulkHLNProgress && !showTimeOutErr && <TaskUpdating />}
            </HHBox>
          </>;
      }
      if (unreadMessageFilters.includes(selectedPtDashboardFilter.filterLabel) && ptUnreadMessagesDualTabFlag) {
        return <>
            <HHBox sx={workflowsTableStyles.bodyContainerTabs}>
              <PtUnreadMessagesTabs tableContent={<WorkflowsTableBody />} />
              {bulkUnreadProgress && !showTimeOutErr && <TaskUpdating />}
            </HHBox>
          </>;
      }
    }
    if (dashboardType === DashboardType.OnboardingSpecialist) {
      if (selectedOsDashboardFilter.filterLabel === OsDashboardFilters.UnreadMessage && unreadMessagesDualTabTreatment) {
        return <HHBox>
            <OSUnreadMessagesTabs tableContent={<WorkflowsTableBody />} />
            {bulkUnreadProgress && !showTimeOutErr && <TaskUpdating />}
          </HHBox>;
      }
    }
    let label;
    if (dashboardType === DashboardType.Coach) {
      label = selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.All ? COACH_WORKFLOWS_TABLE.SECTION_TITLE : selectedCoachDashboardFilter.filterLabel;
    } else if (dashboardType === DashboardType.PhysicalTherapist) {
      label = ptLabel;
    } else {
      label = osLabel;
    }
    return <>
        <HHBox sx={workflowsTableStyles.titleContainer}>
          <HHTypography hhVariant="variant-bypass" sx={workflowsTableStyles.titleTypography}>
            {label}
          </HHTypography>
        </HHBox>
        <HHBox sx={workflowsTableStyles.bodyContainer}>
          <HHDivider />
          <WorkflowsTableBody />
        </HHBox>
      </>;
  };
  return <HHBox data-testid={COACH_LAYOUT.WORKFLOWS_TABLE_CONTAINER_ID} sx={workflowsTableStyles.wrapper}>
      {generateWorkflowBody()}
    </HHBox>;
};