import React from 'react';
import { useLocation } from 'react-router-dom';
type QueueQueryParams = {
  workflowId: string;
  workflowType: string;
};
export default function useQueueQueryParams(): QueueQueryParams {
  const {
    search
  } = useLocation();
  return React.useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    const qid = urlSearchParams.get('qid') ?? '';
    const workflowType = urlSearchParams.get('workflowType') ?? '';
    const workflowId = urlSearchParams.get('workflowId') ?? '';
    // for backward compatibility and sidebar workflows
    if (!qid) {
      return {
        workflowType,
        workflowId
      };
    }
    try {
      const decodedParams = new URLSearchParams(atob(qid));
      return {
        workflowType: decodedParams.get('workflowType') ?? '',
        workflowId: decodedParams.get('workflowId') ?? ''
      };
    } catch (e) {
      return {
        workflowType,
        workflowId
      };
    }
  }, [search]);
}