import { c as _c } from "react-compiler-runtime";
import { gql, MutationFunctionOptions, MutationTuple, OperationVariables, useMutation } from '@apollo/client';
import { PostGenerationResponse } from '../types';
export type GenerateAiContentForUpcomingWorkflowsMutation = {
  __typename?: 'Mutation';
  generateAIContentForUpcomingWorkflows: PostGenerationResponse;
};
export const GENERATE_AI_CONTENT_FOR_UPCOMING_WORKFLOWS = gql`
  mutation generateAIContentForUpcomingWorkflows(
    $input: PostGenerationInputDto!
  ) {
    generateAIContentForUpcomingWorkflows(input: $input) {
      uuid
    }
  }
`;
export const useGenerateAiContentForUpcomingWorkflows = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useMutation(GENERATE_AI_CONTENT_FOR_UPCOMING_WORKFLOWS, options);
};