export enum LoginErrorStatus {
  GOOGLE_ERROR = 'google',
  AUTHENTICATION_ERROR = 'auth',
  SERVER_ERROR = 'server',
}
export enum OktaLocalStorageKeys {
  OktaOriginalUriStorage = 'okta-original-uri-storage',
  OktaCacheStorage = 'okta-cache-storage',
  OktaSharedTransactionStorage = 'okta-shared-transaction-storage',
  OktaTokenStorage = 'okta-token-storage',
}
export const PhiFieldsMaskingKey = 'is-phi-fields-masked';
export const PhiFieldsMaskingStatusChangedEvent = 'phi-fields-masking-status-changed';
export const PhiFieldsMaskingProgressKey = 'phi-masking-progress-key';