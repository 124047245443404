import { PriorityStates } from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
export const getDueState = (isPastDue?: boolean | null, latestTaskDate: string | null = ''): PriorityStates => {
  if (isPastDue || dayjs(latestTaskDate).isBefore(dayjs())) {
    return PriorityStates.Overdue;
  } else if (dayjs(latestTaskDate).isToday()) {
    return PriorityStates.DueSoon;
  } else {
    return PriorityStates.Default;
  }
};