export const TASK_RESOLVE_LABEL = {
  USER_TRANSITIONED: 'Offered pathway change',
  DISMISS: 'Dismiss'
};
export enum MaternityMilestonesTaskType {
  OneWeekPrePartum = '1-week-pre-partum',
  FiveWeeksPostPartum = '5-weeks-post-partum',
  TwelveWeeksPostPartum = '12-weeks-post-partum',
}
export const MATERNITY_MILESTONES_TASK_NAME: Record<MaternityMilestonesTaskType, string> = {
  [MaternityMilestonesTaskType.OneWeekPrePartum]: '1 week to due date',
  [MaternityMilestonesTaskType.FiveWeeksPostPartum]: '5 weeks postpartum',
  [MaternityMilestonesTaskType.TwelveWeeksPostPartum]: '12 weeks postpartum'
};
export const MATERNITY_MILESTONES_TASK_DESCRIPTION: Record<MaternityMilestonesTaskType, string> = {
  [MaternityMilestonesTaskType.OneWeekPrePartum]: 'Wish member well',
  [MaternityMilestonesTaskType.FiveWeeksPostPartum]: 'Prep member for OB postpartum visit',
  [MaternityMilestonesTaskType.TwelveWeeksPostPartum]: 'Help member transition to a new pathway'
};