import { c as _c } from "react-compiler-runtime";
import { HHTabData, HHTabs } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { PtDashboardFilter } from '../../../constants/pt-dashboard';
import { selectAllPTWorkflowAtom, selectedPtDashboardFilters } from '../../../jotai/atoms';
import { PtDashboardFilters } from '../../../types/pt';
export interface WorkflowTableTabsProps {
  initialFilter: PtDashboardFilters;
  tabFilters: PtDashboardFilters[];
  defaultTabData: HHTabData[];
}
export const WorkflowTableTabs: React.FC<WorkflowTableTabsProps> = t0 => {
  const $ = _c(19);
  const {
    initialFilter,
    tabFilters,
    defaultTabData
  } = t0;
  const [selectedDashboardFilter, setSelectedDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [, setAllPTWorkflowFlag] = useAtom(selectAllPTWorkflowAtom);
  const [tabIndex, setTabIndex] = useState(0);
  let t1;
  if ($[0] !== selectedDashboardFilter.filterTabName || $[1] !== initialFilter) {
    t1 = () => {
      if (selectedDashboardFilter.filterTabName === PtDashboardFilter[initialFilter].filterTabName) {
        setTabIndex(0);
      } else {
        setTabIndex(1);
      }
    };
    $[0] = selectedDashboardFilter.filterTabName;
    $[1] = initialFilter;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  let t2;
  if ($[3] !== selectedDashboardFilter || $[4] !== initialFilter) {
    t2 = [selectedDashboardFilter, initialFilter];
    $[3] = selectedDashboardFilter;
    $[4] = initialFilter;
    $[5] = t2;
  } else {
    t2 = $[5];
  }
  useEffect(t1, t2);
  let t3;
  let t4;
  if ($[6] !== setSelectedDashboardFilter || $[7] !== initialFilter) {
    t3 = () => {
      setSelectedDashboardFilter(PtDashboardFilter[initialFilter]);
    };
    t4 = [setSelectedDashboardFilter, initialFilter];
    $[6] = setSelectedDashboardFilter;
    $[7] = initialFilter;
    $[8] = t3;
    $[9] = t4;
  } else {
    t3 = $[8];
    t4 = $[9];
  }
  useEffect(t3, t4);
  let t5;
  if ($[10] !== tabFilters || $[11] !== setSelectedDashboardFilter || $[12] !== setAllPTWorkflowFlag) {
    t5 = tabIndex_0 => {
      const selectedTabFilter = tabFilters[tabIndex_0];
      setSelectedDashboardFilter(PtDashboardFilter[selectedTabFilter]);
      setAllPTWorkflowFlag(false);
    };
    $[10] = tabFilters;
    $[11] = setSelectedDashboardFilter;
    $[12] = setAllPTWorkflowFlag;
    $[13] = t5;
  } else {
    t5 = $[13];
  }
  const handleTabChange = t5;
  let t6;
  if ($[14] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = {
      margin: "2px"
    };
    $[14] = t6;
  } else {
    t6 = $[14];
  }
  let t7;
  if ($[15] !== defaultTabData || $[16] !== handleTabChange || $[17] !== tabIndex) {
    t7 = <HHTabs layoutStyles={t6} tabsData={defaultTabData} onTabChange={handleTabChange} defaultTabIndex={tabIndex} />;
    $[15] = defaultTabData;
    $[16] = handleTabChange;
    $[17] = tabIndex;
    $[18] = t7;
  } else {
    t7 = $[18];
  }
  return t7;
};