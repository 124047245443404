import * as Sentry from '@sentry/browser';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { Resource } from 'i18next';
import { AppEnv } from '../constants/env';
export const SUPPORTED_LANGUAGES = (['en-US', 'es-US'] as const);
export const getAllTranslationFiles = (): Array<string> => {
  let files = [];
  const moduleFiles = require.context('../modules', true, /translations\.json$/).keys();
  const pageFiles = require.context('../pages', true, /translations\.json$/).keys();
  files = [...moduleFiles, ...pageFiles];
  return files;
};
export const getLocalizedTranslationFile = (namespace: string, language: string): Record<string, string> => {
  let translationFiles = {};
  let pagesFiles = {};
  let moduleFiles = {};
  if (namespace.includes('globals')) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    pagesFiles = require(`../pages/globals/locales/${language}/translations.json`);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    moduleFiles = require(`../modules/${namespace}/locales/${language}/translations.json`);
  }
  translationFiles = {
    ...moduleFiles,
    ...pagesFiles
  };
  return translationFiles;
};
const translationConfig: Record<string, string> = {
  dev: 'https://cdn.hingehealth.dev',
  stage: 'https://cdn.hingehealth.io',
  production: 'https://cdn.hingehealth.com'
};
const getTranslationsBase = (): string => {
  switch (process.env.APP_ENV) {
    case AppEnv.Production:
      return translationConfig.production;
    case AppEnv.Stage:
      return translationConfig.stage;
    default:
      return translationConfig.dev;
  }
};
axiosRetry(axios, {
  retries: 3,
  retryDelay: (...args) => axiosRetry.exponentialDelay(...args, 200),
  retryCondition: axiosRetry.isRetryableError
});
async function getTranslatedResource(language: string, translationUrl: string): Promise<Resource> {
  const resource: Resource = {
    [language]: {}
  };
  const translation = await axios.get(translationUrl).catch(error => {
    if (error.code !== 'ECONNABORTED') {
      Sentry.captureMessage(`Error fetching exercise translations for ${language} from ${translationUrl}: ${error}`);
    }
  });
  resource[language] = translation?.data || {};
  return resource;
}
export const getExerciseTranslationURL = (language: string): string => {
  const base = getTranslationsBase();
  return `${base}/localized-text/exercises/v1/${language}.json`;
};
export const getPatientSpecificFunctionalScaleTranslationURL = (language: string): string => {
  const base = getTranslationsBase();
  return `${base}/localized-text/patient-specific-functional-scale/v1/${language}.json`;
};
export const getExerciseResourceByLanguage = async (language: string): Promise<Resource> => {
  const translationUrl = getExerciseTranslationURL(language);
  return getTranslatedResource(language, translationUrl);
};
export const getPatientSpecificFunctionalScaleResource = async (language: string): Promise<Resource> => {
  const translationUrl = getPatientSpecificFunctionalScaleTranslationURL(language);
  return getTranslatedResource(language, translationUrl);
};