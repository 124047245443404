import { OktaRole } from '../types/okta-roles';
export const getAdminRole = (adminRoles: OktaRole[]): OktaRole | string => {
  let role = '';
  if (adminRoles) {
    const validRoles = Object.values(OktaRole);
    const knownRoles = adminRoles.filter((userRole: OktaRole) => validRoles.some(roleValue => userRole === roleValue));
    if (knownRoles && knownRoles.length) {
      role = knownRoles[0];
    }
  }
  return role;
};