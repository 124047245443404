import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { HOUR_FORMAT, TIME_FORMAT } from '../constants/dates';
import { VIDEO_VISIT_15_MIN_TYPE, VIDEO_VISIT_MAP, VIDEO_VISIT_TYPES } from '../constants/strings/acute';
import { VideoVisitsTasksCategoryDetails } from '../contexts/get-workflow.context';
import { AcuteDecoratedWorkflow, AcuteGroupType, FollowupVideoVisitsTasksByCategory, VideoVisitsState, VideoVisitsTasksByCategory, VideoVisitsTasksCategory, VideoVisitsTaskType, VideoVisitsWithoutMedicalHistory, VIDEO_VISITS_OPTIONAL_TASKS, VIDEO_VISIT_WORKFLOWS } from '../custom-types';
import { ApplicationPathway, BaseUser, CarePlan, CarePlanStatus, Maybe, Task, WorkflowPayload } from '../types';
import { capitalizeFirstLetter } from './capitalize-first-letter';
import { createFullName } from './full-name';
import { hasSelection } from './handle-task-update';
dayjs.extend(isToday);
export const VIDEO_VISIT_TYPE = 'video_visit';
export function getVideoVisitsType(carePlans: Maybe<CarePlan[]> | undefined, applicationPathway: Maybe<ApplicationPathway> | undefined, workflowType: string | undefined): string {
  if (workflowType === VideoVisitsWithoutMedicalHistory.FifteenMinute) {
    return VIDEO_VISIT_15_MIN_TYPE;
  }
  let videoVisitStatus = '';
  const program = capitalizeFirstLetter(applicationPathway?.program || '');
  const indication = capitalizeFirstLetter(applicationPathway?.indication || '');
  if (carePlans) {
    const activeCarePlan = carePlans?.find(carePlan => carePlan.status === CarePlanStatus.Open);
    const videoVisitItem = activeCarePlan?.items?.find(item => item.key === VIDEO_VISIT_TYPE);
    if (videoVisitItem) {
      videoVisitStatus = videoVisitItem.currentValue;
    }
  }
  return VIDEO_VISIT_MAP[videoVisitStatus] ? `${VIDEO_VISIT_MAP[videoVisitStatus]}, ${program} ${indication}` : getIndication(applicationPathway);
}
export function getVideoVisitsTypeByFollowups(followups: number, applicationPathway: Maybe<ApplicationPathway> | undefined, workflowType: string | undefined): string {
  if (workflowType === VideoVisitsWithoutMedicalHistory.FifteenMinute) {
    return VIDEO_VISIT_15_MIN_TYPE;
  }
  const program = capitalizeFirstLetter(applicationPathway?.program || '');
  const indication = capitalizeFirstLetter(applicationPathway?.indication || '');
  return VIDEO_VISIT_TYPES[followups] ? `${VIDEO_VISIT_TYPES[followups]}, ${program} ${indication}` : getIndication(applicationPathway);
}
export function getVideoVisitsPatientDetails(patient: Maybe<BaseUser> | undefined): string {
  return patient ? `${createFullName(patient)}, ${patient.id}` : '';
}
export function getVideoVisitsDueSoon(callDateParam: Maybe<string> | undefined): string {
  const callDate = dayjs(callDateParam || undefined);
  if (callDate.isToday() && callDate.isAfter(dayjs())) {
    if (callDate.minute() === 30) {
      return callDate.format(TIME_FORMAT);
    }
    return callDate.format(HOUR_FORMAT);
  }
  return dayjs().to(callDate);
}
export function getIndication(pathway: Maybe<ApplicationPathway> | undefined): string {
  return capitalizeFirstLetter(`${pathway?.program ? pathway.program + ' ' : ''}${pathway?.indication ? pathway.indication : ''}`);
}
export function isVideoVisitsWorkflowType(workflow: WorkflowPayload | AcuteDecoratedWorkflow | undefined): boolean {
  return (VIDEO_VISIT_WORKFLOWS as (string | undefined)[]).includes(workflow?.type);
}
const getVideoVisitTaskCategory = (state: VideoVisitsState, taskType: VideoVisitsTaskType): VideoVisitsTasksCategory | undefined => {
  switch (state) {
    case VideoVisitsState.PerformFollowUpVideoVisitFollowup:
      return FollowupVideoVisitsTasksByCategory[taskType];
    case VideoVisitsState.PerformVideoVisitFollowup:
      return VideoVisitsTasksByCategory[taskType];
  }
};
export function getVideoVisitsTasks(workflow: WorkflowPayload): Record<VideoVisitsTasksCategory, VideoVisitsTasksCategoryDetails> {
  const videoTasks: Record<VideoVisitsTasksCategory, VideoVisitsTasksCategoryDetails> = {
    [VideoVisitsTasksCategory.Asap]: {
      tasks: [],
      isComplete: false,
      dueDate: '',
      groupType: AcuteGroupType.Incomplete
    },
    [VideoVisitsTasksCategory.WithinDay]: {
      tasks: [],
      isComplete: false,
      dueDate: '',
      groupType: AcuteGroupType.Incomplete
    }
  };

  // Set tasks by category
  workflow?.tasks?.map((task: Task) => {
    const taskType = (task.type as VideoVisitsTaskType);
    const category = getVideoVisitTaskCategory((workflow?.state as VideoVisitsState), taskType);
    if (category) {
      videoTasks[category].tasks.push(task);
    }
  });

  // Set isComplete
  Object.keys(videoTasks).forEach(videoVisitsTasksCategory => {
    const category = videoTasks[(videoVisitsTasksCategory as VideoVisitsTasksCategory)];
    category.isComplete = category.tasks.every(task => VIDEO_VISITS_OPTIONAL_TASKS.includes((task.type as VideoVisitsTaskType)) || hasSelection(task.selection));
  });

  // Set dueDate
  videoTasks.ASAP.dueDate = workflow.customFields?.callDate || '';
  videoTasks[VideoVisitsTasksCategory.WithinDay].dueDate = videoTasks[VideoVisitsTasksCategory.WithinDay].isComplete ? '' : videoTasks[VideoVisitsTasksCategory.WithinDay].tasks.filter(task => VIDEO_VISITS_OPTIONAL_TASKS.includes((task.type as VideoVisitsTaskType)) || !hasSelection(task.selection))[0].dueDate || '';

  // Set groupType
  if (videoTasks.ASAP.dueDate && dayjs(videoTasks.ASAP.dueDate).isAfter(dayjs())) {
    videoTasks.ASAP.groupType = AcuteGroupType.Upcoming;
  }
  if (videoTasks[VideoVisitsTasksCategory.WithinDay].dueDate && dayjs(videoTasks[VideoVisitsTasksCategory.WithinDay].dueDate).isAfter(dayjs())) {
    videoTasks[VideoVisitsTasksCategory.WithinDay].groupType = AcuteGroupType.Upcoming;
  }
  return videoTasks;
}