/* eslint-disable @typescript-eslint/naming-convention */

import { ctpTokens, defaultCtpTheme } from '@hinge-health/react-component-library';
import { MEMBER_ALERT_THEME } from './theme';
export const BORDER_BOTTOM = '0.5px solid #b4bbbf';
export const MEMBER_ALERT_FORM_BACKGROUND_COLOR = ctpTokens.palette.neutral[99];
export const LIGHT_GRAY_BACKGROUND = ctpTokens.palette.neutral[95];
export const MEDIUM_GRAY_BACKGROUND = ctpTokens.palette.neutral[90];
export const WHITE_BACKGROUND = ctpTokens.palette.neutral[100];
export const BLACK_BACKGROUND = ctpTokens.palette.neutral[0];
export const GREY_BACKGROUND = ctpTokens.palette.neutral[20];
export const LIGHT_RED = ctpTokens.palette.peach[95];
export const NONE = 'none';
export const FILTER_DROPDOWN_STYLES = {
  width: '220px',
  marginRight: '16px',
  padding: '4px 0 8px'
};
export const CLOSE_ICON_STYLES = {
  cursor: 'pointer',
  padding: '6px',
  borderRadius: '26px',
  '&:hover': {
    backgroundColor: MEMBER_ALERT_FORM_BACKGROUND_COLOR
  }
};
export const BANNER_FORM_CONTAINER_STYLES = {
  borderBottom: BORDER_BOTTOM,
  backgroundColor: MEMBER_ALERT_FORM_BACKGROUND_COLOR,
  width: '100%',
  padding: '16px 16px 0'
};
export const BANNER_FORM_INNER_CONTAINER_STYLES = {
  padding: '0 0 16px 0'
};
export const BANNER_OPTION_HOVER_STYLES = {
  '&:hover': {
    color: WHITE_BACKGROUND,
    backgroundColor: GREY_BACKGROUND,
    borderColor: `${GREY_BACKGROUND} !important`
  }
};
export const BANNER_OPTION_COMMON_STYLES = {
  minWidth: '36px',
  borderRadius: '16px',
  textTransform: 'none'
};
export const BANNER_OPTION_ALL_BUTTON_STYLES = {
  backgroundColor: WHITE_BACKGROUND,
  borderWidth: '0px',
  borderColor: '#BDBFBB !important',
  color: BLACK_BACKGROUND,
  ...BANNER_OPTION_COMMON_STYLES,
  ...BANNER_OPTION_HOVER_STYLES
};
export const BANNER_OPTION_ACTIVE_AND_UPCOMING_BUTTON_STYLES = {
  backgroundColor: BLACK_BACKGROUND,
  borderWidth: '1px',
  color: WHITE_BACKGROUND,
  borderColor: `${BLACK_BACKGROUND} !important`,
  ...BANNER_OPTION_COMMON_STYLES,
  ...BANNER_OPTION_HOVER_STYLES
};
export const MEMBER_ALERTS_FILTER_ICON_STYLES = {
  padding: '8px',
  margin: '0 24px',
  cursor: 'pointer',
  color: ctpTokens.palette.neutral[40],
  backgroundColor: 'none',
  '&:hover': {
    color: ctpTokens.palette.neutral[10],
    backgroundColor: LIGHT_GRAY_BACKGROUND
  }
};
export const MEMBER_ALERTS_FILTER_ICON_STYLES_ACTIVE = {
  ...MEMBER_ALERTS_FILTER_ICON_STYLES,
  color: ctpTokens.palette.neutral[10],
  backgroundColor: LIGHT_GRAY_BACKGROUND
};
export const MEMBER_ALERT_BANNER_BUTTON_STYLES = {
  padding: '0 8px'
};
export const MEMBER_ALERT_BANNER_GRID_STYLES = {
  alignItems: 'center'
};
export const MEMBER_ALERT_BANNER_OPTION_CONTAINER_STYLES = {
  padding: '16px 0'
};
export const MEMBER_ALERT_ERROR_BANNER_STYLES = {
  background: LIGHT_RED,
  padding: '6px 16px',
  alignItems: 'center',
  marginBottom: '16px'
};
export const MEMBER_ALERT_FORM_BANNER_ERROR_STYLES = {
  padding: '14px'
};
export const MEMBER_ALERT_DESCRIPTION_TEXTFIELD_STYLES = {
  padding: '12px 0px 8px 0px',
  width: '100%'
};
export const MEMBER_ALERT_BANNER_GRID_CONTAINER_STYLES = {
  justifyContent: 'space-between',
  alignItems: 'center'
};
export const MEMBER_ALERT_PAGE_STYLES = {
  paddingX: '32px',
  paddingTop: '32px'
};
export const MEMBER_ALERT_FORM_CATEGORY_SELECT_STYLES = {
  padding: '8px 0px',
  width: '100%'
};
export const MEMBER_ALERT_FORM_OPTIONAL_CONTAINER_STYLES = {
  alignItems: 'center',
  padding: '16px 0 8px'
};
export const MEMBER_ALERT_FORM_REMINDER_CHECKBOX_STYLES = {
  paddingLeft: '10px'
};
export const MEMBER_ALERT_FORM_PAUSE_CHECKBOX_STYLES = {
  padding: '16px 10px 8px'
};
export const MEMBER_ALERT_GRID_CATEGORY_EXPIRED_STYLES = {
  color: defaultCtpTheme.palette.text.primary,
  marginBottom: '8px'
};
export const MEMBER_ALERT_GRID_CATEGORY_PAUSED_STYLES = {
  backgroundColor: ctpTokens.palette.red[80],
  color: ctpTokens.palette.neutral[0],
  marginBottom: '8px'
};
export const MEMBER_ALERT_GRID_CATEGORY_STYLES = {
  backgroundColor: ctpTokens.palette.sand[70],
  color: defaultCtpTheme.palette.text.primary,
  marginBottom: '8px'
};
export const MEMBER_ALERT_GRID_CATEGORY_ICON_STYLES = {
  color: ctpTokens.palette.neutral.shades.primary['50p']
};
export const MEMBER_ALERT_GRID_DATES_STYLES = {
  color: MEMBER_ALERT_THEME.COLOR.TEXT.SECONDARY,
  wordWrap: 'break-word'
};
export const EDIT_MEMBER_ALERT_HOVER_STYLES = {
  boxShadow: 0,
  border: 0,
  '.MuiDataGrid-columnHeaders': {
    border: 'none'
  },
  '.member-alert-row': {
    '&:hover': {
      '.edit-member-alert-icon': {
        display: 'block',
        cursor: 'pointer'
      }
    },
    '.MuiDataGrid-cell:focus': {
      outline: 'none',
      border: 'none'
    },
    '.MuiDataGrid-cell:first-child': {
      paddingLeft: 0
    },
    '[data-field="Description"]': {
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'scroll !important',
      div: {
        minWidth: 'inherit',
        maxWidth: 'inherit',
        maxHeight: 'inherit !important',
        p: {
          overflowWrap: 'break-word'
        }
      }
    },
    '.MuiChip-root': {
      '.MuiSvgIcon-root': {
        marginRight: 0
      },
      '.MuiChip-label': {
        textOverflow: 'unset'
      }
    }
  }
};
export const MEMBER_ALERT_DATA_GRID_STYLES = {
  fontFamily: MEMBER_ALERT_THEME.FONT_FAMILY,
  flexGrow: 1
};
export const MEMBER_ALERT_DATE_PICKER_CONTAINER_STYLES = {
  padding: '0px 0 16px',
  '& >div:first-child': {
    minWidth: '82%',
    '>div:first-child': {
      marginTop: 2
    }
  }
};
export const MEMBER_ALERT_DATE_PICKER_INNER_CONTAINER_STYLES = {
  padding: '6px 0 16px'
};
export const MEMBER_ALERT_DATE_PICKER_START_DATE_STYLES = {
  paddingRight: '5px'
};
export const MEMBER_ALERT_DATE_PICKER_BETWEEN_STYLES = {
  padding: '10px 16px',
  alignItems: 'center'
};
export const MEMBER_ALERT_DATE_PICKER_END_DATE_STYLES = {
  paddingLeft: '5px'
};