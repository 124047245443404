import { useApolloClient } from '@apollo/client';
import { ctpTokens, HHDrawer, HHGlobalNav, HHIconButton, HHItemNavLinkProps, HHNavBackgroundBarProps, HHPopover, useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { AppsRounded, Feedback, Logout } from '@mui/icons-material';
import { CustomUserClaim } from '@okta/okta-auth-js';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CTT_URLS, LEGACY_NAVIGATION_LINK_NAMES, NAVIGATION_LINK_NAMES } from '../constants';
import { DROP_SHADOW } from '../constants/colors/colors';
import { SESSION_ID, TIME_SPENT_ON_MEMBER } from '../constants/strings/member';
import { NAVIGATION_TOOLTIPS } from '../constants/strings/navigation-items';
import { FRESHDESK_FEEDBACK_TREATMENT, JIRA_FEEDBACK_TREATMENT, NAV_SPLIT_TREATMENTS, ON } from '../constants/strings/split';
import { COACH_CATEGORY_NAME_ARRAY_INDEX, OS_CATEGORY_NAME_ARRAY_INDEX, PT_CATEGORY_NAME_ARRAY_INDEX, QUEUE_TOOLTIPS } from '../constants/strings/workflows-queue';
import { coachQueueWorkflowCategories, CoachWorkflowTypes, encounterWorkflowTypes, osQueueWorkflowCategories, ptQueueWorkflowCategories, PtWorkflowTypes, PT_VIDEO_VISIT_LABEL, QueueCategoryTypeIndex, WorkflowCategoryTypes, WorkflowTypes } from '../custom-types';
import { useCurrentStoredAdminHook } from '../hooks/use-current-stored-admin';
import { useGenericMixpanelTrack } from '../hooks/use-generic-mixpanel';
import { useGetIncompleteQueueCounts } from '../hooks/use-get-incomplete-queue-counts';
import useQueueQueryParams from '../hooks/use-queue-query-params';
import { NextButtonClicked } from '../lib/mixpanel/events/workflows';
import { MemberRecordExitProps, MixpanelPropertiesContext } from '../lib/mixpanel/types/generic-mixpanel-event-types';
import { SPLIT_TREATMENTS } from '../modules/mass-message/constants/strings/split';
import { MEMBER_SEARCH_SPLIT } from '../modules/member-360/components/activity/constants/splits';
import { CurrentMemberSessionIdContext } from '../modules/member-360/components/profile/session-manager';
import { SIMPLIFIED_WORKFLOWS } from '../modules/member-360/constants/splits';
import { SPLIT_TREATMENTS as MEMBER_360_SPLIT_TREATMENTS } from '../modules/member-360/modules/member-alerts/constants/splits';
import { GET_USER_INFO } from '../modules/member-360/queries/user.graphql';
import { SEARCH_MIXPANEL_EVENTS } from '../modules/member-search/constants/strings/member-search';
import { WORKFLOW_LIST_HEADER_TITLE } from '../modules/sidebar/constants/strings/workflow-list';
import { useShellContext } from '../modules/sidebar/contexts/shell.context';
import { PORTAL_IFRAME_WIDTH_WITH_SIDEBAR_CLOSED } from '../modules/sidebar/types/sidebar.types';
import { portalIframeSrcAtom, portalIframeTitleAtom, portalIframeWidthAtom } from '../state/portal-atoms';
import { coachWorkflowIdMapsByQueueCategoryAtom, currentQueueCategoryIndexAtom, currentQueueTabIndexAtom, currentQueueWorkflowIdAtom, fetchFirstPageCurrentCategoryAtom, fetchMoreCurrentWorkflowIdAtom, lastWorkflowInCategoryAtom, osWorkflowIdMapsByQueueCategoryAtom, ptWorkflowIdMapsByQueueCategoryAtom, queueComplete, queueDisplay, QueueWorkflowIdentifiers, rolesAtom, Sentinels, tempCurrentQueueCategoryIndexAtom, tempQueueWorkflowIdAtom, workflowCountsByQueueCategoryAtom } from '../state/queue-atoms';
import { OktaRole } from '../types/okta-roles';
import { OktaLocalStorageKeys, PhiFieldsMaskingKey } from '../types/types';
import { getLocalStorageItem } from '../utils/local-storage-utils';
import { trackEvent } from '../utils/mixpanel';
import hasPHIAccess from '../utils/phi-acess';
import { getRoleBasedNavItems } from '../utils/role-based-navigation-items';
import { FeedbackDialog } from './navigation-components/feedback-dialog/feedback-dialog';
import { FreshdeskDialog } from './navigation-components/feedback-dialog/freshdesk-dialog';
import { PHIMaskingSwitchButton } from './navigation-components/phi-masking-switch-button';
import { QuickLinks } from './quick-links';
import { acceptedRoles, buildWorkflowUrl, getByOktaRole, getPHIMaskingTooltip } from './utils';
import { QueueCategoryAlert } from './workflows-queue-components/queue-category-alert';
import { WorkflowsQueue } from './workflows-queue/workflows-queue';
const styles = {
  // this will get removed when CTT gets sunsetted
  navIconLayout: {
    color: ctpTokens.palette.neutral[100]
  },
  navIcon: {
    padding: 0,
    '&.Mui-disabled': {
      opacity: 0.4,
      color: 'white'
    }
  },
  drawerPaper: {
    marginLeft: 14,
    boxShadow: DROP_SHADOW
  }
};
type NextQueueWorkflowInfo = {
  newWorkflowId: number;
  newCategoryIndex: number;
} | undefined;
export const MIXPANEL_TIMESTAMP_FORMAT = 'ddd, MMM DD, YYYY hh:mm A z';
const SideNavigation = (): JSX.Element => {
  const splitClient = useClient();
  const apolloClient = useApolloClient();
  const {
    endSession,
    currentMemberSessionId
  } = useContext(CurrentMemberSessionIdContext);
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const {
    adminProfile
  } = useShellContext();
  const {
    hingeHealthAuthClient,
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const setRoles = useSetAtom(rolesAtom);
  const adminHasPHIAccess = hasPHIAccess(hingeHealthAuthState?.accessToken?.accessToken);
  const adminRoles = (hingeHealthAuthState?.accessToken?.claims?.roles as CustomUserClaim[]);
  const genericMixpanelEvents = useGenericMixpanelTrack([MixpanelPropertiesContext.MemberContext]);
  useEffect(() => {
    if (adminRoles) {
      setRoles(adminRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hingeHealthAuthState]);
  const isSplitOnForAdminProfile = useCallback((splitFlag: string) => splitClient?.getTreatment(splitFlag, {
    adminUuid: adminProfile?.uuid || '*'
  }) === ON, [splitClient, adminProfile]);
  const feedbackSplitFlagForJira = useCallback((splitFlag_0: string) => splitClient?.getTreatment(splitFlag_0, {
    adminUuid: currentStoredAdmin?.uuid || '*'
  }) === JIRA_FEEDBACK_TREATMENT, [splitClient, currentStoredAdmin]);
  const feedbackSplitFlagForFreshdesk = useCallback((splitFlag_1: string) => splitClient?.getTreatment(splitFlag_1, {
    adminUuid: currentStoredAdmin?.uuid || '*'
  }) === FRESHDESK_FEEDBACK_TREATMENT, [splitClient, currentStoredAdmin]);
  const massMessagingSplitFlag = isSplitOnForAdminProfile(SPLIT_TREATMENTS.MASS_MESSAGE_LIST);
  const reengagerWorkflowTreatment = isSplitOnForAdminProfile(MEMBER_360_SPLIT_TREATMENTS.REENGAGER_WORKFLOW_MIGRATION);
  const firstWeekEnsoCheckInTreatment = isSplitOnForAdminProfile(MEMBER_360_SPLIT_TREATMENTS.FIRST_WEEK_ENSO_CHECK_IN_WORKFLOW_MIGRATION);
  const weeklyEnsoReviewTreatment = isSplitOnForAdminProfile(MEMBER_360_SPLIT_TREATMENTS.WEEKLY_ENSO_REVIEW_WORKFLOW_MIGRATION);
  const outreachNeededTreatment = isSplitOnForAdminProfile(MEMBER_360_SPLIT_TREATMENTS.OUTREACH_NEEDED_WORKFLOW_MIGRATION);
  const memberSearchTreatment = splitClient?.getTreatment(MEMBER_SEARCH_SPLIT, currentStoredAdmin?.uuid ? {
    adminUuid: currentStoredAdmin?.uuid
  } : undefined) === ON;
  const surgeryTrendWorkflowsTreatment = isSplitOnForAdminProfile(MEMBER_360_SPLIT_TREATMENTS.SURGERY_TREND_WORKFLOW_MIGRATION);
  const careCoordinationWorkflowPanelTreatment = isSplitOnForAdminProfile(MEMBER_360_SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT);
  const ptDashboardSplitFlag = isSplitOnForAdminProfile(NAV_SPLIT_TREATMENTS.PT_DASHBOARD);
  const runtimePHIMaskingSwitchButtonTreatment = isSplitOnForAdminProfile(NAV_SPLIT_TREATMENTS.PHI_MASKING_SWITCH_BUTTON);
  const ptWorkflowsQueueSplitFlag = isSplitOnForAdminProfile(NAV_SPLIT_TREATMENTS.PT_WORKFLOWS_QUEUE);
  const osOnboardingSplitFlag = isSplitOnForAdminProfile(NAV_SPLIT_TREATMENTS.OS_ONBOARDING);
  const feedbackSplitFlagJira = feedbackSplitFlagForJira(NAV_SPLIT_TREATMENTS.FEEDBACK_VENDOR);
  const feedbackSplitFlagFreshdesk = feedbackSplitFlagForFreshdesk(NAV_SPLIT_TREATMENTS.FEEDBACK_VENDOR);
  const mpeQueueSplitFlag = isSplitOnForAdminProfile(NAV_SPLIT_TREATMENTS.MPE_QUEUE);
  const redirectCttToCtpSplitFlag = isSplitOnForAdminProfile(NAV_SPLIT_TREATMENTS.REDIRECT_CTT_TO_CTP);
  const allOktaKeys = Object.values(OktaLocalStorageKeys);
  const location = useLocation();
  const currentLocation = location.pathname + location.search;
  const navigationItems = getRoleBasedNavItems((adminRoles as OktaRole[]), massMessagingSplitFlag, ptDashboardSplitFlag, memberSearchTreatment, ptWorkflowsQueueSplitFlag, osOnboardingSplitFlag, mpeQueueSplitFlag, redirectCttToCtpSplitFlag);
  const [showQuickLinksPopover, setShowQuickLinksPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [phiMaskingEnabled, setPhiMaskingEnabled] = useState(false);
  useEffect(() => {
    const phiMaskingEnabled_0 = localStorage?.getItem(PhiFieldsMaskingKey);
    if (phiMaskingEnabled_0 !== null) {
      setPhiMaskingEnabled(JSON.parse(phiMaskingEnabled_0));
    }
  }, []);
  const [toggleFeedbackDialog, setToggleFeedbackDialog] = useState<boolean>(false);
  const setPortalIframeSrc = useSetAtom(portalIframeSrcAtom);
  const setPortalIframeTitle = useSetAtom(portalIframeTitleAtom);
  const setPortalIframeWidth = useSetAtom(portalIframeWidthAtom);
  const [displayWorkflowQueue, setDisplayWorkflowQueue] = useAtom(queueDisplay);
  const [currentQueueCategoryIndex, setCurrentQueueCategoryIndex] = useAtom(currentQueueCategoryIndexAtom);
  const [currentQueueWorkflowId, setCurrentQueueWorkflowId] = useAtom(currentQueueWorkflowIdAtom);
  const [previousQueueWorkflowId, setPreviousQueueWorkflowId] = useState(0);
  const [tempQueueWorkflowId] = useAtom(tempQueueWorkflowIdAtom);
  const [tempQueueCategoryIndex] = useAtom(tempCurrentQueueCategoryIndexAtom);
  const resetTempQueueWorkflowId = useResetAtom(tempQueueWorkflowIdAtom);
  const resetTempCurrentQueueCategoryIndex = useResetAtom(tempCurrentQueueCategoryIndexAtom);
  const [lastWorkflowInCategory, setLastWorkflowInCategory] = useAtom(lastWorkflowInCategoryAtom);
  const [, setFetchMoreCurrentWorkflowId] = useAtom(fetchMoreCurrentWorkflowIdAtom);
  const tabIndexValue = useAtomValue(currentQueueTabIndexAtom);
  const [workflowCountsByCategory] = useAtom(workflowCountsByQueueCategoryAtom);
  const setFetchFirstPageCurrentCategory = useSetAtom(fetchFirstPageCurrentCategoryAtom);
  const setCurrentQueueTabIndex = useSetAtom(currentQueueTabIndexAtom);
  const [currentWorkflowIdentifier, setCurrentWorkflowIdentifier] = useState<QueueWorkflowIdentifiers | undefined>(undefined);
  const [nextWorkflowIdentifier, setNextWorkflowIdentifier] = useState<QueueWorkflowIdentifiers | undefined>(undefined);
  const coachWorkflowIdMaps = useAtomValue(coachWorkflowIdMapsByQueueCategoryAtom);
  const ptWorkflowIdMaps = useAtomValue(ptWorkflowIdMapsByQueueCategoryAtom);
  const osWorkflowIdMaps = useAtomValue(osWorkflowIdMapsByQueueCategoryAtom);
  const {
    totalCount
  } = useGetIncompleteQueueCounts();
  const [queueIsComplete] = useAtom(queueComplete);
  const navigate = useNavigate();
  const {
    workflowId,
    workflowType
  } = useQueueQueryParams();
  const isSplitOnForCurrentStoreAdmin = useCallback((splitFlag_2: string) => splitClient?.getTreatment(splitFlag_2, {
    adminUuid: currentStoredAdmin?.uuid || '*'
  }) === ON, [splitClient, currentStoredAdmin]);
  const workflowsQueueSplitOn = isSplitOnForCurrentStoreAdmin(NAV_SPLIT_TREATMENTS.WORKFLOWS_QUEUE);
  const threePanelLayoutTreatment = isSplitOnForCurrentStoreAdmin(NAV_SPLIT_TREATMENTS.THREE_PANEL_LAYOUT);
  const ptMemberAlertReminderSplitOn = isSplitOnForCurrentStoreAdmin(NAV_SPLIT_TREATMENTS.PT_MEMBER_ALERTS_REMINDERS);
  const videoVisitDeprecationTreatment = isSplitOnForCurrentStoreAdmin(NAV_SPLIT_TREATMENTS.CTT_DEPRECATION_VIDEO_VISIT);
  const setupActionPlanSplitOn = isSplitOnForCurrentStoreAdmin(NAV_SPLIT_TREATMENTS.SETUP_ACTION_PLAN_WORKFLOW);
  const indicationUpdateSplitOn = isSplitOnForCurrentStoreAdmin(NAV_SPLIT_TREATMENTS.INDICATION_UPDATE_WORKFLOW);
  const coachVideoVisitSplitOn = isSplitOnForCurrentStoreAdmin(NAV_SPLIT_TREATMENTS.COACH_VIDEO_VISIT);
  const isSimplifiedWorkflowsSplitOn = isSplitOnForCurrentStoreAdmin(SIMPLIFIED_WORKFLOWS);

  // Categories in the queue which are protected under split flag
  // If the split flag is OFF, we skip the category
  const treatments: {
    treatment: boolean;
    categories: Array<CoachWorkflowTypes | PtWorkflowTypes>;
  }[] = [{
    treatment: reengagerWorkflowTreatment,
    categories: [CoachWorkflowTypes.ReEngager]
  }, {
    treatment: surgeryTrendWorkflowsTreatment,
    categories: [PtWorkflowTypes.SurgeryTrend]
  }, {
    treatment: careCoordinationWorkflowPanelTreatment,
    categories: [PtWorkflowTypes.EtModification, PtWorkflowTypes.ClinicalEscalation, PtWorkflowTypes.MedicalEmergencyFollowUp, CoachWorkflowTypes.CoachEscalation]
  }, {
    treatment: firstWeekEnsoCheckInTreatment,
    categories: [PtWorkflowTypes.FirstWeekEnsoCheckIn]
  }, {
    treatment: weeklyEnsoReviewTreatment,
    categories: [CoachWorkflowTypes.WeeklyEnsoReview]
  }, {
    treatment: outreachNeededTreatment,
    categories: [CoachWorkflowTypes.OutreachNeeded]
  }, {
    treatment: ptMemberAlertReminderSplitOn,
    categories: [PtWorkflowTypes.PtMemberAlertReminder]
  }, {
    treatment: setupActionPlanSplitOn,
    categories: [PtWorkflowTypes.SetupActionPlan]
  }, {
    treatment: indicationUpdateSplitOn,
    categories: [PtWorkflowTypes.IndicationUpdate]
  }, {
    treatment: coachVideoVisitSplitOn,
    categories: [CoachWorkflowTypes.CoachVideoVisit]
  }];
  const categoriesToSkip = (): Array<WorkflowTypes> => {
    const categories: Array<WorkflowTypes> = [];
    treatments.forEach(({
      treatment,
      categories: categoryArray
    }) => {
      if (!treatment) {
        categories.push(...categoryArray);
      }
    });
    return categories;
  };
  useEffect(() => {
    if (!!workflowType) {
      const workflowCategories = getByOktaRole(coachQueueWorkflowCategories, osQueueWorkflowCategories, ptQueueWorkflowCategories, adminRoles);
      workflowCategories.some((categoryArray_0: Array<WorkflowTypes>, index) => {
        if (categoryArray_0.indexOf((workflowType as WorkflowTypes)) !== -1) {
          setCurrentQueueCategoryIndex(index);
          return true;
        }
      });
    }
  }, [adminRoles, setCurrentQueueCategoryIndex, workflowId, workflowType]);
  const workflowIdMapsBasedOnSplit = getByOktaRole(coachWorkflowIdMaps, osWorkflowIdMaps, ptWorkflowIdMaps, adminRoles);
  const showWorkflowQueueDrawer = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    setDisplayWorkflowQueue(true);
  };
  useEffect(() => {
    const currentWorkflowIdentifier_0 = workflowIdMapsBasedOnSplit[currentQueueCategoryIndex].get(currentQueueWorkflowId);
    if (currentWorkflowIdentifier_0) {
      setLastWorkflowInCategory(currentWorkflowIdentifier_0?.nextId === Sentinels.End && workflowIdMapsBasedOnSplit[currentQueueCategoryIndex].size === 1);
      setCurrentWorkflowIdentifier(currentWorkflowIdentifier_0);
    }
    const nextQueueWorkflowMeta = identifyNextQueueWorkflow({
      enableFetchFirstPage: false
    });
    if (nextQueueWorkflowMeta?.newWorkflowId) {
      const {
        newWorkflowId,
        newCategoryIndex
      } = nextQueueWorkflowMeta;
      const nextWorkflowIdentifier_0 = workflowIdMapsBasedOnSplit[newCategoryIndex].get(newWorkflowId);
      setNextWorkflowIdentifier(nextWorkflowIdentifier_0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueueCategoryIndex, currentQueueWorkflowId, setLastWorkflowInCategory, workflowIdMapsBasedOnSplit]);
  useEffect(() => {
    workflowCountsByCategory.sort((category1, category2) => category1.categoryIndex - category2.categoryIndex);
    const firstCategoryWithWorkflows = workflowCountsByCategory.find(categoryCount => categoryCount.count > 0);
    // Should not reset the category index upon any change in the workflow counts
    // if the current workflow is present in the queue
    if (firstCategoryWithWorkflows && !currentWorkflowIdentifier) {
      // Set the first category with workflows as the current category
      setCurrentQueueCategoryIndex(firstCategoryWithWorkflows.categoryIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowCountsByCategory]);
  const canSkipCategory = (categoryIndex: number): boolean => {
    const category_0 = workflowCountsByCategory.find(category => category.categoryIndex === categoryIndex);

    // Skip category if it is undefined (Case: when the split flag is OFF)
    if (category_0 === undefined) {
      return true;
    }

    // Skip category if it has no open workflows (is in complete state)
    return !!(category_0 && category_0.count === 0);
  };
  const fetchFirstPageOfCategory = (categoryIndex_0: number): void => setFetchFirstPageCurrentCategory({
    categoryIndex: categoryIndex_0,
    fetchFirstPage: true
  });
  const getNewCategoryIndex = (currentIndex: number, workflowCategories_0: WorkflowCategoryTypes): number => {
    for (let i = 0; i < workflowCategories_0.length; i++) {
      const newCategoryIndex_0 = (i + currentIndex) % workflowCategories_0.length;
      if (!canSkipCategory(newCategoryIndex_0) && !categoriesToSkip().includes(workflowCategories_0[newCategoryIndex_0][0])) {
        return newCategoryIndex_0;
      }
    }
    return workflowCategories_0.length;
  };
  const getNewWorkflowId = (categoryIndex_1: number, workflowCategories_1: WorkflowCategoryTypes, enableFetchFirstPage = false): number | undefined => {
    let newWorkflowId_0;
    if (categoryIndex_1 < workflowCategories_1.length) {
      if (enableFetchFirstPage) {
        fetchFirstPageOfCategory(categoryIndex_1);
      }
      newWorkflowId_0 = workflowIdMapsBasedOnSplit[categoryIndex_1].keys().next().value;
    }
    return newWorkflowId_0;
  };
  const getCurrentWorkflowIdentifier = (): QueueWorkflowIdentifiers | undefined => {
    const workflowIdMapsBasedOnSplit_0 = getByOktaRole(coachWorkflowIdMaps, osWorkflowIdMaps, ptWorkflowIdMaps, adminRoles);
    const workflowIdsMap = workflowIdMapsBasedOnSplit_0[currentQueueCategoryIndex];
    const isCurrentWorkflowPresent = workflowIdsMap.has(currentWorkflowIdentifier?.id || currentQueueWorkflowId);
    const previousWorkflowIdentifier = workflowIdsMap.get(previousQueueWorkflowId);

    // If the current workflow is not present in the queue and was the last one in the current page
    // We will use the previous workflow identifier to navigate to the next workflow
    const updatedWorkflowIdentifier = currentWorkflowIdentifier?.nextId === Sentinels.ShowMore && !isCurrentWorkflowPresent ? previousWorkflowIdentifier : currentWorkflowIdentifier;
    return updatedWorkflowIdentifier;
  };
  const identifyNextQueueWorkflow = ({
    enableFetchFirstPage: enableFetchFirstPage_0 = false
  }: {
    enableFetchFirstPage?: boolean;
  }): NextQueueWorkflowInfo => {
    const workflowCategories_2 = getByOktaRole(coachQueueWorkflowCategories, osQueueWorkflowCategories, ptQueueWorkflowCategories, adminRoles);
    let newCategoryIndex_1 = currentQueueCategoryIndex;
    let newWorkflowId_1: number | undefined = undefined;
    const currentWorkflowIdentifier_1 = getCurrentWorkflowIdentifier();
    if (currentWorkflowIdentifier_1?.nextId === Sentinels.End || !currentWorkflowIdentifier_1 && lastWorkflowInCategory) {
      newCategoryIndex_1 = getNewCategoryIndex(currentQueueCategoryIndex + 1, workflowCategories_2);
      newWorkflowId_1 = getNewWorkflowId(newCategoryIndex_1, workflowCategories_2, enableFetchFirstPage_0);
    } else if (!currentWorkflowIdentifier_1 && !lastWorkflowInCategory) {
      newCategoryIndex_1 = getNewCategoryIndex(currentQueueCategoryIndex, workflowCategories_2);
      const value = getNewWorkflowId(newCategoryIndex_1, workflowCategories_2, enableFetchFirstPage_0);
      // If a workflow is removed from queue, we set it as a temporary identifier
      // to be able to continue next navigation from that place
      newWorkflowId_1 = tempQueueWorkflowId ? tempQueueWorkflowId : value;
      if (tempQueueWorkflowId && tempQueueCategoryIndex) {
        newWorkflowId_1 = tempQueueWorkflowId;
        newCategoryIndex_1 = tempQueueCategoryIndex;
      }
    } else if (currentWorkflowIdentifier_1?.nextId !== Sentinels.ShowMore) {
      newWorkflowId_1 = currentWorkflowIdentifier_1?.nextId;
    }
    if (newWorkflowId_1 != undefined) {
      return {
        newWorkflowId: newWorkflowId_1,
        newCategoryIndex: newCategoryIndex_1
      };
    }
  };
  const handleChangeQueueCategoryTab = (categoryIndex_2: number, workflowCategories_3: WorkflowCategoryTypes): void => {
    const currentCategoryIndex = adminRoles?.includes(OktaRole.Coach) ? COACH_CATEGORY_NAME_ARRAY_INDEX.indexOf(WORKFLOW_LIST_HEADER_TITLE[CoachWorkflowTypes.CoachCall]) : PT_CATEGORY_NAME_ARRAY_INDEX.indexOf(PT_VIDEO_VISIT_LABEL);
    setCurrentQueueCategoryIndex(currentCategoryIndex);
    const isEncounterWorkflowCategory = tabIndexValue === 1 && encounterWorkflowTypes.includes(workflowCategories_3[categoryIndex_2][0]);

    // Focus the tab based on the workflow category
    const queueCategoryTabIndex = QueueCategoryTypeIndex[isEncounterWorkflowCategory ? 'encounter' : 'workflow'];
    setCurrentQueueTabIndex(queueCategoryTabIndex);
  };
  const moveToNextQueueWorkflow = (event_0: React.MouseEvent<HTMLElement>, workflowCategories_4: WorkflowCategoryTypes): void => {
    trackEvent(new NextButtonClicked({
      adminCtx: adminProfile,
      workflowID: currentWorkflowIdentifier?.id,
      workflowType: currentWorkflowIdentifier?.type,
      sessionId: currentMemberSessionId || ''
    }));
    event_0.preventDefault();
    const isCurrentWorkflowIdentifierPresent = workflowIdMapsBasedOnSplit[currentQueueCategoryIndex].has(currentQueueWorkflowId);
    const nextQueueWorkflowInfo = identifyNextQueueWorkflow({
      enableFetchFirstPage: true
    });
    if (nextQueueWorkflowInfo) {
      const {
        newWorkflowId: newWorkflowId_2,
        newCategoryIndex: newCategoryIndex_2
      } = nextQueueWorkflowInfo;
      if (newWorkflowId_2 != undefined) {
        const nextWorkflowIdentifier_1 = workflowIdMapsBasedOnSplit[newCategoryIndex_2].get(newWorkflowId_2);
        const nextWorkflowUrl = buildWorkflowUrl(nextWorkflowIdentifier_1, threePanelLayoutTreatment, isSimplifiedWorkflowsSplitOn, videoVisitDeprecationTreatment);
        if (nextWorkflowUrl) {
          handleChangeQueueCategoryTab(newCategoryIndex_2, workflowCategories_4);
          setCurrentQueueCategoryIndex(newCategoryIndex_2);
          // Set the current workflow as the previous workflow
          setPreviousQueueWorkflowId(currentQueueWorkflowId);
          // Set the next workflow as the current workflow
          setCurrentQueueWorkflowId(newWorkflowId_2);
          resetTempCurrentQueueCategoryIndex();
          resetTempQueueWorkflowId();
          navigate(nextWorkflowUrl);
        }
      }
    }
    // Only fetch next page if the current workflow is present and is the last one in the page
    if (isCurrentWorkflowIdentifierPresent && currentWorkflowIdentifier?.nextId === Sentinels.ShowMore) {
      setFetchMoreCurrentWorkflowId(currentWorkflowIdentifier.id);
    } else {
      setFetchMoreCurrentWorkflowId(0);
    }
  };
  const handleClick = (title: string, url: string) => (): void => {
    setPortalIframeTitle(title);
    setPortalIframeSrc(url);
    setPortalIframeWidth(PORTAL_IFRAME_WIDTH_WITH_SIDEBAR_CLOSED);

    // Hackety-hack to force an iframe to reload when the src hasn't changed
    // which can happen when navigating within an iframe, which is one of the
    // many reasons why iframes are the worst thing ever.
    const iframe = document.querySelector('iframe');
    if (!iframe || !iframe.contentWindow) return;
    iframe.src = url;
  };
  const preFetchMemberDetails = useCallback((): void => {
    if (nextWorkflowIdentifier?.patientId) {
      apolloClient.query({
        query: GET_USER_INFO,
        variables: {
          uuid: nextWorkflowIdentifier.patientId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextWorkflowIdentifier]);
  const workflowCategories_5 = getByOktaRole(coachQueueWorkflowCategories, osQueueWorkflowCategories, ptQueueWorkflowCategories, adminRoles);
  const navLinkClickHandlers = {
    [LEGACY_NAVIGATION_LINK_NAMES.TASKS]: handleClick(LEGACY_NAVIGATION_LINK_NAMES.TASKS, CTT_URLS.TASKS),
    [LEGACY_NAVIGATION_LINK_NAMES.TEAMS]: (): void => {
      handleClick(LEGACY_NAVIGATION_LINK_NAMES.TEAMS, CTT_URLS.TEAMS)();
      const sessionId = getLocalStorageItem(SESSION_ID) || null;
      endSession();
      const sessionTimeSpent = getLocalStorageItem(TIME_SPENT_ON_MEMBER) || null;
      genericMixpanelEvents<MemberRecordExitProps>(SEARCH_MIXPANEL_EVENTS.MY_TEAMS, {
        sessionId,
        timeSpent: sessionTimeSpent
      });
    },
    [LEGACY_NAVIGATION_LINK_NAMES.MESSAGING]: handleClick(LEGACY_NAVIGATION_LINK_NAMES.MESSAGING, CTT_URLS.MESSAGING),
    [LEGACY_NAVIGATION_LINK_NAMES.CARE_PLANS]: handleClick(LEGACY_NAVIGATION_LINK_NAMES.CARE_PLANS, CTT_URLS.ADMIN_CARE_PLANS),
    [NAVIGATION_LINK_NAMES.MASS_MESSAGING]: (): void => {
      handleClick(NAVIGATION_LINK_NAMES.MASS_MESSAGING, CTT_URLS.MASS_MESSAGING)();
      const sessionId = getLocalStorageItem(SESSION_ID) || null;
      if (massMessagingSplitFlag) {
        endSession();
        const sessionTimeSpent = getLocalStorageItem(TIME_SPENT_ON_MEMBER) || null;
        genericMixpanelEvents<MemberRecordExitProps>(SEARCH_MIXPANEL_EVENTS.MASS_MESSAGE_ICON, {
          sessionId,
          timeSpent: sessionTimeSpent
        });
      }
    },
    [NAVIGATION_LINK_NAMES.QUEUE]: showWorkflowQueueDrawer,
    [NAVIGATION_LINK_NAMES.NEXT]: (event: React.MouseEvent<HTMLElement>) => moveToNextQueueWorkflow(event, workflowCategories_5),
    [NAVIGATION_LINK_NAMES.MEMBER_SEARCH]: (): void => {
      const sessionId = getLocalStorageItem(SESSION_ID) || null;
      if (memberSearchTreatment) {
        endSession();
        const sessionTimeSpent = getLocalStorageItem(TIME_SPENT_ON_MEMBER) || null;
        genericMixpanelEvents<MemberRecordExitProps>(SEARCH_MIXPANEL_EVENTS.CLICK_MEMBER_SEARCH, {
          sessionId,
          timeSpent: sessionTimeSpent
        });
      }
    },
    [NAVIGATION_LINK_NAMES.COACH]: (): void => {
      if (adminRoles) {
        const sessionId = getLocalStorageItem(SESSION_ID) || null;
        endSession();
        const sessionTimeSpent = getLocalStorageItem(TIME_SPENT_ON_MEMBER) || null;
        genericMixpanelEvents<MemberRecordExitProps>(SEARCH_MIXPANEL_EVENTS.DASHBOARD, {
          sessionId,
          timeSpent: sessionTimeSpent
        });
      }
    },
    [NAVIGATION_LINK_NAMES.ACUTE]: (): void => {
      if (adminRoles) {
        const sessionId = getLocalStorageItem(SESSION_ID) || null;
        endSession();
        const sessionTimeSpent = getLocalStorageItem(TIME_SPENT_ON_MEMBER) || null;
        genericMixpanelEvents<MemberRecordExitProps>(SEARCH_MIXPANEL_EVENTS.DASHBOARD, {
          sessionId,
          timeSpent: sessionTimeSpent
        });
      }
    },
    [NAVIGATION_LINK_NAMES.PT]: (): void => {
      if (adminRoles) {
        const sessionId = getLocalStorageItem(SESSION_ID) || null;
        endSession();
        const sessionTimeSpent = getLocalStorageItem(TIME_SPENT_ON_MEMBER) || null;
        genericMixpanelEvents<MemberRecordExitProps>(SEARCH_MIXPANEL_EVENTS.DASHBOARD, {
          sessionId,
          timeSpent: sessionTimeSpent
        });
      }
    }
  };
  const hasQueueWorkflowButtons = (navLink: HHItemNavLinkProps): boolean => [NAVIGATION_LINK_NAMES.QUEUE, NAVIGATION_LINK_NAMES.NEXT].includes(navLink.primary);
  const filterWorkflowQueueButton = (navLink_0: HHItemNavLinkProps): boolean => {
    // filters out the workflow queue buttons (chip and next button)
    // from the navigation bar if the split flag is OFF
    const queueChip = navLink_0.primary === NAVIGATION_LINK_NAMES.QUEUE;
    const nextButton = navLink_0.primary === NAVIGATION_LINK_NAMES.NEXT;
    const hideNextButton = !isNaN(+totalCount) ? +totalCount <= 1 : false;
    if (queueChip && !workflowsQueueSplitOn) {
      return false;
    }
    return !(nextButton && (!workflowsQueueSplitOn || hideNextButton));
  };
  const addClickHandler = (navLink_1: HHItemNavLinkProps): HHItemNavLinkProps => {
    const clickHandlerFn = navLinkClickHandlers[navLink_1.primary];
    const clickHandler = (event_1: React.MouseEvent<HTMLElement>): void => {
      if (!hasQueueWorkflowButtons(navLink_1)) {
        setDisplayWorkflowQueue(false);
      }
      clickHandlerFn && clickHandlerFn(event_1);
    };

    // Queue icon tooltips
    if (navLink_1.primary === NAVIGATION_LINK_NAMES.QUEUE && queueIsComplete) {
      navLink_1.tooltip = QUEUE_TOOLTIPS.QUEUE_COMPLETE;
    }
    if (navLink_1.primary === NAVIGATION_LINK_NAMES.NEXT) {
      const categoryIncrementValue = currentWorkflowIdentifier?.nextId === Sentinels.End ? 1 : 0;
      const newCategoryIndex_3 = getNewCategoryIndex(currentQueueCategoryIndex + categoryIncrementValue, workflowCategories_5);
      const CATEGORY_NAME_ARRAY_INDEX = getByOktaRole(COACH_CATEGORY_NAME_ARRAY_INDEX, OS_CATEGORY_NAME_ARRAY_INDEX, PT_CATEGORY_NAME_ARRAY_INDEX, adminRoles);
      const categoryName = CATEGORY_NAME_ARRAY_INDEX[newCategoryIndex_3];
      navLink_1.tooltip = categoryName ? `${QUEUE_TOOLTIPS.NEXT} ${categoryName}` : '';
    }

    // Array of buttons to use only click handlers instead of the link
    const disableNavLinkToUseClickHandler = [NAVIGATION_LINK_NAMES.QUEUE, NAVIGATION_LINK_NAMES.NEXT];
    if (disableNavLinkToUseClickHandler.includes(navLink_1.primary)) {
      navLink_1.disabled = true;
    }
    const icon = navLink_1.icon;
    const onMouseOverHandler = navLink_1.primary === NAVIGATION_LINK_NAMES.NEXT ? preFetchMemberDetails : undefined;
    const disableNavItem = Object.values(LEGACY_NAVIGATION_LINK_NAMES).includes(navLink_1.primary) && phiMaskingEnabled;
    return {
      ...navLink_1,
      disabled: disableNavItem,
      icon: <HHIconButton hhVariant={'variant-bypass'} sx={{
        ...styles.navIconLayout,
        ...styles.navIcon
      }} onClick={disableNavItem ? undefined : clickHandler} disabled={disableNavItem} onMouseOver={onMouseOverHandler}>
          {icon}
        </HHIconButton>
    };
  };
  const navigationItemsWithHandlers = useMemo(() => navigationItems.filter(filterWorkflowQueueButton).map(addClickHandler),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [currentWorkflowIdentifier, currentQueueCategoryIndex, navigationItems]);
  const handleLogout = async (): Promise<void> => {
    try {
      await hingeHealthAuthClient.signOut();
      localStorage.setItem(PhiFieldsMaskingKey, false.toString());
    } finally {
      allOktaKeys.forEach((oktaKey: string) => localStorage.removeItem(oktaKey));
    }
    const sessionId = getLocalStorageItem(SESSION_ID) || null;
    endSession();
    const sessionTimeSpent = getLocalStorageItem(TIME_SPENT_ON_MEMBER) || null;
    genericMixpanelEvents<MemberRecordExitProps>(SEARCH_MIXPANEL_EVENTS.LOGOUT, {
      sessionId,
      timeSpent: sessionTimeSpent
    });
  };
  const showQuickLinks = (event_2: React.MouseEvent<HTMLElement>): void => {
    event_2.preventDefault();
    setAnchorEl(event_2?.currentTarget);
    setShowQuickLinksPopover(true);
  };
  const handleClose = (event_3: React.MouseEvent<HTMLElement>): void => {
    event_3.preventDefault();
    setShowQuickLinksPopover(false);
    setAnchorEl(null);
  };
  const coachDashboardButtonIndex = navigationItemsWithHandlers.findIndex(navItem => navItem.primary === NAVIGATION_LINK_NAMES.COACH);
  const ptDashboardButtonIndex = navigationItemsWithHandlers.findIndex(navItem_0 => navItem_0.primary === NAVIGATION_LINK_NAMES.PT);
  const osDashboardButtonIndex = navigationItemsWithHandlers.findIndex(navItem_1 => navItem_1.primary === NAVIGATION_LINK_NAMES.OS);
  const nextWorkflowButtonIndex = navigationItemsWithHandlers.findIndex(navItem_2 => navItem_2.primary === NAVIGATION_LINK_NAMES.NEXT);
  const queueButtonIndex = navigationItemsWithHandlers.findIndex(navItem_3 => navItem_3.primary === NAVIGATION_LINK_NAMES.QUEUE);
  const lastQueueButtonIndex = nextWorkflowButtonIndex != -1 ? nextWorkflowButtonIndex : queueButtonIndex;
  const backgroundBarProps: HHNavBackgroundBarProps = {
    startIndex: getByOktaRole(coachDashboardButtonIndex, osDashboardButtonIndex, ptDashboardButtonIndex, adminRoles),
    endIndex: lastQueueButtonIndex + 1,
    barDividerBeforeIndex: 1
  };
  return <>
      <HHGlobalNav navigationItems={navigationItemsWithHandlers} footerItems={[...(runtimePHIMaskingSwitchButtonTreatment && adminHasPHIAccess ? [{
      to: currentLocation,
      primary: 'PHI Masking',
      tooltip: getPHIMaskingTooltip(phiMaskingEnabled),
      icon: <PHIMaskingSwitchButton onChange={setPhiMaskingEnabled} />
    }] : []), ...(feedbackSplitFlagJira ? [{
      to: currentLocation,
      primary: 'feedback-button',
      icon: <>
                      <HHIconButton hhVariant={'variant-bypass'} aria-label="Feedback button" sx={{
          color: 'white'
        }} title="Send Feedback." onClick={(): void => setToggleFeedbackDialog(!toggleFeedbackDialog)}>
                        <Feedback />
                      </HHIconButton>
                      {toggleFeedbackDialog && <FeedbackDialog isOpen={toggleFeedbackDialog} toggleFunction={setToggleFeedbackDialog} />}
                    </>
    }] : []), ...(feedbackSplitFlagFreshdesk ? [{
      to: currentLocation,
      primary: 'feedback-button',
      icon: <>
                      <HHIconButton hhVariant={'variant-bypass'} aria-label="Feedback button" sx={{
          color: 'white'
        }} title="Send Feedback." onClick={(): void => setToggleFeedbackDialog(!toggleFeedbackDialog)}>
                        <Feedback />
                      </HHIconButton>
                      {toggleFeedbackDialog && <FreshdeskDialog isOpen={toggleFeedbackDialog} toggleFunction={setToggleFeedbackDialog} />}
                    </>
    }] : []), {
      to: currentLocation,
      primary: '',
      tooltip: showQuickLinksPopover ? '' : NAVIGATION_TOOLTIPS.OTHER_TOOLS,
      icon: <>
                <HHIconButton hhVariant={'variant-bypass'} onClick={showQuickLinks} aria-label="quick-links" sx={{
          color: 'white',
          ...styles.navIcon
        }}>
                  <AppsRounded />
                </HHIconButton>
                <HHPopover anchorEl={anchorEl} open={showQuickLinksPopover} onClose={handleClose} children={<QuickLinks setShowQuickLinksPopover={setShowQuickLinksPopover} />} anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }} transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }} />
              </>
    }, {
      to: currentLocation,
      primary: 'Logout',
      tooltip: NAVIGATION_TOOLTIPS.LOGOUT,
      icon: <HHIconButton hhVariant={'variant-bypass'} onClick={handleLogout} aria-label="Avatar logout button" sx={styles.navIcon}>
                <Logout sx={{
          color: 'white'
        }} />
              </HHIconButton>
    }]} backgroundBarProps={backgroundBarProps} />
      {adminRoles?.some(role => acceptedRoles.includes(role)) && <HHDrawer anchor="left" open={displayWorkflowQueue} children={<WorkflowsQueue />} ModalProps={{
      keepMounted: true
    }} PaperProps={{
      sx: styles.drawerPaper
    }} variant="persistent" />}
      <QueueCategoryAlert />
    </>;
};
export default SideNavigation;