export const COACH = {
  ERROR: 'Unable to load Coach pages'
};
export const REMOVED_SELECTION = 'removed';
export const QUICK_LINKS = {
  FRESHDESK: 'Freshdesk',
  HELPSCOUT: 'Helpscout'
};

// eslint-disable-next-line quotes
export const FILTER_TITLE = "Today's Tasks";