import { AdminWithOmitedFields } from '../../../contexts/admin.context';
import { getPriorityString } from '../../../modules/dashboard/utils/get-workflow-priority';
import { User, WorkflowPayload } from '../../../types';
import { CommonAdminMixpanelProperties, CommonMemberMixpanelProperties, CommonWorkflowMixpanelProperties } from '../types/generic-mixpanel-event-types';
export const getWorkflowMixpanelProperties = (workflowCtx: Partial<WorkflowPayload>): CommonWorkflowMixpanelProperties => {
  const priorityString = getPriorityString(workflowCtx?.priority ?? null);
  return {
    currentWorkflowID: (workflowCtx?.id as number),
    currentWorkflowName: (workflowCtx?.type as string),
    currentWorkflowState: (workflowCtx?.state as string),
    currentWorkflowCreatedAt: (workflowCtx?.createdAt as string),
    currentWorkflowPriority: (priorityString as string)
  };
};
export const getMemberMixpanelProperties = (memberCtx: Partial<User>): CommonMemberMixpanelProperties => ({
  currentMemberID: (memberCtx?.id as string),
  currentMemberUuid: (memberCtx?.uuid as string),
  currentMemberProgramType: (memberCtx?.applicationPathway?.program as string),
  currentMemberProgramIndication: (memberCtx?.applicationPathway?.indication as string)
});
export const getAdminMixpanelProperties = (adminCtx: Partial<AdminWithOmitedFields>): CommonAdminMixpanelProperties => ({
  currentAdminUuid: (adminCtx?.uuid as string),
  currentAdminID: (adminCtx?.id as string),
  currentAdminEmail: (adminCtx?.email as string),
  currentAdminRole: (adminCtx?.role as string),
  currentAdminOktaRole: (adminCtx?.oktaRole as string)
});