import { c as _c } from "react-compiler-runtime";
import { HHAlert, HHBox, HHSnackbarAlert, HHStack, HHTypography } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { BATCH_GENERATION_TYPES } from '../../constants/strings/batch-generation-types';
import { PT_LAYOUT } from '../../constants/strings/pt-layout';
import { BULK_TRANSITION_TIMEOUT_MESSAGE, FAILED_TO_COMPLETE, PLEASE_REFRESH_THE_PAGE_WORKFLOW_PARTIAL_SUCCESSFUL, PLEASE_TRY_AGAIN_LATER } from '../../constants/strings/workflows-table';
import { DashboardType } from '../../custom-types';
import { useFilterStates } from '../../hooks/use-filter-states';
import { useGetPtCounts } from '../../hooks/use-get-pt-counts';
import { bulkShowHLNFailedAlert, bulkShowHLNSuccessAlert, bulkShowUnreadFailedAlert, bulkShowUnreadSuccessAlert, markCompleteAlertMessage, markCompleteAlertType, shouldRefetchWorkflows, showMarkCompleteAlert, showTimeoutAlert, workflowIdsFailedTransition, workflowIdsSuccessfullyTransitioned } from '../../jotai/atoms';
import { GenerateAiContentForUpcomingWorkflowsMutation, useGenerateAiContentForUpcomingWorkflows } from '../../mutations/generate-ai-content-for-upcoming-workflows.graphql';
import { PtDashboardFilters } from '../../types/pt';
import { WorkflowFilters } from './filters/workflow-filters';
import { WorkflowsTable } from './workflows-table/workflows-table';
const styles = {
  containerStyle: {
    display: 'flex',
    flexDirection: 'row'
  },
  tableContainerStyle: {
    flex: 1
  }
};
export const PtDashboard = () => {
  const $ = _c(60);
  const filterStates = useFilterStates(DashboardType.PhysicalTherapist);
  const [bulkHLNSuccess, setBulkHLNSuccess] = useAtom(bulkShowHLNSuccessAlert);
  const [bulkUnreadSuccess, setBulkUnreadSuccess] = useAtom(bulkShowUnreadSuccessAlert);
  const [bulkHLNFailed] = useAtom(bulkShowHLNFailedAlert);
  const [bulkUnreadFailed] = useAtom(bulkShowUnreadFailedAlert);
  const [workflowSuccessful] = useAtom(workflowIdsSuccessfullyTransitioned);
  const [workflowFailed] = useAtom(workflowIdsFailedTransition);
  const [showMarkAlert, setShowAlert] = useAtom(showMarkCompleteAlert);
  const [alertMessageType] = useAtom(markCompleteAlertType);
  const [message] = useAtom(markCompleteAlertMessage);
  const workflowTypesServed = filterStates[PtDashboardFilters.All].workflowTypes;
  let t0;
  if ($[0] !== workflowTypesServed) {
    t0 = {
      workflowTypes: workflowTypesServed
    };
    $[0] = workflowTypesServed;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const {
    counts,
    loading
  } = useGetPtCounts(t0);
  const [showAlertTimeout] = useAtom(showTimeoutAlert);
  const [refetchWorkflows, setShouldRefetchWorkflows] = useAtom(shouldRefetchWorkflows);
  const [generateAIContentForUpcomingWorkflows] = useGenerateAiContentForUpcomingWorkflows();
  let t1;
  let t2;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => {
      window.dispatchEvent(new Event("onLoadPTDashboard"));
    };
    t2 = [];
    $[2] = t1;
    $[3] = t2;
  } else {
    t1 = $[2];
    t2 = $[3];
  }
  useEffect(t1, t2);
  let t3;
  if ($[4] !== setShowAlert) {
    t3 = () => {
      setShowAlert(false);
    };
    $[4] = setShowAlert;
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  const handleClose = t3;
  let t4;
  let t5;
  if ($[6] !== generateAIContentForUpcomingWorkflows) {
    t4 = () => {
      const generateAiMessagesContent = async () => (await generateAIContentForUpcomingWorkflows({
        variables: {
          input: {
            type: BATCH_GENERATION_TYPES.MESSAGES
          }
        }
      }) as GenerateAiContentForUpcomingWorkflowsMutation);
      const generateAiInsightsContent = async () => (await generateAIContentForUpcomingWorkflows({
        variables: {
          input: {
            type: BATCH_GENERATION_TYPES.INSIGHTS
          }
        }
      }) as GenerateAiContentForUpcomingWorkflowsMutation);
      generateAiMessagesContent();
      generateAiInsightsContent();
    };
    t5 = [generateAIContentForUpcomingWorkflows];
    $[6] = generateAIContentForUpcomingWorkflows;
    $[7] = t4;
    $[8] = t5;
  } else {
    t4 = $[7];
    t5 = $[8];
  }
  useEffect(t4, t5);
  let t6;
  if ($[9] !== counts || $[10] !== loading) {
    t6 = <WorkflowFilters counts={counts} loading={loading} />;
    $[9] = counts;
    $[10] = loading;
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  let t7;
  if ($[12] !== counts) {
    t7 = <HHBox sx={styles.tableContainerStyle}><WorkflowsTable counts={counts} /></HHBox>;
    $[12] = counts;
    $[13] = t7;
  } else {
    t7 = $[13];
  }
  let t8;
  let t9;
  if ($[14] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = () => {
      window.location.reload();
    };
    t9 = {
      cursor: "pointer"
    };
    $[14] = t8;
    $[15] = t9;
  } else {
    t8 = $[14];
    t9 = $[15];
  }
  let t10;
  if ($[16] !== showAlertTimeout) {
    t10 = <HHBox onClick={t8} sx={t9} data-testid="refresh-page-workflow"><HHSnackbarAlert severity="error" message={PLEASE_REFRESH_THE_PAGE_WORKFLOW_PARTIAL_SUCCESSFUL} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={showAlertTimeout} /></HHBox>;
    $[16] = showAlertTimeout;
    $[17] = t10;
  } else {
    t10 = $[17];
  }
  let t11;
  if ($[18] !== t6 || $[19] !== t7 || $[20] !== t10) {
    t11 = <HHBox data-testid={PT_LAYOUT.LAYOUT_CONTAINER_ID} sx={styles.containerStyle}>{t6}{t7}{t10}</HHBox>;
    $[18] = t6;
    $[19] = t7;
    $[20] = t10;
    $[21] = t11;
  } else {
    t11 = $[21];
  }
  const t12 = workflowSuccessful.HLN + " tasks marked complete";
  const t13 = bulkHLNSuccess && workflowFailed.HLN === 0;
  let t14;
  if ($[22] !== setBulkHLNSuccess) {
    t14 = () => setBulkHLNSuccess(false);
    $[22] = setBulkHLNSuccess;
    $[23] = t14;
  } else {
    t14 = $[23];
  }
  let t15;
  if ($[24] !== t12 || $[25] !== t13 || $[26] !== t14) {
    t15 = <HHSnackbarAlert data-testid="alert" severity="success" message={t12} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={t13} autoHideTime={3000} onClose={t14} />;
    $[24] = t12;
    $[25] = t13;
    $[26] = t14;
    $[27] = t15;
  } else {
    t15 = $[27];
  }
  const t16 = workflowSuccessful.Unread + " tasks marked complete";
  const t17 = bulkUnreadSuccess && workflowFailed.Unread === 0;
  let t18;
  if ($[28] !== setBulkUnreadSuccess) {
    t18 = () => setBulkUnreadSuccess(false);
    $[28] = setBulkUnreadSuccess;
    $[29] = t18;
  } else {
    t18 = $[29];
  }
  let t19;
  if ($[30] !== t16 || $[31] !== t17 || $[32] !== t18) {
    t19 = <HHSnackbarAlert data-testid="alert" severity="success" message={t16} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={t17} autoHideTime={3000} onClose={t18} />;
    $[30] = t16;
    $[31] = t17;
    $[32] = t18;
    $[33] = t19;
  } else {
    t19 = $[33];
  }
  let t20;
  if ($[34] !== setShouldRefetchWorkflows) {
    t20 = () => {
      setShouldRefetchWorkflows(false);
    };
    $[34] = setShouldRefetchWorkflows;
    $[35] = t20;
  } else {
    t20 = $[35];
  }
  let t21;
  if ($[36] !== refetchWorkflows || $[37] !== t20) {
    t21 = <HHSnackbarAlert data-testid="alert" severity="success" message={BULK_TRANSITION_TIMEOUT_MESSAGE} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={refetchWorkflows} autoHideTime={3000} onClose={t20} />;
    $[36] = refetchWorkflows;
    $[37] = t20;
    $[38] = t21;
  } else {
    t21 = $[38];
  }
  let t22;
  if ($[39] !== bulkHLNFailed || $[40] !== workflowFailed) {
    t22 = bulkHLNFailed && workflowFailed.HLN > 0 && <HHAlert severity="error" hhVariant="standard" layoutStyles={{
      position: "fixed",
      top: 39,
      right: 0
    }}><HHTypography sx={{
        fontWeight: "bold"
      }} hhVariant="inline">{FAILED_TO_COMPLETE} {workflowFailed.HLN} tasks</HHTypography><br />{PLEASE_TRY_AGAIN_LATER}</HHAlert>;
    $[39] = bulkHLNFailed;
    $[40] = workflowFailed;
    $[41] = t22;
  } else {
    t22 = $[41];
  }
  let t23;
  if ($[42] !== bulkUnreadFailed || $[43] !== workflowFailed) {
    t23 = bulkUnreadFailed && workflowFailed.Unread > 0 && <HHAlert severity="error" hhVariant="standard" layoutStyles={{
      position: "fixed",
      top: 39,
      right: 0
    }}><HHTypography sx={{
        fontWeight: "bold"
      }} hhVariant="inline">{FAILED_TO_COMPLETE} {workflowFailed.Unread} tasks</HHTypography><br />{PLEASE_TRY_AGAIN_LATER}</HHAlert>;
    $[42] = bulkUnreadFailed;
    $[43] = workflowFailed;
    $[44] = t23;
  } else {
    t23 = $[44];
  }
  let t24;
  if ($[45] !== showMarkAlert || $[46] !== alertMessageType || $[47] !== message || $[48] !== handleClose) {
    t24 = showMarkAlert && <HHSnackbarAlert severity={alertMessageType} message={message} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={showMarkAlert} onClose={handleClose} autoHideTime={3000} />;
    $[45] = showMarkAlert;
    $[46] = alertMessageType;
    $[47] = message;
    $[48] = handleClose;
    $[49] = t24;
  } else {
    t24 = $[49];
  }
  let t25;
  if ($[50] !== t15 || $[51] !== t19 || $[52] !== t21 || $[53] !== t22 || $[54] !== t23 || $[55] !== t24) {
    t25 = <HHStack direction="column" spacing="60px">{t15}{t19}{t21}{t22}{t23}{t24}</HHStack>;
    $[50] = t15;
    $[51] = t19;
    $[52] = t21;
    $[53] = t22;
    $[54] = t23;
    $[55] = t24;
    $[56] = t25;
  } else {
    t25 = $[56];
  }
  let t26;
  if ($[57] !== t11 || $[58] !== t25) {
    t26 = <>{t11}{t25}</>;
    $[57] = t11;
    $[58] = t25;
    $[59] = t26;
  } else {
    t26 = $[59];
  }
  return t26;
};