import jwtDecode from 'jwt-decode';
import { defaultErrorHandler } from './errors';
interface DecodedToken {
  phiaccess?: boolean;
}
export const DECODING_TOKEN_ERROR = 'Error decoding token';

// Function to check for PHI access in the token
const hasPHIAccess = (token: string | undefined): boolean => {
  try {
    if (token === '' || token === undefined) return false;
    const decoded: DecodedToken = (jwtDecode(token) as DecodedToken);
    return decoded.phiaccess === true;
  } catch (error) {
    defaultErrorHandler(`${DECODING_TOKEN_ERROR} : ${(error as Error).message}`);
    return false;
  }
};
export default hasPHIAccess;