import { ctpTokens, defaultCtpTheme } from '@hinge-health/react-component-library';
export const SECONDARY_TEXT_COLOR = 'rgba(0, 0, 0, 0.6)';
export const MENU_LABEL = defaultCtpTheme.palette.primary.main;
export const NOTE_COLOR = '#FBFAFA';
export const TITLE_LIGHT = 'rgba(0, 0, 0, 0.38)';
export const CONTENT_LIGHT = 'rgba(0, 0, 0, .87)';
export const LABEL_LIGHT = 'rgba(0, 0, 0, .6)';
export const ICON_LIGHT = 'rgba(0, 0, 0, 0.54)';
export const MENU_BORDER = '#CCCCCC';
export const CALENDAR_EVENT_COLOR1 = defaultCtpTheme.palette.success.light;
export const CALENDAR_EVENT_COLOR2 = ctpTokens.palette.green[90];
export const CALENDAR_EVENT_COLOR3 = ctpTokens.palette.yellow[80];
export const CALENDAR_EVENT_TEXT_COLOR1 = 'black';
export const NOTE_TEXT = '#111111';
export const CALENDAR_EVENT_BORDER_COLOR1 = '#E0E0E0';
export const ICON_BLUE = '#607D8B';
export const ERROR_COLOR = '#D32F2F';
export const WARNING_COLOR = '#ED6C02';
export const SIDE_BAR_SUB_MENU_TITLE_COLOR = '#78909C';
export const SIDEBAR_CHIP_COLOR = '#EBEBEB';
export const ACTIVE_CHIP_COLOR = '#FFFFFF';
export const SELECTED_CHIP_COLOR = defaultCtpTheme.palette.text.secondary;
export const SELECTED_CHIP_TEXT = defaultCtpTheme.palette.primary.contrastText;
export const INACTIVE_CHIP_COLOR = ctpTokens.palette.neutral[95];
export const NON_VIDEO_CALENDAR_EVENT = ctpTokens.palette.yellow[90];
export const DIVIDER_COLOR = '#0000001F';
export const ACCORDION_BORDER = '#C4C4C4';
export const GREEN = '#4CAF50';
export const DARKGREY = '#5C6469';
export const GOOGLE_ACCESS_BUTTON_TEXT_COLOR = '#1F3D69';
export const DARKESTGRAY = '#181D1F';
export const WARNING_BACKGROUND = '#fdf0e6';
export const LIGHT_TEXT_DISABLED = '#969FA3';
export const VISITED_LINK = '#681DA8';
export const ACUTE_MENU_COLORS = {
  MENU_LABEL,
  SECONDARY_TEXT_COLOR,
  MENU_BORDER,
  TITLE_LIGHT,
  ICON_BLUE,
  DIVIDER_COLOR
};
export const FULL_CALENDAR_COLORS = {
  CALENDAR_EVENT_COLOR1,
  CALENDAR_EVENT_COLOR2,
  CALENDAR_EVENT_COLOR3,
  CALENDAR_EVENT_TEXT_COLOR1,
  CALENDAR_EVENT_BORDER_COLOR1,
  NON_VIDEO_CALENDAR_EVENT,
  CONTENT_LIGHT,
  ICON_LIGHT,
  LABEL_LIGHT
};
export const MEMBER_DETAILS_COLORS = {
  BLACK: CALENDAR_EVENT_TEXT_COLOR1,
  WHITE: ACTIVE_CHIP_COLOR,
  SECONDARY_TEXT_COLOR,
  ICON_GREY: SIDE_BAR_SUB_MENU_TITLE_COLOR,
  NOTE_COLOR,
  NOTE_BORDER: ACCORDION_BORDER,
  NOTE_TEXT
};
export const SIDEBAR_GOOGLE_ACCESS_COLORS = {
  BLACK: CALENDAR_EVENT_TEXT_COLOR1,
  WARNING_BACKGROUND
};
export const CARE_PLAN_CHIPS = {
  PENDING_EXTENSION: defaultCtpTheme.palette.primary.main,
  PENDING_EXTENSION_OUTLINE: defaultCtpTheme.palette.primary.light,
  EXPIRING_SOON: '#EC407A',
  EXPIRING_SOON_OUTLINE: '#F8BBD0'
};