import { c as _c } from "react-compiler-runtime";
import { HHStack, HHTableCell, HHTypography } from '@hinge-health/react-component-library';
import { COACH_WORKFLOWS_TABLE } from '../../../../constants/strings/workflows-table';
import { getMonthDateTimeLowercaseFormat } from '../../../../utils/get-formatted-date';
import { styles } from './style';
export const WorkflowDateCell = t0 => {
  const $ = _c(4);
  const {
    date
  } = t0;
  let t1;
  if ($[0] !== date) {
    t1 = date ? getMonthDateTimeLowercaseFormat(date) : COACH_WORKFLOWS_TABLE.SECTION_DETAILS.DEFAULT;
    $[0] = date;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== t1) {
    t2 = <HHTableCell sx={styles.cell}><HHStack data-testid="workflow-table-created-at" width={120}><HHTypography hhVariant="body">{t1}</HHTypography></HHStack></HHTableCell>;
    $[2] = t1;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  return t2;
};