export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.substring(1);
};
export const sentenceCase = (str: string): string => capitalizeFirstLetter(str.toLowerCase());
export const quoteString = (string: string | undefined | null): string => string ? `"${string}"` : '';
export const checkStringContent = (str: string): {
  hasNumber: boolean;
  hasLetter: boolean;
} => {
  const hasNumber = /\d/.test(str);
  const hasLetter = /[a-zA-Z]/.test(str);
  return {
    hasNumber,
    hasLetter
  };
};