import { ctpTokens, defaultCtpTheme } from '@hinge-health/react-component-library';
export const workflowsTableStyles = {
  wrapper: {
    padding: defaultCtpTheme.spacing(6, 8, 8)
  },
  titleContainer: {
    padding: defaultCtpTheme.spacing(0, 2, 2)
  },
  bodyContainer: {
    marginTop: 6
  },
  bodyContainerTabs: {
    marginTop: 5
  },
  titleTypography: {
    fontWeight: ctpTokens.fontWeights['roboto-2'],
    fontSize: 26
  },
  spinnerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 296
  },
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 296,
    backgroundColor: 'rgb(250 243 243 / 50%)'
  }
};