import { c as _c } from "react-compiler-runtime";
import { HHBox, HHTableCell, HHTypography } from '@hinge-health/react-component-library';
import { WorkflowMemberStateFragment } from '../../../../types';
import { styles } from './style';
interface WorkflowMemberStateCellProps {
  workflow: WorkflowMemberStateFragment;
}
export const WorkflowMemberStateCell = t0 => {
  const $ = _c(3);
  const {
    workflow
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      display: "flex",
      flexDirection: "column"
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const t2 = workflow?.memberState;
  let t3;
  if ($[1] !== t2) {
    t3 = <HHTableCell sx={styles.cell}><HHBox data-testid="workflow-member-state" sx={t1}><HHTypography hhVariant="body">{t2}</HHTypography></HHBox></HHTableCell>;
    $[1] = t2;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  return t3;
};