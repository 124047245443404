import { c as _c } from "react-compiler-runtime";
import SkipNext from '@mui/icons-material/SkipNext';
const styles = {
  icon: {
    cursor: 'pointer'
  }
};
export const QueueNextNavIcon = () => {
  const $ = _c(1);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = <><SkipNext sx={styles.icon} /></>;
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  return t0;
};