export const COACH_WORKFLOWS_TABLE = {
  SECTION_TITLE: 'All tasks',
  HEADERS: {
    SELECT: 'Select',
    TYPE: 'Type',
    MEMBER: 'Member',
    CREATED_ON: 'Created on',
    COMPLETED_ON: 'Completed on',
    DUE: 'Due',
    COUNT_SELECTED: '  Selected',
    MARK_COMPLETE: 'Mark Complete',
    MARK: '',
    ENCOUNTER_DATE: 'Encounter date',
    MARKCOMPLETE: 'Mark complete',
    CHANNEL: 'Channel'
  },
  ERROR: 'Sorry, we are having trouble loading your tasks',
  SECTION_DETAILS: {
    DEFAULT: '--'
  }
};
export const PT_WORKFLOWS_TABLE = {
  HEADERS: {
    MESSAGE: 'Message',
    PLAN_EXPIRATION: 'Plan expiration',
    ASSIGN_TO_ME: 'Assign to Me',
    TYPE: 'Type',
    MEMBER: 'Member',
    STATE: 'State',
    CHANNEL: 'Channel'
  },
  ALERT: {
    SUCCESS: ' tasks assigned to you',
    FAILED: 'Failed to assign tasks'
  }
};
export const MEMBER_ALERT_REMIND_AT = 'Reminder task:';
export const TASKS_LOAD_ERROR = 'Tasks could not load. Please refresh the page';
export const HEALTH_LOG_NOTES_LOAD_ERROR = 'Health log - notes could not load. Please refresh the page.';
export const UNREAD_MESSAGES_LOAD_ERROR = 'Unread messages could not load. Please refresh the page.';
export const MARK_COMPLETE_MESSAGE = {
  SUCCESS: '1 task marked complete',
  ERROR: 'Task could not be marked complete. Please try again.'
};
export const MARK_READ = 'Mark as read';
export const CONFIRM_BOX = {
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  STATEMENT: 'Tasks selected will be permanently marked as completed.',
  MASS_MSG_DELETED: 'Messages selected will be permanently deleted',
  TASK_ASSIGNED: 'Tasks selected will be assigned to me.'
};
export const TASKS_BEING_UPDATED = 'Tasks are being updated';
export const DASHBOARD_UPDATE_WHEN_DONE = 'The dashboard will update when done';
export const PLEASE_TRY_AGAIN_LATER = 'Please try again later';
export const FAILED_TO_COMPLETE = 'Failed to complete ';
export const UNREAD_PROGRESS = 'unreadProgress';
export const HLN_PROGRESS = 'hlnProgress';
export const UNREAD_COMPLETED = 'unreadCompleted';
export const HLN_COMPLETED = 'hlnCompleted';
export const PLEASE_REFRESH_THE_PAGE_WORKFLOW_PARTIAL_SUCCESSFUL = 'Please refresh the page, workflow is partially successful';
export const BULK_TRANSITION_TIMEOUT_MESSAGE = 'Auto-refreshing the page';
export const WORKFLOW_TRANSITIONED_EVENT_RECEIVED = 'Workflow transitioned event received';
export const BULK_WORKFLOW_TRANSITIONED_EVENT_RECEIVED = 'Bulk Workflow transitioned event received';
export const BULK_WORKFLOW_TRANSITIONED_HANDLE_SUCCESS = 'Bulk Workflow transition event handled successfully';
export const BULK_WORKFLOW_TRANSITIONED_HANDLE_FAILED = 'Bulk Workflow transition event handling failed';
export const BULK_WORKFLOW_TRANSITIONED_HANDLE_TIMEDOUT = 'Bulk Workflow transition event handling timed out';
export const LAST_OUTREACH = 'Last outreach';
export const SORT_PROPERTY = {
  LATEST_TASK_DATE: 'latestTaskDate',
  LAST_COMPLETED_AT: 'lastCompletedAt'
};