import { c as _c } from "react-compiler-runtime";
import { HHButton, HHChip, HHDialogActions, HHDialogContent, HHDialogContentText, HHLink, HHList, HHListItem, HHTextField, HHTypography, TextArea, Typography } from '@hinge-health/react-component-library';
import { DeleteOutlineRounded, DoneRounded, ErrorOutlineRounded, InfoOutlined, UploadFileSharp } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { USER_FEEDBACK } from '../../../constants/strings/user-feedback';
import { FeedbackMessage, FeedbackType } from './feedback-dialog';
export const FeedbackSubmissionForm = t0 => {
  const $ = _c(115);
  let props;
  if ($[0] !== t0) {
    ({
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
  } else {
    props = $[1];
  }
  if (props.feedbackSubmitted) {
    let t1;
    if ($[2] !== props) {
      t1 = (!props.fileUploadMessage.status || !props.feedbackSubmittedSuccessFully) && <div style={{
        marginTop: "10px",
        textAlign: "center",
        color: "rgba(207, 49, 43, 1)"
      }}><ErrorOutlineRounded sx={props.sxStyles.errorOutlineRounded} /></div>;
      $[2] = props;
      $[3] = t1;
    } else {
      t1 = $[3];
    }
    const t2 = props.feedbackSubmittedSuccessFully ? USER_FEEDBACK.SUCCESS_TITLE : USER_FEEDBACK.FAILURE_TITLE;
    let t3;
    if ($[4] !== props.layoutStyles.typography || $[5] !== t2) {
      t3 = <HHTypography hhVariant="section-title" layoutStyles={props.layoutStyles.typography}>{t2}</HHTypography>;
      $[4] = props.layoutStyles.typography;
      $[5] = t2;
      $[6] = t3;
    } else {
      t3 = $[6];
    }
    let t4;
    if ($[7] !== props.layoutStyles.typography) {
      t4 = {
        ...props.layoutStyles.typography,
        padding: "0px 80px 0px 80px"
      };
      $[7] = props.layoutStyles.typography;
      $[8] = t4;
    } else {
      t4 = $[8];
    }
    const t5 = USER_FEEDBACK.JIRA_BASE_TICKET_URL + props.jiraTicketNumber;
    let t6;
    if ($[9] !== t5 || $[10] !== props.jiraTicketNumber) {
      t6 = <HHLink href={t5} target="_blank">{props.jiraTicketNumber}</HHLink>;
      $[9] = t5;
      $[10] = props.jiraTicketNumber;
      $[11] = t6;
    } else {
      t6 = $[11];
    }
    let t7;
    if ($[12] !== t4 || $[13] !== t6) {
      t7 = <HHTypography hhVariant="muted" layoutStyles={t4}>{USER_FEEDBACK.SUCCESS_ALERT_PREFIX}{t6}{USER_FEEDBACK.SUCCESS_ALERT_SUFFIX}</HHTypography>;
      $[12] = t4;
      $[13] = t6;
      $[14] = t7;
    } else {
      t7 = $[14];
    }
    let t8;
    if ($[15] !== props) {
      t8 = () => props.setFeedbackSubmitted(false);
      $[15] = props;
      $[16] = t8;
    } else {
      t8 = $[16];
    }
    let t9;
    if ($[17] !== props.layoutStyles.typography || $[18] !== t8) {
      t9 = <HHButton hhVariant="text" fullWidth={true} layoutStyles={props.layoutStyles.typography} onClick={t8}>{USER_FEEDBACK.SUBMIT_ANOTHER_BUTTON_TEXT}</HHButton>;
      $[17] = props.layoutStyles.typography;
      $[18] = t8;
      $[19] = t9;
    } else {
      t9 = $[19];
    }
    let t10;
    if ($[20] !== t1 || $[21] !== t3 || $[22] !== t7 || $[23] !== t9) {
      t10 = <HHDialogContent>{t1}{t3}{t7}{t9}</HHDialogContent>;
      $[20] = t1;
      $[21] = t3;
      $[22] = t7;
      $[23] = t9;
      $[24] = t10;
    } else {
      t10 = $[24];
    }
    return t10;
  }
  let t1;
  if ($[25] !== props.sxStyles.hhDialogContentText) {
    t1 = {
      ...props.sxStyles.hhDialogContentText,
      marginTop: "0px"
    };
    $[25] = props.sxStyles.hhDialogContentText;
    $[26] = t1;
  } else {
    t1 = $[26];
  }
  let t2;
  if ($[27] !== t1) {
    t2 = <HHDialogContentText sx={t1}>{USER_FEEDBACK.DIALOG_TEXTS.TITLE_BUG_REQUEST_QUES}</HHDialogContentText>;
    $[27] = t1;
    $[28] = t2;
  } else {
    t2 = $[28];
  }
  const t3 = props.feedbackMessage.feedbackType === USER_FEEDBACK.BUG_STRING ? "secondary" : "default";
  const t4 = props.bugFeatureChip.bug.charAt(0).toUpperCase() + props.bugFeatureChip.bug.slice(1).toLocaleLowerCase();
  let t5;
  if ($[29] !== props) {
    t5 = () => props.handleToggleBugFeatureChip("bug");
    $[29] = props;
    $[30] = t5;
  } else {
    t5 = $[30];
  }
  let t6;
  if ($[31] !== t3 || $[32] !== t4 || $[33] !== t5) {
    t6 = <HHChip clickable={true} color={t3} hhVariant="filled" label={t4} onClick={t5} />;
    $[31] = t3;
    $[32] = t4;
    $[33] = t5;
    $[34] = t6;
  } else {
    t6 = $[34];
  }
  const t7 = props.feedbackMessage.feedbackType === USER_FEEDBACK.REQUEST_STRING ? "secondary" : "default";
  let t8;
  if ($[35] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = {
      marginLeft: "10px"
    };
    $[35] = t8;
  } else {
    t8 = $[35];
  }
  const t9 = props.bugFeatureChip.productFeature.charAt(0).toUpperCase() + props.bugFeatureChip.productFeature.slice(1).toLocaleLowerCase();
  let t10;
  if ($[36] !== props) {
    t10 = () => props.handleToggleBugFeatureChip("productFeature");
    $[36] = props;
    $[37] = t10;
  } else {
    t10 = $[37];
  }
  let t11;
  if ($[38] !== t7 || $[39] !== t9 || $[40] !== t10) {
    t11 = <HHChip clickable={true} hhVariant="filled" color={t7} layoutStyles={t8} label={t9} onClick={t10} />;
    $[38] = t7;
    $[39] = t9;
    $[40] = t10;
    $[41] = t11;
  } else {
    t11 = $[41];
  }
  const t12 = USER_FEEDBACK.DIALOG_TEXTS[(props.feedbackMessage.feedbackType as FeedbackType)];
  let t13;
  if ($[42] !== props.sxStyles.hhDialogContentText || $[43] !== t12.TITLE) {
    t13 = <HHDialogContentText sx={props.sxStyles.hhDialogContentText}>{t12.TITLE}</HHDialogContentText>;
    $[42] = props.sxStyles.hhDialogContentText;
    $[43] = t12.TITLE;
    $[44] = t13;
  } else {
    t13 = $[44];
  }
  let t14;
  if ($[45] === Symbol.for("react.memo_cache_sentinel")) {
    t14 = {
      minRows: 1,
      className: "title-input"
    };
    $[45] = t14;
  } else {
    t14 = $[45];
  }
  const t15 = USER_FEEDBACK.DIALOG_TEXTS[(props.feedbackMessage.feedbackType as FeedbackType)];
  let t16;
  if ($[46] === Symbol.for("react.memo_cache_sentinel")) {
    t16 = {
      width: "100%"
    };
    $[46] = t16;
  } else {
    t16 = $[46];
  }
  let t17;
  if ($[47] !== props) {
    t17 = e => props.setFeedbackMessage(prevData => ({
      ...prevData,
      title: e.target.value
    }));
    $[47] = props;
    $[48] = t17;
  } else {
    t17 = $[48];
  }
  const t18 = props.feedbackErrorMessage.titleError.length ? true : false;
  let t19;
  if ($[49] !== t15.TITLE_PLACEHOLDER || $[50] !== props.feedbackMessage.title || $[51] !== props.feedbackErrorMessage.titleError || $[52] !== t17 || $[53] !== t18) {
    t19 = <HHTextField hhVariant="outlined" label="" InputProps={t14} placeholder={t15.TITLE_PLACEHOLDER} size="medium" defaultValue={props.feedbackMessage.title} fullWidth={true} sx={t16} onChange={t17} error={t18} helperText={props.feedbackErrorMessage.titleError} />;
    $[49] = t15.TITLE_PLACEHOLDER;
    $[50] = props.feedbackMessage.title;
    $[51] = props.feedbackErrorMessage.titleError;
    $[52] = t17;
    $[53] = t18;
    $[54] = t19;
  } else {
    t19 = $[54];
  }
  const t20 = USER_FEEDBACK.DIALOG_TEXTS[(props.feedbackMessage.feedbackType as FeedbackType)];
  let t21;
  if ($[55] !== props.sxStyles.hhDialogContentText || $[56] !== t20.DESCRIPTION) {
    t21 = <HHDialogContentText sx={props.sxStyles.hhDialogContentText}>{t20.DESCRIPTION}</HHDialogContentText>;
    $[55] = props.sxStyles.hhDialogContentText;
    $[56] = t20.DESCRIPTION;
    $[57] = t21;
  } else {
    t21 = $[57];
  }
  const t22 = USER_FEEDBACK.DIALOG_TEXTS[(props.feedbackMessage.feedbackType as FeedbackType)];
  let t23;
  if ($[58] === Symbol.for("react.memo_cache_sentinel")) {
    t23 = {
      width: "100%"
    };
    $[58] = t23;
  } else {
    t23 = $[58];
  }
  let t24;
  if ($[59] !== props) {
    t24 = e_0 => props.setFeedbackMessage(prevData_0 => ({
      ...prevData_0,
      description: e_0.target.value
    }));
    $[59] = props;
    $[60] = t24;
  } else {
    t24 = $[60];
  }
  const t25 = props.feedbackErrorMessage.descriptionError.length ? true : false;
  let t26;
  if ($[61] !== t22.DESCRIPTION_PLACEHOLDER || $[62] !== props.feedbackMessage.description || $[63] !== props.feedbackErrorMessage.descriptionError || $[64] !== t24 || $[65] !== t25) {
    t26 = <HHTextField hhVariant="outlined" label="" multiline={true} fullWidth={true} rows={4} placeholder={t22.DESCRIPTION_PLACEHOLDER} size="medium" defaultValue={props.feedbackMessage.description} sx={t23} onChange={t24} error={t25} helperText={props.feedbackErrorMessage.descriptionError} />;
    $[61] = t22.DESCRIPTION_PLACEHOLDER;
    $[62] = props.feedbackMessage.description;
    $[63] = props.feedbackErrorMessage.descriptionError;
    $[64] = t24;
    $[65] = t25;
    $[66] = t26;
  } else {
    t26 = $[66];
  }
  let t27;
  if ($[67] !== props) {
    t27 = props.bugFeatureChip.bug === props.feedbackMessage.feedbackType && <><HHDialogContentText sx={props.sxStyles.hhDialogContentText}>{USER_FEEDBACK.DIALOG_TEXTS.MEMBER_UUID_TITLE}</HHDialogContentText><TextArea label="" placeholder={USER_FEEDBACK.DIALOG_TEXTS.MEMBER_UUID_PLACEHOLDER} InputProps={{
        minRows: 1
      }} defaultValue={props.feedbackMessage.memberUuids} sx={{
        width: "100%"
      }} onChange={e_1 => props.setFeedbackMessage(prevData_1 => ({
        ...prevData_1,
        memberUuids: e_1.target.value
      }))} /></>;
    $[67] = props;
    $[68] = t27;
  } else {
    t27 = $[68];
  }
  let t28;
  if ($[69] !== props.sxStyles.hhDialogContentText) {
    t28 = {
      ...props.sxStyles.hhDialogContentText,
      marginBottom: "0px"
    };
    $[69] = props.sxStyles.hhDialogContentText;
    $[70] = t28;
  } else {
    t28 = $[70];
  }
  let t29;
  if ($[71] !== t28) {
    t29 = <HHDialogContentText sx={t28}>{USER_FEEDBACK.DIALOG_TEXTS.ATTACHMENT_TEXT}</HHDialogContentText>;
    $[71] = t28;
    $[72] = t29;
  } else {
    t29 = $[72];
  }
  let t30;
  let t31;
  if ($[73] === Symbol.for("react.memo_cache_sentinel")) {
    t30 = {
      display: "none"
    };
    t31 = e_2 => e_2.stopPropagation();
    $[73] = t30;
    $[74] = t31;
  } else {
    t30 = $[73];
    t31 = $[74];
  }
  let t32;
  if ($[75] !== props.fileInputRef || $[76] !== props.handleFeedbackFilesUpload) {
    t32 = <input data-testid={USER_FEEDBACK.TEST_IDS.FILE_INPUT} type="file" accept="image/*, video/*, .har, .doc, .docx, .pdf" multiple={true} ref={props.fileInputRef} style={t30} onClick={t31} onChange={props.handleFeedbackFilesUpload} />;
    $[75] = props.fileInputRef;
    $[76] = props.handleFeedbackFilesUpload;
    $[77] = t32;
  } else {
    t32 = $[77];
  }
  let t33;
  if ($[78] === Symbol.for("react.memo_cache_sentinel")) {
    t33 = {
      marginTop: "8px",
      lineHeight: "40px"
    };
    $[78] = t33;
  } else {
    t33 = $[78];
  }
  let t34;
  if ($[79] === Symbol.for("react.memo_cache_sentinel")) {
    t34 = <UploadFileSharp sx={{
      marginRight: "8px"
    }} />;
    $[79] = t34;
  } else {
    t34 = $[79];
  }
  let t35;
  if ($[80] !== props.handleButtonClick) {
    t35 = <HHButton hhVariant="outlined" layoutStyles={t33} onClick={props.handleButtonClick}>{t34}{" "}{USER_FEEDBACK.UPLOAD_FILES_TEXT}</HHButton>;
    $[80] = props.handleButtonClick;
    $[81] = t35;
  } else {
    t35 = $[81];
  }
  let t36;
  if ($[82] === Symbol.for("react.memo_cache_sentinel")) {
    t36 = <br />;
    $[82] = t36;
  } else {
    t36 = $[82];
  }
  let t37;
  if ($[83] !== props) {
    t37 = props.feedbackErrorMessage.fileUploadError && <HHTypography sx={props.sxStyles.fileUploadErrorTypography} layoutStyles={props.layoutStyles.fileUploadErrorTypography} hhVariant="inline">{props.feedbackErrorMessage.fileUploadError}</HHTypography>;
    $[83] = props;
    $[84] = t37;
  } else {
    t37 = $[84];
  }
  let t38;
  if ($[85] === Symbol.for("react.memo_cache_sentinel")) {
    t38 = <InfoOutlined sx={{
      color: "rgba(103, 111, 109, 1)"
    }} />;
    $[85] = t38;
  } else {
    t38 = $[85];
  }
  let t39;
  if ($[86] !== props.sxStyles.phiDisclaimerTypography) {
    t39 = <Stack direction="row" spacing={2}>{t38}{" "}<Typography sx={props.sxStyles.phiDisclaimerTypography} align="center">{USER_FEEDBACK.DISCLAIMER_TEXT}</Typography></Stack>;
    $[86] = props.sxStyles.phiDisclaimerTypography;
    $[87] = t39;
  } else {
    t39 = $[87];
  }
  let t40;
  if ($[88] !== props.sxStyles.phiDisclaimerBox || $[89] !== t39) {
    t40 = <Box sx={props.sxStyles.phiDisclaimerBox}>{t39}</Box>;
    $[88] = props.sxStyles.phiDisclaimerBox;
    $[89] = t39;
    $[90] = t40;
  } else {
    t40 = $[90];
  }
  let t41;
  if ($[91] !== props) {
    t41 = props.feedbackFiles && props.feedbackFiles.length > 0 && <HHList>{Array.from((props.feedbackFiles as File[])).map((file, index) => <HHListItem key={file.name} sx={props.sxStyles.hhListItem}><Stack direction="row" spacing="12px" alignItems="center" sx={props.sxStyles.stack}><DoneRounded sx={{
            color: "rgba(0, 0, 0, 0.54)"
          }} /><Typography variant="body1" sx={{
            flex: 1
          }}>{file.name}</Typography><DeleteOutlineRounded data-testid={USER_FEEDBACK.TEST_IDS.DELETE_BUTTON + index} sx={{
            marginLeft: "100px",
            color: "rgba(0, 0, 0, 0.54)",
            cursor: "pointer"
          }} onClick={() => props.handleDeleteItem(index)} /></Stack></HHListItem>)}</HHList>;
    $[91] = props;
    $[92] = t41;
  } else {
    t41 = $[92];
  }
  const t42 = props.feedbackLoader ? undefined : props.handleSubmitFeedback;
  let t43;
  if ($[93] !== t42 || $[94] !== props.feedbackLoader) {
    t43 = <HHButton hhVariant="contained" color="primary" onClick={t42} fullWidth={true} disabled={props.feedbackLoader}>{USER_FEEDBACK.SUBMIT_FORM_BUTTON_TEXT}</HHButton>;
    $[93] = t42;
    $[94] = props.feedbackLoader;
    $[95] = t43;
  } else {
    t43 = $[95];
  }
  let t44;
  if ($[96] !== props.sxStyles.hhDialogActions || $[97] !== t43) {
    t44 = <HHDialogActions sx={props.sxStyles.hhDialogActions}>{t43}</HHDialogActions>;
    $[96] = props.sxStyles.hhDialogActions;
    $[97] = t43;
    $[98] = t44;
  } else {
    t44 = $[98];
  }
  let t45;
  if ($[99] !== t2 || $[100] !== t6 || $[101] !== t11 || $[102] !== t13 || $[103] !== t19 || $[104] !== t21 || $[105] !== t26 || $[106] !== t27 || $[107] !== t29 || $[108] !== t32 || $[109] !== t35 || $[110] !== t37 || $[111] !== t40 || $[112] !== t41 || $[113] !== t44) {
    t45 = <HHDialogContent>{t2}{t6}{t11}{t13}{t19}{t21}{t26}{t27}{t29}{t32}{t35}{t36}{t37}{t40}{t41}{t44}</HHDialogContent>;
    $[99] = t2;
    $[100] = t6;
    $[101] = t11;
    $[102] = t13;
    $[103] = t19;
    $[104] = t21;
    $[105] = t26;
    $[106] = t27;
    $[107] = t29;
    $[108] = t32;
    $[109] = t35;
    $[110] = t37;
    $[111] = t40;
    $[112] = t41;
    $[113] = t44;
    $[114] = t45;
  } else {
    t45 = $[114];
  }
  return t45;
};