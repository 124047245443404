import { EVENTS } from './events';
import { CTT_PATHS, CTT_URLS } from './legacy-links';
import { NESTED_ROUTES, ROUTES } from './routes';
import { LOGIN } from './strings/login';
import { MEMBER } from './strings/member';
import { LEGACY_NAVIGATION_LINK_NAMES, NAVIGATION_LINK_NAMES } from './strings/navigation-items';
const TEXT = {
  LOGIN,
  MEMBER
};
export { CTT_URLS, CTT_PATHS, EVENTS, LEGACY_NAVIGATION_LINK_NAMES, NAVIGATION_LINK_NAMES, NESTED_ROUTES, ROUTES, TEXT };