import { ApolloError } from '@apollo/client';
import { SseEvents } from './constants/events';
import { CoachAssistantReplyModel, WorkflowHealthLogFragment, WorkflowPayload, WorkflowTaskFieldsFragment, WorkflowTypeCustomFieldsActionPlansFragment } from './types';
import { CoachDashboardFilters, CoachWorkflowTypes, CommonCoachState, commonCompletedStates } from './types/coach';
import { OsDashboardFilters } from './types/os';
import { commonPtCompletedStates, PtDashboardFilters } from './types/pt';
export const completeAndArchivedStates = [...commonCompletedStates, ...commonPtCompletedStates, CommonCoachState.CoachArchive];
export const hiddenNonCompleteStates = [CommonCoachState.PostFwg];
export const completeWorkflowFilters = [PtDashboardFilters.Completed, CoachDashboardFilters.Completed, OsDashboardFilters.Completed];
export const healthLogWorkflows = [CoachDashboardFilters.MemberHealthLog, PtDashboardFilters.HealthLogNotes];
export const unreadMessageWorkflows = [CoachDashboardFilters.UnreadMessages, PtDashboardFilters.UnreadMessage];
export const healthLogCoachWorkflows = [CoachWorkflowTypes.MemberHealthLog, CoachWorkflowTypes.MemberPainUptick, CoachWorkflowTypes.BalanceAssessment];
export const healthLogCoachWorkflowsWithoutPainUptick = [CoachWorkflowTypes.MemberHealthLog, CoachWorkflowTypes.BalanceAssessment];
export const mediumTierRetentionWorkflows = [CoachWorkflowTypes.ActionPlanCheckIn, CoachWorkflowTypes.FirstWeekCheckIn];

// Custom type to match alert type in RCL
export interface Alert {
  category: string;
  description: string;
  startAt: string;
  endAt: string;
  remindAt?: string;
  paused?: boolean;
}
export interface WorkflowSseData {
  type: SseEvents;
  workflowId: number;
  serviceName: string;
  stateName: string;
}
export enum DashboardType {
  Coach = 'coach',
  PhysicalTherapist = 'physical_therapist',
  OnboardingSpecialist = 'onboarding_specialist',
}
export enum UnreadFilterType {
  AllTypes = 'All Types',
  Messages = 'Messages',
  HealthLog = 'HLN',
}
export interface TaskSseData {
  type: string;
  workflow: WorkflowPayload;
}
export interface CoachAssistantReplyAtomInterface {
  loading: boolean;
  data: CoachAssistantReplyModel | undefined;
  error: ApolloError | undefined;
}
export type WorkflowWithListProperties = WorkflowTypeCustomFieldsActionPlansFragment & WorkflowTaskFieldsFragment & WorkflowHealthLogFragment & WorkflowPayload;
export type SortWorkflowType = {
  property: string;
  order: 'ASC' | 'DESC';
};