export const PANEL_TITLES = {
  RECORD: 'Member Records',
  COMMUNICATIONS: 'Communications',
  WORKFLOWS: 'Workflows'
};
export const PANEL_COLS = {
  SM: 3,
  MED: 4,
  LG: 8,
  MAX: 12
};
export const PANEL_HEADER_HEIGHT = '110px';
export const PANEL_CONTENT_STYLES = {
  HEIGHT: `calc(100vh - ${PANEL_HEADER_HEIGHT})`
};
export const CREATE_WORKFLOW_TAB = {
  TAB_LABEL: 'Create',
  TAB_ARIA_LABEL: 'create-new-workflow-aria-label'
};
export const CARE_TEAM_MESSAGE = 'Care team message';
export const CARE_TEAM = 'Care team';
export const UNREAD_MESSAGE = 'Unread';
export const MESSAGE = 'Message';
export const HLN_UNREAD = 'HL message';
export const HL_PAIN_UPTICK = 'HL Pain uptick';
export const CARE_TEAM_HLN = 'Care team HL message';
export const INDIVIDUAL_HLN = 'Unread HL message';
export const IAM_UNREAD_MESSAGE = 'Unread Message';
export const UNREAD_HL_PAIN_UPTICK = 'Unread HL Pain uptick';
export const CATEGORY = 'Category';
export const DURATION = 'Duration';
export const PROACTIVE_WORKFLOWS = 'Proactive workflows';
export const PAUSED = 'Paused';
export const ACTIVE = 'Active';
export const DESCRIPTION = 'Description';
export const CHANNEL_CREATION_ERROR = 'Failed to create conversation for in-app messaging: A one_on_one conversation with pt already exists for this member';