import { ApolloError } from '@apollo/client';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { PtWorkflowTypes } from '../../../custom-types';
import { HouseCallsWorkflows } from '../../acute-physical-therapy/custom-types';
import { SseEvents } from '../constants/events';
import { OVERDUE_CHECK_TIME } from '../constants/gql-query-constants';
import { ON, SPLIT_TREATMENTS } from '../constants/splits';
import { useShellContext } from '../contexts/shell.context';
import { totalAllTypesCount, totalAllTypesCountLowPriority, totalModularIamCount, totalModularIamCountLowPriority, totalUnreadCount, totalUnreadCountLowPriority } from '../jotai/atoms';
import { CountWorkflowsFiltersInputDto, useCountWorkflowsWithWorkflowTypesQuery, WorkflowCount } from '../types';
import { HouseCallState } from '../types/house-calls';
import { commonPtCompletedStates, commonPtExcludedStates, CommonPtState, PtDashboardFilters, PtVideoVisits } from '../types/pt';
import { VideoVisitsState } from '../types/video-visit';
import { PtWorkflowFilter } from '../types/workflow-filter';
import { getEndOfDay, getStartOfDay } from '../utils/get-formatted-date';
import { getSeparatedWorkflowCounts, WorkflowResult } from '../utils/get-separated-workflow-counts';
import { useCustomEventListener } from './use-get-dashboard-workflows';
export type CountRecord = Record<PtDashboardFilters, number>;
export type UseGetCountOutcome = {
  counts: CountRecord;
  loading: boolean;
  error: ApolloError | undefined;
};
type IncompleteCount = Omit<CountRecord, 'Completed today'>;
const initialIncompleteCount: IncompleteCount = {
  [PtDashboardFilters.All]: 0,
  [PtDashboardFilters.UnreadMessage]: 0,
  [PtDashboardFilters.VideoVisit]: 0,
  [PtDashboardFilters.VideoVisitUpcoming]: 0,
  [PtDashboardFilters.VideoVisitIncomplete]: 0,
  [PtDashboardFilters.HouseCall]: 0,
  [PtDashboardFilters.HouseCallUpcoming]: 0,
  [PtDashboardFilters.HouseCallIncomplete]: 0,
  [PtDashboardFilters.HealthLogNotes]: 0,
  [PtDashboardFilters.PainUptick]: 0,
  [PtDashboardFilters.ExpiringCarePlans]: 0,
  [PtDashboardFilters.DischargeAndClosePlan]: 0,
  [PtDashboardFilters.SurgeryTrend]: 0,
  [PtDashboardFilters.EtModification]: 0,
  [PtDashboardFilters.IndicationUpdate]: 0,
  [PtDashboardFilters.ClinicalEscalation]: 0,
  [PtDashboardFilters.MedicalEmergencyFollowUp]: 0,
  [PtDashboardFilters.SetupActionPlan]: 0,
  [PtDashboardFilters.FirstWeekEnsoCheckIn]: 0,
  [PtDashboardFilters.AqRedFlags]: 0,
  [PtDashboardFilters.HealthLogNoResponse]: 0,
  [PtDashboardFilters.UnreadMessageNoResponse]: 0,
  [PtDashboardFilters.PtMemberAlertReminder]: 0,
  [PtDashboardFilters.ActionPlanReview]: 0
};
const getNewCount = (workflows: number | undefined): number => workflows || 0;
const getFilteredWorkflowsCount = (workflows: WorkflowCount[] | undefined, workflowType: PtWorkflowTypes, excludedStates: string[] = commonPtExcludedStates): number => {
  if (!workflows) {
    return 0;
  }
  return workflows.reduce((count, curr: WorkflowCount) => {
    if (curr.workflowType === workflowType && !excludedStates.includes((curr.state as CommonPtState))) {
      return count + curr.count;
    }
    return count;
  }, 0);
};
export const useGetPtCounts = (filterState: PtWorkflowFilter): UseGetCountOutcome => {
  const [incompleteCount, setIncompleteCount] = useState<IncompleteCount>(initialIncompleteCount);
  const [completeCount, setCompleteCount] = useState<number>(0);
  const {
    adminProfile,
    useSse
  } = useShellContext();
  const [ptPooledDashboardAdminId, setPtPooledDashboardAdminId] = useState<string | null>(null);
  const [isAssignmentDone, setIsAssignmentDone] = useState(false);
  const [allTypesCountTotal, setTotalUnreadCount] = useAtom(totalAllTypesCount);
  const [messageCountTotal, setTotalMessageCount] = useAtom(totalUnreadCount);
  const [iamMessageCountTotal, setTotalModularIAMCount] = useAtom(totalModularIamCount);
  const [, setUnreadLowPriority] = useAtom(totalUnreadCountLowPriority);
  const [, setAllTypesLowPriority] = useAtom(totalAllTypesCountLowPriority);
  const [, setModularIAMLowPriority] = useAtom(totalModularIamCountLowPriority);
  const sseEvents = [SseEvents.WorkflowCreated, SseEvents.WorkflowTransitioned, SseEvents.TaskUpdated];
  const splitClient = useClient();
  const healthLogNotesMlSplitFlag = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_HLN_ML, {
    adminUuid: adminProfile.uuid
  }) === ON;
  const unreadMessagesMlSplitFlagValue = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_UNREAD_MESSAGES_ML, {
    adminUuid: adminProfile.uuid
  }) === ON;
  const sseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, sseEvents, {
    replayLastEvent: true
  });
  const bulkMarkSseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, [SseEvents.BulkWorkflowTransitionCompleted, SseEvents.BulkWorkflowTransitionFailed], {
    replayLastEvent: true
  });
  const lowPrioritySplitFlag = healthLogNotesMlSplitFlag || unreadMessagesMlSplitFlagValue;
  const countWorkflowsFiltersInputDto: CountWorkflowsFiltersInputDto = {
    adminUuids: [ptPooledDashboardAdminId || adminProfile.uuid],
    ...filterState,
    completedStates: commonPtCompletedStates,
    useCoverage: false
  };
  const {
    data: incompleteData,
    loading: incompleteLoading,
    error: incompleteError,
    refetch: refetchIncomplete
  } = useCountWorkflowsWithWorkflowTypesQuery({
    variables: {
      countWorkflowsFilters: {
        ...countWorkflowsFiltersInputDto,
        excludeStates: commonPtExcludedStates,
        countByPriority: lowPrioritySplitFlag
      }
    },
    pollInterval: OVERDUE_CHECK_TIME,
    errorPolicy: 'all',
    returnPartialData: true,
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setIsAssignmentDone(false)
  });
  const {
    data: completeData,
    loading: completeLoading,
    error: completeError,
    refetch: refetchComplete
  } = useCountWorkflowsWithWorkflowTypesQuery({
    variables: {
      countWorkflowsFilters: {
        ...countWorkflowsFiltersInputDto,
        completedAfterDate: getStartOfDay(),
        completedBeforeDate: getEndOfDay()
      }
    },
    onCompleted: () => setIsAssignmentDone(false),
    pollInterval: OVERDUE_CHECK_TIME,
    errorPolicy: 'all',
    returnPartialData: true,
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });
  const incompleteResults = incompleteData?.countWorkflowsWithWorkflowTypes?.results;
  let lowCountHLN = 0;
  const lowCountHLNTotal = incompleteData?.countWorkflowsWithWorkflowTypes?.subtotals?.filter(item => item.workflowType == PtWorkflowTypes.HealthLog);
  if (lowCountHLNTotal) {
    lowCountHLN = lowCountHLNTotal[0]?.countByPriority?.low ? lowCountHLNTotal[0]?.countByPriority?.low : 0;
  }
  const [unreadMessageLowPriorityCount_0, modularIAMLowPriorityCount_0] = useMemo(() => {
    let unreadMessageLowPriorityCount = 0;
    let modularIAMLowPriorityCount = 0;
    const subtotals = incompleteData?.countWorkflowsWithWorkflowTypes?.subtotals || [];
    const unreadMessageSubtotal = subtotals.find(item_0 => item_0.workflowType === PtWorkflowTypes.UnreadMessages);
    const modularIAMSubtotal = subtotals.find(item_1 => item_1.workflowType === PtWorkflowTypes.ModularIamUnreadMessages);
    if (unreadMessageSubtotal) {
      unreadMessageLowPriorityCount = unreadMessageSubtotal.countByPriority?.low || 0;
      setUnreadLowPriority(unreadMessageLowPriorityCount);
    }
    if (modularIAMSubtotal) {
      modularIAMLowPriorityCount = modularIAMSubtotal.countByPriority?.low || 0;
      setModularIAMLowPriority(modularIAMLowPriorityCount);
    }
    return [unreadMessageLowPriorityCount, modularIAMLowPriorityCount];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incompleteData?.countWorkflowsWithWorkflowTypes]);
  useEffect(() => {
    setAllTypesLowPriority(unreadMessageLowPriorityCount_0 + modularIAMLowPriorityCount_0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessageLowPriorityCount_0, modularIAMLowPriorityCount_0]);
  useEffect(() => {
    const refetchCounts = async (): Promise<void> => {
      if (sseData || ptPooledDashboardAdminId || isAssignmentDone || lowPrioritySplitFlag || bulkMarkSseData) {
        await Promise.all([refetchIncomplete(), refetchComplete()]);
      }
    };
    refetchCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseData, isAssignmentDone, bulkMarkSseData]);
  useEffect(() => {
    // Deriving completedStates from what existed for coach. Excluding all the 'completed' workflows from incompleteResults.
    const allWorkflows = incompleteResults?.reduce((acc, curr: WorkflowCount) => {
      if (!commonPtCompletedStates.includes((curr.state as CommonPtState))) {
        return acc + curr.count;
      }
      return acc;
    }, 0);
    const videoVisitWorkflowsCounts = getSeparatedWorkflowCounts({
      results: ((incompleteResults || []) as WorkflowResult[]),
      workflowTypes: PtVideoVisits,
      upcomingStates: [VideoVisitsState.CompleteVideoVisit],
      incompleteStates: [VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup],
      excludedStates: [...commonPtExcludedStates, ...commonPtCompletedStates]
    });
    const unreadMessageWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.UnreadMessages);
    setTotalMessageCount(unreadMessageWorkflows);
    const modularIamMessageWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.ModularIamUnreadMessages);
    setTotalModularIAMCount(modularIamMessageWorkflows);
    const totalUnreadWorkflows = unreadMessageWorkflows + modularIamMessageWorkflows;
    setTotalUnreadCount(totalUnreadWorkflows);
    const houseCallWorkflowsCounts = getSeparatedWorkflowCounts({
      results: ((incompleteResults || []) as WorkflowResult[]),
      workflowTypes: HouseCallsWorkflows,
      excludedStates: [...commonPtExcludedStates, ...commonPtCompletedStates],
      upcomingStates: [HouseCallState.PrevisitHouseCall],
      incompleteStates: [HouseCallState.PerformHouseCall, HouseCallState.PostHouseCallFollowup]
    });
    const healthLogNoteWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.HealthLog);
    const painUptickWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.PainUptick);
    const memberPainUptickWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.MemberPainUptick);
    const expiringCarePlanWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.Expiring);
    const carePlanToCloseWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.Closing);
    const surgeryTrendWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.SurgeryTrend);
    const etModificationWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.EtModification);
    const clinicalEscalationWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.ClinicalEscalation);
    const medicalEmergencyFollowUpWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.MedicalEmergencyFollowUp);
    const firstWeekEnsoCheckInWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.FirstWeekEnsoCheckIn);
    const aqRedFlagsWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.AqRedFlags);
    const alertRemindersWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.PtMemberAlertReminder);
    const actionPlanReviewWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.ActionPlanReview);
    const setupActionPlanWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.SetupActionPlan);
    const indicationUpdateWorkflows = getFilteredWorkflowsCount(incompleteResults, PtWorkflowTypes.IndicationUpdate);
    const newCounts: IncompleteCount = {
      [PtDashboardFilters.All]: getNewCount(allWorkflows),
      [PtDashboardFilters.UnreadMessage]: getNewCount(totalUnreadWorkflows),
      [PtDashboardFilters.ModularIamUnreadMessages]: iamMessageCountTotal,
      [PtDashboardFilters.Messages]: messageCountTotal,
      [PtDashboardFilters.AllTypes]: allTypesCountTotal,
      [PtDashboardFilters.VideoVisit]: getNewCount(videoVisitWorkflowsCounts.all),
      [PtDashboardFilters.VideoVisitUpcoming]: getNewCount(videoVisitWorkflowsCounts.upcoming),
      [PtDashboardFilters.VideoVisitIncomplete]: getNewCount(videoVisitWorkflowsCounts.incomplete),
      [PtDashboardFilters.HouseCall]: getNewCount(houseCallWorkflowsCounts.all),
      [PtDashboardFilters.HouseCallUpcoming]: getNewCount(houseCallWorkflowsCounts.upcoming),
      [PtDashboardFilters.HouseCallIncomplete]: getNewCount(houseCallWorkflowsCounts.incomplete),
      [PtDashboardFilters.HealthLogNotes]: getNewCount(healthLogNoteWorkflows),
      [PtDashboardFilters.HealthLogNoResponse]: lowCountHLN,
      [PtDashboardFilters.UnreadMessageNoResponse]: unreadMessageLowPriorityCount_0,
      [PtDashboardFilters.PainUptick]: getNewCount(painUptickWorkflows) + getNewCount(memberPainUptickWorkflows),
      [PtDashboardFilters.ExpiringCarePlans]: getNewCount(expiringCarePlanWorkflows),
      [PtDashboardFilters.DischargeAndClosePlan]: getNewCount(carePlanToCloseWorkflows),
      [PtDashboardFilters.SurgeryTrend]: getNewCount(surgeryTrendWorkflows),
      [PtDashboardFilters.FirstWeekEnsoCheckIn]: getNewCount(firstWeekEnsoCheckInWorkflows),
      [PtDashboardFilters.EtModification]: getNewCount(etModificationWorkflows),
      [PtDashboardFilters.IndicationUpdate]: getNewCount(indicationUpdateWorkflows),
      [PtDashboardFilters.ClinicalEscalation]: getNewCount(clinicalEscalationWorkflows),
      [PtDashboardFilters.MedicalEmergencyFollowUp]: getNewCount(medicalEmergencyFollowUpWorkflows),
      [PtDashboardFilters.AqRedFlags]: getNewCount(aqRedFlagsWorkflows),
      [PtDashboardFilters.PtMemberAlertReminder]: getNewCount(alertRemindersWorkflows),
      [PtDashboardFilters.ActionPlanReview]: getNewCount(actionPlanReviewWorkflows),
      [PtDashboardFilters.SetupActionPlan]: getNewCount(setupActionPlanWorkflows)
    };
    setIncompleteCount(newCounts);
  }, [allTypesCountTotal, iamMessageCountTotal, incompleteResults, lowCountHLN, unreadMessageLowPriorityCount_0, messageCountTotal, setTotalMessageCount, setTotalModularIAMCount, setTotalUnreadCount]);
  const completedCount = completeData?.countWorkflowsWithWorkflowTypes.total;
  useEffect(() => {
    if (completedCount !== undefined && completedCount !== null) {
      setCompleteCount(completedCount);
    }
  }, [completedCount]);
  useCustomEventListener('onPtPooledDashboardChange', (event: Event) => {
    const newAdminId = (event as CustomEvent<string>).detail;
    setPtPooledDashboardAdminId(newAdminId);
  });
  useCustomEventListener('onWorkflowsAssigned', () => {
    setIsAssignmentDone(true);
  });
  const counts: Record<PtDashboardFilters, number> = {
    ...incompleteCount,
    [PtDashboardFilters.Completed]: completeCount
  };
  return {
    counts,
    loading: incompleteLoading || completeLoading,
    error: incompleteError || completeError
  };
};