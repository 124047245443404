import dayjs from 'dayjs';
export const MONTH_DATE_TIME_LOWER_FORMAT = 'MMM D, h:mma';
export const getMonthDateTimeLowercaseFormat = (dateString: string): string => dayjs(dateString).format(MONTH_DATE_TIME_LOWER_FORMAT);
export const getStartOfDay = (): string => dayjs().startOf('day').toISOString();
export const getEndOfDay = (): string => dayjs().endOf('day').toISOString();
export const todayAtStartOfHour = (): string => dayjs().startOf('hour').toISOString();
export const getEndOfTomorrowInBusinessDays = (date: dayjs.Dayjs = dayjs()): string => {
  // get day number from 0 (Sunday) to 6 (Saturday)
  const dayNumberToday = date.day();
  // if friday, add 3 days till end of monday
  if (dayNumberToday === 5) {
    return date.add(3, 'day').endOf('day').toISOString();
  }
  // if saturday, add 2 days till end of monday
  if (dayNumberToday === 6) {
    return date.add(2, 'day').endOf('day').toISOString();
  }
  // else add one day to get end of tomorrow
  return date.add(1, 'day').endOf('day').toISOString();
};