import { capitalize } from 'lodash';
export const transformText = (text: string | number | undefined | null, textLength = 20): string => {
  if (!text) return '';
  const transformedText = String(text);
  if (transformedText.length <= textLength) return transformedText;
  const shortenedText = transformedText.slice(0, textLength);
  return `${shortenedText}...`;
};
export const transformIndicationsToText = (indications?: {
  name: string;
  isPrimary: boolean;
}[]): string => {
  if (!indications) {
    return '';
  }
  return indications.toSorted((a, b) => {
    if (a.isPrimary === b.isPrimary) {
      return 0;
    }
    if (a.isPrimary) {
      return -1;
    }
    return 1;
  }).map(flag => `${capitalize(flag.name)} ${flag.isPrimary ? '(P)' : ''}`.trim()).join(', ');
};