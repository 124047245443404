// legacy CTT
export const LEGACY_NAVIGATION_LINK_NAMES = {
  CARE_PLANS: 'Care Plans',
  MESSAGING: 'Messaging',
  TASKS: 'Tasks',
  TEAMS: 'Teams'
};
export const NAVIGATION_LINK_NAMES = {
  HOME: 'Home',
  MEMBER: 'Member',
  ACUTE: 'Acute',
  PT: 'Physical Therapist',
  OS: 'Onboarding Specialist',
  COACH: 'Coach',
  QUEUE: 'Queue List',
  NEXT: 'Next',
  TEST: 'Test',
  MEMBER_SEARCH: 'Member search',
  MASS_MESSAGING: 'Mass Messaging',
  MPE_QUEUE: 'MPE Queue'
};
export const NAV_QUEUE_COUNTS_LOADING_ID = 'queue-counts-loading';
export const NAVIGATION_TOOLTIPS = {
  MEMBER_SEARCH: 'Member search',
  MY_TEAMS: 'My teams',
  CTT_TASKS: 'CTT Tasks',
  CTT_MESSAGING: 'CTT messaging',
  MASS_MESSENGER: 'Mass messenger',
  OTHER_TOOLS: 'Other tools',
  PHI_MASKING: 'PHI mask',
  LOGOUT: 'Logout',
  TEST_USERS: 'Test users',
  MPE_QUEUE: 'MPE Queue'
};