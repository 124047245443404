import { c as _c } from "react-compiler-runtime";
import { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import React, { createContext, useContext, useMemo } from 'react';
import { SseEvents } from '../constants/events';
import { Admin, CarePlanData } from '../types';
export interface ShellContextType {
  children?: React.ReactNode;
  adminProfile: Admin;
  mixpanelTrack?: (eventName: string, properties?: Dict, optionsOrCallback?: RequestOptions | Callback, callback?: Callback) => void;
  useSse: (url: string, events: SseEvents[], options?: {
    replayLastEvent?: boolean;
  }) => unknown;
  genericMixpanelTrack?: <T extends Dict>(eventName: string, properties?: T, optionsOrCallback?: RequestOptions | Callback, callback?: Callback) => void;
}
const initialContext: ShellContextType = {
  useSse: (_url: string, _events: unknown[]) => undefined,
  adminProfile: {
    avatarUrl: '',
    carePlanData: ({} as CarePlanData),
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    role: '',
    uuid: 'no-uuid'
  }
};
export const ShellContext = createContext<ShellContextType>(initialContext);
export const ShellProvider = t0 => {
  const $ = _c(8);
  const {
    children,
    adminProfile,
    mixpanelTrack,
    genericMixpanelTrack,
    useSse
  } = t0;
  let t1;
  let t2;
  if ($[0] !== adminProfile || $[1] !== mixpanelTrack || $[2] !== genericMixpanelTrack || $[3] !== useSse) {
    t2 = {
      adminProfile,
      mixpanelTrack,
      genericMixpanelTrack,
      useSse
    };
    $[0] = adminProfile;
    $[1] = mixpanelTrack;
    $[2] = genericMixpanelTrack;
    $[3] = useSse;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  t1 = t2;
  let t3;
  if ($[5] !== t1 || $[6] !== children) {
    t3 = <ShellContext.Provider value={t1}>{children}</ShellContext.Provider>;
    $[5] = t1;
    $[6] = children;
    $[7] = t3;
  } else {
    t3 = $[7];
  }
  return t3;
};
export const useShellContext = () => {
  return useContext(ShellContext);
};