/* eslint-disable @typescript-eslint/naming-convention */
import { CoachWorkflowTypes, PtWorkflowTypes } from '../../custom-types';
import { AllAcuteGroupTypes } from '../../modules/acute-physical-therapy/custom-types';
export const portalSidebarUrl = (workflowId: number, type: string, groupType?: AllAcuteGroupTypes): string => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const appendGroupType = groupType ? `&groupType=${groupType}` : '';
  return `/portal?sidebarStatus=open&sidebarContext=workflow&workflowId=${workflowId}&workflowType=${type}${appendGroupType}`;
};
export const getTabForWorkflowType = (workflowType: PtWorkflowTypes | CoachWorkflowTypes): string => {
  switch (workflowType) {
    case PtWorkflowTypes.FirstWeekEnsoCheckIn:
    case CoachWorkflowTypes.WeeklyEnsoReview:
    case PtWorkflowTypes.ClinicalEscalation:
    case PtWorkflowTypes.PainUptick:
    case PtWorkflowTypes.MemberPainUptick:
    case CoachWorkflowTypes.OutreachNeeded:
      return 'activity';
    case PtWorkflowTypes.Closing:
    case PtWorkflowTypes.Expiring:
    case CoachWorkflowTypes.CoachWeeklyGoalExpiry:
    case CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal:
      return 'care-plan';
    default:
      return 'profile';
  }
};
export const memberLayoutUrlWithWorkflow = (workflowId: number, workflowType: string, memberId: string): string => {
  const queryParams = `workflowId=${workflowId}&workflowType=${workflowType}`;
  const qid = btoa(queryParams);
  return `/member/${memberId}/profile/?${queryParams}&qid=${qid}`;
};
export const memberLayoutUrl = (workflowId: number, workflowType: string, memberId?: string, {
  tab,
  isSimplifiedWorkflowsSplitOn = false
}: {
  tab?: string;
  isSimplifiedWorkflowsSplitOn?: boolean;
} = {}): string => {
  let queryParams = `workflowId=${workflowId}&workflowType=${workflowType}`;
  if (isSimplifiedWorkflowsSplitOn) {
    const qid = btoa(queryParams);
    queryParams = `qid=${qid}`;
    tab = 'profile';
  }
  if (!tab && !isSimplifiedWorkflowsSplitOn) {
    tab = getTabForWorkflowType((workflowType as PtWorkflowTypes | CoachWorkflowTypes));
  }
  return `/member/${memberId}/${tab}/?${queryParams}`;
};
export const acuteLayoutUrl = (workflowId: number, workflowType: string): string => `/acute?workflowId=${workflowId}&workflowType=${workflowType}`;