import { c as _c } from "react-compiler-runtime";
import { gql, LazyQueryHookOptions, LazyQueryResultTuple, OperationVariables, useLazyQuery } from '@apollo/client';
export interface AdminProps {
  firstName: string;
  email: string;
  lastName: string;
  uuid: string;
}
export type GetPtQuery = {
  __typename?: 'Query';
  findAdmins: [AdminProps];
};
export const GET_PT_QUERY = gql`
  query findAdmins($roles: [String!]!, $itemsPerPage: Float) {
    findAdmins(roles: $roles, itemsPerPage: $itemsPerPage) {
      email
      firstName
      lastName
      uuid
    }
  }
`;
export const useGetPTList = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useLazyQuery(GET_PT_QUERY, options);
};