export const PORTAL_IFRAME_WIDTH_WITH_SIDEBAR_OPEN = 'calc(100% - 321px)';
export const PORTAL_IFRAME_WIDTH_WITH_SIDEBAR_CLOSED = '100%';
export interface SidebarQueryParams extends Record<string, string> {
  sidebarStatus: string;
  sidebarContext: string;
  workflowId: string;
}
export interface CurrentAdmin {
  uuid: string;
  email?: string;
  firstName: string;
  lastName: string;
  role?: string;
}