import { c as _c } from "react-compiler-runtime";
import { HHCheckbox } from '@hinge-health/react-component-library';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useAtom } from 'jotai';
import { ChangeEvent, useEffect, useState } from 'react';
import { useGenericMixpanelTrack } from '../../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../../lib/mixpanel/mixpanel-event-names';
import { SelectedWorkflowMixpanelProperties } from '../../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../../constants/strings/pt-layout';
import { DashboardType, UnreadFilterType } from '../../../../custom-types';
import { listToMarkWorkflowComplete, listToMarkWorkflowPending, selectAllPTWorkflowAtom, selectAllWorkflowAtom, selectedDashboardType, totalWorkflowCount } from '../../../../jotai/atoms';
interface WorkflowBulkMarkProps {
  workflowId?: number;
  markType: 'body' | 'header';
  onSelect?: (checked: boolean, workflowId: number, workflowName: UnreadFilterType) => void;
  workflowName?: UnreadFilterType;
  workflowCreatedAt?: string;
  workflowState?: string;
  workflowMemberUuid?: string;
}
export const WorkflowBulkMark = t0 => {
  const $ = _c(53);
  const {
    workflowId: t1,
    markType,
    onSelect,
    workflowName: t2,
    workflowCreatedAt,
    workflowState,
    workflowMemberUuid
  } = t0;
  const workflowId = t1 === undefined ? 0 : t1;
  const workflowName = t2 === undefined ? UnreadFilterType.AllTypes : t2;
  const [allWorkflowFlag, setAllWorkflowFlag] = useAtom(selectAllWorkflowAtom);
  const [listToMarkComplete, setListToMarkComplete] = useAtom(listToMarkWorkflowComplete);
  const [listToMarkPending, setListToMarkPending] = useAtom(listToMarkWorkflowPending);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isDeterminate, setIsDeterminate] = useState(false);
  const [totalCount] = useAtom(totalWorkflowCount);
  const [currentDashboard] = useAtom(selectedDashboardType);
  const [allPTWorkflowFlag, setAllPTWorkflowFlag] = useAtom(selectAllPTWorkflowAtom);
  const trackEvent = useGenericMixpanelTrack();
  let t3;
  if ($[0] !== markType || $[1] !== setAllWorkflowFlag || $[2] !== setAllPTWorkflowFlag || $[3] !== setListToMarkComplete || $[4] !== setListToMarkPending || $[5] !== onSelect || $[6] !== workflowId || $[7] !== workflowName) {
    t3 = event => {
      if (markType === "header") {
        setAllWorkflowFlag(event.target.checked);
        setAllPTWorkflowFlag(event.target.checked);
        setListToMarkComplete([]);
        setListToMarkPending([]);
      } else {
        onSelect?.(event.target.checked, workflowId, workflowName);
      }
    };
    $[0] = markType;
    $[1] = setAllWorkflowFlag;
    $[2] = setAllPTWorkflowFlag;
    $[3] = setListToMarkComplete;
    $[4] = setListToMarkPending;
    $[5] = onSelect;
    $[6] = workflowId;
    $[7] = workflowName;
    $[8] = t3;
  } else {
    t3 = $[8];
  }
  const handleCheckBox = t3;
  let t4;
  if ($[9] !== markType || $[10] !== totalCount || $[11] !== workflowId || $[12] !== currentDashboard || $[13] !== allPTWorkflowFlag || $[14] !== listToMarkComplete || $[15] !== listToMarkPending || $[16] !== allWorkflowFlag) {
    t4 = () => {
      const getCompletedDeterminateState = (allFlag, markedList, unmarkedList) => {
        if (markType === "header") {
          setIsCompleted(allFlag || markedList.length === totalCount && totalCount > 0);
          setIsDeterminate(markedList.length > 0 && markedList.length !== totalCount || unmarkedList.length > 0 && unmarkedList.length !== totalCount);
        } else {
          setIsCompleted(allFlag && !unmarkedList.includes(workflowId) || markedList.includes(workflowId));
        }
      };
      if (currentDashboard === DashboardType.PhysicalTherapist) {
        getCompletedDeterminateState(allPTWorkflowFlag, listToMarkComplete, listToMarkPending);
      } else {
        getCompletedDeterminateState(allWorkflowFlag, listToMarkComplete, listToMarkPending);
      }
    };
    $[9] = markType;
    $[10] = totalCount;
    $[11] = workflowId;
    $[12] = currentDashboard;
    $[13] = allPTWorkflowFlag;
    $[14] = listToMarkComplete;
    $[15] = listToMarkPending;
    $[16] = allWorkflowFlag;
    $[17] = t4;
  } else {
    t4 = $[17];
  }
  let t5;
  if ($[18] !== allWorkflowFlag || $[19] !== listToMarkPending || $[20] !== listToMarkComplete || $[21] !== markType || $[22] !== workflowId || $[23] !== totalCount || $[24] !== setAllWorkflowFlag || $[25] !== currentDashboard || $[26] !== allPTWorkflowFlag) {
    t5 = [allWorkflowFlag, listToMarkPending, listToMarkComplete, markType, workflowId, totalCount, setAllWorkflowFlag, currentDashboard, allPTWorkflowFlag];
    $[18] = allWorkflowFlag;
    $[19] = listToMarkPending;
    $[20] = listToMarkComplete;
    $[21] = markType;
    $[22] = workflowId;
    $[23] = totalCount;
    $[24] = setAllWorkflowFlag;
    $[25] = currentDashboard;
    $[26] = allPTWorkflowFlag;
    $[27] = t5;
  } else {
    t5 = $[27];
  }
  useEffect(t4, t5);
  let t6;
  if ($[28] !== allWorkflowFlag || $[29] !== listToMarkPending.length || $[30] !== allPTWorkflowFlag) {
    t6 = () => {
      if (allWorkflowFlag && listToMarkPending.length === 0 || allPTWorkflowFlag && listToMarkPending.length === 0) {
        setIsDeterminate(false);
      }
    };
    $[28] = allWorkflowFlag;
    $[29] = listToMarkPending.length;
    $[30] = allPTWorkflowFlag;
    $[31] = t6;
  } else {
    t6 = $[31];
  }
  let t7;
  if ($[32] !== allPTWorkflowFlag || $[33] !== allWorkflowFlag || $[34] !== listToMarkPending) {
    t7 = [allPTWorkflowFlag, allWorkflowFlag, listToMarkPending];
    $[32] = allPTWorkflowFlag;
    $[33] = allWorkflowFlag;
    $[34] = listToMarkPending;
    $[35] = t7;
  } else {
    t7 = $[35];
  }
  useEffect(t6, t7);
  const t8 = "bulk-mark-" + markType;
  let t9;
  if ($[36] !== markType) {
    t9 = markType === "header" ? {
      position: "relative",
      left: "19%",
      padding: 0
    } : {};
    $[36] = markType;
    $[37] = t9;
  } else {
    t9 = $[37];
  }
  let t10;
  if ($[38] !== trackEvent || $[39] !== workflowId || $[40] !== workflowName || $[41] !== workflowState || $[42] !== workflowCreatedAt || $[43] !== workflowMemberUuid) {
    t10 = event_0 => {
      trackEvent(`${MIXPANEL_EVENTS.UNREAD_MESSAGE_WORKFLOW_CHECKED_FROM_CHECKBOX}-${NO_RESPONSE_PREDICTED_TAB_NAME}`, {
        selectedWorkflowID: workflowId,
        selectedWorkflowName: workflowName,
        selectedWorkflowState: (workflowState as string),
        selectedWorkflowCreatedAt: (workflowCreatedAt as string),
        selectedWorkflowMemberUuid: (workflowMemberUuid as string)
      });
      event_0.stopPropagation();
    };
    $[38] = trackEvent;
    $[39] = workflowId;
    $[40] = workflowName;
    $[41] = workflowState;
    $[42] = workflowCreatedAt;
    $[43] = workflowMemberUuid;
    $[44] = t10;
  } else {
    t10 = $[44];
  }
  let t11;
  if ($[45] === Symbol.for("react.memo_cache_sentinel")) {
    t11 = <IndeterminateCheckBoxIcon />;
    $[45] = t11;
  } else {
    t11 = $[45];
  }
  let t12;
  if ($[46] !== t8 || $[47] !== t9 || $[48] !== t10 || $[49] !== isCompleted || $[50] !== handleCheckBox || $[51] !== isDeterminate) {
    t12 = <HHCheckbox data-testid={t8} sx={t9} onClick={t10} checked={isCompleted} onChange={handleCheckBox} hhVariant="variant-bypass" indeterminate={isDeterminate} indeterminateIcon={t11} />;
    $[46] = t8;
    $[47] = t9;
    $[48] = t10;
    $[49] = isCompleted;
    $[50] = handleCheckBox;
    $[51] = isDeterminate;
    $[52] = t12;
  } else {
    t12 = $[52];
  }
  return t12;
};