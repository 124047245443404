import { CoachWorkflowTypes, OsWorkflowTypes, PtWorkflowTypes, UnreadMessagesWorkflowTypes } from '../../../../custom-types';
export const WORKFLOW_PANEL_TITLES: Record<string, string> = {
  [CoachWorkflowTypes.FirstActionPlan]: 'Create action plan',
  [CoachWorkflowTypes.UnreadMessages]: 'Unread Message',
  [PtWorkflowTypes.UnreadMessages]: 'Unread Message',
  [CoachWorkflowTypes.MemberAlertReminder]: 'Alert Reminder',
  [CoachWorkflowTypes.ActionPlanReview]: 'Review and recalibrate',
  [CoachWorkflowTypes.FirstWeekGoal]: 'Review and recalibrate',
  [CoachWorkflowTypes.PregnancyStatusChange]: 'Pregnancy Status Change',
  [CoachWorkflowTypes.OfferVideoVisit]: 'Offer Video Visit',
  [CoachWorkflowTypes.MemberHealthLog]: 'Note',
  [PtWorkflowTypes.HealthLog]: 'Note',
  [CoachWorkflowTypes.MemberPainUptick]: 'Pain uptick',
  [PtWorkflowTypes.PainUptick]: 'Pain uptick',
  [CoachWorkflowTypes.MaternityMilestones]: 'Maternity Milestones',
  [CoachWorkflowTypes.FirstWeekCheckIn]: 'First week check-in',
  [CoachWorkflowTypes.ActionPlanCheckIn]: 'Action plan check-in',
  [CoachWorkflowTypes.ReEngager]: 'Re-engager',
  [PtWorkflowTypes.SurgeryTrend]: 'Surgery trend',
  [CoachWorkflowTypes.BalanceAssessment]: 'Balance Assessment',
  [PtWorkflowTypes.EtModification]: 'ET modification',
  [PtWorkflowTypes.IndicationUpdate]: 'Indication update',
  [PtWorkflowTypes.ClinicalEscalation]: 'Clinical escalation',
  [PtWorkflowTypes.MedicalEmergencyFollowUp]: 'Medical emergency follow-up',
  [CoachWorkflowTypes.CoachEscalation]: 'Coach escalation',
  [PtWorkflowTypes.FirstWeekEnsoCheckIn]: 'Enso first week checkin',
  [CoachWorkflowTypes.WeeklyEnsoReview]: 'Weekly Enso review',
  [UnreadMessagesWorkflowTypes.CareTeamMessage]: 'Care team message',
  [CoachWorkflowTypes.OutreachNeeded]: 'Outreach needed',
  [PtWorkflowTypes.AqRedFlags]: 'AQ red flags',
  [PtWorkflowTypes.PtMemberAlertReminder]: 'Alert reminder',
  [PtWorkflowTypes.ActionPlanReview]: 'Review and recalibrate',
  [PtWorkflowTypes.SetupActionPlan]: 'Setup action plan',
  [PtWorkflowTypes.ModularIamUnreadMessages]: 'Care team HLN',
  [CoachWorkflowTypes.CoachWeeklyGoalExpiry]: 'Review Plan',
  [PtWorkflowTypes.Closing]: 'Discharge and close plan',
  [PtWorkflowTypes.Expiring]: 'Expiring care plans',
  [CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal]: 'Member updated plan',
  [OsWorkflowTypes.MemberAlertReminder]: 'Alert Reminder',
  [OsWorkflowTypes.UnreadMessages]: 'Unread Message',
  [OsWorkflowTypes.ReEngager]: 'Re-engager',
  [OsWorkflowTypes.OutreachNeeded]: 'Outreach Needed',
  [OsWorkflowTypes.Nuto]: 'Onboard member - New',
  [OsWorkflowTypes.Ruto]: 'Onboard member - Returning'
};
export const WORKFLOW_PANEL_SUBTITLES: Partial<Record<CoachWorkflowTypes | PtWorkflowTypes, string>> = {
  [CoachWorkflowTypes.MemberHealthLog]: 'Health log',
  [CoachWorkflowTypes.MemberPainUptick]: 'Health log',
  [CoachWorkflowTypes.BalanceAssessment]: 'Health log',
  [PtWorkflowTypes.HealthLog]: 'Health log',
  [PtWorkflowTypes.PainUptick]: 'Health log',
  [UnreadMessagesWorkflowTypes.CareTeamMessage]: 'Unread',
  [PtWorkflowTypes.SetupActionPlan]: 'Action plans',
  [PtWorkflowTypes.ModularIamUnreadMessages]: '',
  [CoachWorkflowTypes.CoachWeeklyGoalExpiry]: 'Action plans',
  [CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal]: 'Action plans'
};