import { c as _c } from "react-compiler-runtime";
import { HHBox, HHCircularProgress, HHGrid, HHTypography } from '@hinge-health/react-component-library';
import { ctpTokens } from '@hinge-health/react-component-library/dist/styles/ctp-theme';
import { ErrorOutlineRounded, Event, Person, WarningAmberRounded } from '@mui/icons-material';
import { useCallback } from 'react';
import { QueueIconTypes } from '../../custom-types';
type QueueAccordionHeaderCountProps = {
  iconType: QueueIconTypes;
  value: string;
  loading: boolean;
};
const styles = {
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  typography: {
    fontWeight: 500,
    fontSize: 12,
    marginTop: 0.5,
    marginLeft: 1
  }
};
export const QueueAccordionHeaderCount = t0 => {
  const $ = _c(10);
  const {
    iconType,
    value,
    loading
  } = t0;
  let t1;
  if ($[0] !== iconType) {
    t1 = () => {
      switch (iconType) {
        case QueueIconTypes.Overdue:
          {
            return <ErrorOutlineRounded sx={{
              color: ctpTokens.palette.red[40]
            }} />;
          }
        case QueueIconTypes.DueSoon:
          {
            return <WarningAmberRounded sx={{
              color: ctpTokens.palette.yellow[30]
            }} />;
          }
        case QueueIconTypes.Total:
          {
            return <Person sx={{
              color: ctpTokens.palette.neutral.shades.primary["50p"]
            }} />;
          }
        case QueueIconTypes.Event:
          {
            return <Event sx={{
              color: ctpTokens.palette.navy[40]
            }} />;
          }
        default:
          {
            return null;
          }
      }
    };
    $[0] = iconType;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const renderIcon = t1;
  if (loading) {
    let t2;
    if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
      t2 = <HHCircularProgress size={10} />;
      $[2] = t2;
    } else {
      t2 = $[2];
    }
    return t2;
  }
  let t2;
  if ($[3] !== renderIcon) {
    t2 = renderIcon();
    $[3] = renderIcon;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  let t3;
  if ($[5] !== value) {
    t3 = <HHTypography hhVariant="variant-bypass" sx={styles.typography}>{value}</HHTypography>;
    $[5] = value;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  let t4;
  if ($[7] !== t2 || $[8] !== t3) {
    t4 = <HHGrid item={true} xs={2}><HHBox sx={styles.box}>{t2}{t3}</HHBox></HHGrid>;
    $[7] = t2;
    $[8] = t3;
    $[9] = t4;
  } else {
    t4 = $[9];
  }
  return t4;
};