export const SPLIT_TREATMENTS = {
  VIDEO_VISIT_FOLLOWUPS: 'video-visit-followups',
  THREE_PANEL_LAYOUT: 'three-panel-layout',
  PT_UNREAD_MESSAGES: 'pt-unread-messages',
  PT_DASHBOARD: 'pt-dashboard',
  PT_HOUSE_CALLS: 'pt-house-calls',
  CTT_DEPRECATION_VIDEO_VISIT: 'ctt-deprecation-video-visit',
  RING_CENTRAL_TO_ZOOM_MIGRATION: 'ring-central-to-zoom-migration',
  PT_POOLED_REASSIGNEMNT: 'pt-pooled-reassignment',
  PT_POOLED_DASHBOARD: 'pt-pooled-dashboard',
  MODULAR_IAM_UNREAD_MESSAGE: 'modular-iam-unread-message',
  MODULAR_IAM_PAIN_INSIGHTS: 'modular-iam-pain-insights'
};
export const ON = 'on';
export const OFF = 'off';