export const ACTIVITY_TAB = 'activity-tab';
export const INTERACTIONS_TAB = 'member-interactions';
export const HEALTH_LOG_AND_PAIN_DATA = 'health-log-and-pain-data';
export const MEMBER_RECORD_CUT_OVER_SPLIT = 'member-record-cut-over';
export const PELVIC_TRAINER_DATA = 'pelvic-trainer-data';
export const LIBRARY_DMET_TABLE = 'library-dmet-table';
export const NO_SENSOR_REP_COUNT = 'no-sensor-rep-counts';
export const MEMBER_SEARCH_SPLIT = 'member-search';
export const PAIN_SCORE_CHART_SPLIT = 'pain-score-chart';
export const EXERCISE_THERAPY_COUNT_CHART_SPLIT = 'exercise-therapy-count-chart';
export const EXERCISE_THERAPY_GOALS_CHART_SPLIT = 'exercise-therapy-goals-chart';
export const DMET_AND_LIBRARY_ET_VIZ_GOALS_SPLIT = 'dmet-and-library-et-viz-and-goals';
export const ENSO_ROUTINE_VISUALIZATION_SPLIT = 'enso-routine-visualisation';
export const ENABLE_SEE_MORE_ENSO_USAGE = 'enable-see-more-enso-usage';
export const ENABLE_TOGGLE_PAIN_CHART = 'pain-score-chart-toggle-buttons';
export const MPE_QUEUE_SPLIT = 'mpe-queue';
export const ON = 'on';
export const OFF = 'off';