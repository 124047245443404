import { Admin, Maybe } from '../types';
export interface Patient {
  firstName: string;
  lastName: string;
  firstNamePreferred?: string;
}
export const createFullName = (patient?: Maybe<Patient>): string => {
  if (!patient) return '';
  const firstName = patient.firstName;
  const lastName = patient.lastName;
  return `${firstName} ${lastName}`;
};
export const createFirstNameLastInitial = (patient?: Maybe<Patient>): string => {
  if (!patient) return '';
  const firstName = patient.firstName || '';
  const lastInitial = patient.lastName?.charAt(0) || '';
  return `${firstName} ${lastInitial}`;
};
export const adminFirstNameLastInitial = (adminProfile: Admin): string => {
  const {
    firstName,
    lastName
  } = adminProfile;
  return `${firstName} ${lastName[0]}`;
};