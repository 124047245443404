import { c as _c } from "react-compiler-runtime";
import { HHBox, HHSnackbarAlert, HHStack } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { BATCH_GENERATION_TYPES } from '../../constants/strings/batch-generation-types';
import { OS_LAYOUT } from '../../constants/strings/os-layout';
import { BULK_TRANSITION_TIMEOUT_MESSAGE, PLEASE_REFRESH_THE_PAGE_WORKFLOW_PARTIAL_SUCCESSFUL } from '../../constants/strings/workflows-table';
import { DashboardType } from '../../custom-types';
import { useFilterStates } from '../../hooks/use-filter-states';
import { useGetOsCounts } from '../../hooks/use-get-os-counts';
import { shouldRefetchWorkflows, showTimeoutAlert } from '../../jotai/atoms';
import { GenerateAiContentForUpcomingWorkflowsMutation, useGenerateAiContentForUpcomingWorkflows } from '../../mutations/generate-ai-content-for-upcoming-workflows.graphql';
import { OsDashboardFilters } from '../../types/os';
import { WorkflowFilters } from './filters/workflow-filters';
import { WorkflowsTable } from './workflows-table/workflows-table';
const styles = {
  containerStyle: {
    display: 'flex',
    flexDirection: 'row'
  },
  tableContainerStyle: {
    flex: 1
  }
};
export const OsDashboard = () => {
  const $ = _c(26);
  const filterStates = useFilterStates(DashboardType.OnboardingSpecialist);
  const workflowTypesServed = filterStates[OsDashboardFilters.All].workflowTypes;
  let t0;
  if ($[0] !== workflowTypesServed) {
    t0 = {
      workflowTypes: workflowTypesServed
    };
    $[0] = workflowTypesServed;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const {
    counts,
    loading
  } = useGetOsCounts(t0);
  const [showAlertTimeout] = useAtom(showTimeoutAlert);
  const [refetchWorkflows, setShouldRefetchWorkflows] = useAtom(shouldRefetchWorkflows);
  const [generateAIContentForUpcomingWorkflows] = useGenerateAiContentForUpcomingWorkflows();
  let t1;
  let t2;
  if ($[2] !== generateAIContentForUpcomingWorkflows) {
    t1 = () => {
      const generateAiMessagesContent = async () => (await generateAIContentForUpcomingWorkflows({
        variables: {
          input: {
            type: BATCH_GENERATION_TYPES.MESSAGES
          }
        },
        onError: () => {}
      }) as GenerateAiContentForUpcomingWorkflowsMutation);
      const generateAiInsightsContent = async () => (await generateAIContentForUpcomingWorkflows({
        variables: {
          input: {
            type: BATCH_GENERATION_TYPES.INSIGHTS
          }
        },
        onError: () => {}
      }) as GenerateAiContentForUpcomingWorkflowsMutation);
      generateAiMessagesContent();
      generateAiInsightsContent();
    };
    t2 = [generateAIContentForUpcomingWorkflows];
    $[2] = generateAIContentForUpcomingWorkflows;
    $[3] = t1;
    $[4] = t2;
  } else {
    t1 = $[3];
    t2 = $[4];
  }
  useEffect(t1, t2);
  let t3;
  if ($[5] !== counts || $[6] !== loading) {
    t3 = <WorkflowFilters counts={counts} loading={loading} />;
    $[5] = counts;
    $[6] = loading;
    $[7] = t3;
  } else {
    t3 = $[7];
  }
  let t4;
  if ($[8] !== counts) {
    t4 = <HHBox sx={styles.tableContainerStyle}><WorkflowsTable counts={counts} /></HHBox>;
    $[8] = counts;
    $[9] = t4;
  } else {
    t4 = $[9];
  }
  let t5;
  let t6;
  if ($[10] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = () => {
      window.location.reload();
    };
    t6 = {
      cursor: "pointer"
    };
    $[10] = t5;
    $[11] = t6;
  } else {
    t5 = $[10];
    t6 = $[11];
  }
  let t7;
  if ($[12] !== showAlertTimeout) {
    t7 = <HHBox onClick={t5} sx={t6} data-testid="refresh-page-workflow"><HHSnackbarAlert severity="error" message={PLEASE_REFRESH_THE_PAGE_WORKFLOW_PARTIAL_SUCCESSFUL} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={showAlertTimeout} /></HHBox>;
    $[12] = showAlertTimeout;
    $[13] = t7;
  } else {
    t7 = $[13];
  }
  let t8;
  if ($[14] !== t3 || $[15] !== t4 || $[16] !== t7) {
    t8 = <HHBox data-testid={OS_LAYOUT.LAYOUT_CONTAINER_ID} sx={styles.containerStyle}>{t3}{t4}{t7}</HHBox>;
    $[14] = t3;
    $[15] = t4;
    $[16] = t7;
    $[17] = t8;
  } else {
    t8 = $[17];
  }
  let t9;
  if ($[18] !== setShouldRefetchWorkflows) {
    t9 = () => {
      setShouldRefetchWorkflows(false);
    };
    $[18] = setShouldRefetchWorkflows;
    $[19] = t9;
  } else {
    t9 = $[19];
  }
  let t10;
  if ($[20] !== refetchWorkflows || $[21] !== t9) {
    t10 = <HHStack direction="column" spacing="60px"><HHSnackbarAlert data-testid="alert" severity="success" message={BULK_TRANSITION_TIMEOUT_MESSAGE} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={refetchWorkflows} autoHideTime={3000} onClose={t9} /></HHStack>;
    $[20] = refetchWorkflows;
    $[21] = t9;
    $[22] = t10;
  } else {
    t10 = $[22];
  }
  let t11;
  if ($[23] !== t8 || $[24] !== t10) {
    t11 = <>{t8}{t10}</>;
    $[23] = t8;
    $[24] = t10;
    $[25] = t11;
  } else {
    t11 = $[25];
  }
  return t11;
};