import { c as _c } from "react-compiler-runtime";
import { ApolloError } from '@apollo/client';
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { createContext, useEffect, useMemo } from 'react';
import { useGlobalClickTracking } from '../hooks/use-mixpanel-click-tracking';
import { Admin, useCurrentAdminQuery } from '../types';
import { OktaRole } from '../types/okta-roles';
import { defaultErrorHandler } from '../utils/errors';
export type AdminWithOkta = Omit<Admin, 'carePlanData' | 'coverageTypes'> & {
  oktaRole?: string;
};
export type AdminWithOmitedFields = AdminWithOkta | undefined;
export const AdminContext = createContext<[AdminWithOmitedFields, boolean, ApolloError | undefined]>([undefined, true, undefined]);
export const AdminContextProvider = t0 => {
  const $ = _c(19);
  const {
    children
  } = t0;
  const {
    data,
    loading,
    error
  } = useCurrentAdminQuery();
  useGlobalClickTracking(data?.admin?.uuid);
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  let t1;
  let t2;
  if ($[0] !== hingeHealthAuthState) {
    t2 = hingeHealthAuthState?.accessToken?.claims.roles || [];
    $[0] = hingeHealthAuthState;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  const adminRoles = t2;
  let t3;
  if ($[2] !== adminRoles) {
    const validRoles = Object.values(OktaRole);
    t3 = adminRoles.find(userRole => validRoles.includes(userRole)) || "";
    $[2] = adminRoles;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const oktaRole = t3;
  let t4;
  if ($[4] !== data || $[5] !== oktaRole) {
    t4 = data?.admin ? {
      ...data.admin,
      oktaRole
    } : undefined;
    $[4] = data;
    $[5] = oktaRole;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  const adminData = t4;
  let t5;
  if ($[7] !== adminData || $[8] !== loading || $[9] !== error) {
    t5 = [adminData, loading, error];
    $[7] = adminData;
    $[8] = loading;
    $[9] = error;
    $[10] = t5;
  } else {
    t5 = $[10];
  }
  t1 = t5;
  const adminContextValue = t1;
  let t6;
  if ($[11] !== error) {
    t6 = () => {
      if (error) {
        defaultErrorHandler(error.message);
      }
    };
    $[11] = error;
    $[12] = t6;
  } else {
    t6 = $[12];
  }
  const t7 = data?.admin;
  let t8;
  if ($[13] !== t7 || $[14] !== error) {
    t8 = [t7, error];
    $[13] = t7;
    $[14] = error;
    $[15] = t8;
  } else {
    t8 = $[15];
  }
  useEffect(t6, t8);
  let t9;
  if ($[16] !== adminContextValue || $[17] !== children) {
    t9 = <AdminContext.Provider value={adminContextValue}>{children}</AdminContext.Provider>;
    $[16] = adminContextValue;
    $[17] = children;
    $[18] = t9;
  } else {
    t9 = $[18];
  }
  return t9;
};