import { ArrowForwardRounded } from '@mui/icons-material';
import { transformIndicationsToText } from '../../../../utils/transform-text';
export type Indication = {
  name: string;
  isPrimary: boolean;
};
export const IndicationUpdate = (previousIndications: Indication[], currentIndications: Indication[]): JSX.Element => <>
    {transformIndicationsToText(previousIndications)}
    <ArrowForwardRounded fontSize="inherit" sx={{
    margin: '0 2px 0 2px'
  }} />
    {transformIndicationsToText(currentIndications)}
  </>;