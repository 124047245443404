import { HOUSE_CALL_TAB_NAMES } from '../../../constants/strings/pt-layout';
import { PtDashboardFilters } from '../../../types/pt';
import { WorkflowTableTabsProps } from '../workflow-table-tabs';
export const getHouseCallTabsProps = (tabContent: JSX.Element): WorkflowTableTabsProps => ({
  initialFilter: PtDashboardFilters.HouseCallUpcoming,
  tabFilters: [PtDashboardFilters.HouseCallUpcoming, PtDashboardFilters.HouseCallIncomplete],
  defaultTabData: [{
    tabContent,
    tabLabel: HOUSE_CALL_TAB_NAMES.UPCOMING_HOUSE_CALLS,
    key: HOUSE_CALL_TAB_NAMES.UPCOMING_HOUSE_CALLS
  }, {
    tabContent,
    tabLabel: HOUSE_CALL_TAB_NAMES.INCOMPLETE_HOUSE_CALLS,
    key: HOUSE_CALL_TAB_NAMES.INCOMPLETE_HOUSE_CALLS
  }]
});