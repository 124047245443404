import { AcuteDecoratedWorkflow, CarePlanGroup, ExpiringCarePlanState } from '../custom-types';
import { Maybe } from '../types';
import { getCarePlanExpirationStatuses } from '../utils/get-care-plan-expiration-status';
export const useCarePlanChipProps = (workflow: AcuteDecoratedWorkflow): Record<string, string | boolean | undefined | null> => {
  const {
    type,
    progress,
    state,
    carePlans,
    tasks
  } = workflow;
  const isExpiringCarePlan = type === CarePlanGroup.Expiring;
  let expirationDate: Maybe<string> | undefined = '';
  if (carePlans?.length) {
    expirationDate = carePlans[0].expires;
  }
  const {
    isWithinTenDaysOfExpiration,
    isSameOrAfterExpirationDate,
    timeFromExpiration,
    isAfterExpiration,
    canShowCarePlans
  } = getCarePlanExpirationStatuses(expirationDate, type);
  const canCheckTaskProgress = state === ExpiringCarePlanState.ExpiringFull && progress && tasks?.length;
  const isConnectWithMemberTaskCompleted = !!(canCheckTaskProgress && progress[0] >= 1);
  const allTasksCompleted = !!(canCheckTaskProgress && progress[0] === progress[1]);
  return {
    isExpiringCarePlan,
    isSameOrAfterExpirationDate,
    isWithinTenDaysOfExpiration,
    isConnectWithMemberTaskCompleted,
    isAfterExpiration,
    timeFromExpiration,
    canShowCarePlans,
    expirationDate,
    allTasksCompleted
  };
};