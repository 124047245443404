import { c as _c } from "react-compiler-runtime";
import { HHCircularProgress } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import { COACH_LAYOUT } from '../constants/strings/coach-layout';
import { DashboardType } from '../custom-types';
import { selectedDashboardType } from '../jotai/atoms';
import { CoachDashboard } from './dashboard/coach-dashboard';
import { OsDashboard } from './dashboard/os-dashboard';
import { PtDashboard } from './dashboard/pt-dashboard';
const styles = {
  spinnerStyle: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
};
interface CoachLayoutProps {
  loading?: boolean;
}
export const DashboardLayout = props => {
  const $ = _c(4);
  const [dashboardType] = useAtom(selectedDashboardType);
  const {
    loading
  } = props;
  if (loading) {
    let t0;
    if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <HHCircularProgress size={24} data-testid={COACH_LAYOUT.LOADER_ID} sx={styles.spinnerStyle} />;
      $[0] = t0;
    } else {
      t0 = $[0];
    }
    return t0;
  }
  switch (dashboardType) {
    case DashboardType.PhysicalTherapist:
      {
        let t0;
        if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
          t0 = <PtDashboard />;
          $[1] = t0;
        } else {
          t0 = $[1];
        }
        return t0;
      }
    case DashboardType.OnboardingSpecialist:
      {
        let t0;
        if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
          t0 = <OsDashboard />;
          $[2] = t0;
        } else {
          t0 = $[2];
        }
        return t0;
      }
    default:
      {
        let t0;
        if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
          t0 = <CoachDashboard />;
          $[3] = t0;
        } else {
          t0 = $[3];
        }
        return t0;
      }
  }
};