export const MEMBER_ALERT_THEME = {
  FONT_FAMILY: 'Roboto',
  FONT_SIZES: {
    X_LARGE: 24,
    LARGE: 16,
    SMALL: 14,
    X_SMALL: 12,
    MEDIUM: 15
  },
  COLOR: {
    TEXT: {
      SECONDARY: '#5C6469',
      ERROR: '#D61916',
      SUCCESS: '#0A7D8F'
    },
    ICON: {
      NEUTRAL_50: '#7A8387',
      SECONDARY: 'rgba(0, 0, 0, 0.26)'
    },
    BACKGROUND: {
      SECONDARY: 'rgba(0, 0, 0, 0.08)',
      ERROR: '#FFD7CF',
      SUCCESS: '#C3E2E5'
    }
  }
};