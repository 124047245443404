import { c as _c } from "react-compiler-runtime";
import { HHAlert, HHBox, HHSnackbarAlert, HHStack, HHTypography } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { BATCH_GENERATION_TYPES } from '../../constants/strings/batch-generation-types';
import { COACH_LAYOUT } from '../../constants/strings/coach-layout';
import { BULK_TRANSITION_TIMEOUT_MESSAGE, FAILED_TO_COMPLETE, PLEASE_REFRESH_THE_PAGE_WORKFLOW_PARTIAL_SUCCESSFUL, PLEASE_TRY_AGAIN_LATER } from '../../constants/strings/workflows-table';
import { DashboardType } from '../../custom-types';
import { useFilterStates } from '../../hooks/use-filter-states';
import { useGetCoachCounts } from '../../hooks/use-get-coach-counts';
import { bulkShowHLNFailedAlert, bulkShowHLNSuccessAlert, bulkShowUnreadFailedAlert, bulkShowUnreadSuccessAlert, markCompleteAlertMessage, markCompleteAlertType, shouldRefetchWorkflows, showMarkCompleteAlert, showTimeoutAlert, workflowIdsFailedTransition, workflowIdsSuccessfullyTransitioned } from '../../jotai/atoms';
import { useGenerateAiContentForUpcomingWorkflows } from '../../mutations/generate-ai-content-for-upcoming-workflows.graphql';
import { GenerateAiContentForUpcomingWorkflowsMutation } from '../../types';
import { CoachDashboardFilters } from '../../types/coach';
import { WorkflowFilters } from './filters/workflow-filters';
import { WorkflowsTable } from './workflows-table/workflows-table';
const styles = {
  containerStyle: {
    display: 'flex',
    flexDirection: 'row'
  },
  tableContainerStyle: {
    flex: 1
  }
};
export const CoachDashboard = () => {
  const $ = _c(58);
  const filterStates = useFilterStates(DashboardType.Coach);
  const workflowTypesServed = filterStates[CoachDashboardFilters.All].workflowTypes;
  let t0;
  if ($[0] !== workflowTypesServed) {
    t0 = {
      workflowTypes: workflowTypesServed
    };
    $[0] = workflowTypesServed;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const {
    counts,
    loading
  } = useGetCoachCounts(t0);
  const [generateAIContentForUpcomingWorkflows] = useGenerateAiContentForUpcomingWorkflows();
  const [bulkHLNSuccess, setBulkHLNSuccess] = useAtom(bulkShowHLNSuccessAlert);
  const [bulkUnreadSuccess, setBulkUnreadSuccess] = useAtom(bulkShowUnreadSuccessAlert);
  const [bulkHLNFailed, setBulkHLNFailed] = useAtom(bulkShowHLNFailedAlert);
  const [bulkUnreadFailed, setBulkUnreadFailed] = useAtom(bulkShowUnreadFailedAlert);
  const [workflowSuccessful] = useAtom(workflowIdsSuccessfullyTransitioned);
  const [workflowFailed] = useAtom(workflowIdsFailedTransition);
  const [showAlertTimeout] = useAtom(showTimeoutAlert);
  const [showMarkAlert, setShowAlert] = useAtom(showMarkCompleteAlert);
  const [alertMessageType] = useAtom(markCompleteAlertType);
  const [message] = useAtom(markCompleteAlertMessage);
  const [refetchWorkflows, setShouldRefetchWorkflows] = useAtom(shouldRefetchWorkflows);
  let t1;
  if ($[2] !== setShowAlert) {
    t1 = () => {
      setShowAlert(false);
    };
    $[2] = setShowAlert;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  const handleClose = t1;
  setTimeout(() => {
    setBulkHLNSuccess(false);
    setBulkUnreadSuccess(false);
    setBulkHLNFailed(false);
    setBulkUnreadFailed(false);
    setShowAlert(false);
  }, 3000);
  let t2;
  let t3;
  if ($[4] !== generateAIContentForUpcomingWorkflows) {
    t2 = () => {
      const generateAiMessagesContent = async () => (await generateAIContentForUpcomingWorkflows({
        variables: {
          input: {
            type: BATCH_GENERATION_TYPES.MESSAGES
          }
        },
        onError: () => {}
      }) as GenerateAiContentForUpcomingWorkflowsMutation);
      const generateAiInsightsContent = async () => (await generateAIContentForUpcomingWorkflows({
        variables: {
          input: {
            type: BATCH_GENERATION_TYPES.INSIGHTS
          }
        },
        onError: () => {}
      }) as GenerateAiContentForUpcomingWorkflowsMutation);
      generateAiMessagesContent();
      generateAiInsightsContent();
    };
    t3 = [generateAIContentForUpcomingWorkflows];
    $[4] = generateAIContentForUpcomingWorkflows;
    $[5] = t2;
    $[6] = t3;
  } else {
    t2 = $[5];
    t3 = $[6];
  }
  useEffect(t2, t3);
  let t4;
  if ($[7] !== counts || $[8] !== loading) {
    t4 = <WorkflowFilters counts={counts} loading={loading} />;
    $[7] = counts;
    $[8] = loading;
    $[9] = t4;
  } else {
    t4 = $[9];
  }
  let t5;
  if ($[10] !== counts) {
    t5 = <HHBox sx={styles.tableContainerStyle}><WorkflowsTable counts={counts} /></HHBox>;
    $[10] = counts;
    $[11] = t5;
  } else {
    t5 = $[11];
  }
  let t6;
  if ($[12] !== t4 || $[13] !== t5) {
    t6 = <HHBox data-testid={COACH_LAYOUT.LAYOUT_CONTAINER_ID} sx={styles.containerStyle}>{t4}{t5}</HHBox>;
    $[12] = t4;
    $[13] = t5;
    $[14] = t6;
  } else {
    t6 = $[14];
  }
  let t7;
  if ($[15] !== alertMessageType || $[16] !== message || $[17] !== showMarkAlert || $[18] !== handleClose) {
    t7 = <HHBox><HHSnackbarAlert severity={alertMessageType} message={message} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={showMarkAlert} onClose={handleClose} autoHideTime={3000} /></HHBox>;
    $[15] = alertMessageType;
    $[16] = message;
    $[17] = showMarkAlert;
    $[18] = handleClose;
    $[19] = t7;
  } else {
    t7 = $[19];
  }
  let t8;
  let t9;
  if ($[20] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = () => {
      window.location.reload();
    };
    t9 = {
      cursor: "pointer"
    };
    $[20] = t8;
    $[21] = t9;
  } else {
    t8 = $[20];
    t9 = $[21];
  }
  let t10;
  if ($[22] !== showAlertTimeout) {
    t10 = <HHBox onClick={t8} sx={t9} data-testid="refresh-page-workflow"><HHSnackbarAlert severity="error" message={PLEASE_REFRESH_THE_PAGE_WORKFLOW_PARTIAL_SUCCESSFUL} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={showAlertTimeout} autoHideTime={3000} /></HHBox>;
    $[22] = showAlertTimeout;
    $[23] = t10;
  } else {
    t10 = $[23];
  }
  const t11 = workflowSuccessful.HLN + " tasks marked complete";
  const t12 = bulkHLNSuccess && workflowFailed.HLN === 0;
  let t13;
  if ($[24] !== t11 || $[25] !== t12) {
    t13 = <HHSnackbarAlert data-testid="alert" severity="success" message={t11} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={t12} />;
    $[24] = t11;
    $[25] = t12;
    $[26] = t13;
  } else {
    t13 = $[26];
  }
  const t14 = workflowSuccessful.Unread + " tasks marked complete";
  const t15 = bulkUnreadSuccess && workflowFailed.Unread === 0;
  let t16;
  if ($[27] !== t14 || $[28] !== t15) {
    t16 = <HHSnackbarAlert data-testid="alert" severity="success" message={t14} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={t15} />;
    $[27] = t14;
    $[28] = t15;
    $[29] = t16;
  } else {
    t16 = $[29];
  }
  let t17;
  if ($[30] !== setShouldRefetchWorkflows) {
    t17 = () => {
      setShouldRefetchWorkflows(false);
    };
    $[30] = setShouldRefetchWorkflows;
    $[31] = t17;
  } else {
    t17 = $[31];
  }
  let t18;
  if ($[32] !== refetchWorkflows || $[33] !== t17) {
    t18 = <HHSnackbarAlert data-testid="alert" severity="success" message={BULK_TRANSITION_TIMEOUT_MESSAGE} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={refetchWorkflows} autoHideTime={3000} onClose={t17} />;
    $[32] = refetchWorkflows;
    $[33] = t17;
    $[34] = t18;
  } else {
    t18 = $[34];
  }
  let t19;
  if ($[35] !== bulkHLNFailed || $[36] !== workflowFailed) {
    t19 = bulkHLNFailed && workflowFailed.HLN > 0 && <HHAlert severity="error" hhVariant="standard" layoutStyles={{
      position: "fixed",
      top: 39,
      right: 0
    }}><HHTypography sx={{
        fontWeight: "bold"
      }} hhVariant="inline">{FAILED_TO_COMPLETE} {workflowFailed.HLN} tasks</HHTypography><br />{PLEASE_TRY_AGAIN_LATER}</HHAlert>;
    $[35] = bulkHLNFailed;
    $[36] = workflowFailed;
    $[37] = t19;
  } else {
    t19 = $[37];
  }
  let t20;
  if ($[38] !== bulkUnreadFailed || $[39] !== workflowFailed) {
    t20 = bulkUnreadFailed && workflowFailed.Unread > 0 && <HHAlert severity="error" hhVariant="standard" layoutStyles={{
      position: "fixed",
      top: 39,
      right: 0
    }}><HHTypography sx={{
        fontWeight: "bold"
      }} hhVariant="inline">{FAILED_TO_COMPLETE} {workflowFailed.Unread} tasks</HHTypography><br />{PLEASE_TRY_AGAIN_LATER}</HHAlert>;
    $[38] = bulkUnreadFailed;
    $[39] = workflowFailed;
    $[40] = t20;
  } else {
    t20 = $[40];
  }
  let t21;
  if ($[41] !== showMarkAlert || $[42] !== alertMessageType || $[43] !== message || $[44] !== handleClose) {
    t21 = showMarkAlert && <HHSnackbarAlert severity={alertMessageType} message={message} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={showMarkAlert} onClose={handleClose} autoHideTime={3000} />;
    $[41] = showMarkAlert;
    $[42] = alertMessageType;
    $[43] = message;
    $[44] = handleClose;
    $[45] = t21;
  } else {
    t21 = $[45];
  }
  let t22;
  if ($[46] !== t13 || $[47] !== t16 || $[48] !== t18 || $[49] !== t19 || $[50] !== t20 || $[51] !== t21) {
    t22 = <HHStack direction="column" spacing="60px">{t13}{t16}{t18}{t19}{t20}{t21}</HHStack>;
    $[46] = t13;
    $[47] = t16;
    $[48] = t18;
    $[49] = t19;
    $[50] = t20;
    $[51] = t21;
    $[52] = t22;
  } else {
    t22 = $[52];
  }
  let t23;
  if ($[53] !== t6 || $[54] !== t7 || $[55] !== t10 || $[56] !== t22) {
    t23 = <>{t6}{t7}{t10}{t22}</>;
    $[53] = t6;
    $[54] = t7;
    $[55] = t10;
    $[56] = t22;
    $[57] = t23;
  } else {
    t23 = $[57];
  }
  return t23;
};