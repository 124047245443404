import { useQuery } from '@apollo/client';
import { HHBox, HHTypography } from '@hinge-health/react-component-library';
import { ctpTokens } from '@hinge-health/react-component-library/dist/styles/ctp-theme';
import Tooltip from '@mui/material/Tooltip';
import { Key, useEffect, useState } from 'react';
import { CoachWorkflowTypes, PtWorkflowTypes } from '../../../../../../custom-types';
import { generateKey } from '../../../../../../utils/app-utils';
import { GET_LAST_ET_SESSION } from '../../../../../member-360/queries/user.graphql';
import { COACH_WORKFLOWS_TABLE } from '../../../../constants/strings/workflows-table';
import { WorkflowWithListProperties } from '../../../../custom-types';
import { getWorkflowSubtitle } from '../../../../utils/get-workflow-subtitle';
export const WORKFLOW_TYPE_SECONDARY_TEXT_TEST_ID = 'workflow-type-secondary-text';
export const WORKFLOW_TYPE_SECONDARY_TEXT = 'workflow-type-secondary-text';
interface WorkflowTypeSecondaryTextProps {
  workflow: WorkflowWithListProperties;
}
export const WorkflowTypeSecondaryText = ({
  workflow
}: WorkflowTypeSecondaryTextProps): JSX.Element => {
  const [truncatedText, setTruncatedText] = useState(false);
  const [showToolTipValue, setShowToolTipValue] = useState(false);
  const {
    data: lastEtSessionData
  } = useQuery(GET_LAST_ET_SESSION, {
    variables: {
      uuid: workflow.patient?.id
    },
    skip: !workflow.patient?.id || workflow.type !== PtWorkflowTypes.FirstWeekEnsoCheckIn && workflow.type !== CoachWorkflowTypes.WeeklyEnsoReview
  });
  let subTitle = getWorkflowSubtitle(workflow, undefined, lastEtSessionData);
  if (workflow?.customFields?.messageBodies?.length > 1 && typeof subTitle === 'string' && subTitle.slice(-3) !== '...') {
    subTitle += '...';
  }
  const showSubtitle = subTitle !== null;
  const subText = subTitle || COACH_WORKFLOWS_TABLE.SECTION_DETAILS.DEFAULT;
  const truncatedFlag = typeof subText === 'string' && subText.length === 53 && subText.slice(-3) === '...' || workflow?.customFields?.messageBodies?.length > 1;
  let completeSubText = subText;
  if (truncatedFlag) {
    const messageBodiesString = workflow?.customFields?.messageBodies?.map((message: string, index: Key) => <div key={generateKey(WORKFLOW_TYPE_SECONDARY_TEXT, index)}>
          {message}
        </div>);
    completeSubText = workflow?.customFields?.memberAlert?.description || workflow?.customFields?.messageBodies?.length > 1 && <>{messageBodiesString}</> || workflow?.customFields?.body || workflow?.healthLog?.note || subText;
  }
  useEffect(() => {
    setTruncatedText(truncatedFlag);
  }, [truncatedFlag]);
  if (!showSubtitle) {
    return <></>;
  }
  return <HHBox data-testid={WORKFLOW_TYPE_SECONDARY_TEXT_TEST_ID} onMouseEnter={(): void => setShowToolTipValue(true)} onMouseLeave={(): void => setShowToolTipValue(false)}>
      <HHTypography hhVariant="variant-bypass" variant="body1" color={ctpTokens.palette.neutral[40]}>
        {subText}
      </HHTypography>
      {truncatedText && showToolTipValue && <Tooltip title={completeSubText} open={showToolTipValue} arrow>
          <HHTypography hhVariant="body" />
        </Tooltip>}
    </HHBox>;
};