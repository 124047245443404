import { c as _c } from "react-compiler-runtime";
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { OktaRole } from '../types/okta-roles';
interface AdminRoleContextType {
  adminOktaRole: OktaRole | string;
}
const OktaAdminRoleContext = createContext<AdminRoleContextType>(({} as AdminRoleContextType));
export const OktaAdminRoleProvider = t0 => {
  const $ = _c(9);
  const {
    children
  } = t0;
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  let t1;
  let t2;
  if ($[0] !== hingeHealthAuthState) {
    t2 = hingeHealthAuthState?.accessToken?.claims.roles || [];
    $[0] = hingeHealthAuthState;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  const adminRoles = t2;
  let t3;
  if ($[2] !== adminRoles) {
    const validRoles = Object.values(OktaRole);
    t3 = adminRoles.find(userRole => validRoles.includes(userRole)) || "";
    $[2] = adminRoles;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  t1 = t3;
  const adminOktaRole = t1;
  let t4;
  if ($[4] !== adminOktaRole) {
    t4 = {
      adminOktaRole
    };
    $[4] = adminOktaRole;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  let t5;
  if ($[6] !== t4 || $[7] !== children) {
    t5 = <OktaAdminRoleContext.Provider value={t4}>{children}</OktaAdminRoleContext.Provider>;
    $[6] = t4;
    $[7] = children;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  return t5;
};
export const useOktaAdminRole = () => {
  return useContext(OktaAdminRoleContext);
};