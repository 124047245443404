import { c as _c } from "react-compiler-runtime";
import { HHAlert, HHCollapse } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { SseEvents } from '../../constants/strings/events';
import { completedAlert, QUEUE_ALERT_CATEGORY_NAMES } from '../../constants/strings/workflows-queue';
import { commonCoachCompletedStates, CommonCoachState } from '../../custom-types';
import { useSse } from '../../hooks/use-sse';
import { currentQueueWorkflowIdAtom, lastWorkflowInCategoryAtom } from '../../state/queue-atoms';
const alertStyles = {
  position: 'absolute',
  top: 75,
  zIndex: 100,
  left: '50%',
  transform: 'translateX(-50%)'
};
export const QueueCategoryAlert = () => {
  const $ = _c(19);
  const [completedCategory, setCompletedCategory] = useState();
  const [currentQueueWorkflowId] = useAtom(currentQueueWorkflowIdAtom);
  const [lastWorkflowInCategory] = useAtom(lastWorkflowInCategoryAtom);
  const [showAlert, setShowAlert] = useState(false);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = [SseEvents.WorkflowTransitioned];
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const sseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, t0);
  let t1;
  let t2;
  if ($[1] !== sseData || $[2] !== currentQueueWorkflowId || $[3] !== lastWorkflowInCategory) {
    t1 = () => {
      const transitionData = (sseData as {
        workflowId: number;
        stateName: string;
        serviceName: string;
      });
      if (transitionData?.workflowId === currentQueueWorkflowId && commonCoachCompletedStates.includes((transitionData?.stateName as CommonCoachState)) && lastWorkflowInCategory) {
        const serviceName = (transitionData.serviceName as keyof typeof QUEUE_ALERT_CATEGORY_NAMES);
        setCompletedCategory(QUEUE_ALERT_CATEGORY_NAMES[serviceName]);
      }
    };
    t2 = [currentQueueWorkflowId, lastWorkflowInCategory, sseData];
    $[1] = sseData;
    $[2] = currentQueueWorkflowId;
    $[3] = lastWorkflowInCategory;
    $[4] = t1;
    $[5] = t2;
  } else {
    t1 = $[4];
    t2 = $[5];
  }
  useEffect(t1, t2);
  let t3;
  if ($[6] !== showAlert) {
    t3 = () => {
      if (showAlert) {
        const timer = setTimeout(() => {
          setCompletedCategory(undefined);
        }, 5000);
        return () => clearTimeout(timer);
      }
    };
    $[6] = showAlert;
    $[7] = t3;
  } else {
    t3 = $[7];
  }
  let t4;
  if ($[8] !== lastWorkflowInCategory || $[9] !== showAlert) {
    t4 = [lastWorkflowInCategory, showAlert];
    $[8] = lastWorkflowInCategory;
    $[9] = showAlert;
    $[10] = t4;
  } else {
    t4 = $[10];
  }
  useEffect(t3, t4);
  let t5;
  let t6;
  if ($[11] !== completedCategory) {
    t5 = () => {
      setShowAlert(completedCategory !== undefined);
    };
    t6 = [completedCategory];
    $[11] = completedCategory;
    $[12] = t5;
    $[13] = t6;
  } else {
    t5 = $[12];
    t6 = $[13];
  }
  useEffect(t5, t6);
  let t7;
  if ($[14] !== completedCategory || $[15] !== showAlert) {
    t7 = completedCategory && <HHCollapse in={showAlert}><HHAlert hhVariant="variant-bypass" children={completedAlert(completedCategory)} severity="success" sx={alertStyles} /></HHCollapse>;
    $[14] = completedCategory;
    $[15] = showAlert;
    $[16] = t7;
  } else {
    t7 = $[16];
  }
  let t8;
  if ($[17] !== t7) {
    t8 = <>{t7}</>;
    $[17] = t7;
    $[18] = t8;
  } else {
    t8 = $[18];
  }
  return t8;
};