import { TIME_STRINGS } from './acute';
export const DAY_JS_LOCALES = {
  /* eslint-disable @typescript-eslint/naming-convention */
  relativeTime: {
    future: `${TIME_STRINGS.IN} %s`,
    past: '+%s',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%d min',
    h: 'an hour',
    hh: '%d hrs',
    d: 'a day',
    dd: `%d ${TIME_STRINGS.DAYS}`,
    M: 'a month',
    MM: '%d mo',
    y: 'a year',
    yy: '%d y'
  }
  /* eslint-enable @typescript-eslint/naming-convention */
};
export const WORKFLOW_DAY = '1 day';
export const CARE_PLAN_TIME_STRINGS = {
  IN_ONE_DAY: `${TIME_STRINGS.IN} ${WORKFLOW_DAY}`,
  TODAY: 'Today'
};