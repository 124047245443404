import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useEffect, useMemo, useState } from 'react';
import { CoachWorkflowTypes, PtWorkflowTypes } from '../../../custom-types';
import { useGetIncompleteQueueCounts } from '../../../hooks/use-get-incomplete-queue-counts';
import { OktaRole } from '../../../types/okta-roles';
export const useWorkflowsCompleted = (setQueueComplete: (value: boolean) => void): {
  queueWorkflowsCompleted: boolean;
  encounterWorkflowsCompleted: boolean;
} => {
  const [queueWorkflowsCompleted, setQueueWorkflowsCompleted] = useState(false);
  const [encounterWorkflowsCompleted, setEncounterWorkflowsCompleted] = useState(false);
  const {
    subTotalCounts
  } = useGetIncompleteQueueCounts();
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const workflowType = useMemo(() => hingeHealthAuthState?.accessToken?.claims.roles.includes(OktaRole.Coach) ? [CoachWorkflowTypes.CoachCall, CoachWorkflowTypes.CoachVideoVisit] : [PtWorkflowTypes.Chronic, PtWorkflowTypes.HouseCall, PtWorkflowTypes.Acute, PtWorkflowTypes.Surgery, PtWorkflowTypes.FifteenMinute], [hingeHealthAuthState?.accessToken?.claims.roles]);
  useEffect(() => {
    if (subTotalCounts) {
      const encountersIncomplete = subTotalCounts.filter(subCount => workflowType?.includes(subCount.workflowType)).some(category => category.count && category.count > 0);
      setEncounterWorkflowsCompleted(!encountersIncomplete);
      const queueWorkflowsIncomplete = subTotalCounts.filter(subCount_0 => !workflowType?.includes(subCount_0.workflowType)).some(category_0 => category_0.count && category_0.count > 0);
      setQueueWorkflowsCompleted(!queueWorkflowsIncomplete);
    }
  }, [subTotalCounts, workflowType]);
  useEffect(() => {
    setQueueComplete(queueWorkflowsCompleted && encounterWorkflowsCompleted);
  }, [encounterWorkflowsCompleted, queueWorkflowsCompleted, setQueueComplete]);
  return {
    queueWorkflowsCompleted,
    encounterWorkflowsCompleted
  };
};