export const DEFAULT_COACH_DASHBOARD_TAB_NAME = 'All Incoming';
export const LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME = 'No Response Predicted';
export const DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER = 0;
export const COACH_CALL_TAB_POST_CALL_TASKS = 'Post Call Tasks';
export const COACH_CALL_TAB_UPCOMING_COACH_CALLS = 'Upcoming Coach Calls';
export const ENSO_WEEK_1_WORKFLOWS = 'Workflows';
export const ENSO_WEEK_1_ENCOUNTERS = 'Encounters';
export const COACH_VIDEO_VISIT_TAB_NAMES = {
  INCOMPLETE_VIDEO_VISITS: 'Post Video Visit Tasks',
  UPCOMING_VIDEO_VISITS: 'Upcoming Video Visits'
};