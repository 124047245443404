export const MAX_CHARACTERS = 4000;
export const COMMON_FORM = {
  DESCRIPTOIN: 'Description',
  ADDITIONAL_CONTEXT: 'Additional context',
  ASSIGNEE: 'Assignee',
  NO_ASSIGNEE: 'No assignee',
  ERROR: 'There was an error',
  REQUIRED_FIELD_ERROR: 'This field is required',
  MAX_CHARACTERS_ERROR: `Character limit reached (max ${MAX_CHARACTERS})`
};
export const CLINICAL_ESCALATION_FORM = {
  TITLE: 'Clinical escalation',
  REASON_FOR_ESCALATION: 'Reason for escalation',
  ADDITIONAL_CONTEXT_HELPER: 'Optional: Include resources and guidance already shared'
};
export const COACH_ESCALATION_FORM = {
  TITLE: 'Coach escalation',
  REASON_FOR_ESCALATION: CLINICAL_ESCALATION_FORM.REASON_FOR_ESCALATION,
  ADDITIONAL_CONTEXT_HELPER: CLINICAL_ESCALATION_FORM.ADDITIONAL_CONTEXT_HELPER
};
export const MEDICAL_EMERGENCY_FORM = {
  TITLE: 'Medical emergency follow-up',
  LEVEL_OF_EMERGENCY: 'Level of emergency',
  LEVEL_OF_EMERGENCY_OPTIONS: ['Level 1', 'Level 2'],
  GUIDELINES_LINK: 'https://app.getguru.com/card/TjApLMpc/Clinical-Incidents-Medical-Emergency-Guidelines',
  GUIDELINES_LINK_TEXT: 'guidelines',
  ADDITIONAL_CONTEXT_HELPER: CLINICAL_ESCALATION_FORM.ADDITIONAL_CONTEXT_HELPER
};
export const ET_MODIFICATION_FORM = {
  TITLE: 'ET modification',
  BODY_PART: 'Body part',
  SIDE_OF_BODY: 'Side of body',
  SIDE_OF_BODY_OPTIONS: ['Left side', 'Right side', 'Both', 'N/A'],
  ADDITIONAL_CONTEXT_HELPER: 'Optional: Include background, if any restrictions, and resources and guidance already shared.'
};
export const FORM_ACTION_BUTTONS = {
  CANCEL: 'Cancel',
  CREATE: 'Create workflow'
};
export const CANCEL_CONFIRMATION = {
  TITLE: 'Discard new workflow?',
  CONTENT: 'The workflow has not been created yet.',
  CANCEL: FORM_ACTION_BUTTONS.CANCEL,
  DISCARD: 'Discard',
  DIALOG_WIDTH: 396
};