import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import { IEventable, INamedEvent } from '../lib/mixpanel/mixins';
import { isDev, isProduction } from './environment';
export const mixpanelToken = !isProduction ? process.env.MIXPANEL_TOKEN_DEV : process.env.MIXPANEL_TOKEN_PROD;
export const initMixpanel = (): void => {
  if (!mixpanelToken) return;
  mixpanel.init(mixpanelToken, {
    debug: isDev,
    track_pageview: 'full-url'
  });
};
export const mixpanelTrack = (eventName: string, properties?: Dict, optionsOrCallback?: RequestOptions | Callback, callback?: Callback): void => {
  if (!mixpanelToken) return;
  mixpanel.track(eventName, properties, optionsOrCallback, callback);
};
export const genericMixpanelTrack = <T extends Dict,>(eventName: string, properties?: T, optionsOrCallback?: RequestOptions | Callback, callback?: Callback): void => {
  if (!mixpanelToken) {
    return;
  }
  mixpanel.track(eventName, properties, optionsOrCallback, callback);
};
export const trackAdmin = (adminUUID: string, adminRole: string): void => {
  mixpanel.identify(adminUUID);
  return mixpanel.people.set({
    adminUUID,
    adminRole
  });
};

// This function is used to track events in Mixpanel with standardized event objects.
// e is to be an instance of a class that implements IEventable and has an eventName property.
export function trackEvent(e: IEventable & INamedEvent): void {
  const properties = e.build();
  if (!mixpanelToken) {
    return;
  }
  mixpanel.track(e.eventName, properties);
}