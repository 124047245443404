import { c as _c } from "react-compiler-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, ApolloLink, ApolloProvider, FieldFunctionOptions, from, HttpLink, InMemoryCache, NormalizedCacheObject, Observable, Operation } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import * as Sentry from '@sentry/browser';
import { withScalars } from 'apollo-link-scalars';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { ReactNode, useMemo } from 'react';
import { MEMBER_UUID_STORAGE_KEY } from '../modules/member-360/constants/strings/member';
import { PhiFieldsMaskingKey } from '../types/types';
import introspectionResult from '../utils/introspection.json';
import { getLocalStorageItem } from '../utils/local-storage-utils';
import { checkNetworkStatus } from '../utils/network-status';
const schema = buildClientSchema(((introspectionResult as unknown) as IntrospectionQuery));
const PII_FIELDS_MASKED_CUSTOM_HEADER = 'x-hh-pii-masked';
const MEMBER_UUID_CUSTOM_HEADER = 'x-hh-member-uuid';
export const networkStatusLink = new ApolloLink((operation, forward) => new Observable(observer => {
  (async (): Promise<void> => {
    const online = await checkNetworkStatus();
    if (online) {
      forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer)
      });
    } else {
      const networkOfflineMessage = 'Offline: The network is currently offline.';
      console.log(networkOfflineMessage);
      observer.error(new Error(networkOfflineMessage));
    }
  })();
}));
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BFF_URL}/graphql`,
  credentials: 'include'
});
const automationLink = new HttpLink({
  uri: `${process.env.AUTOMATION_BFF_URL}/graphql`,
  credentials: 'include'
});
const directionalTest = (operation: Operation): boolean => !!process.env.AUTOMATION_BFF_URL && operation.getContext()['automation-bff'];
const directionalLink = ApolloLink.split(directionalTest, automationLink, httpLink);
const scalarsLink = withScalars({
  schema,
  typesMap: {
    DateTime: {
      serialize: (parsed: Date | null): string | null => parsed && parsed.toISOString(),
      parseValue: (raw: number | string | null): Date | null | 0 | '' => raw && new Date(raw)
    }
  }
});
export const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, _operation): boolean => !!error
  },
  delay: {
    initial: 100,
    max: 1000,
    jitter: true
  }
});
export const errorLink = onError(({
  operation,
  graphQLErrors,
  networkError
}) => {
  if (graphQLErrors) graphQLErrors.forEach(({
    message,
    locations,
    path
  }) => {
    if (ignoreSentryLogging({
      path,
      message
    })) return;
    Sentry.captureMessage(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
  });
  if (networkError) Sentry.captureMessage(`[Network error]: ${networkError}, [Operation]: ${operation.operationName}`, 'log');
});
const authorizationHeaderCheckLink = new ApolloLink((operation, forward) => {
  const headers = operation.getContext().headers;
  if (!headers['authorization']) {
    return null;
  }
  return forward(operation);
});
const apolloLink = ApolloLink.from([authorizationHeaderCheckLink, networkStatusLink, errorLink, retryLink, scalarsLink, directionalLink]);
interface ApolloProps {
  children: ReactNode;
  client: ApolloClient<NormalizedCacheObject>;
}
export const apolloCacheOptions = {
  typePolicies: {
    Query: {
      fields: {
        user: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          read(_: any, {
            args,
            toReference
          }: any): any {
            return toReference({
              __typename: 'User',
              // id is the key for the user object in the cache which is derived from the input args i.e uuid
              id: args.uuid
            });
          }
        }
      }
    },
    WorkflowListPayload: {
      fields: {
        workflows: {
          keyArgs: [],
          merge(existing: any[] = [], incoming: any[] = [], {
            variables
          }: Partial<FieldFunctionOptions>): any[] {
            const mergeResult = variables?.workflowFiltersInput?.mergePaginatedResult;
            return mergeResult ? [...existing, ...incoming] : [...incoming];
          }
        }
      }
    },
    WorkflowPayload: {
      fields: {
        availableTransitions: {
          merge(existing: any[] = [], incoming: any[] = [], {
            readField
          }: FieldFunctionOptions): any[] {
            const filteredIncoming = incoming.filter(transition => {
              const transitionToState = readField('to', transition);
              return !existing.some(existingTransition => readField('to', existingTransition) === transitionToState);
            });
            return [...existing, ...filteredIncoming];
          }
        },
        tasks: {
          merge(existing: any[] = [], incoming: any[] = [], {
            readField
          }: FieldFunctionOptions): any[] {
            const filteredIncoming = incoming.filter(task => {
              const taskId = readField('id', task);
              return !existing.some(existingTask => readField('id', existingTask) === taskId);
            });
            return [...existing, ...filteredIncoming];
          }
        }
      }
    }
  }
};
const cache = new InMemoryCache(apolloCacheOptions);
interface AuthClient {
  isAuthenticated: () => Promise<boolean>;
  getAccessToken: () => string | undefined;
  getOrRenewAccessToken: () => Promise<string | null>;
}
export const apolloContextLink = (client: AuthClient): ApolloLink => setContext(async (_request, {
  headers
}) => {
  const accessToken = await client.getOrRenewAccessToken();
  // Get the PHI masking status from the local storage
  // and send it as a custom header to the server
  // to mask the PHI fields in the response
  const isPIIFieldsMasked = (getLocalStorageItem(PhiFieldsMaskingKey) === 'true').toString();
  const memberUuid = getLocalStorageItem(MEMBER_UUID_STORAGE_KEY);
  return {
    headers: {
      'client-type': 'with-okta-link',
      ...headers,
      ...(accessToken && {
        authorization: `Bearer ${accessToken}`
      }),
      [PII_FIELDS_MASKED_CUSTOM_HEADER]: isPIIFieldsMasked,
      ...(memberUuid && {
        [MEMBER_UUID_CUSTOM_HEADER]: memberUuid
      })
    }
  };
});
const Apollo = t0 => {
  const $ = _c(5);
  const {
    children
  } = t0;
  const {
    hingeHealthAuthClient
  } = useHingeHealthSecurityContext();
  let t1;
  const t2 = (hingeHealthAuthClient as AuthClient);
  let t3;
  if ($[0] !== t2) {
    const contextLink = apolloContextLink(t2);
    const link = from([contextLink, apolloLink]);
    t3 = new ApolloClient({
      cache,
      link
    });
    $[0] = t2;
    $[1] = t3;
  } else {
    t3 = $[1];
  }
  t1 = t3;
  const client = t1;
  let t4;
  if ($[2] !== client || $[3] !== children) {
    t4 = <ApolloProvider client={client}>{children}</ApolloProvider>;
    $[2] = client;
    $[3] = children;
    $[4] = t4;
  } else {
    t4 = $[4];
  }
  return t4;
};
const ignoreSentryLogging = ({
  path: rawPath,
  message
}: {
  path: readonly (string | number)[] | undefined;
  message: string;
}): boolean => {
  const path = rawPath?.toString();
  return path === 'refreshJwt' && message === 'Unauthorized' || path === 'user' && message.includes('404') || path === 'createActionPlan' && message.includes('Member already has an active classic action plan') || path === 'closeActionPlan' && message.includes('Failed to update action plan') ||
  // Excluding this error as this error is shown in the UI
  message.includes('Failed to create in-app message: Message interpolation null or empty value');
};
export default Apollo;