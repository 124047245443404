import { c as _c } from "react-compiler-runtime";
import { HHBox } from '@hinge-health/react-component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { namespace } from '../pages/globals/locales/namespaces';
const useStyles = makeStyles(createStyles({
  otherRoles: {
    backgroundColor: '#263744',
    color: 'white',
    minHeight: '100vh',
    padding: '24px',
    fontFamily: 'Lato',
    fontSize: '28px',
    fontWeight: 300,
    letterSpacing: '0.21px',
    display: 'flex',
    flexDirection: 'column'
  }
}));
export const NotFound = () => {
  const $ = _c(5);
  const classes = useStyles();
  const {
    t
  } = useTranslation(namespace);
  let t0;
  if ($[0] !== t) {
    t0 = t("pageNotFound");
    $[0] = t;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  let t1;
  if ($[2] !== classes.otherRoles || $[3] !== t0) {
    t1 = <HHBox className={classes.otherRoles}>{t0}</HHBox>;
    $[2] = classes.otherRoles;
    $[3] = t0;
    $[4] = t1;
  } else {
    t1 = $[4];
  }
  return t1;
};
export default NotFound;