export const HEADER_STRINGS = {
  TYPE: 'Type',
  EVENT_TIME: 'Event time',
  DUE: 'Due',
  MEMBER: 'Member'
};
export const CARD_TYPE_TEXT = 'House call';

// The task order in HouseCallTaskType impacts display order in <HouseCallTabBody />.
export enum HouseCallTaskType {
  CompleteSoapNote = 'complete-soap-note',
  UpdateCarePlan = 'update-care-plan',
  SendSummaryEmail = 'send-summary-email',
  SubmitTravelReimbursement = 'submit-travel-reimbursement',
  UpdateExercisePlan = 'update-exercise-plan',
  UploadNoteTo = 'upload-note-to-phi-drive',
  CompleteDischargeNote = 'complete-discharge-note',
}
export const HOUSE_CALL_TASKS_NAME: { [key in HouseCallTaskType]: string } = {
  [HouseCallTaskType.CompleteSoapNote]: 'Complete ',
  [HouseCallTaskType.UpdateCarePlan]: 'Create, update, and/or close ',
  [HouseCallTaskType.SendSummaryEmail]: 'Send summary ',
  [HouseCallTaskType.SubmitTravelReimbursement]: 'Submit ',
  [HouseCallTaskType.UpdateExercisePlan]: 'Update ',
  [HouseCallTaskType.UploadNoteTo]: 'Update note to ',
  [HouseCallTaskType.CompleteDischargeNote]: 'Complete '
};