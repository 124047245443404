import { c as _c } from "react-compiler-runtime";
import { AcuteCarePlanChips } from '../../../../acute-physical-therapy/components/acute-care-plan-chips';
import { useCarePlanChipProps } from '../../../../acute-physical-therapy/hooks/use-care-plan-chip-props';
import { WorkflowWithListProperties } from '../../../custom-types';
export interface CarePlanChipProps {
  workflow: WorkflowWithListProperties;
}
export const CarePlanChip = props => {
  const $ = _c(6);
  const {
    isWithinTenDaysOfExpiration,
    isConnectWithMemberTaskCompleted,
    isAfterExpiration,
    expirationDate,
    allTasksCompleted
  } = useCarePlanChipProps(props.workflow);
  const {
    carePlans
  } = props.workflow;
  if (!carePlans?.length || !expirationDate) {
    let t0;
    if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <></>;
      $[0] = t0;
    } else {
      t0 = $[0];
    }
    return t0;
  }
  const t0 = !!isWithinTenDaysOfExpiration;
  const t1 = !!isConnectWithMemberTaskCompleted;
  const t2 = !!isAfterExpiration;
  const t3 = !!allTasksCompleted;
  let t4;
  if ($[1] !== t0 || $[2] !== t1 || $[3] !== t2 || $[4] !== t3) {
    t4 = <AcuteCarePlanChips isWithinTenDaysOfExpiration={t0} isConnectWithMemberTaskCompleted={t1} isAfterExpiration={t2} allTasksCompleted={t3} />;
    $[1] = t0;
    $[2] = t1;
    $[3] = t2;
    $[4] = t3;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  return t4;
};