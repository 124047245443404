import { SplitSdk } from '@splitsoftware/splitio-react';
import { NodeEnv } from '../constants/env';
export const splitSdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.SPLIT_AUTH_KEY || '',
    key: 'userid'
  }
};
const basiliskSplitFactory: SplitIO.ISDK = SplitSdk({
  core: {
    authorizationKey: process.env.SPLIT_AUTH_KEY_BASILISK || '',
    key: 'userUuid'
  }
});
export const basiliskSplitClient = basiliskSplitFactory.client();
export const axeCoreEnvironmentExceptionList = [NodeEnv.Development, NodeEnv.Production];