import { c as _c } from "react-compiler-runtime";
import { HHBox, HHTableCell } from '@hinge-health/react-component-library';
import PersonIcon from '@mui/icons-material/Person';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { WorkflowPayload } from '../../../../../member-360/modules/panel-layouts/types';
import { PT_WORKFLOWS_TABLE } from '../../../../constants/strings/workflows-table';
import { useShellContext } from '../../../../contexts/shell.context';
import { assignedWorkflowsState, selectAllPTWorkflowAtom, showAssignedAlert } from '../../../../jotai/atoms';
import { useUpdateWorkflowListMutation } from '../../../../mutations/update-workflow-list.mutation';
import { styles } from './style';
interface WorkflowAssignCellProps {
  workflow: WorkflowPayload;
  displayAssignIcon: boolean;
}
export const tooltipStyles = {
  tooltip: {
    position: 'absolute',
    color: 'white',
    marginTop: '3vh',
    marginLeft: '-1.5vw',
    backgroundColor: 'rgb(85, 85, 85)',
    opacity: 1,
    transition: 'opacity 0.3s ease 0s',
    width: '5.7vw',
    textAlign: 'center',
    borderRadius: '4px',
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: 'rgb(85, 85, 85) transparent transparent'
  }
};
export const WorkflowAssignCell = t0 => {
  const $ = _c(19);
  const {
    workflow,
    displayAssignIcon
  } = t0;
  const [showTooltip, setShowTooltip] = useState(false);
  const [allPTWorkflowFlag] = useAtom(selectAllPTWorkflowAtom);
  const [updateWorkflowListTransitionMutation] = useUpdateWorkflowListMutation();
  const [, setAssignedState] = useAtom(assignedWorkflowsState);
  const {
    adminProfile
  } = useShellContext();
  const [, setShowAssignedAlert] = useAtom(showAssignedAlert);
  let t1;
  if ($[0] !== updateWorkflowListTransitionMutation || $[1] !== adminProfile.uuid || $[2] !== allPTWorkflowFlag || $[3] !== workflow.id || $[4] !== setAssignedState || $[5] !== setShowAssignedAlert) {
    t1 = async () => {
      ;
      try {
        await updateWorkflowListTransitionMutation({
          variables: {
            updateWorkflowListInputDto: {
              adminUuids: [adminProfile.uuid],
              allWorkflowsChecked: allPTWorkflowFlag,
              workflowIds: [workflow.id],
              workflowIdsToExclude: []
            },
            workflowListFiltersInputDto: null
          }
        });
        window.dispatchEvent(new Event("onWorkflowsAssigned"));
        setAssignedState({
          totalAssignedCount: 1,
          assignedMessage: 1 + PT_WORKFLOWS_TABLE.ALERT.SUCCESS,
          assignedType: "success"
        });
      } catch (t2) {
        setAssignedState(prevState => ({
          ...prevState,
          assignedMessage: PT_WORKFLOWS_TABLE.ALERT.FAILED,
          assignedType: "error"
        }));
      }
      setShowAssignedAlert(true);
    };
    $[0] = updateWorkflowListTransitionMutation;
    $[1] = adminProfile.uuid;
    $[2] = allPTWorkflowFlag;
    $[3] = workflow.id;
    $[4] = setAssignedState;
    $[5] = setShowAssignedAlert;
    $[6] = t1;
  } else {
    t1 = $[6];
  }
  const assignToMe = t1;
  const t2 = `${workflow.id}`;
  let t3;
  if ($[7] !== assignToMe) {
    t3 = event => {
      assignToMe();
      event.stopPropagation();
    };
    $[7] = assignToMe;
    $[8] = t3;
  } else {
    t3 = $[8];
  }
  let t4;
  if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = {
      display: "flex",
      flexDirection: "row",
      width: 60
    };
    $[9] = t4;
  } else {
    t4 = $[9];
  }
  let t5;
  if ($[10] !== displayAssignIcon || $[11] !== showTooltip) {
    t5 = displayAssignIcon && <><PersonIcon sx={{
        "&:hover": {
          background: "#cfe5d9",
          borderRadius: "50%"
        },
        cursor: "pointer"
      }} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />{showTooltip && <span style={(tooltipStyles.tooltip as React.CSSProperties)}>{PT_WORKFLOWS_TABLE.HEADERS.ASSIGN_TO_ME}</span>}</>;
    $[10] = displayAssignIcon;
    $[11] = showTooltip;
    $[12] = t5;
  } else {
    t5 = $[12];
  }
  let t6;
  if ($[13] !== t5) {
    t6 = <HHBox sx={t4}>{t5}</HHBox>;
    $[13] = t5;
    $[14] = t6;
  } else {
    t6 = $[14];
  }
  let t7;
  if ($[15] !== t2 || $[16] !== t3 || $[17] !== t6) {
    t7 = <HHTableCell sx={styles.cell} id={t2} data-testid="workflow-row-mark" onClick={t3}>{t6}</HHTableCell>;
    $[15] = t2;
    $[16] = t3;
    $[17] = t6;
    $[18] = t7;
  } else {
    t7 = $[18];
  }
  return t7;
};