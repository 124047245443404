let lastChecked = 0;
let isOnlineCache = false;
const CHECK_INTERVAL = 30000; // 30 seconds

export async function isOnline(): Promise<boolean> {
  try {
    // perform check against the same origin as the application to avoid CORS issues.
    const response = await fetch(`${window.location.origin}`, {
      method: 'HEAD',
      cache: 'no-store'
    });
    return response.ok;
  } catch (error) {
    return false;
  }
}
export async function checkNetworkStatus(): Promise<boolean> {
  const now = Date.now();
  if (now - lastChecked > CHECK_INTERVAL) {
    isOnlineCache = await isOnline();
    lastChecked = now;
  }
  return isOnlineCache;
}