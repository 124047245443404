import React, { lazy, LazyExoticComponent } from 'react';
import { NESTED_ROUTES, ROUTES } from './constants';
import Coach from './pages/coach';
import { OsPage } from './pages/os';
import { PtPage } from './pages/pt';
import { isProduction } from './utils/environment';
const AuthRedirectCallback = lazy(() => import( /* webpackChunkName: "auth-redirect" */'./components/auth-redirect-callback'));
const Acute = lazy(() => import( /* webpackChunkName: "acute" */'./pages/acute'));
const CoachAssistantApp = lazy(() => import( /* webpackChunkName: "coach-assistant" */'./pages/coach-assistant'));
const Login = lazy(() => import( /* webpackChunkName: "login" */'./pages/login'));
const MassMessage = lazy(() => import( /* webpackChunkName: "mass-message" */'./pages/mass-message'));
const MassMessagingList = lazy(() => import( /* webpackChunkName: "mass-message-list" */'./pages/mass-message-list'));
const Member = lazy(() => import( /* webpackChunkName: "member" */'./pages/member'));
const NotFound = lazy(() => import( /* webpackChunkName: "not-found" */'./pages/not-found'));
const Portal = lazy(() => import( /* webpackChunkName: "portal" */'./pages/portal'));
const Test = lazy(() => import( /* webpackChunkName: "test" */'./pages/test'));
const MemberSearch = lazy(() => import( /* webpackChunkName: "member-search" */'./pages/member-search'));
const MPEQueue = lazy(() => import( /* webpackChunkName: "mpe-queue" */'./pages/mpe-queue'));
export interface RouteDetails {
  path: string;
  label?: string;
  icon?: {
    svg?: React.ComponentType<SVGElement>;
    png?: string;
  };
  component: LazyExoticComponent<() => JSX.Element> | (() => JSX.Element);
  children: RouteDetails[];
}
export const authRoutes: RouteDetails[] = [{
  path: ROUTES.AUTH_REDIRECT_CALLBACK,
  component: AuthRedirectCallback,
  children: []
}, {
  path: ROUTES.LOGIN,
  component: Login,
  children: []
}];
export const homeRoutes: RouteDetails[] = [{
  path: ROUTES.HOME,
  component: Coach,
  children: []
}, {
  path: `${ROUTES.MEMBER}/${NESTED_ROUTES.ID}`,
  component: Member,
  children: [{
    path: NESTED_ROUTES.PROFILE,
    component: Member,
    children: []
  }, {
    path: NESTED_ROUTES.ACTIVITY,
    component: Member,
    children: [{
      path: NESTED_ROUTES.DAILY_ACTIVITY,
      component: Member,
      children: []
    }]
  }, {
    path: NESTED_ROUTES.CARE_PLAN,
    component: Member,
    children: [{
      path: NESTED_ROUTES.EXERCISE_THERAPY,
      component: Member,
      children: []
    }]
  }, {
    path: NESTED_ROUTES.INTERACTIONS,
    component: Member,
    children: []
  }, {
    path: NESTED_ROUTES.NOTES,
    component: Member,
    children: []
  }, {
    path: NESTED_ROUTES.VIDEO_VISIT,
    component: Member,
    children: []
  }]
}, {
  path: ROUTES.ACUTE,
  component: Acute,
  children: []
}, {
  path: ROUTES.PT,
  component: PtPage,
  children: []
}, {
  path: ROUTES.COACH,
  component: Coach,
  children: []
}, {
  path: ROUTES.OS,
  component: OsPage,
  children: []
}, {
  path: ROUTES.PORTAL,
  component: Portal,
  children: []
}, {
  path: `${ROUTES.COACH_ASSISTANT}/${NESTED_ROUTES.ID}`,
  component: CoachAssistantApp,
  children: []
}, {
  path: ROUTES.MASS_MESSAGE_LIST,
  component: MassMessagingList,
  children: []
}, {
  path: ROUTES.MASS_MESSAGE,
  component: MassMessage,
  children: []
}, {
  path: ROUTES.MEMBER_SEARCH,
  component: MemberSearch,
  children: []
}];
export const nonProductionRoutes: RouteDetails[] = [{
  path: ROUTES.TEST,
  component: Test,
  children: []
}, {
  path: ROUTES.MPE_QUEUE,
  component: MPEQueue,
  children: []
}, {
  path: `${ROUTES.MPE_QUEUE}/${NESTED_ROUTES.ID}`,
  component: MPEQueue,
  children: []
}];
export const otherRoutes: RouteDetails[] = [{
  path: ROUTES.OTHER,
  component: NotFound,
  children: []
}];
export const getAllRoutes = (): RouteDetails[] => {
  const allRoutes = [...homeRoutes, ...otherRoutes];
  if (!isProduction) {
    allRoutes.push(...nonProductionRoutes);
  }
  return allRoutes;
};