import { c as _c } from "react-compiler-runtime";
import { HHBox, HHTypography } from '@hinge-health/react-component-library';
import { ctpTokens } from '@hinge-health/react-component-library/dist/styles/ctp-theme';
import { CheckCircleOutlined } from '@mui/icons-material';
import { COMPLETE } from '../../constants/strings/workflows-queue';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 29,
    marginRight: 6
  },
  textBox: {
    marginTop: 0.6,
    marginRight: 1
  },
  icon: {
    color: ctpTokens.palette.green[50]
  }
};
export const QueueComplete = () => {
  const $ = _c(2);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = <HHBox sx={styles.textBox}><HHTypography hhVariant="body1">{COMPLETE}</HHTypography></HHBox>;
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  let t1;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = <HHBox sx={styles.container}>{t0}<CheckCircleOutlined sx={styles.icon} /></HHBox>;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  return t1;
};