import { c as _c } from "react-compiler-runtime";
import { gql, LazyQueryHookOptions, LazyQueryResultTuple, OperationVariables, useLazyQuery } from '@apollo/client';
export type GetAllBodyPartsQuery = {
  getAllBodyParts: string[];
};
export const GET_ALL_BODY_PARTS_QUERY = gql`
  query GetAllBodyParts {
    getAllBodyParts
  }
`;
export const useGetAllBodyParts = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useLazyQuery(GET_ALL_BODY_PARTS_QUERY, options);
};