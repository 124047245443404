import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { SseClient } from '../utils/sse-client';

// A singleton to prevent running into connection limits
const sseClient = new SseClient();
export const useSse = (url: string, events: string[], options?: {
  replayLastEvent?: boolean;
}): unknown => {
  const [data, setData] = useState<unknown>();
  const {
    hingeHealthAuthState,
    hingeHealthAuthClient
  } = useHingeHealthSecurityContext();
  const subscriberId = useRef(v4());
  useEffect(() => {
    if (sseClient.initialized) {
      sseClient.close();
    }
  }, [hingeHealthAuthState?.accessToken?.accessToken]);
  const initSseClient = async (): Promise<void> => {
    await sseClient.init({
      url,
      token: hingeHealthAuthState?.accessToken?.accessToken,
      accessToken: hingeHealthAuthState?.accessToken,
      getOrRenewAccessToken: hingeHealthAuthClient?.getOrRenewAccessToken
    });
  };
  useEffect(() => {
    if (!sseClient.initialized) {
      initSseClient();
    }
    events.forEach(event => {
      sseClient.subscribe(event, subscriberId.current, messageEvent => {
        setData(messageEvent);
      });
      if (options?.replayLastEvent) {
        sseClient.replay(event);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, hingeHealthAuthState?.accessToken?.accessToken]);
  useEffect(() => () => {
    events.forEach(event_0 => {
      sseClient.unsubscribe(event_0, subscriberId.current);
    });
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return data;
};