import { ctpTokens, HHAlert, HHAvatar, HHCircularProgress, HHStack, HHTypography } from '@hinge-health/react-component-library';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { OktaRole } from '../../../../../../types/okta-roles';
import { currentUserAtom } from '../../../../state/atoms';
import { createCareCoordinationWorkflowAtom, workflowAssigneeUuidAtom } from '../../state/atoms';
import { COMMON_FORM } from '../constants/create-workflow-forms';
import { getInitials } from '../utils/create-workflow-form-helpers';
import { useGetUserCoverages, UserCoverage } from './queries/get-user-coverages.query';
export const WORKFLOW_FORM_HEADER_TEST_ID = 'workflow-form-header';
export const ASSIGNEE_AVATAR_TEST_ID = 'assignee-avatar';
export interface WorkflowFormHeaderProps {
  title: string;
  assigneeRole: OktaRole;
}
export const WorkflowFormHeader = ({
  title,
  assigneeRole
}: WorkflowFormHeaderProps): JSX.Element => {
  const [currentUser] = useAtom(currentUserAtom);
  const [createCareCoordinationWorkflow] = useAtom(createCareCoordinationWorkflowAtom);
  const setWorkflowAssignee = useSetAtom(workflowAssigneeUuidAtom);
  const [assignee, setAssignee] = useState<UserCoverage | undefined>();
  const [GetUserCoverages, {
    data: userCoverages,
    loading: userCoveragesLoading
  }] = useGetUserCoverages();
  useEffect(() => {
    if (currentUser?.uuid) {
      GetUserCoverages({
        variables: {
          userUuid: currentUser.uuid
        },
        fetchPolicy: 'cache-first'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  useEffect(() => {
    if (userCoverages?.getUserCoverages?.coverages) {
      const assignee_0 = assigneeRole === OktaRole.PhysicalTherapist ? userCoverages.getUserCoverages.coverages.physicalTherapist : userCoverages.getUserCoverages.coverages.healthCoach;
      setAssignee(assignee_0);
      if (!!assignee_0?.uuid) {
        setWorkflowAssignee(assignee_0.uuid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCoverages]);
  const getAssigneeDetails = (): JSX.Element => {
    if (userCoveragesLoading) {
      return <HHCircularProgress size={20} />;
    }
    return assignee && assignee?.name ? <HHStack flexDirection={'row'} columnGap={2} alignContent={'center'}>
        <HHAvatar data-testid={ASSIGNEE_AVATAR_TEST_ID} hhVariant="small" color={ctpTokens.palette.sand[70]}>
          <HHTypography hhVariant="caption" color={ctpTokens.palette.neutral[10]}>
            {getInitials(assignee.name)}
          </HHTypography>
        </HHAvatar>
        <HHTypography hhVariant="body1">{assignee.name}</HHTypography>
      </HHStack> : <HHTypography hhVariant={'caption'} color={ctpTokens.palette.red[40]}>
        {COMMON_FORM.NO_ASSIGNEE}
      </HHTypography>;
  };
  return <HHStack rowGap={4} data-testid={WORKFLOW_FORM_HEADER_TEST_ID}>
      {createCareCoordinationWorkflow.error && <HHAlert hhVariant="standard" severity="error">
          {createCareCoordinationWorkflow.errorMessage || COMMON_FORM.ERROR}
        </HHAlert>}
      <HHTypography hhVariant="h1">{title}</HHTypography>
      <HHStack flexDirection={'row'} columnGap={4} paddingY={1}>
        <HHTypography hhVariant="input-label">
          {COMMON_FORM.ASSIGNEE}
        </HHTypography>
        {getAssigneeDetails()}
      </HHStack>
    </HHStack>;
};