import { c as _c } from "react-compiler-runtime";
import { useContext } from 'react';
import { AdminContext, AdminWithOmitedFields } from '../contexts/admin.context';
export const useCurrentStoredAdminHook = () => {
  const $ = _c(4);
  const [currentStoredAdmin, loading, error] = useContext(AdminContext);
  const t0 = error?.message;
  let t1;
  if ($[0] !== currentStoredAdmin || $[1] !== loading || $[2] !== t0) {
    t1 = {
      currentStoredAdmin,
      loading,
      adminError: t0
    };
    $[0] = currentStoredAdmin;
    $[1] = loading;
    $[2] = t0;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  return t1;
};