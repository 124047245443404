import { capitalizeWords } from '../../../utils/capitalize-name';
import { BaseUser, Maybe } from '../types';
export interface Patient {
  firstName: string;
  lastName: string;
}
export const createFullName = (patient?: Maybe<Patient>): string => {
  if (!patient) return '';
  const firstName = patient.firstName;
  const lastName = patient.lastName;
  const fullName = `${firstName} ${lastName}`;
  return capitalizeWords(fullName);
};
export const getPatientDetails = (patient?: Maybe<BaseUser>): string => patient ? `${createFullName(patient)}, ${patient.id}` : '';