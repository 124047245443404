/* eslint-disable @typescript-eslint/naming-convention */
export const MEMBER = {
  PAGE_TITLE: 'Member Page',
  ERROR: 'Unable to load Member page'
};
export const IAM = 'IAM';
export const SESSION_ID = 'sessionId';
export const sessionId = 'test-session-id';
export const SESSON_START_TIME = 'sessionStartTime';
export const CURRENT_MEMBER_ID = 'currentMemberId';
export const CURRENT_MEMBER_UUID = 'currentMemberUuid';
export const CURRENT_MEMBER_PROGRAM_TYPE = 'currentMemberProgramType';
export const CURRENT_MEMBER_PROGRAM_INDICATION = 'currentMemberProgramIndication';
export const TIME_SPENT_ON_MEMBER = 'timeSpent';
export const MEMBER_PAGE_NAV = {
  profile: 'member_record.tab.member_profile_clicked',
  activity: 'member_record.tab.activity_clicked',
  'care-plan': 'member_record.tab.care_plan_clicked',
  interactions: 'member_record.tab.interactions_clicked',
  notes: 'member_record.tab.notes_clicked'
};
export const CURRENT_TAB_CHANGED = 'currentTab';