import { HHTabData, HHTabs } from '@hinge-health/react-component-library';
import { AutoFixHigh } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useGenericMixpanelTrack } from '../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../lib/mixpanel/mixpanel-event-names';
import { DashboardTabSelectionType } from '../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { OsDashboardFilter } from '../../../constants/os-dashboard';
import { DEFAULT_OS_DASHBOARD_TAB_NAME, OS_NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/os-layout';
import { selectedOsDashboardFilters } from '../../../jotai/atoms';
import { OsDashboardFilters } from '../../../types/os';
export interface UnreadMessageTabsProps {
  tableContent: JSX.Element;
}
export const OSUnreadMessagesTabs: React.FC<UnreadMessageTabsProps> = ({
  tableContent
}) => {
  const [selectedOSDashboardFilter, setSelectedOSDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const [tabIndex, setTabIndex] = useState(0);
  const trackEvent = useGenericMixpanelTrack();
  const invokeMixpanelEvent = async (event: string, tabname: string): Promise<void> => {
    trackEvent<DashboardTabSelectionType>(event, {
      selectedTabName: tabname
    });
  };
  useEffect(() => {
    if (selectedOSDashboardFilter.filterTabName === DEFAULT_OS_DASHBOARD_TAB_NAME) {
      invokeMixpanelEvent(MIXPANEL_EVENTS.ALL_INCOMING_UNREAD_MESSAGES_TAB_SELECTED, DEFAULT_OS_DASHBOARD_TAB_NAME);
      setTabIndex(0);
    } else {
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOSDashboardFilter]);
  useEffect(() => {
    setSelectedOSDashboardFilter(OsDashboardFilter[OsDashboardFilters.UnreadMessage]);
  }, [setSelectedOSDashboardFilter]);
  const handleTabChange = (event_0: number): void => {
    if (event_0 === 0) {
      setSelectedOSDashboardFilter(OsDashboardFilter[OsDashboardFilters.UnreadMessage]);
    }
    if (event_0 === 1) {
      setSelectedOSDashboardFilter(OsDashboardFilter[OsDashboardFilters.UnreadMessageNoResponse]);
      invokeMixpanelEvent(MIXPANEL_EVENTS.NO_RESPONSE_PREDICTED_UNREAD_MESSAGES_TAB_SELECTED, OS_NO_RESPONSE_PREDICTED_TAB_NAME);
    }
  };
  const tabsDataContent: HHTabData[] = [{
    tabContent: tableContent,
    tabLabel: DEFAULT_OS_DASHBOARD_TAB_NAME,
    key: DEFAULT_OS_DASHBOARD_TAB_NAME
  }, {
    icon: <AutoFixHigh />,
    iconPosition: 'start',
    tabContent: tableContent,
    tabLabel: OS_NO_RESPONSE_PREDICTED_TAB_NAME,
    key: OS_NO_RESPONSE_PREDICTED_TAB_NAME
  }];
  return <HHTabs layoutStyles={{
    margin: '2px'
  }} tabsData={tabsDataContent} onTabChange={handleTabChange} defaultTabIndex={tabIndex} />;
};