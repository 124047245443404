import { c as _c } from "react-compiler-runtime";
import { useAtom, WritableAtom } from 'jotai';
import { QueueCategoryType } from '../../custom-types';
import { useGetQueueWorkflowTypes } from '../../hooks/use-get-queue-workflow-types';
import { FetchMoreWorkflowsAtom } from '../../state/queue-atoms';
import { QueueAccordion } from './queue-accordion';
interface ConditionalQueueAccordionProps {
  workflowCategoryAtom: WritableAtom<FetchMoreWorkflowsAtom, unknown[], void>;
  type: QueueCategoryType;
}
export const ConditionalQueueAccordion = t0 => {
  const $ = _c(4);
  const {
    workflowCategoryAtom,
    type
  } = t0;
  const workflowQueueTypes = useGetQueueWorkflowTypes();
  const [workflowAtom] = useAtom(workflowCategoryAtom);
  if (!workflowAtom.queueCategory.find(queueCategory => workflowQueueTypes.includes(queueCategory))) {
    let t1;
    if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
      t1 = <></>;
      $[0] = t1;
    } else {
      t1 = $[0];
    }
    return t1;
  }
  let t1;
  if ($[1] !== workflowCategoryAtom || $[2] !== type) {
    t1 = <QueueAccordion workflowCategoryAtom={workflowCategoryAtom} type={type} />;
    $[1] = workflowCategoryAtom;
    $[2] = type;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  return t1;
};