import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox, HHTypography } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtomValue, WritableAtom } from 'jotai';
import { NAV_SPLIT_TREATMENTS, ON } from '../../constants/strings/split';
import { WORKFLOWS_COMPLETED } from '../../constants/strings/workflows-queue';
import { QueueCategoryType } from '../../custom-types';
import { useCurrentStoredAdminHook } from '../../hooks/use-current-stored-admin';
import { FetchMoreWorkflowsAtom, workflowCategoryAtoms } from '../../state/queue-atoms';
import { ReactComponent as ArmIllustration } from '../assets/images/queue-happy-completed.svg';
import { ConditionalQueueAccordion } from '../workflows-queue-components/conditional-queue-accordion';
const styles = {
  accordionsBox: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden'
  },
  illustrationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    bottom: 0,
    left: 0
  },
  completedText: {
    marginTop: 16
  }
};
interface WorkflowsAccordionsProps {
  queueWorkflowsCompleted: boolean;
}
export const WorkflowsAccordions = t0 => {
  const $ = _c(19);
  const {
    queueWorkflowsCompleted
  } = t0;
  const workflowCategories = (useAtomValue(workflowCategoryAtoms) as WritableAtom<FetchMoreWorkflowsAtom, unknown[], void>[]);
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const splitClient = useClient();
  const encountersTabTreatment = splitClient?.getTreatment(NAV_SPLIT_TREATMENTS.ENCOUNTERS_TAB, {
    adminUuid: currentStoredAdmin?.uuid || "*"
  }) === ON;
  let t1;
  if ($[0] !== queueWorkflowsCompleted) {
    t1 = queueWorkflowsCompleted ? {
      backgroundColor: ctpTokens.palette.yellow["90"]
    } : undefined;
    $[0] = queueWorkflowsCompleted;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== encountersTabTreatment) {
    t2 = encountersTabTreatment ? {
      minHeight: "calc(100vh - 90px)"
    } : undefined;
    $[2] = encountersTabTreatment;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  let t3;
  if ($[4] !== t1 || $[5] !== t2) {
    t3 = {
      ...t1,
      ...t2
    };
    $[4] = t1;
    $[5] = t2;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  const workflowsAccordionsStyles = t3;
  let t4;
  if ($[7] !== workflowCategories) {
    let t5;
    if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
      t5 = (workflowCategoryAtom, index) => <ConditionalQueueAccordion workflowCategoryAtom={workflowCategoryAtom} type={QueueCategoryType.Workflow} key={index} />;
      $[9] = t5;
    } else {
      t5 = $[9];
    }
    t4 = workflowCategories.map(t5);
    $[7] = workflowCategories;
    $[8] = t4;
  } else {
    t4 = $[8];
  }
  let t5;
  if ($[10] !== t4) {
    t5 = <HHBox sx={styles.accordionsBox}>{t4}</HHBox>;
    $[10] = t4;
    $[11] = t5;
  } else {
    t5 = $[11];
  }
  let t6;
  if ($[12] !== queueWorkflowsCompleted || $[13] !== encountersTabTreatment) {
    t6 = queueWorkflowsCompleted && <><HHTypography hhVariant="h2" layoutStyles={styles.completedText} align="center">{WORKFLOWS_COMPLETED}</HHTypography><HHBox sx={{
        ...styles.illustrationContainer,
        ...(encountersTabTreatment ? {
          position: "absolute"
        } : undefined)
      }}><ArmIllustration width={480} height={360} viewBox="0 0 1025 765" dataTestId="armIllustration" /></HHBox></>;
    $[12] = queueWorkflowsCompleted;
    $[13] = encountersTabTreatment;
    $[14] = t6;
  } else {
    t6 = $[14];
  }
  let t7;
  if ($[15] !== workflowsAccordionsStyles || $[16] !== t5 || $[17] !== t6) {
    t7 = <HHBox sx={workflowsAccordionsStyles}>{t5}{t6}</HHBox>;
    $[15] = workflowsAccordionsStyles;
    $[16] = t5;
    $[17] = t6;
    $[18] = t7;
  } else {
    t7 = $[18];
  }
  return t7;
};