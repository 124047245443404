export interface CoverageDate {
  endDate: string | null;
  startsAt: string;
}
export interface CareTeamCoverageInfo {
  active: boolean;
  adminEmail: string;
  adminName: string;
  adminRole: string;
  adminUuid: string;
  type: string;
  coverageDates: CoverageDate[];
}
export interface CareTeamData {
  careTeam: CareTeamCoverageInfo[];
}
export enum AcceptedRoles {
  Coach = 'coach',
  Super = 'super',
  PhysicalTherapist = 'physical_therapist',
  PT = 'pt',
}
export type DetermineConversationExists = (streamRole: string, oneOnOneExists: boolean, groupExists: boolean, iamCareTeamMessagingEnabled: boolean) => boolean;
export type ConversationRule = (oneOnOneExists: boolean, groupExists: boolean, iamCareTeamMessagingEnabled: boolean) => boolean;
export enum WorkflowStatusKeys {
  OPEN_WORKFLOWS = 'open-workflows',
  CLOSED_WORKFLOWS = 'closed-workflows',
}
export type WorkflowListStatus = {
  [WorkflowStatusKeys.OPEN_WORKFLOWS]: boolean;
  [WorkflowStatusKeys.CLOSED_WORKFLOWS]: boolean;
};
export enum AcceptedRolesForWorkflowList {
  BillingOps = 'BILLING_OPS',
  C10RUser = 'C10R_USER',
  CareCoordinator = 'CARE_COORDINATOR',
  Coach = 'COACH',
  DeliveryOps = 'DELIVERY_OPS',
  MyUser = 'MY_USER',
  NursePractitioner = 'NURSE_PRACTITIONER',
  PartnershipManager = 'PARTNERSHIP_MANAGER',
  PhxUser = 'PHX_USER',
  PhysicalTherapist = 'PHYSICAL_THERAPIST',
  Super = 'SUPER',
  OnboardingSpecialist = 'ONBOARDING_SPECIALIST',
}