import { HHDate, HHDateProps, HHStack, HHTableCell, HHTypography, PriorityStates } from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { HouseCallsWorkflows } from '../../../../../acute-physical-therapy/custom-types';
import { HOUSE_CALL_TAB_NAMES } from '../../../../constants/strings/pt-layout';
import { COACH_WORKFLOWS_TABLE } from '../../../../constants/strings/workflows-table';
import { selectedPtDashboardFilters } from '../../../../jotai/atoms';
import { WorkflowPayload } from '../../../../types';
import { CoachWorkflowTypes } from '../../../../types/coach';
import { getDashboardDueDate } from '../../../../utils/get-due-date';
import { styles } from './style';
dayjs.extend(isToday);
export const WORKFLOW_TABLE_DUE_CELL_TEST_ID = 'workflow-table-due-cell';
interface WorkflowDueCellProps {
  workflow: WorkflowPayload;
}
export const WorkflowDueCell = ({
  workflow
}: WorkflowDueCellProps): JSX.Element => {
  const [selectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [useScheduledAtDate, setUseScheduledAtDate] = useState(false);
  const {
    isPastDue,
    type,
    latestTaskDate
  } = workflow;
  useEffect(() => {
    if (!selectedPtDashboardFilter?.filterTabName) return;
    const isUpcomingTabHouseCallsTab = selectedPtDashboardFilter.filterTabName === HOUSE_CALL_TAB_NAMES.UPCOMING_HOUSE_CALLS && workflow.type === HouseCallsWorkflows.HouseCall;
    if (isUpcomingTabHouseCallsTab) {
      setUseScheduledAtDate(true);
    } else {
      setUseScheduledAtDate(false);
    }
  }, [selectedPtDashboardFilter.filterTabName, workflow.type]);
  const dueDate = useMemo(() => {
    const scheduledAtDate = workflow.customFields?.scheduledAt;
    if (useScheduledAtDate && scheduledAtDate) {
      return scheduledAtDate;
    }
    return type === CoachWorkflowTypes.FirstActionPlan ? getDashboardDueDate(workflow) : latestTaskDate || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useScheduledAtDate, workflow.customFields?.scheduledAt, type, latestTaskDate]);

  // EMO-2341: Remove once isPastDue is reliably true/false
  const overdue = isPastDue || dayjs(dueDate).isBefore(dayjs());
  let dueStateProps: HHDateProps = {
    dueDate,
    withoutSuffix: true,
    dueState: PriorityStates.Default
  };
  if (dayjs(dueDate).isToday() && !overdue) {
    dueStateProps = {
      ...dueStateProps,
      dueState: PriorityStates.DueSoon,
      iconPosition: 'left'
    };
  }
  if (overdue) {
    dueStateProps = {
      ...dueStateProps,
      dueState: PriorityStates.Overdue,
      iconPosition: 'left'
    };
  }
  return <HHTableCell sx={styles.cell} data-testid={WORKFLOW_TABLE_DUE_CELL_TEST_ID}>
      <HHStack flexDirection={'row'} marginLeft={0} width={120}>
        <HHTypography hhVariant="body">
          {/* EMO-2037: Modify RCL HHSidebarDate component to render the dashboard version based on latestTaskDate */}

          {dueDate ? <HHDate {...dueStateProps} /> : COACH_WORKFLOWS_TABLE.SECTION_DETAILS.DEFAULT}
        </HHTypography>
      </HHStack>
    </HHTableCell>;
};