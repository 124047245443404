import { c as _c } from "react-compiler-runtime";
import { HHAlert, HHBox, HHCircularProgress, HHTypography } from '@hinge-health/react-component-library';
import { DASHBOARD_UPDATE_WHEN_DONE, TASKS_BEING_UPDATED } from '../../../constants/strings/workflows-table';
import { workflowsTableStyles } from './workflows-table-styles';
export const TaskUpdating = () => {
  const $ = _c(7);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = <HHBox sx={workflowsTableStyles.overlay}><HHCircularProgress data-testid="hh-circular-progress" /></HHBox>;
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  let t1;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      zIndex: 10000,
      position: "fixed"
    };
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {
      position: "fixed",
      top: 39,
      right: 0
    };
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      fontWeight: "bold"
    };
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  let t4;
  let t5;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = <HHTypography sx={t3} hhVariant="inline">{TASKS_BEING_UPDATED}</HHTypography>;
    t5 = <br />;
    $[4] = t4;
    $[5] = t5;
  } else {
    t4 = $[4];
    t5 = $[5];
  }
  let t6;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = <>{t0}<HHBox sx={t1}><HHAlert severity="info" hhVariant="filled" layoutStyles={t2}>{t4}{t5}{DASHBOARD_UPDATE_WHEN_DONE}</HHAlert></HHBox></>;
    $[6] = t6;
  } else {
    t6 = $[6];
  }
  return t6;
};