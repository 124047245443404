import { HHTabData, HHTabs } from '@hinge-health/react-component-library';
import { AutoFixHigh } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useGenericMixpanelTrack } from '../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../lib/mixpanel/mixpanel-event-names';
import { DashboardTabSelectionType } from '../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { CoachDashboardFilter } from '../../../constants/coach-dashboard';
import { DEFAULT_COACH_DASHBOARD_TAB_NAME, LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from '../../../constants/strings/coach-dashboard-default';
import { NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/pt-layout';
import { selectedCoachDashboardFilters } from '../../../jotai/atoms';
import { CoachDashboardFilters } from '../../../types/coach';
export interface UnreadMessageTabsProps {
  tableContent: JSX.Element;
}
export const UnreadMessagesTabs: React.FC<UnreadMessageTabsProps> = ({
  tableContent
}) => {
  const [selectedDashboardFilter, setSelectedDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [tabIndex, setTabIndex] = useState(0);
  const trackEvent = useGenericMixpanelTrack();
  const invokeMixpanelEvent = async (event: string, tabname: string): Promise<void> => {
    trackEvent<DashboardTabSelectionType>(event, {
      selectedTabName: tabname
    });
  };
  useEffect(() => {
    if (selectedDashboardFilter.filterTabName === DEFAULT_COACH_DASHBOARD_TAB_NAME) {
      invokeMixpanelEvent(MIXPANEL_EVENTS.ALL_INCOMING_UNREAD_MESSAGES_TAB_SELECTED, DEFAULT_COACH_DASHBOARD_TAB_NAME);
      setTabIndex(0);
    } else {
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboardFilter]);
  useEffect(() => {
    setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.UnreadMessages]);
  }, [setSelectedDashboardFilter]);
  const handleTabChange = (event_0: number): void => {
    if (event_0 === 0) {
      setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.UnreadMessages]);
    }
    if (event_0 === 1) {
      setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.UnreadMessagesNoResponse]);
      invokeMixpanelEvent(MIXPANEL_EVENTS.NO_RESPONSE_PREDICTED_UNREAD_MESSAGES_TAB_SELECTED, NO_RESPONSE_PREDICTED_TAB_NAME);
    }
  };
  const tabsDataContent: HHTabData[] = [{
    tabContent: tableContent,
    tabLabel: DEFAULT_COACH_DASHBOARD_TAB_NAME,
    key: DEFAULT_COACH_DASHBOARD_TAB_NAME
  }, {
    icon: <AutoFixHigh />,
    iconPosition: 'start',
    tabContent: tableContent,
    tabLabel: LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME,
    key: LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME
  }];
  return <HHTabs layoutStyles={{
    margin: '2px'
  }} tabsData={tabsDataContent} onTabChange={handleTabChange} defaultTabIndex={tabIndex} />;
};