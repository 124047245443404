import axios from 'axios';
import { AppEnv } from '../constants/env';
import { USER_FEEDBACK } from '../constants/strings/user-feedback';
import { isProduction, isStage } from './environment';
interface Fields {
  description: {
    content: {
      content: {
        text: string;
        type: string;
      }[];
      type: string;
    }[];
    type: string;
    version: number;
  };
  issuetype: {
    name: string;
  };
  labels: string[];
  priority: {
    name: string;
  };
  project: {
    key: string;
  };
  summary: string;
  // customfield_11473 -> environment field in jira for bug type
  customfield_11473?: [{
    value: string;
  }];
}
const getEnv = (): string => {
  let env = 'dev';
  if (isStage) {
    env = AppEnv.Stage;
  } else if (isProduction) {
    env = AppEnv.Production;
  }
  return env.charAt(0).toUpperCase() + env.slice(1);
};
const BFF_URL = process.env.REACT_APP_BFF_URL || '';
export interface FeedbackParams {
  userData: {
    name: string;
    email: string;
    uuid: string;
  };
  message: string;
  type: string;
  feedbackFiles: FileList | null;
  title: string;
}
export const sendFeedback = async (params: FeedbackParams): Promise<unknown> => {
  const {
    userData,
    message,
    type,
    feedbackFiles,
    title
  } = params;
  const description = {
    content: [{
      content: [{
        text: `${message}\nReported By: ${userData.name}\nReporter Email: ${userData.email}\nReporter UUID: ${userData.uuid}`,
        type: 'text'
      }],
      type: 'paragraph'
    }],
    type: 'doc',
    version: 1
  };
  const issuetype = {
    name: type === USER_FEEDBACK.BUG_STRING ? 'Bug' : 'Story'
  };
  const labels: string[] = [];
  if (type === USER_FEEDBACK.BUG_STRING) {
    labels.push(USER_FEEDBACK.TICKET_LABEL.BUG);
  } else {
    labels.push(USER_FEEDBACK.TICKET_LABEL.REQUEST);
  }
  let fields: Fields = {
    priority: {
      name: 'Medium'
    },
    project: {
      key: 'MUK'
    },
    summary: title,
    description,
    issuetype,
    labels
  };
  if (type === USER_FEEDBACK.BUG_STRING) {
    fields = {
      ...fields,
      customfield_11473: [{
        value: getEnv()
      }]
    };
  }
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    if (feedbackFiles) {
      for (const file of feedbackFiles) {
        formData.append('files', file);
      }
    }
    const getTotalFileSize = (files: FileList): number => {
      let totalSize = 0;
      for (const file of files) {
        totalSize += file.size;
      }
      return totalSize;
    };
    if (feedbackFiles && getTotalFileSize(feedbackFiles) > USER_FEEDBACK.MAX_FILE_SIZE_IN_MB * 1024 * 1024) {
      reject({
        status: false,
        message: USER_FEEDBACK.FILE_SIZE_ERROR
      });
    }
    formData.append('fields', JSON.stringify({
      fields
    }));
    axios.post(BFF_URL + '/v1/user-feedback', formData).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    });
  });
};