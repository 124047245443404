import { NAV_SPLIT_TREATMENTS } from '../../../../../constants/strings/split';
export const SPLIT_TREATMENTS = {
  MEMBER_ALERT_PAUSE: 'member-alert-pause',
  REENGAGER_WORKFLOW_MIGRATION: 'reengager-workflow-migration',
  SURGERY_TREND_WORKFLOW_MIGRATION: 'surgerytrend-workflow-migration',
  CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT: NAV_SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT,
  FIRST_WEEK_ENSO_CHECK_IN_WORKFLOW_MIGRATION: 'first-week-enso-check-in-workflow-migration',
  WEEKLY_ENSO_REVIEW_WORKFLOW_MIGRATION: 'weekly-enso-review-workflow-migration',
  IAM_CARE_TEAM_MESSAGING: 'iam-care-team-messaging',
  OUTREACH_NEEDED_WORKFLOW_MIGRATION: 'outreach-needed-workflow-migration',
  PT_POOLED_REASSIGNEMNT: 'pt-pooled-reassignment',
  MODULAR_IAM_UNREAD_MESSAGE: 'modular-iam-unread-message',
  MODULAR_IAM_PAIN_INSIGHTS: 'modular-iam-pain-insights'
};
export const ON = 'on';
export const OFF = 'off';