import { ctpTokens, HHAccordion, HHAccordionDetails, HHAccordionSummary, HHBox, HHDivider, HHDrawer, HHTypography } from '@hinge-health/react-component-library';
import { ExpandMore } from '@mui/icons-material';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useGenericMixpanelTrack } from '../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../lib/mixpanel/mixpanel-event-names';
import { CoachDashboardFilter } from '../../../constants/coach-dashboard';
import { OsDashboardFilter } from '../../../constants/os-dashboard';
import { PtDashboardFilter } from '../../../constants/pt-dashboard';
import { ON, SPLIT_TREATMENTS } from '../../../constants/splits';
import { FILTER_TITLE } from '../../../constants/strings/coach';
import { COACH_LAYOUT } from '../../../constants/strings/coach-layout';
import { OS_LAYOUT } from '../../../constants/strings/os-layout';
import { PT_LAYOUT } from '../../../constants/strings/pt-layout';
import { SORT_PROPERTY } from '../../../constants/strings/workflows-table';
import { useShellContext } from '../../../contexts/shell.context';
import { DashboardType, SortWorkflowType, UnreadFilterType } from '../../../custom-types';
import { bulkShowHLNFailedAlert, bulkShowHLNSuccessAlert, bulkShowUnreadFailedAlert, bulkShowUnreadSuccessAlert, bulkWorkflowHLNProgress, bulkWorkflowUnreadProgress, dashboardFilterPrevState, listToMarkWorkflowComplete, listToMarkWorkflowPending, selectAllPTWorkflowAtom, selectAllWorkflowAtom, selectedCoachDashboardFilters, selectedDashboardType, selectedOsDashboardFilters, selectedPtDashboardFilters, selectedUnreadType, showMarkCompleteAlert, showTimeoutAlert, sortWorkflowsAtom, timeoutIdAtom, workflowListLoading } from '../../../jotai/atoms';
import { CoachDashboardFilters } from '../../../types/coach';
import { OsDashboardFilters } from '../../../types/os';
import { PtDashboardFilters } from '../../../types/pt';
import { AdminProps, PTList } from '../pt-list';
import { useGetFilterCategories } from './hooks/use-get-filter-categories';
import { WorkflowFilterItem } from './workflow-filter-item';
import { filterStyles, listContainerStyles, titleStyles } from './workflow-filter-styles';
const FILTER_CONTAINER_IDS = {
  [DashboardType.Coach]: COACH_LAYOUT.FILTER_CONTAINER_ID,
  [DashboardType.PhysicalTherapist]: PT_LAYOUT.FILTER_CONTAINER_ID,
  [DashboardType.OnboardingSpecialist]: OS_LAYOUT.FILTER_CONTAINER_ID
};
export interface WorkflowFilterProps<T extends CoachDashboardFilters | PtDashboardFilters | OsDashboardFilters> {
  counts: Record<T, number>;
  loading: boolean;
}
export const handleEventTracking = (label: string, mixpanelTrack: (eventName: string) => void): void => {
  switch (label) {
    case PtDashboardFilters.UnreadMessage:
    case CoachDashboardFilters.UnreadMessages:
      mixpanelTrack(MIXPANEL_EVENTS.UNREAD_MESSAGE_SIDEBAR_FILTER_SELECTED);
      break;
    case PtDashboardFilters.HealthLogNotes:
    case CoachDashboardFilters.MemberHealthLog:
      mixpanelTrack(MIXPANEL_EVENTS.HEALTH_LOG_NOTES_SIDEBAR_FILTER_SELECTED);
      break;
    case PtDashboardFilters.PainUptick:
      mixpanelTrack(MIXPANEL_EVENTS.PAIN_UPTICK_SIDEBAR_FILTER_SELECTED);
      break;
    case PtDashboardFilters.VideoVisit:
      mixpanelTrack(MIXPANEL_EVENTS.VIDEO_VISITS_DASHBOARD_SIDEBAR_FILTER_CLICKED);
      break;
  }
};
export const WorkflowFilters = <T extends CoachDashboardFilters | PtDashboardFilters | OsDashboardFilters,>(props: WorkflowFilterProps<T>): JSX.Element => {
  const {
    counts,
    loading
  } = props;
  const mixpanelTrack = useGenericMixpanelTrack();
  const dashboardType = useAtomValue(selectedDashboardType);
  const [selectedCoachDashboardFilter, setSelectedCoachDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [selectedPtDashboardFilter, setSelectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsDashboardFilter, setSelectedOsDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const workflowListLoadingState = useAtomValue(workflowListLoading);
  const bulkHLNProgress = useAtomValue(bulkWorkflowHLNProgress);
  const bulkUnreadProgress = useAtomValue(bulkWorkflowUnreadProgress);
  const setSortWorkflowsAtom = useSetAtom(sortWorkflowsAtom);
  const setAllWorkflowFlag = useSetAtom(selectAllWorkflowAtom);
  const setListToMarkComplete = useSetAtom(listToMarkWorkflowComplete);
  const setListToMarkPending = useSetAtom(listToMarkWorkflowPending);
  const setBulkHLNSuccess = useSetAtom(bulkShowHLNSuccessAlert);
  const setBulkUnreadSuccess = useSetAtom(bulkShowUnreadSuccessAlert);
  const setBulkHLNFailed = useSetAtom(bulkShowHLNFailedAlert);
  const setBulkUnreadFailed = useSetAtom(bulkShowUnreadFailedAlert);
  const setShowAlert = useSetAtom(showMarkCompleteAlert);
  const setShowAlertTimeout = useSetAtom(showTimeoutAlert);
  const setPrevfilter = useSetAtom(dashboardFilterPrevState);
  const setAllWorkflowPtFlag = useSetAtom(selectAllPTWorkflowAtom);
  const setSelectedUnreadFilter = useSetAtom(selectedUnreadType);
  const accordionList = useGetFilterCategories<T>(dashboardType);
  const [, setTimeoutId] = useAtom(timeoutIdAtom);
  let selectedLabel = '';
  if (dashboardType === DashboardType.Coach) {
    selectedLabel = selectedCoachDashboardFilter.filterLabel;
  } else if (dashboardType === DashboardType.PhysicalTherapist) {
    selectedLabel = selectedPtDashboardFilter.filterLabel;
  } else {
    selectedLabel = selectedOsDashboardFilter.filterLabel;
  }
  selectedLabel = selectedLabel.split(':')[0].trim();
  const {
    adminProfile
  } = useShellContext();
  const splitClient = useClient();
  const unreadMessagesTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.UNREAD_MESSAGES_FILTER, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const healthLogNotesTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.HEALTH_LOG_NOTES, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const unreadMessagesMlTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.UNREAD_MESSAGE_ML, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const ptPooledDashboardFlag = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_POOLED_DASHBOARD, {
    adminUuid: adminProfile?.uuid || '*'
  }) === ON;
  const ptUnreadMessageML = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_UNREAD_MESSAGES_ML, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const ptHealthLogNotesML = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_HLN_ML, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const adminDetails: AdminProps = {
    firstName: adminProfile.firstName,
    email: adminProfile.email,
    lastName: adminProfile.lastName,
    uuid: adminProfile.uuid
  };
  useEffect(() => {
    if (dashboardType === DashboardType.PhysicalTherapist) {
      mixpanelTrack(MIXPANEL_EVENTS.VIDEO_VISITS_DASHBOARD_SIDEBAR_FILTER_CLICKED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onPTClick = (label: Exclude<PtDashboardFilters, PtDashboardFilters.All>): void => {
    if (workflowListLoadingState) {
      return;
    }
    let sortOptions: SortWorkflowType = {
      property: SORT_PROPERTY.LATEST_TASK_DATE,
      order: 'ASC'
    };
    if (label === PtDashboardFilters.Completed) {
      sortOptions = {
        property: SORT_PROPERTY.LAST_COMPLETED_AT,
        order: 'DESC'
      };
    }
    setPrevfilter(selectedPtDashboardFilter);
    setSelectedPtDashboardFilter(PtDashboardFilter[label]);
    setSortWorkflowsAtom(sortOptions);
    setAllWorkflowPtFlag(false);
    setListToMarkComplete([]);
    setListToMarkPending([]);
    setShowAlertTimeout(false);
    setBulkHLNSuccess(false);
    setBulkUnreadSuccess(false);
    setBulkHLNFailed(false);
    setBulkUnreadFailed(false);
    setSelectedUnreadFilter(UnreadFilterType.AllTypes);
  };
  const onCoachClick = (label_0: CoachDashboardFilters): void => {
    if (workflowListLoadingState) {
      return;
    }
    let sortOptions_0: SortWorkflowType = {
      property: SORT_PROPERTY.LATEST_TASK_DATE,
      order: 'ASC'
    };
    if (label_0 === CoachDashboardFilters.Completed) {
      sortOptions_0 = {
        property: SORT_PROPERTY.LAST_COMPLETED_AT,
        order: 'DESC'
      };
    }
    setPrevfilter(selectedCoachDashboardFilter);
    setSelectedCoachDashboardFilter(CoachDashboardFilter[label_0]);
    setSortWorkflowsAtom(sortOptions_0);
    setAllWorkflowFlag(false);
    setListToMarkComplete([]);
    setListToMarkPending([]);
    setBulkHLNSuccess(false);
    setBulkUnreadSuccess(false);
    setBulkHLNFailed(false);
    setBulkUnreadFailed(false);
    setShowAlert(false);
    setShowAlertTimeout(false);
  };
  const onOsClick = (label_1: OsDashboardFilters): void => {
    if (workflowListLoadingState) {
      return;
    }
    let sortOptions_1: SortWorkflowType = {
      property: SORT_PROPERTY.LATEST_TASK_DATE,
      order: 'ASC'
    };
    if (label_1 === OsDashboardFilters.Completed) {
      sortOptions_1 = {
        property: SORT_PROPERTY.LAST_COMPLETED_AT,
        order: 'DESC'
      };
    }
    setPrevfilter(selectedOsDashboardFilter);
    setSelectedOsDashboardFilter(OsDashboardFilter[label_1]);
    setSortWorkflowsAtom(sortOptions_1);
    setAllWorkflowFlag(false);
    setListToMarkComplete([]);
    setListToMarkPending([]);
    setBulkHLNSuccess(false);
    setBulkUnreadSuccess(false);
    setBulkHLNFailed(false);
    setBulkUnreadFailed(false);
    setShowAlert(false);
    setShowAlertTimeout(false);
  };
  const isCoachDashboardDisable = (label_2: CoachDashboardFilters): boolean => {
    if (bulkHLNProgress && healthLogNotesTreatment) {
      if ([CoachDashboardFilters.All, CoachDashboardFilters.Completed, CoachDashboardFilters.MemberHealthLog].includes(label_2)) {
        return true;
      }
    }
    if (bulkUnreadProgress && unreadMessagesMlTreatment) {
      if ([CoachDashboardFilters.All, CoachDashboardFilters.Completed, CoachDashboardFilters.UnreadMessages].includes(label_2)) {
        return true;
      }
    }
    return false;
  };
  const isPtDashboardDisable = (label_3: PtDashboardFilters): boolean => {
    if (bulkHLNProgress && ptHealthLogNotesML) {
      if ([PtDashboardFilters.All, PtDashboardFilters.Completed, PtDashboardFilters.HealthLogNotes].includes(label_3)) {
        return true;
      }
    }
    if (bulkUnreadProgress && ptUnreadMessageML) {
      if ([PtDashboardFilters.All, PtDashboardFilters.Completed, PtDashboardFilters.UnreadMessage].includes(label_3)) {
        return true;
      }
    }
    return false;
  };
  const isOsDashboardDisable = (label_4: OsDashboardFilters): boolean => {
    if (bulkUnreadProgress && unreadMessagesMlTreatment) {
      if ([OsDashboardFilters.All, OsDashboardFilters.Completed, OsDashboardFilters.UnreadMessage].includes(label_4)) {
        return true;
      }
    }
    return false;
  };
  const getClickHandler = (dashboardType_0: DashboardType): ((label: CoachDashboardFilters) => void) | ((label: Exclude<PtDashboardFilters, PtDashboardFilters.All>) => void) | ((label: OsDashboardFilters) => void) => {
    switch (dashboardType_0) {
      case DashboardType.PhysicalTherapist:
        return onPTClick;
      case DashboardType.OnboardingSpecialist:
        return onOsClick;
      default:
        return onCoachClick;
    }
  };
  const handleCategoryClick = (dashboardType_1: DashboardType, label_5: T): void => {
    setTimeoutId(true);
    const clickHandleFn = (getClickHandler(dashboardType_1) as (label_5: T) => void);
    handleEventTracking(label_5, mixpanelTrack);
    clickHandleFn(label_5);
  };
  const getIsDisabled = (dashboardType_2: DashboardType, label_6: string): boolean => {
    switch (dashboardType_2) {
      case DashboardType.PhysicalTherapist:
        return isPtDashboardDisable((label_6 as PtDashboardFilters));
      case DashboardType.OnboardingSpecialist:
        return isOsDashboardDisable((label_6 as OsDashboardFilters));
      default:
        return isCoachDashboardDisable((label_6 as CoachDashboardFilters));
    }
  };
  return <HHDrawer anchor="left" open={true} variant="permanent" hideBackdrop={true} sx={filterStyles.drawer} PaperProps={filterStyles.paperProps} data-testid={FILTER_CONTAINER_IDS[dashboardType] || FILTER_CONTAINER_IDS[DashboardType.Coach]}>
      <HHBox sx={titleStyles} {...dashboardType === DashboardType.PhysicalTherapist && {
      'data-testid': 'pt-autocomplete'
    }}>
        {dashboardType === DashboardType.PhysicalTherapist && ptPooledDashboardFlag ? <PTList {...adminDetails} /> : <HHTypography hhVariant={'h2'}>{FILTER_TITLE}</HHTypography>}
      </HHBox>
      <HHBox sx={listContainerStyles}>
        {accordionList.map(item => {
        const showDivider = item.showDivider && (unreadMessagesTreatment || healthLogNotesTreatment);
        const showCategoryHeader = item.category;
        return <React.Fragment key={item.category}>
              {showCategoryHeader ? <HHAccordion sx={filterStyles.accordion} disableGutters defaultExpanded>
                  <HHAccordionSummary expandIcon={<ExpandMore />} sx={{
              padding: '0 1rem',
              '&:hover': {
                borderRadius: '4px',
                backgroundColor: ctpTokens.palette.sand.shades['30p']
              }
            }}>
                    <HHTypography hhVariant="body">
                      {item.category}
                    </HHTypography>
                  </HHAccordionSummary>
                  {item.name.map(label_7 => <HHAccordionDetails key={label_7} sx={{
              padding: 0
            }}>
                      <HHTypography hhVariant="body">
                        <WorkflowFilterItem<T> isCategoryHeader={item.showCategoryHeader} label={label_7} key={item.id} count={loading ? '-' : counts[label_7]} onClick={(): void => handleCategoryClick(dashboardType, label_7)} isSelected={label_7 === selectedLabel} isDisabled={getIsDisabled(dashboardType, label_7)} />
                      </HHTypography>
                    </HHAccordionDetails>)}
                </HHAccordion> : <WorkflowFilterItem<T> label={item.name[0]} key={item.id} count={loading ? '-' : counts[item.name[0]]} onClick={(): void => handleCategoryClick(dashboardType, item.name[0])} isSelected={item.name[0] === selectedLabel} isDisabled={getIsDisabled(dashboardType, item.name[0])} />}
              {(dashboardType === DashboardType.Coach ? showDivider : item.showDivider) && <HHDivider sx={filterStyles.divider}></HHDivider>}
            </React.Fragment>;
      })}
      </HHBox>
    </HHDrawer>;
};