import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox, HHButton, HHDialog, HHDialogActions, HHDialogContent, HHDialogContentText, HHDialogTitle, HHTableCell, HHTableHead, HHTableRow, HHTypography } from '@hinge-health/react-component-library';
import { makeStyles } from '@mui/styles';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { OsWorkflowTypes, PtWorkflowTypes } from '../../../../../custom-types';
import { MutationCreateTransitionArgs } from '../../../../sidebar/types';
import { PAGE_SIZE } from '../../../constants/gql-query-constants';
import { COACH_WORKFLOWS_TABLE, CONFIRM_BOX, HLN_PROGRESS, UNREAD_PROGRESS } from '../../../constants/strings/workflows-table';
import { useShellContext } from '../../../contexts/shell.context';
import { DashboardType, UnreadFilterType } from '../../../custom-types';
import { useFilterStates } from '../../../hooks/use-filter-states';
import { bulkWorkflowHLNProgress, bulkWorkflowStateAtom, bulkWorkflowUnreadProgress, listOfIncompletedWorkflowsObject, listOfWorkflowsObject, listToMarkWorkflowComplete, listToMarkWorkflowPending, selectAllPTWorkflowAtom, selectAllWorkflowAtom, selectedCoachDashboardFilters, selectedDashboardType, selectedOsDashboardFilters, selectedPtDashboardFilters, selectedUnreadType, sortWorkflowsAtom } from '../../../jotai/atoms';
import { BulkCreateUserWorkflowTransitionMutationVariables, useBulkCreateUserWorkflowTransitionMutation, WorkflowPriority } from '../../../types';
import { CoachDashboardFilters, CoachWorkflowTypes, CommonCoachState, commonCompletedStates } from '../../../types/coach';
import { OsDashboardFilters } from '../../../types/os';
import { CommonPtState, PtDashboardFilters } from '../../../types/pt';
import { getEndOfTomorrowInBusinessDays } from '../../../utils/get-formatted-date';
import { WorkflowBulkMark } from './table-row-cells/workflow-bulk-mark';
const PANEL_COLOR = '#f5f3ec';
const useStyles = makeStyles({
  root: {
    backgroundColor: PANEL_COLOR + '!important'
  },
  cell: {
    borderBottom: 'none',
    cursor: 'pointer'
  },
  markCompleteText: {
    marginLeft: '2vw',
    cursor: 'pointer'
  },
  markCompleteCell: {
    display: 'flex',
    position: 'relative',
    top: '1vh',
    left: '1vw'
  }
});
interface WorkflowsSelectionHeaderProps {
  totalCount: number;
}
export const WorkflowsSelectionHeader = t0 => {
  const $ = _c(106);
  const {
    totalCount
  } = t0;
  const [incompleteList, setListToMarkPending] = useAtom(listToMarkWorkflowPending);
  const [listToMarkComplete, setListToMarkComplete] = useAtom(listToMarkWorkflowComplete);
  const [allWorkflowFlag, setAllWorkflowFlag] = useAtom(selectAllWorkflowAtom);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const classes = useStyles();
  const [createUserBulkWorkflowTransitionMutation] = useBulkCreateUserWorkflowTransitionMutation();
  const [selectedCoachFilter] = useAtom(selectedCoachDashboardFilters);
  const {
    adminProfile
  } = useShellContext();
  const [sortWorkflows] = useAtom(sortWorkflowsAtom);
  const setBulkState = useSetAtom(bulkWorkflowStateAtom);
  const [, setBulkHLNProgress] = useAtom(bulkWorkflowHLNProgress);
  const [, setBulkUnreadProgress] = useAtom(bulkWorkflowUnreadProgress);
  const [currentDashboard] = useAtom(selectedDashboardType);
  const filterStates = useFilterStates(currentDashboard);
  const [allPTWorkflowFlag, setAllPTWorkflowFlag] = useAtom(selectAllPTWorkflowAtom);
  const [selectedPTFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsFilter] = useAtom(selectedOsDashboardFilters);
  const [listOfSelectedWorkflows, setListOfMarkedObjects] = useAtom(listOfWorkflowsObject);
  const [listOfUnselectedWorkflows, setListOfUnMarkedObjects] = useAtom(listOfIncompletedWorkflowsObject);
  const [selectedUnreadFilter] = useAtom(selectedUnreadType);
  let t1;
  if ($[0] !== allWorkflowFlag || $[1] !== allPTWorkflowFlag || $[2] !== totalCount || $[3] !== incompleteList.length || $[4] !== listToMarkComplete.length) {
    t1 = () => allWorkflowFlag || allPTWorkflowFlag ? totalCount - incompleteList.length : listToMarkComplete.length;
    $[0] = allWorkflowFlag;
    $[1] = allPTWorkflowFlag;
    $[2] = totalCount;
    $[3] = incompleteList.length;
    $[4] = listToMarkComplete.length;
    $[5] = t1;
  } else {
    t1 = $[5];
  }
  const getWorkflowMarkCount = t1;
  const count = getWorkflowMarkCount();
  let t2;
  let t3;
  if ($[6] !== count || $[7] !== setAllWorkflowFlag || $[8] !== setAllPTWorkflowFlag || $[9] !== setListToMarkComplete || $[10] !== setListToMarkPending) {
    t2 = () => {
      if (count === 0) {
        setAllWorkflowFlag(false);
        setAllPTWorkflowFlag(false);
        setListToMarkComplete([]);
        setListToMarkPending([]);
      }
    };
    t3 = [count, setAllPTWorkflowFlag, setAllWorkflowFlag, setListToMarkComplete, setListToMarkPending];
    $[6] = count;
    $[7] = setAllWorkflowFlag;
    $[8] = setAllPTWorkflowFlag;
    $[9] = setListToMarkComplete;
    $[10] = setListToMarkPending;
    $[11] = t2;
    $[12] = t3;
  } else {
    t2 = $[11];
    t3 = $[12];
  }
  useEffect(t2, t3);
  let t4;
  if ($[13] !== allWorkflowFlag || $[14] !== allPTWorkflowFlag || $[15] !== listToMarkComplete || $[16] !== incompleteList) {
    t4 = () => {
      if (!allWorkflowFlag || !allPTWorkflowFlag) {
        return {
          markWorkflowComplete: listToMarkComplete,
          markWorkflowIncomplete: [],
          allWorkflowSelected: false
        };
      }
      if (incompleteList.length === 0) {
        return {
          markWorkflowComplete: [],
          markWorkflowIncomplete: [],
          allWorkflowSelected: true
        };
      }
      return {
        markWorkflowComplete: [],
        markWorkflowIncomplete: incompleteList,
        allWorkflowSelected: true
      };
    };
    $[13] = allWorkflowFlag;
    $[14] = allPTWorkflowFlag;
    $[15] = listToMarkComplete;
    $[16] = incompleteList;
    $[17] = t4;
  } else {
    t4 = $[17];
  }
  const dataToSend = t4;
  let t5;
  if ($[18] !== selectedCoachFilter.filterLabel) {
    t5 = () => {
      if (selectedCoachFilter.filterLabel === CoachDashboardFilters.MemberHealthLog) {
        return CoachWorkflowTypes.MemberHealthLog;
      }
      if (selectedCoachFilter.filterLabel === CoachDashboardFilters.UnreadMessages) {
        return CoachWorkflowTypes.UnreadMessages;
      }
      return "";
    };
    $[18] = selectedCoachFilter.filterLabel;
    $[19] = t5;
  } else {
    t5 = $[19];
  }
  const getWorkflowType = t5;
  let t6;
  if ($[20] !== selectedOsFilter.filterLabel) {
    t6 = () => {
      if (selectedOsFilter.filterLabel === OsDashboardFilters.UnreadMessage) {
        return OsWorkflowTypes.UnreadMessages;
      }
      return "";
    };
    $[20] = selectedOsFilter.filterLabel;
    $[21] = t6;
  } else {
    t6 = $[21];
  }
  const getOSWorkflowType = t6;
  let t7;
  if ($[22] !== selectedPTFilter.filterLabel || $[23] !== selectedUnreadFilter) {
    t7 = () => {
      if (selectedPTFilter.filterLabel === PtDashboardFilters.UnreadMessage) {
        if (selectedUnreadFilter === UnreadFilterType.HealthLog) {
          return PtWorkflowTypes.ModularIamUnreadMessages;
        } else {
          return PtWorkflowTypes.UnreadMessages;
        }
      } else {
        return PtWorkflowTypes.HealthLog;
      }
    };
    $[22] = selectedPTFilter.filterLabel;
    $[23] = selectedUnreadFilter;
    $[24] = t7;
  } else {
    t7 = $[24];
  }
  const getPTWorkflowType = t7;
  let t8;
  if ($[25] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = workflowType => workflowType === PtWorkflowTypes.HealthLog ? CommonPtState.AcuteComplete : CommonCoachState.CoachComplete;
    $[25] = t8;
  } else {
    t8 = $[25];
  }
  const getTransitionState = t8;
  let t9;
  if ($[26] !== currentDashboard || $[27] !== getWorkflowType || $[28] !== getPTWorkflowType || $[29] !== getOSWorkflowType) {
    t9 = (bulkMarkData, ptWorkflowType) => {
      const workflowToMarked = [];
      let workflowType_0;
      workflowType_0 = "";
      if (ptWorkflowType) {
        workflowType_0 = ptWorkflowType;
      } else {
        bb59: switch (currentDashboard) {
          case DashboardType.Coach:
            {
              workflowType_0 = getWorkflowType();
              break bb59;
            }
          case DashboardType.PhysicalTherapist:
            {
              workflowType_0 = getPTWorkflowType();
              break bb59;
            }
          case DashboardType.OnboardingSpecialist:
            {
              workflowType_0 = getOSWorkflowType();
            }
        }
      }
      const toState = getTransitionState(workflowType_0);
      bulkMarkData.markWorkflowComplete.forEach(markData => workflowToMarked.push({
        createTransitionInputDto: {
          id: markData,
          toState
        },
        workflowType: workflowType_0
      }));
      return workflowToMarked;
    };
    $[26] = currentDashboard;
    $[27] = getWorkflowType;
    $[28] = getPTWorkflowType;
    $[29] = getOSWorkflowType;
    $[30] = t9;
  } else {
    t9 = $[30];
  }
  const getWorkflowTransition = t9;
  let completeWorkflows;
  if ($[31] !== currentDashboard || $[32] !== getWorkflowType || $[33] !== getPTWorkflowType || $[34] !== getOSWorkflowType || $[35] !== dataToSend || $[36] !== selectedCoachFilter.filterLabel || $[37] !== selectedPTFilter.filterLabel || $[38] !== getWorkflowTransition || $[39] !== adminProfile.uuid || $[40] !== filterStates || $[41] !== sortWorkflows.order || $[42] !== sortWorkflows.property || $[43] !== createUserBulkWorkflowTransitionMutation || $[44] !== setAllPTWorkflowFlag || $[45] !== setAllWorkflowFlag || $[46] !== setListToMarkComplete || $[47] !== setListToMarkPending || $[48] !== setListOfMarkedObjects || $[49] !== setListOfUnMarkedObjects || $[50] !== setBulkState || $[51] !== setBulkHLNProgress || $[52] !== selectedOsFilter.filterLabel || $[53] !== setBulkUnreadProgress || $[54] !== selectedUnreadFilter || $[55] !== allPTWorkflowFlag || $[56] !== listOfSelectedWorkflows || $[57] !== listOfUnselectedWorkflows) {
    const bulkMarkWorkflow = async () => {
      let workflowType_1 = "";
      bb70: switch (currentDashboard) {
        case DashboardType.Coach:
          {
            workflowType_1 = getWorkflowType();
            break bb70;
          }
        case DashboardType.PhysicalTherapist:
          {
            workflowType_1 = getPTWorkflowType();
            break bb70;
          }
        case DashboardType.OnboardingSpecialist:
          {
            workflowType_1 = getOSWorkflowType();
          }
      }
      const bulkMarkData_0 = dataToSend();
      const filterLabel = currentDashboard == DashboardType.Coach ? selectedCoachFilter.filterLabel : selectedPTFilter.filterLabel;
      const toState_0 = getTransitionState(workflowType_1);
      const bulkCreateTransitionVariables = {
        bulkCreateTransitionInputDto: {
          allWorkflowsChecked: bulkMarkData_0.allWorkflowSelected,
          submissionId: uuid(),
          toState: toState_0,
          workflowType: workflowType_1,
          workflowsToExclude: bulkMarkData_0.markWorkflowIncomplete,
          workflowsToTransition: getWorkflowTransition(bulkMarkData_0),
          workflowListFilterInputDto: {
            adminUuids: [adminProfile.uuid],
            pageSize: PAGE_SIZE,
            pageNumber: 1,
            completedStates: commonCompletedStates,
            ...filterStates[filterLabel],
            useCoverage: true,
            dueTaskEndDate: getEndOfTomorrowInBusinessDays(),
            priority: WorkflowPriority.Low,
            order: sortWorkflows.order,
            orderBy: sortWorkflows.property
          }
        }
      };
      await createUserBulkWorkflowTransitionMutation({
        variables: bulkCreateTransitionVariables
      }).then(() => {
        resetState();
      });
    };
    const resetState = () => {
      setAllPTWorkflowFlag(false);
      setAllWorkflowFlag(false);
      setListToMarkComplete([]);
      setListToMarkPending([]);
      setListOfMarkedObjects([]);
      setListOfUnMarkedObjects([]);
    };
    let t10;
    if ($[59] !== selectedCoachFilter.filterLabel || $[60] !== selectedPTFilter.filterLabel || $[61] !== setBulkState || $[62] !== setBulkHLNProgress || $[63] !== selectedOsFilter.filterLabel || $[64] !== setBulkUnreadProgress) {
      t10 = () => {
        if (selectedCoachFilter.filterLabel === CoachDashboardFilters.MemberHealthLog || selectedPTFilter.filterLabel === PtDashboardFilters.HealthLogNotes) {
          setBulkState(prevState => ({
            ...prevState,
            HLN: "progress"
          }));
          setBulkHLNProgress(true);
          window.dispatchEvent(new Event(HLN_PROGRESS));
          sessionStorage.setItem("hlnProgress", "true");
        }
        if (selectedCoachFilter.filterLabel === CoachDashboardFilters.UnreadMessages || selectedPTFilter.filterLabel === PtDashboardFilters.UnreadMessage || selectedOsFilter.filterLabel === OsDashboardFilters.UnreadMessage) {
          setBulkState(prevState_0 => ({
            ...prevState_0,
            Unread: "progress"
          }));
          setBulkUnreadProgress(true);
          window.dispatchEvent(new Event(UNREAD_PROGRESS));
          sessionStorage.setItem("unreadProgress", "true");
        }
      };
      $[59] = selectedCoachFilter.filterLabel;
      $[60] = selectedPTFilter.filterLabel;
      $[61] = setBulkState;
      $[62] = setBulkHLNProgress;
      $[63] = selectedOsFilter.filterLabel;
      $[64] = setBulkUnreadProgress;
      $[65] = t10;
    } else {
      t10 = $[65];
    }
    const setProgressStateForQueue = t10;
    let t11;
    if ($[66] === Symbol.for("react.memo_cache_sentinel")) {
      t11 = (arr, targetName) => arr.filter(obj => obj.name === targetName).map(obj_0 => obj_0.id);
      $[66] = t11;
    } else {
      t11 = $[66];
    }
    const getIdsByName = t11;
    let t12;
    if ($[67] === Symbol.for("react.memo_cache_sentinel")) {
      t12 = arr_0 => {
        if (arr_0.length === 0) {
          return null;
        }
        const firstObjectName = arr_0[0].name;
        const allNamesSame = arr_0.every(obj_1 => obj_1.name === firstObjectName);
        return allNamesSame ? firstObjectName : null;
      };
      $[67] = t12;
    } else {
      t12 = $[67];
    }
    const areAllNamesSame = t12;
    completeWorkflows = async () => {
      setShowConfirmBox(false);
      setProgressStateForQueue();
      const isPtUnreadMessageCategory = selectedPTFilter.filterLabel === PtDashboardFilters.UnreadMessage;
      if (isPtUnreadMessageCategory && selectedUnreadFilter === UnreadFilterType.AllTypes) {
        if (!allPTWorkflowFlag) {
          await handleSelectedWorkflows();
        } else {
          await handleAllSelectedWorkflows();
        }
      } else {
        bulkMarkWorkflow();
      }
    };
    const handleSelectedWorkflows = async () => {
      const sameName = areAllNamesSame(listOfSelectedWorkflows);
      if (sameName) {
        await processSameNameWorkflows(sameName);
      } else {
        await processDifferentNameWorkflows();
      }
    };
    const processSameNameWorkflows = async sameName_0 => {
      let list;
      let workflowType_2;
      if (selectedPTFilter.filterLabel === PtDashboardFilters.UnreadMessageNoResponse) {
        if (sameName_0 === UnreadFilterType.HealthLog) {
          list = getIdsByName(listOfSelectedWorkflows, UnreadFilterType.HealthLog);
          workflowType_2 = PtWorkflowTypes.ModularIamUnreadMessages;
        } else {
          list = getIdsByName(listOfSelectedWorkflows, UnreadFilterType.Messages);
          workflowType_2 = PtWorkflowTypes.UnreadMessages;
        }
      } else {
        list = listOfSelectedWorkflows.map(obj_2 => obj_2.id);
        workflowType_2 = listOfSelectedWorkflows[0].workflowType;
      }
      const data = {
        markWorkflowComplete: list,
        markWorkflowIncomplete: [],
        allWorkflowSelected: false
      };
      await createTransition(data, workflowType_2);
    };
    const processDifferentNameWorkflows = async () => {
      const healthLogList = getIdsByName(listOfSelectedWorkflows, UnreadFilterType.HealthLog);
      const messageList = getIdsByName(listOfSelectedWorkflows, UnreadFilterType.Messages);
      await createTransition({
        markWorkflowComplete: healthLogList,
        markWorkflowIncomplete: [],
        allWorkflowSelected: false
      }, PtWorkflowTypes.ModularIamUnreadMessages);
      await createTransition({
        markWorkflowComplete: messageList,
        markWorkflowIncomplete: [],
        allWorkflowSelected: false
      }, PtWorkflowTypes.UnreadMessages);
    };
    const handleAllSelectedWorkflows = async () => {
      if (listOfUnselectedWorkflows.length === 0) {
        await createTransition({
          markWorkflowComplete: [],
          markWorkflowIncomplete: [],
          allWorkflowSelected: true
        }, PtWorkflowTypes.UnreadMessages);
      } else {
        const sameName_1 = areAllNamesSame(listOfUnselectedWorkflows);
        if (sameName_1) {
          await processUnselectedSameNameWorkflows(sameName_1);
        } else {
          await processUnselectedDifferentNameWorkflows();
        }
      }
    };
    const processUnselectedSameNameWorkflows = async sameName_2 => {
      let unselectedList;
      let workflowType_3;
      if (sameName_2 === UnreadFilterType.HealthLog) {
        unselectedList = getIdsByName(listOfUnselectedWorkflows, UnreadFilterType.HealthLog);
        workflowType_3 = PtWorkflowTypes.ModularIamUnreadMessages;
      } else {
        unselectedList = getIdsByName(listOfUnselectedWorkflows, UnreadFilterType.Messages);
        workflowType_3 = PtWorkflowTypes.UnreadMessages;
      }
      await createTransition({
        markWorkflowComplete: [],
        markWorkflowIncomplete: unselectedList,
        allWorkflowSelected: true
      }, workflowType_3);
    };
    const processUnselectedDifferentNameWorkflows = async () => {
      const healthLogList_0 = getIdsByName(listOfUnselectedWorkflows, UnreadFilterType.HealthLog);
      const messageList_0 = getIdsByName(listOfUnselectedWorkflows, UnreadFilterType.Messages);
      await createTransition({
        markWorkflowComplete: [],
        markWorkflowIncomplete: healthLogList_0,
        allWorkflowSelected: true
      }, PtWorkflowTypes.ModularIamUnreadMessages);
      await createTransition({
        markWorkflowComplete: [],
        markWorkflowIncomplete: messageList_0,
        allWorkflowSelected: true
      }, PtWorkflowTypes.UnreadMessages);
    };
    const createTransition = async (data_0, workflowType_4) => {
      const toState_1 = getTransitionState(workflowType_4);
      const bulkCreateTransitionVariables_0 = {
        bulkCreateTransitionInputDto: {
          allWorkflowsChecked: data_0.allWorkflowSelected,
          submissionId: uuid(),
          toState: toState_1,
          workflowType: workflowType_4,
          workflowsToExclude: data_0.markWorkflowIncomplete,
          workflowsToTransition: getWorkflowTransition(data_0, workflowType_4),
          workflowListFilterInputDto: {
            adminUuids: [adminProfile.uuid],
            pageSize: PAGE_SIZE,
            pageNumber: 1,
            completedStates: commonCompletedStates,
            ...filterStates[selectedPTFilter.filterLabel],
            useCoverage: true,
            dueTaskEndDate: getEndOfTomorrowInBusinessDays(),
            priority: WorkflowPriority.Low,
            order: sortWorkflows.order,
            orderBy: sortWorkflows.property
          }
        }
      };
      await createUserBulkWorkflowTransitionMutation({
        variables: bulkCreateTransitionVariables_0
      }).then(() => {
        resetState();
      });
    };
    $[31] = currentDashboard;
    $[32] = getWorkflowType;
    $[33] = getPTWorkflowType;
    $[34] = getOSWorkflowType;
    $[35] = dataToSend;
    $[36] = selectedCoachFilter.filterLabel;
    $[37] = selectedPTFilter.filterLabel;
    $[38] = getWorkflowTransition;
    $[39] = adminProfile.uuid;
    $[40] = filterStates;
    $[41] = sortWorkflows.order;
    $[42] = sortWorkflows.property;
    $[43] = createUserBulkWorkflowTransitionMutation;
    $[44] = setAllPTWorkflowFlag;
    $[45] = setAllWorkflowFlag;
    $[46] = setListToMarkComplete;
    $[47] = setListToMarkPending;
    $[48] = setListOfMarkedObjects;
    $[49] = setListOfUnMarkedObjects;
    $[50] = setBulkState;
    $[51] = setBulkHLNProgress;
    $[52] = selectedOsFilter.filterLabel;
    $[53] = setBulkUnreadProgress;
    $[54] = selectedUnreadFilter;
    $[55] = allPTWorkflowFlag;
    $[56] = listOfSelectedWorkflows;
    $[57] = listOfUnselectedWorkflows;
    $[58] = completeWorkflows;
  } else {
    completeWorkflows = $[58];
  }
  let t10;
  if ($[68] === Symbol.for("react.memo_cache_sentinel")) {
    t10 = <WorkflowBulkMark markType="header" />;
    $[68] = t10;
  } else {
    t10 = $[68];
  }
  let t11;
  if ($[69] !== classes.cell) {
    t11 = <HHTableCell className={classes.cell} size="small">{t10}</HHTableCell>;
    $[69] = classes.cell;
    $[70] = t11;
  } else {
    t11 = $[70];
  }
  let t12;
  if ($[71] === Symbol.for("react.memo_cache_sentinel")) {
    t12 = {
      fontWeight: "bold"
    };
    $[71] = t12;
  } else {
    t12 = $[71];
  }
  const t13 = count + COACH_WORKFLOWS_TABLE.HEADERS.COUNT_SELECTED;
  let t14;
  if ($[72] !== t13) {
    t14 = <HHTypography style={t12} hhVariant="body" color={ctpTokens.palette.neutral[40]}>{t13}</HHTypography>;
    $[72] = t13;
    $[73] = t14;
  } else {
    t14 = $[73];
  }
  let t15;
  if ($[74] !== classes.cell || $[75] !== t14) {
    t15 = <HHTableCell className={classes.cell} size="small" padding="none">{t14}</HHTableCell>;
    $[74] = classes.cell;
    $[75] = t14;
    $[76] = t15;
  } else {
    t15 = $[76];
  }
  let t16;
  if ($[77] === Symbol.for("react.memo_cache_sentinel")) {
    t16 = {
      fontWeight: "bold"
    };
    $[77] = t16;
  } else {
    t16 = $[77];
  }
  let t17;
  if ($[78] === Symbol.for("react.memo_cache_sentinel")) {
    t17 = () => {
      setShowConfirmBox(true);
    };
    $[78] = t17;
  } else {
    t17 = $[78];
  }
  let t18;
  if ($[79] === Symbol.for("react.memo_cache_sentinel")) {
    t18 = <HHTypography sx={t16} hhVariant="body" color={ctpTokens.palette.green[40]} onClick={t17}>{COACH_WORKFLOWS_TABLE.HEADERS.MARK_COMPLETE}</HHTypography>;
    $[79] = t18;
  } else {
    t18 = $[79];
  }
  let t19;
  if ($[80] !== classes.markCompleteText) {
    t19 = <HHTableCell className={classes.markCompleteText} size="small" padding="none">{t18}</HHTableCell>;
    $[80] = classes.markCompleteText;
    $[81] = t19;
  } else {
    t19 = $[81];
  }
  let t20;
  if ($[82] !== classes.markCompleteCell || $[83] !== t15 || $[84] !== t19) {
    t20 = <HHBox className={classes.markCompleteCell}>{t15}{t19}</HHBox>;
    $[82] = classes.markCompleteCell;
    $[83] = t15;
    $[84] = t19;
    $[85] = t20;
  } else {
    t20 = $[85];
  }
  let t21;
  if ($[86] === Symbol.for("react.memo_cache_sentinel")) {
    t21 = <HHTableCell colSpan={6} padding="none" />;
    $[86] = t21;
  } else {
    t21 = $[86];
  }
  let t22;
  if ($[87] !== classes.root || $[88] !== t11 || $[89] !== t20) {
    t22 = <HHTableRow className={classes.root}>{t11}{t20}{t21}</HHTableRow>;
    $[87] = classes.root;
    $[88] = t11;
    $[89] = t20;
    $[90] = t22;
  } else {
    t22 = $[90];
  }
  let t23;
  if ($[91] !== classes.root || $[92] !== t22) {
    t23 = <HHTableHead className={classes.root}>{t22}</HHTableHead>;
    $[91] = classes.root;
    $[92] = t22;
    $[93] = t23;
  } else {
    t23 = $[93];
  }
  let t24;
  if ($[94] === Symbol.for("react.memo_cache_sentinel")) {
    t24 = <HHDialogTitle>{COACH_WORKFLOWS_TABLE.HEADERS.MARKCOMPLETE}</HHDialogTitle>;
    $[94] = t24;
  } else {
    t24 = $[94];
  }
  let t25;
  if ($[95] === Symbol.for("react.memo_cache_sentinel")) {
    t25 = <HHDialogContent><HHDialogContentText>{CONFIRM_BOX.STATEMENT}</HHDialogContentText></HHDialogContent>;
    $[95] = t25;
  } else {
    t25 = $[95];
  }
  let t26;
  if ($[96] === Symbol.for("react.memo_cache_sentinel")) {
    t26 = () => setShowConfirmBox(false);
    $[96] = t26;
  } else {
    t26 = $[96];
  }
  let t27;
  if ($[97] === Symbol.for("react.memo_cache_sentinel")) {
    t27 = <HHButton hhVariant="variant-bypass" onClick={t26}>{CONFIRM_BOX.CANCEL}</HHButton>;
    $[97] = t27;
  } else {
    t27 = $[97];
  }
  let t28;
  if ($[98] !== completeWorkflows) {
    t28 = <HHDialogActions>{t27}<HHButton hhVariant="variant-bypass" onClick={completeWorkflows}>{CONFIRM_BOX.CONFIRM}</HHButton></HHDialogActions>;
    $[98] = completeWorkflows;
    $[99] = t28;
  } else {
    t28 = $[99];
  }
  let t29;
  if ($[100] !== showConfirmBox || $[101] !== t28) {
    t29 = <HHDialog open={showConfirmBox} data-testid="confirm-box">{t24}{t25}{t28}</HHDialog>;
    $[100] = showConfirmBox;
    $[101] = t28;
    $[102] = t29;
  } else {
    t29 = $[102];
  }
  let t30;
  if ($[103] !== t23 || $[104] !== t29) {
    t30 = <React.Fragment>{t23}{t29}</React.Fragment>;
    $[103] = t23;
    $[104] = t29;
    $[105] = t30;
  } else {
    t30 = $[105];
  }
  return t30;
};