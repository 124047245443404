import { c as _c } from "react-compiler-runtime";
import { MutationFunctionOptions, MutationTuple, OperationVariables, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { InputMaybe, Scalars, WorkflowListFiltersInputDto } from '../types';
export type UpdateWorkflowListMutation = {
  updateWorkflowListInputDto: {
    adminUuids: InputMaybe<Array<Scalars['ID']>>;
    allWorkflowsChecked: boolean;
    workflowIds: Array<Scalars['Int']>;
    workflowIdsToExclude: Array<Scalars['Int']>;
  };
  workflowListFiltersInputDto: WorkflowListFiltersInputDto;
};
export const UPDATE_WORKFLOW_LIST = gql`
  mutation UpdateWorkflowList(
    $updateWorkflowListInputDto: UpdateWorkflowListInputDto!
    $workflowListFiltersInputDto: WorkflowListFiltersInputDto
  ) {
    updateWorkflowList(
      updateWorkflowListInputDto: $updateWorkflowListInputDto
      workflowListFiltersInputDto: $workflowListFiltersInputDto
    ) {
      id
    }
  }
`;
export const useUpdateWorkflowListMutation = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useMutation(UPDATE_WORKFLOW_LIST, options);
};