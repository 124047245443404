import axios, { AxiosResponse } from 'axios';
import { FreshdeskTicketCustomFields, FreshdeskTicketDetails } from '../components/navigation-components/feedback-dialog/freshdesk-dialog';
import { USER_FEEDBACK } from '../constants/strings/user-feedback';
const FRESHDESK_URL = process.env.FRESHDESK_URL;
const API_KEY = process.env.FRESHDESK_API_KEY;
export interface FeedbackParams {
  ticketDetails: FreshdeskTicketDetails;
  customTicketDetails: FreshdeskTicketCustomFields;
}
interface ValidationResult {
  status: boolean;
  message: string;
}
type CustomFieldKey = keyof FreshdeskTicketCustomFields;
const processNumbers = (input: string): {
  firstNumber: number;
  restOfNumbers: string;
} => {
  // split the user id with space, comma, or semicolon
  const numbers = input.split(/[\s,;]+/).map(Number).filter(n => !isNaN(n));
  // Get the first number
  const firstNumber = numbers[0];
  // Get the rest of the numbers
  const restOfNumbers = numbers.slice(1);
  // Format the rest of the numbers as a comma-separated string
  const formattedRest = restOfNumbers.join(',');
  return {
    firstNumber,
    restOfNumbers: formattedRest
  };
};
export const validateTicketDetails = (ticketDetails: FreshdeskTicketDetails, attachments: FileList | null): number | ValidationResult | undefined => {
  const getTotalFileSize = (attachments: FileList): number => {
    let totalSize = 0;
    for (const file of attachments) {
      totalSize += file.size;
    }
    return totalSize;
  };
  if (attachments && attachments.length && getTotalFileSize(attachments) > USER_FEEDBACK.MAX_FILE_SIZE_IN_MB * 1024 * 1024) {
    return {
      status: false,
      message: USER_FEEDBACK.FILE_SIZE_ERROR
    };
  }
};
export const freshdeskCreateTicket = async (params: FeedbackParams): Promise<unknown> => {
  const {
    ticketDetails,
    customTicketDetails
  } = params;
  const userIds = customTicketDetails.cf_user_id;
  if (userIds) {
    const {
      firstNumber,
      restOfNumbers
    } = processNumbers(String(userIds));
    customTicketDetails.cf_user_id = firstNumber;
    if (restOfNumbers) {
      ticketDetails.description = ticketDetails.description + '\n' + 'User IDs: ' + restOfNumbers;
    }
  }
  const custom_fields: FreshdeskTicketCustomFields = {
    cf_user_id: customTicketDetails.cf_user_id
  };
  Object.entries(customTicketDetails).forEach(([key, value]) => {
    if (value && typeof value === 'string') {
      custom_fields[(key as CustomFieldKey)] = value;
    }
  });
  try {
    const response = await axios.post(FRESHDESK_URL + USER_FEEDBACK.FRESHDESK_CREATE_UPDATE_TICKET_PATH, {
      ...ticketDetails,
      custom_fields
    }, {
      headers: {
        Authorization: `Basic ${btoa(API_KEY || '')}`
      }
    });
    return response;
  } catch (error) {
    return {
      message: (error as Error).message
    };
  }
};
export const uploadTicketAttachments = async (ticketId: string, attachments: FileList | null): Promise<AxiosResponse> => {
  const formData = new FormData();
  if (attachments && attachments.length) {
    for (const file of attachments) {
      formData.append('attachments[]', file);
    }
  }
  const fileUploadResponse = await axios.put(FRESHDESK_URL + USER_FEEDBACK.FRESHDESK_CREATE_UPDATE_TICKET_PATH + ticketId, formData, {
    headers: {
      Authorization: `Basic ${btoa(API_KEY || '')}`
    }
  });
  return fileUploadResponse;
};