import { datadogRum } from '@datadog/browser-rum';
import { CtpThemeProvider } from '@hinge-health/react-component-library';
import * as Sentry from '@sentry/react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Streami18n } from 'stream-chat-react';
import WebFont from 'webfontloader';
import App from './app';
import { axeCoreEnvironmentExceptionList, splitSdkConfig } from './config';
import { NodeEnv } from './constants/env';
import './i18n';
import './index.css';
import { getBaseRoute, isDev, isProduction } from './utils/environment';
import { initMixpanel } from './utils/mixpanel';
import { lazyLoadFontFamilies } from './utils/web-font-loader';
const i18nInstance = new Streami18n({
  dayjsLocaleConfigForLanguage: {
    calendar: {
      lastDay: '[Yesterday at] h:mm A',
      lastWeek: 'dddd [at] h:mm A',
      nextDay: '[Tomorrow at] h:mm A',
      nextWeek: 'dddd [at] h:mm A',
      sameDay: '[Today at] h:mm A',
      sameElse: 'MM/DD/YYYY [at] h:mm A'
    }
  }
});
if (!i18nInstance) {
  console.warn('Failed to set custom day message for IAM');
}
(async (): Promise<void> => {
  if (!axeCoreEnvironmentExceptionList.includes((process.env.NODE_ENV as NodeEnv))) {
    const axe = await import('@axe-core/react');
    axe.default(React, ReactDOM, 1000);
  }
})();
WebFont.load({
  google: {
    families: lazyLoadFontFamilies(['Roboto:wght@100,300,400,500,600,700,900', 'Lato:wght@100,300,400,500,600,700,900', 'sans-serif'])
  }
});
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  debug: isDev,
  environment: process.env.APP_ENV,
  release: process.env.VERSION,
  attachStacktrace: true,
  integrations: [Sentry.browserTracingIntegration({
    enableInp: true
  })],
  // Capture 100% of traces in pre-production and 10% in production
  tracesSampleRate: isProduction ? 0.1 : 1.0
});
initMixpanel();
datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'care-team-products-service',
  env: process.env.APP_ENV,
  traceSampleRate: 100,
  trackUserInteractions: false,
  trackResources: true,
  trackLongTasks: true,
  version: process.env.VERSION,
  useSecureSessionCookie: true
});
const baseName = getBaseRoute();
ReactDOM.render(<React.StrictMode>
    <BrowserRouter basename={baseName}>
      {process.env.SPLIT_AUTH_KEY ? <SplitFactory config={splitSdkConfig}>
          <CtpThemeProvider>
            <App />
          </CtpThemeProvider>
        </SplitFactory> : <CtpThemeProvider>
          <App />
        </CtpThemeProvider>}
    </BrowserRouter>
  </React.StrictMode>, document.getElementById('root'));
export default Sentry.withProfiler(App);