/* eslint-disable @typescript-eslint/naming-convention */

import { PtDashboardFilters } from './../types/pt';
import { DashboardFilterType } from './coach-dashboard';
import { DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER } from './strings/coach-dashboard-default';
import { DEFAULT_PT_DASHBOARD_TAB_NAME, HOUSE_CALL_TAB_NAMES, NO_RESPONSE_PREDICTED_TAB_NAME, PT_VIDEO_VISIT_TAB_NAMES } from './strings/pt-layout';
export type PtDashboardFilterType = DashboardFilterType<PtDashboardFilters>;
export const PtDashboardFilter: PtDashboardFilterType = {
  [PtDashboardFilters.All]: {
    filterLabel: PtDashboardFilters.All,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.UnreadMessage]: {
    filterLabel: PtDashboardFilters.UnreadMessage,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.UnreadMessageNoResponse]: {
    filterLabel: PtDashboardFilters.UnreadMessage,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: NO_RESPONSE_PREDICTED_TAB_NAME
  },
  [PtDashboardFilters.VideoVisit]: {
    filterLabel: PtDashboardFilters.VideoVisitUpcoming,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: PT_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS
  },
  [PtDashboardFilters.VideoVisitIncomplete]: {
    filterLabel: PtDashboardFilters.VideoVisitIncomplete,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: PT_VIDEO_VISIT_TAB_NAMES.INCOMPLETE_VIDEO_VISITS
  },
  [PtDashboardFilters.VideoVisitUpcoming]: {
    filterLabel: PtDashboardFilters.VideoVisitUpcoming,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: PT_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS
  },
  [PtDashboardFilters.HouseCall]: {
    filterLabel: PtDashboardFilters.HouseCall,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: HOUSE_CALL_TAB_NAMES.UPCOMING_HOUSE_CALLS
  },
  [PtDashboardFilters.HouseCallIncomplete]: {
    filterLabel: PtDashboardFilters.HouseCallIncomplete,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: HOUSE_CALL_TAB_NAMES.INCOMPLETE_HOUSE_CALLS
  },
  [PtDashboardFilters.HouseCallUpcoming]: {
    filterLabel: PtDashboardFilters.HouseCallUpcoming,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: HOUSE_CALL_TAB_NAMES.UPCOMING_HOUSE_CALLS
  },
  [PtDashboardFilters.HealthLogNotes]: {
    filterLabel: PtDashboardFilters.HealthLogNotes,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.HealthLogNoResponse]: {
    filterLabel: PtDashboardFilters.HealthLogNotes,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: NO_RESPONSE_PREDICTED_TAB_NAME
  },
  [PtDashboardFilters.PainUptick]: {
    filterLabel: PtDashboardFilters.PainUptick,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.ExpiringCarePlans]: {
    filterLabel: PtDashboardFilters.ExpiringCarePlans,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.DischargeAndClosePlan]: {
    filterLabel: PtDashboardFilters.DischargeAndClosePlan,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.SurgeryTrend]: {
    filterLabel: PtDashboardFilters.SurgeryTrend,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.FirstWeekEnsoCheckIn]: {
    filterLabel: PtDashboardFilters.FirstWeekEnsoCheckIn,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.Completed]: {
    filterLabel: PtDashboardFilters.Completed,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.MedicalEmergencyFollowUp]: {
    filterLabel: PtDashboardFilters.MedicalEmergencyFollowUp,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.EtModification]: {
    filterLabel: PtDashboardFilters.EtModification,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.ClinicalEscalation]: {
    filterLabel: PtDashboardFilters.ClinicalEscalation,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.AqRedFlags]: {
    filterLabel: PtDashboardFilters.AqRedFlags,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.ActionPlanReview]: {
    filterLabel: PtDashboardFilters.ActionPlanReview,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.PtMemberAlertReminder]: {
    filterLabel: PtDashboardFilters.PtMemberAlertReminder,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.SetupActionPlan]: {
    filterLabel: PtDashboardFilters.SetupActionPlan,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  },
  [PtDashboardFilters.IndicationUpdate]: {
    filterLabel: PtDashboardFilters.IndicationUpdate,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_PT_DASHBOARD_TAB_NAME
  }
};