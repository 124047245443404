import dayjs from 'dayjs';
import _, { capitalize } from 'lodash';
import { basiliskSplitClient } from '../../../config';
import { OsWorkflowTypes, PtWorkflowTypes } from '../../../custom-types';
import { GetLastEtSessionQuery } from '../../../types';
import { quoteString } from '../../../utils/string-helpers';
import { HouseCallsWorkflows, VideoVisitsWithoutMedicalHistory, VideoVisitsWorkflows } from '../../acute-physical-therapy/custom-types';
import { getVideoVisitsType, getVideoVisitsTypeByFollowups } from '../../acute-physical-therapy/utils/video-visits';
import { getSubtitleForMemberAlertsDashboard } from '../../member-360/modules/panel-layouts/workflows-panel/clinical-template/utils/clinical-template-panel-util';
import { OUTREACH_NEEDED_WORKFLOW_INSIGHTS_LABEL } from '../../member-360/modules/panel-layouts/workflows-panel/constants/workflow-panels';
import { MaternityMilestonesTaskType, MATERNITY_MILESTONES_TASK_NAME } from '../../sidebar/womens-pelvic-health/maternity-milestones/constants';
import { transformText } from '../../utils/transform-text';
import { CarePlanChip } from '../components/dashboard/workflows-table/care-plan-chip';
import { IndicationUpdate } from '../components/dashboard/workflows-table/indication-update';
import { HIGH_PAIN_THRESHOLD, PainScoreChange, PAIN_THRESHOLD } from '../components/dashboard/workflows-table/pain-score-change';
import { ON } from '../constants/splits';
import { WORKFLOW_SUBHEADERS } from '../constants/strings/coach';
import { ACTION_PLAN_REVIEW_LABEL, ENSO_WORKFLOW_LABEL, FIRST_ACTION_PLAN_LABEL } from '../constants/strings/coach-layout';
import { SURGERY_TREND_SECONDARY_INFO } from '../constants/strings/pt-layout';
import { LAST_OUTREACH } from '../constants/strings/workflows-table';
import { WorkflowWithListProperties } from '../custom-types';
import { MultiIndicationPainScores, WorkflowHealthLogFragment, WorkflowPayload, WorkflowTypeCustomFieldsActionPlansFragment } from '../types';
import { CoachWorkflowState, CoachWorkflowTypes } from '../types/coach';
import { sentenceCaseFromKebabCase } from './sentence-case-from-kebab-case';
export const TRANSFORM_TEXT_LENGTH = 50;
const LEVEL_TEXT = 'Level';
const getActionPlanStatus = (workflow: WorkflowTypeCustomFieldsActionPlansFragment): string => {
  if (workflow.state === CoachWorkflowState.NoPlan) {
    return FIRST_ACTION_PLAN_LABEL.NO_PLAN;
  } else if (workflow.actionPlans && workflow.actionPlans.length > 0) {
    const {
      userUuid,
      createdBy
    } = workflow.actionPlans[0];
    return userUuid === createdBy ? FIRST_ACTION_PLAN_LABEL.CREATE_BY_MEMBER : FIRST_ACTION_PLAN_LABEL.CREATE_BY_ADMIN;
  } else {
    return '';
  }
};
export function getVideoVisitSubtitle(workflow: WorkflowPayload, newVideoVisitFollowups?: boolean): string {
  const followups = workflow?.customFields?.followups || 0;
  const indication = workflow?.patient?.applicationPathway;
  return newVideoVisitFollowups ? getVideoVisitsTypeByFollowups(followups, indication, workflow?.type) : getVideoVisitsType(workflow?.carePlans, indication, workflow?.type);
}
export function getSurgeryTrendSubtitle(workflow: WorkflowPayload): string {
  const isEmptyUpcomingSurgery = !workflow?.customFields?.upcomingSurgery?.otherUpcomingSurgeryType && !workflow?.customFields?.upcomingSurgery?.upcomingSurgeryType?.length && !workflow?.customFields?.upcomingSurgery?.upcomingSurgeryDate || _.isEmpty(workflow?.customFields?.upcomingSurgery);
  return !isEmptyUpcomingSurgery ? SURGERY_TREND_SECONDARY_INFO.UPCOMING_PROCEDURE : SURGERY_TREND_SECONDARY_INFO.INCREASED_LIKELIHOOD;
}
export function getEnsoSubtitle(workflow: WorkflowPayload, lastEtSessionData?: GetLastEtSessionQuery | undefined): string {
  const lastEtSessionAt = lastEtSessionData?.user.lastEtSessionAt || '';
  const isEtActive = dayjs(lastEtSessionAt).isValid() ? dayjs().diff(dayjs(lastEtSessionAt), 'day') <= 7 : false;
  let sessionsData;
  if (_.isEmpty(workflow?.customFields)) {
    sessionsData = ENSO_WORKFLOW_LABEL.NO_ENSO_DATA;
  } else {
    const sessionThisWeek = `${workflow?.customFields?.numberOfSessionsThisWeek} ${ENSO_WORKFLOW_LABEL.SESSIONS_THIS_WEEK}`;
    const sessionLastWeek = `${workflow?.customFields?.numberOfSessionsLastWeek} ${ENSO_WORKFLOW_LABEL.LAST_WEEK}`;
    sessionsData = workflow?.customFields?.numberOfSessionsThisWeek === 0 && workflow?.customFields?.numberOfSessionsLastWeek === 0 ? `${ENSO_WORKFLOW_LABEL.NO_SESSIONS_IN_TWO_WEEKS}` : `${sessionThisWeek} | ${sessionLastWeek}`;
  }
  const ensoSubtitle = isEtActive ? `${ENSO_WORKFLOW_LABEL.ET_ACTIVE} | ${sessionsData}` : `${sessionsData}`;
  return ensoSubtitle;
}
const getActionPlanReviewStatus = (workflow: WorkflowTypeCustomFieldsActionPlansFragment): string => {
  switch (workflow.state) {
    case CoachWorkflowState.PendingCreation:
    case CoachWorkflowState.PendingCreationImmediate:
    case CoachWorkflowState.AdminCreatedPlan:
    case CoachWorkflowState.ReviewActionPlanFwg:
    case CoachWorkflowState.ReviewActionPlanFwgImmediate:
    case CoachWorkflowState.Closed:
    case CoachWorkflowState.Dismiss:
      return ACTION_PLAN_REVIEW_LABEL.REVIEW_PLAN;
    case CoachWorkflowState.MemberUpdatedPlan:
    case CoachWorkflowState.PendingReview:
    case CoachWorkflowState.MemberCreatedNextActionPlanPostFwg:
      return ACTION_PLAN_REVIEW_LABEL.MEMBER_CREATED_PLAN;
    default:
      return '';
  }
};
const getLatestTaskName = (workflow?: WorkflowWithListProperties): string => {
  const latestTask = workflow?.tasks?.find(t => t.id === workflow.latestTaskId);
  if (!latestTask) {
    return 'No latest task found';
  }
  switch (workflow?.type) {
    case CoachWorkflowTypes.MaternityMilestones:
      return MATERNITY_MILESTONES_TASK_NAME[(latestTask.type as MaternityMilestonesTaskType)];
    default:
      return '';
  }
};
const getHealthLogNote = (workflow?: WorkflowHealthLogFragment): string | null => transformText(workflow?.healthLog?.note, TRANSFORM_TEXT_LENGTH) || null;
export const isSignificantPainChange = (workflow: WorkflowPayload): boolean => {
  const {
    pain_reports: painReports
  } = workflow?.healthLog || {};
  const highestPainIncreaseReport = getHighestPainIncreaseReport(painReports);
  if (!highestPainIncreaseReport) {
    return false; // No pain report, so not significant
  }
  const isHighPainSplitFlagActive = basiliskSplitClient?.getTreatment('high_pain_threshold') === ON;
  const painThreshold = isHighPainSplitFlagActive ? HIGH_PAIN_THRESHOLD : PAIN_THRESHOLD;

  // Use nullish coalescing to provide a default value of 0 if change is null or undefined
  return (highestPainIncreaseReport.change ?? 0) >= painThreshold;
};
export const getHighestPainIncreaseReport = (painReports: MultiIndicationPainScores[] | undefined | null): MultiIndicationPainScores | undefined => {
  const copiedPainReports = painReports?.slice();
  copiedPainReports?.sort((a, b) => (a?.change ?? 0) - (b?.change ?? 0));
  const highestPainIncreaseReport = copiedPainReports?.pop();
  return highestPainIncreaseReport;
};
export const getPainScoreChange = (workflow?: WorkflowHealthLogFragment): JSX.Element | null => {
  const {
    pain_reports: painReports
  } = workflow?.healthLog || {};
  const highestPainIncreaseReport = getHighestPainIncreaseReport(painReports);
  if (highestPainIncreaseReport && highestPainIncreaseReport.change !== 0) {
    return PainScoreChange({
      painDetails: highestPainIncreaseReport
    });
  }
  return null;
};
export const getOutreachNeededSubtitle = (workflow?: WorkflowPayload): string => {
  const lastOutreachAt = workflow?.customFields?.lastOutreachAt;
  if (!lastOutreachAt) return OUTREACH_NEEDED_WORKFLOW_INSIGHTS_LABEL.NO_OUTREACH;
  const formattedLastOutreach = dayjs(lastOutreachAt).format('h:mmA, M/D/YY');
  return `${LAST_OUTREACH}: ${formattedLastOutreach}`;
};
export const etModificationTransformText = (workflow: WorkflowWithListProperties): string => transformText(`${capitalize(workflow?.customFields?.bodyPart)}` + `${workflow?.customFields?.side === 'N/A' ? '' : ', ' + capitalize(workflow?.customFields?.side)}`, TRANSFORM_TEXT_LENGTH);
export const getIndicationUpdateSubtitle = (workflow?: WorkflowPayload): JSX.Element => {
  const previousIndications = workflow?.customFields?.previousIndications;
  const currentIndications = (workflow?.indications || []).map(({
    name,
    priority
  }) => ({
    name,
    isPrimary: priority === 0
  }));
  return IndicationUpdate(previousIndications, currentIndications);
};
export const getWorkflowSubtitle = (workflow?: WorkflowWithListProperties, newVideoVisitFollowupsFlag?: boolean, lastEtSessionData?: GetLastEtSessionQuery): string | JSX.Element | null => {
  switch (workflow?.type) {
    case CoachWorkflowTypes.FirstActionPlan:
      return getActionPlanStatus(workflow);
    case CoachWorkflowTypes.MemberAlertReminder:
    case OsWorkflowTypes.MemberAlertReminder:
      return transformText(workflow?.customFields?.memberAlert?.description, 50);
    case CoachWorkflowTypes.UnreadMessages:
    case OsWorkflowTypes.UnreadMessages:
    case PtWorkflowTypes.UnreadMessages:
    case PtWorkflowTypes.ModularIamUnreadMessages:
      return transformText(workflow?.customFields?.messageBodies?.length > 1 ? workflow.customFields.messageBodies[0] : workflow?.customFields?.body, TRANSFORM_TEXT_LENGTH);
    case CoachWorkflowTypes.ActionPlanReview:
    case CoachWorkflowTypes.FirstWeekGoal:
      return getActionPlanReviewStatus(workflow);
    case CoachWorkflowTypes.OfferVideoVisit:
    case CoachWorkflowTypes.PregnancyStatusChange:
      return sentenceCaseFromKebabCase(workflow?.state);
    case CoachWorkflowTypes.MaternityMilestones:
      return getLatestTaskName(workflow);
    case CoachWorkflowTypes.MemberHealthLog:
      return transformText(quoteString(workflow?.customFields?.note), TRANSFORM_TEXT_LENGTH);
    case CoachWorkflowTypes.CoachEscalation:
      return transformText(quoteString(workflow?.customFields?.description), 50);
    case PtWorkflowTypes.HealthLog:
      return getHealthLogNote(workflow);
    case PtWorkflowTypes.PainUptick:
      return getPainScoreChange(workflow);
    case PtWorkflowTypes.MemberPainUptick:
      return getPainScoreChange(workflow);
    case PtWorkflowTypes.EtModification:
      return etModificationTransformText(workflow);
    case PtWorkflowTypes.MedicalEmergencyFollowUp:
      return transformText(`${LEVEL_TEXT} ${workflow?.customFields?.level}`, TRANSFORM_TEXT_LENGTH);
    case PtWorkflowTypes.ClinicalEscalation:
      return transformText(quoteString(workflow?.customFields?.description), TRANSFORM_TEXT_LENGTH);
    case CoachWorkflowTypes.MemberPainUptick:
      return workflow?.healthLog?.isMultiShim ? getPainScoreChange(workflow) : WORKFLOW_SUBHEADERS.PAIN_UPTICK;
    case HouseCallsWorkflows.HouseCall:
    case VideoVisitsWorkflows.Acute:
    case VideoVisitsWorkflows.Chronic:
    case VideoVisitsWorkflows.Surgery:
    case VideoVisitsWithoutMedicalHistory.FifteenMinute:
    case CoachWorkflowTypes.CoachVideoVisit:
      return getVideoVisitSubtitle(workflow, newVideoVisitFollowupsFlag);
    case PtWorkflowTypes.Closing:
    case PtWorkflowTypes.Expiring:
      return CarePlanChip({
        workflow
      });
    case CoachWorkflowTypes.BalanceAssessment:
      return WORKFLOW_SUBHEADERS.BALANCE_ASSESSMENT;
    case PtWorkflowTypes.SurgeryTrend:
      return getSurgeryTrendSubtitle(workflow);
    case CoachWorkflowTypes.ActionPlanCheckIn:
    case CoachWorkflowTypes.FirstWeekCheckIn:
    case CoachWorkflowTypes.CoachCall:
    case CoachWorkflowTypes.ReEngager:
    case OsWorkflowTypes.ReEngager:
    case PtWorkflowTypes.AqRedFlags:
    case CoachWorkflowTypes.CoachWeeklyGoalExpiry:
    case CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal:
    case OsWorkflowTypes.Nuto:
    case OsWorkflowTypes.Ruto:
      return null;
    case PtWorkflowTypes.FirstWeekEnsoCheckIn:
    case CoachWorkflowTypes.WeeklyEnsoReview:
      return getEnsoSubtitle(workflow, lastEtSessionData);
    case CoachWorkflowTypes.OutreachNeeded:
    case OsWorkflowTypes.OutreachNeeded:
      return getOutreachNeededSubtitle(workflow);
    case PtWorkflowTypes.PtMemberAlertReminder:
      return getSubtitleForMemberAlertsDashboard(workflow);
    case PtWorkflowTypes.ActionPlanReview:
      return getActionPlanReviewStatus(workflow);
    case PtWorkflowTypes.SetupActionPlan:
      return WORKFLOW_SUBHEADERS.SETUP_ACTION_PLAN;
    case PtWorkflowTypes.IndicationUpdate:
      return getIndicationUpdateSubtitle(workflow);
    default:
      return '';
  }
};
export const getWorkflowCellContent = (workflow?: WorkflowWithListProperties): string | JSX.Element | null => {
  if (workflow?.type === PtWorkflowTypes.UnreadMessages) {
    return transformText(workflow?.customFields?.body, 50);
  }
  return getWorkflowSubtitle(workflow);
};