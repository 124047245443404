import { c as _c } from "react-compiler-runtime";
import { HHButton, HHChip, HHDialogActions, HHDialogContent, HHDialogContentText, HHList, HHListItem, HHTextField, TextArea, Typography } from '@hinge-health/react-component-library';
import { DeleteOutlineRounded, DoneRounded, InfoOutlined, UploadFileSharp } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import { USER_FEEDBACK } from '../../../constants/strings/user-feedback';
import { FeedbackMessage, FeedbackType } from './feedback-dialog';
import { FreshdeskTicketDetails } from './freshdesk-dialog';
export const FreshdeskSubmissionForm = t0 => {
  const $ = _c(93);
  let props;
  if ($[0] !== t0) {
    ({
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
  } else {
    props = $[1];
  }
  const fileInputRef = useRef(null);
  const [typeChip, setTypeChip] = useState(USER_FEEDBACK.BUG_STRING);
  let t1;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  const handleButtonClick = t1;
  let t2;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = type => {
      setTypeChip(type);
    };
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const handleToggleBugFeatureChip = t2;
  let t3;
  if ($[4] !== props.sxStyles.hhDialogContentText) {
    t3 = {
      ...props.sxStyles.hhDialogContentText,
      marginTop: "0px"
    };
    $[4] = props.sxStyles.hhDialogContentText;
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  let t4;
  if ($[6] !== t3) {
    t4 = <HHDialogContentText sx={t3}>{USER_FEEDBACK.DIALOG_TEXTS.TITLE_BUG_REQUEST_QUES}</HHDialogContentText>;
    $[6] = t3;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  const t5 = typeChip === USER_FEEDBACK.BUG_STRING ? "secondary" : "default";
  let t6;
  if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = () => handleToggleBugFeatureChip(USER_FEEDBACK.BUG_STRING);
    $[8] = t6;
  } else {
    t6 = $[8];
  }
  let t7;
  if ($[9] !== t5) {
    t7 = <HHChip clickable={true} color={t5} hhVariant="filled" label={USER_FEEDBACK.BUG_STRING.charAt(0).toUpperCase() + USER_FEEDBACK.BUG_STRING.slice(1).toLocaleLowerCase()} onClick={t6} />;
    $[9] = t5;
    $[10] = t7;
  } else {
    t7 = $[10];
  }
  const t8 = typeChip === USER_FEEDBACK.REQUEST_STRING ? "secondary" : "default";
  let t9;
  if ($[11] === Symbol.for("react.memo_cache_sentinel")) {
    t9 = {
      marginLeft: "10px"
    };
    $[11] = t9;
  } else {
    t9 = $[11];
  }
  let t10;
  if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
    t10 = () => handleToggleBugFeatureChip(USER_FEEDBACK.REQUEST_STRING);
    $[12] = t10;
  } else {
    t10 = $[12];
  }
  let t11;
  if ($[13] !== t8) {
    t11 = <HHChip clickable={true} hhVariant="filled" color={t8} layoutStyles={t9} label={USER_FEEDBACK.REQUEST_STRING.charAt(0).toUpperCase() + USER_FEEDBACK.REQUEST_STRING.slice(1).toLocaleLowerCase()} onClick={t10} />;
    $[13] = t8;
    $[14] = t11;
  } else {
    t11 = $[14];
  }
  const t12 = USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)];
  let t13;
  if ($[15] !== props.sxStyles.hhDialogContentText || $[16] !== t12.TITLE) {
    t13 = <HHDialogContentText sx={props.sxStyles.hhDialogContentText}>{t12.TITLE}</HHDialogContentText>;
    $[15] = props.sxStyles.hhDialogContentText;
    $[16] = t12.TITLE;
    $[17] = t13;
  } else {
    t13 = $[17];
  }
  let t14;
  if ($[18] === Symbol.for("react.memo_cache_sentinel")) {
    t14 = {
      minRows: 1,
      className: "title-input"
    };
    $[18] = t14;
  } else {
    t14 = $[18];
  }
  const t15 = USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)];
  let t16;
  if ($[19] === Symbol.for("react.memo_cache_sentinel")) {
    t16 = {
      width: "100%"
    };
    $[19] = t16;
  } else {
    t16 = $[19];
  }
  let t17;
  if ($[20] !== props) {
    t17 = e => props.updateTicketData(prevData => ({
      ...prevData,
      subject: e.target.value
    }));
    $[20] = props;
    $[21] = t17;
  } else {
    t17 = $[21];
  }
  const t18 = props.titleError.length ? true : false;
  let t19;
  if ($[22] !== t15.TITLE_PLACEHOLDER || $[23] !== props.freshdeskTicketDetails.subject || $[24] !== props.titleError || $[25] !== t17 || $[26] !== t18) {
    t19 = <HHTextField hhVariant="outlined" label="" InputProps={t14} placeholder={t15.TITLE_PLACEHOLDER} size="medium" defaultValue={props.freshdeskTicketDetails.subject} fullWidth={true} sx={t16} onChange={t17} error={t18} helperText={props.titleError} />;
    $[22] = t15.TITLE_PLACEHOLDER;
    $[23] = props.freshdeskTicketDetails.subject;
    $[24] = props.titleError;
    $[25] = t17;
    $[26] = t18;
    $[27] = t19;
  } else {
    t19 = $[27];
  }
  const t20 = USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)];
  let t21;
  if ($[28] !== props.sxStyles.hhDialogContentText || $[29] !== t20.TITLE) {
    t21 = <HHDialogContentText sx={props.sxStyles.hhDialogContentText}>{t20.TITLE}</HHDialogContentText>;
    $[28] = props.sxStyles.hhDialogContentText;
    $[29] = t20.TITLE;
    $[30] = t21;
  } else {
    t21 = $[30];
  }
  const t22 = USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)];
  let t23;
  if ($[31] === Symbol.for("react.memo_cache_sentinel")) {
    t23 = {
      width: "100%"
    };
    $[31] = t23;
  } else {
    t23 = $[31];
  }
  let t24;
  if ($[32] !== props) {
    t24 = e_0 => props.updateTicketData(prevData_0 => ({
      ...prevData_0,
      description: e_0.target.value
    }));
    $[32] = props;
    $[33] = t24;
  } else {
    t24 = $[33];
  }
  const t25 = props.descriptionError.length ? true : false;
  let t26;
  if ($[34] !== t22.DESCRIPTION_PLACEHOLDER || $[35] !== props.freshdeskTicketDetails.description || $[36] !== props.descriptionError || $[37] !== t24 || $[38] !== t25) {
    t26 = <HHTextField hhVariant="outlined" label="" multiline={true} fullWidth={true} rows={4} placeholder={t22.DESCRIPTION_PLACEHOLDER} size="medium" defaultValue={props.freshdeskTicketDetails.description} sx={t23} onChange={t24} error={t25} helperText={props.descriptionError} />;
    $[34] = t22.DESCRIPTION_PLACEHOLDER;
    $[35] = props.freshdeskTicketDetails.description;
    $[36] = props.descriptionError;
    $[37] = t24;
    $[38] = t25;
    $[39] = t26;
  } else {
    t26 = $[39];
  }
  let t27;
  if ($[40] !== props.sxStyles.hhDialogContentText) {
    t27 = <HHDialogContentText sx={props.sxStyles.hhDialogContentText}>{USER_FEEDBACK.DIALOG_TEXTS.MEMBER_ID_TITLE}</HHDialogContentText>;
    $[40] = props.sxStyles.hhDialogContentText;
    $[41] = t27;
  } else {
    t27 = $[41];
  }
  let t28;
  if ($[42] === Symbol.for("react.memo_cache_sentinel")) {
    t28 = {
      minRows: 1
    };
    $[42] = t28;
  } else {
    t28 = $[42];
  }
  let t29;
  if ($[43] === Symbol.for("react.memo_cache_sentinel")) {
    t29 = {
      width: "100%"
    };
    $[43] = t29;
  } else {
    t29 = $[43];
  }
  let t30;
  if ($[44] !== props) {
    t30 = e_1 => props.updateCustomFields(prevData_1 => ({
      ...prevData_1,
      cf_user_id: e_1.target.value
    }));
    $[44] = props;
    $[45] = t30;
  } else {
    t30 = $[45];
  }
  let t31;
  if ($[46] !== props.customFields.cf_user_id || $[47] !== t30) {
    t31 = <TextArea label="" placeholder={USER_FEEDBACK.DIALOG_TEXTS.MEMBER_ID_PLACEHOLDER} InputProps={t28} defaultValue={props.customFields.cf_user_id} sx={t29} onChange={t30} />;
    $[46] = props.customFields.cf_user_id;
    $[47] = t30;
    $[48] = t31;
  } else {
    t31 = $[48];
  }
  let t32;
  if ($[49] !== props.sxStyles.hhDialogContentText) {
    t32 = {
      ...props.sxStyles.hhDialogContentText,
      marginBottom: "0px"
    };
    $[49] = props.sxStyles.hhDialogContentText;
    $[50] = t32;
  } else {
    t32 = $[50];
  }
  let t33;
  if ($[51] !== t32) {
    t33 = <HHDialogContentText sx={t32}>{USER_FEEDBACK.DIALOG_TEXTS.ATTACHMENT_TEXT}</HHDialogContentText>;
    $[51] = t32;
    $[52] = t33;
  } else {
    t33 = $[52];
  }
  let t34;
  let t35;
  if ($[53] === Symbol.for("react.memo_cache_sentinel")) {
    t34 = {
      display: "none"
    };
    t35 = e_2 => e_2.stopPropagation();
    $[53] = t34;
    $[54] = t35;
  } else {
    t34 = $[53];
    t35 = $[54];
  }
  let t36;
  if ($[55] !== fileInputRef || $[56] !== props.handleFileUpload) {
    t36 = <input data-testid={USER_FEEDBACK.TEST_IDS.FILE_INPUT} type="file" accept="image/*, video/*, .har, .doc, .docx, .pdf" multiple={true} ref={fileInputRef} style={t34} onClick={t35} onChange={props.handleFileUpload} />;
    $[55] = fileInputRef;
    $[56] = props.handleFileUpload;
    $[57] = t36;
  } else {
    t36 = $[57];
  }
  let t37;
  if ($[58] === Symbol.for("react.memo_cache_sentinel")) {
    t37 = {
      marginTop: "8px",
      lineHeight: "40px"
    };
    $[58] = t37;
  } else {
    t37 = $[58];
  }
  let t38;
  if ($[59] === Symbol.for("react.memo_cache_sentinel")) {
    t38 = <UploadFileSharp sx={{
      marginRight: "8px"
    }} />;
    $[59] = t38;
  } else {
    t38 = $[59];
  }
  let t39;
  if ($[60] !== handleButtonClick) {
    t39 = <HHButton hhVariant="outlined" layoutStyles={t37} onClick={handleButtonClick}>{t38}{" "}{USER_FEEDBACK.UPLOAD_FILES_TEXT}</HHButton>;
    $[60] = handleButtonClick;
    $[61] = t39;
  } else {
    t39 = $[61];
  }
  let t40;
  if ($[62] === Symbol.for("react.memo_cache_sentinel")) {
    t40 = <br />;
    $[62] = t40;
  } else {
    t40 = $[62];
  }
  let t41;
  if ($[63] === Symbol.for("react.memo_cache_sentinel")) {
    t41 = <InfoOutlined sx={{
      color: "rgba(103, 111, 109, 1)"
    }} />;
    $[63] = t41;
  } else {
    t41 = $[63];
  }
  let t42;
  if ($[64] !== props.sxStyles.phiDisclaimerTypography) {
    t42 = <Stack direction="row" spacing={2}>{t41}{" "}<Typography sx={props.sxStyles.phiDisclaimerTypography} align="center">{USER_FEEDBACK.DISCLAIMER_TEXT}</Typography></Stack>;
    $[64] = props.sxStyles.phiDisclaimerTypography;
    $[65] = t42;
  } else {
    t42 = $[65];
  }
  let t43;
  if ($[66] !== props.sxStyles.phiDisclaimerBox || $[67] !== t42) {
    t43 = <Box sx={props.sxStyles.phiDisclaimerBox}>{t42}</Box>;
    $[66] = props.sxStyles.phiDisclaimerBox;
    $[67] = t42;
    $[68] = t43;
  } else {
    t43 = $[68];
  }
  let t44;
  if ($[69] !== props) {
    t44 = props.feedbackFiles && props.feedbackFiles.length > 0 && <HHList>{Array.from((props.feedbackFiles as File[])).map((file, index) => <HHListItem key={file.name} sx={props.sxStyles.hhListItem}><Stack direction="row" spacing="12px" alignItems="center" sx={props.sxStyles.stack}><DoneRounded sx={{
            color: "rgba(0, 0, 0, 0.54)"
          }} /><Typography variant="body1" sx={{
            flex: 1
          }}>{file.name}</Typography><DeleteOutlineRounded data-testid={USER_FEEDBACK.TEST_IDS.DELETE_BUTTON + index} sx={{
            marginLeft: "100px",
            color: "rgba(0, 0, 0, 0.54)",
            cursor: "pointer"
          }} onClick={() => props.handleDeleteItem(index)} /></Stack></HHListItem>)}</HHList>;
    $[69] = props;
    $[70] = t44;
  } else {
    t44 = $[70];
  }
  const t45 = props.loader ? undefined : props.handleSubmit;
  let t46;
  if ($[71] !== t45 || $[72] !== props.loader) {
    t46 = <HHButton hhVariant="contained" color="primary" onClick={t45} fullWidth={true} disabled={props.loader}>{USER_FEEDBACK.SUBMIT_FORM_BUTTON_TEXT}</HHButton>;
    $[71] = t45;
    $[72] = props.loader;
    $[73] = t46;
  } else {
    t46 = $[73];
  }
  let t47;
  if ($[74] !== props.sxStyles.hhDialogActions || $[75] !== t46) {
    t47 = <HHDialogActions sx={props.sxStyles.hhDialogActions}>{t46}</HHDialogActions>;
    $[74] = props.sxStyles.hhDialogActions;
    $[75] = t46;
    $[76] = t47;
  } else {
    t47 = $[76];
  }
  let t48;
  if ($[77] !== t4 || $[78] !== t7 || $[79] !== t11 || $[80] !== t13 || $[81] !== t19 || $[82] !== t21 || $[83] !== t26 || $[84] !== t27 || $[85] !== t31 || $[86] !== t33 || $[87] !== t36 || $[88] !== t39 || $[89] !== t43 || $[90] !== t44 || $[91] !== t47) {
    t48 = <HHDialogContent>{t4}{t7}{t11}{t13}{t19}{t21}{t26}{t27}{t31}{t33}{t36}{t39}{t40}{t43}{t44}{t47}</HHDialogContent>;
    $[77] = t4;
    $[78] = t7;
    $[79] = t11;
    $[80] = t13;
    $[81] = t19;
    $[82] = t21;
    $[83] = t26;
    $[84] = t27;
    $[85] = t31;
    $[86] = t33;
    $[87] = t36;
    $[88] = t39;
    $[89] = t43;
    $[90] = t44;
    $[91] = t47;
    $[92] = t48;
  } else {
    t48 = $[92];
  }
  return t48;
};