import { OsWorkflowTypes, PtWorkflowTypes } from '../../custom-types';
import { CoachWorkflowTypes } from '../../modules/sidebar/custom-types';
export const COACH_QUEUE_CATEGORY_NAMES: Partial<Record<CoachWorkflowTypes, string>> = {
  [CoachWorkflowTypes.UnreadMessages]: 'Unread messages',
  [CoachWorkflowTypes.MemberPainUptick]: 'Pain uptick',
  [CoachWorkflowTypes.MemberHealthLog]: 'Health log notes',
  [CoachWorkflowTypes.FirstActionPlan]: 'Action plans',
  [CoachWorkflowTypes.ActionPlanCheckIn]: 'Action plan check-in',
  [CoachWorkflowTypes.FirstWeekCheckIn]: 'First week check-in',
  [CoachWorkflowTypes.MemberAlertReminder]: 'Alert reminder',
  [CoachWorkflowTypes.CoachCall]: 'Coach calls',
  [CoachWorkflowTypes.ReEngager]: 'Re-engager',
  [CoachWorkflowTypes.CoachEscalation]: 'Coach escalation',
  [CoachWorkflowTypes.WeeklyEnsoReview]: 'Weekly Enso review',
  [CoachWorkflowTypes.OutreachNeeded]: 'Outreach needed',
  [CoachWorkflowTypes.CoachWeeklyGoalExpiry]: 'Review plan',
  [CoachWorkflowTypes.ActionPlanReview]: 'Review and recalibrate',
  [CoachWorkflowTypes.FirstWeekGoal]: 'First week review',
  [CoachWorkflowTypes.MaternityMilestones]: 'Maternity milestones',
  [CoachWorkflowTypes.PregnancyStatusChange]: 'Pregnancy status change',
  [CoachWorkflowTypes.BalanceAssessment]: 'Balance assessment',
  [CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal]: 'Member updated plan',
  [CoachWorkflowTypes.CoachVideoVisit]: 'Video visit'
};
export const PT_QUEUE_CATEGORY_NAMES: Partial<Record<PtWorkflowTypes, string>> = {
  [PtWorkflowTypes.EtModification]: 'ET modification',
  [PtWorkflowTypes.ClinicalEscalation]: 'Clinical escalation',
  [PtWorkflowTypes.MedicalEmergencyFollowUp]: 'Medical emergency follow-up',
  [PtWorkflowTypes.SurgeryTrend]: 'Surgery trend',
  [PtWorkflowTypes.Expiring]: 'Expiring care plans',
  [PtWorkflowTypes.Closing]: 'Discharge and close plan',
  [PtWorkflowTypes.PainUptick]: 'Pain uptick',
  [PtWorkflowTypes.HealthLog]: 'Health log notes',
  [PtWorkflowTypes.UnreadMessages]: 'Unread messages',
  [PtWorkflowTypes.Chronic]: 'Video Visit',
  [PtWorkflowTypes.HouseCall]: 'House call',
  [PtWorkflowTypes.AqRedFlags]: 'AQ red flags',
  [PtWorkflowTypes.Acute]: 'Post visit tasks',
  [PtWorkflowTypes.FirstWeekEnsoCheckIn]: 'Enso first week checkin',
  [PtWorkflowTypes.PtMemberAlertReminder]: 'Alert reminder',
  [PtWorkflowTypes.ActionPlanReview]: 'Review and recalibrate',
  [PtWorkflowTypes.SetupActionPlan]: 'Setup action plan',
  [PtWorkflowTypes.IndicationUpdate]: 'Indication update'
};
const OS_QUEUE_CATEGORY_NAMES: Partial<Record<OsWorkflowTypes, string>> = {
  [OsWorkflowTypes.UnreadMessages]: 'Unread messages',
  [OsWorkflowTypes.MemberAlertReminder]: 'Alert reminder',
  [OsWorkflowTypes.ReEngager]: 'Re-engager',
  [OsWorkflowTypes.OutreachNeeded]: 'Outreach needed',
  [OsWorkflowTypes.Nuto]: 'New user to onboard',
  [OsWorkflowTypes.Ruto]: 'Returning user to onboard'
};
export const QUEUE_CATEGORY_NAMES = {
  ...COACH_QUEUE_CATEGORY_NAMES,
  ...PT_QUEUE_CATEGORY_NAMES,
  ...OS_QUEUE_CATEGORY_NAMES
};
export const QUEUE_TITLE = 'Your queue';
export const QUEUE_SUBTITLE = 'workflows completed today';
export const SHOW_MORE = 'Show More';
export const COMPLETE = 'Complete';
export const NAV_QUEUE_COUNTS_LOADING_ID = 'queue-counts-loading';
export const MEMBER_ALERT_REMIND_AT = 'Reminder task:';
export const UNKNOWN_COUNT = '-';
export const POSSIBLY_MORE_INDICATOR = '+';
export const WORKFLOWS_COMPLETED = 'All workflows complete';
export const NO_ENCOUNTERS = 'You have no upcoming encounters';
export const QUEUE_TOOLTIPS = {
  DASHBOARD: 'Dashboard',
  QUEUE_LIST: 'Queue',
  QUEUE_COMPLETE: 'Queue Complete',
  NEXT: 'Next:'
};
export const COACH_CATEGORY_NAME_ARRAY_INDEX = ['Video visit', 'Unread message', 'Pain uptick', 'Health log note', 'Re-engager', 'First week review', 'Review and recalibrate', 'Action plan check-in', 'First week check-in', 'Alert reminder', 'Coach escalation', 'Weekly enso review', 'Outreach needed', 'Review plan', 'Maternity milestones', 'Pregnancy status change', 'Balance assessment', 'Member updated plan', 'Coach call'];
export const PT_CATEGORY_NAME_ARRAY_INDEX = ['Post visit tasks', 'Unread message', 'Health Log Note', 'Pain uptick', 'Surgery trend', 'Clinical escalation', 'ET Modification', 'Indication update', 'Medical emergency follow-up', 'Setup action plan', 'Review and recalibrate', 'Expiring care plans', 'Discharge and close plan', 'AQ red flags', 'Enso first week checkin', 'Alert reminder', 'Video visit', 'House call'];
export const OS_CATEGORY_NAME_ARRAY_INDEX = ['Unread message', 'Alert reminder', 'Re-engager', 'Outreach needed'];
export const COACH_QUEUE_ALERT_CATEGORY_NAMES: Partial<Record<CoachWorkflowTypes, string>> = {
  [CoachWorkflowTypes.UnreadMessages]: 'Unread Message',
  [CoachWorkflowTypes.MemberPainUptick]: 'Pain Uptick',
  [CoachWorkflowTypes.MemberHealthLog]: 'Health Log Note',
  [(CoachWorkflowTypes.FirstActionPlan, CoachWorkflowTypes.ActionPlanReview, CoachWorkflowTypes.FirstWeekGoal)]: 'Action Plan',
  [CoachWorkflowTypes.ActionPlanCheckIn]: 'Action plan check-in',
  [CoachWorkflowTypes.FirstWeekCheckIn]: 'First week check-in',
  [CoachWorkflowTypes.MemberAlertReminder]: 'Alert reminder',
  [CoachWorkflowTypes.ReEngager]: 'Re-engager',
  [CoachWorkflowTypes.CoachEscalation]: 'Coach escalation',
  [CoachWorkflowTypes.WeeklyEnsoReview]: 'Weekly Enso review',
  [CoachWorkflowTypes.OutreachNeeded]: 'Outreach needed',
  [CoachWorkflowTypes.CoachVideoVisit]: 'Video visit'
};
export const PT_QUEUE_ALERT_CATEGORY_NAMES: Partial<Record<PtWorkflowTypes, string>> = {
  [PtWorkflowTypes.EtModification]: 'ET modification',
  [PtWorkflowTypes.IndicationUpdate]: 'Indication update',
  [PtWorkflowTypes.ClinicalEscalation]: 'Clinical escalation',
  [PtWorkflowTypes.MedicalEmergencyFollowUp]: 'Medical emergency follow-up',
  [PtWorkflowTypes.SetupActionPlan]: 'Setup action plan',
  [PtWorkflowTypes.SurgeryTrend]: 'Surgery trend',
  [PtWorkflowTypes.Expiring]: 'Expiring care plans',
  [PtWorkflowTypes.Closing]: 'Discharge and close plan',
  [PtWorkflowTypes.PainUptick]: 'Pain uptick',
  [PtWorkflowTypes.HealthLog]: 'Health log notes',
  [PtWorkflowTypes.UnreadMessages]: 'Unread messages',
  [(PtWorkflowTypes.Chronic, PtWorkflowTypes.Acute, PtWorkflowTypes.Surgery, PtWorkflowTypes.FifteenMinute)]: 'Video visit',
  [PtWorkflowTypes.HouseCall]: 'House call'
};
export const QUEUE_ALERT_CATEGORY_NAMES = {
  ...COACH_QUEUE_ALERT_CATEGORY_NAMES,
  ...PT_QUEUE_ALERT_CATEGORY_NAMES
};
export const completedAlert = (category: string): string => `You've completed your ${category} workflows`;
export const QUEUE_TAB_LABELS = {
  WORKFLOWS: 'Workflows',
  ENCOUNTERS: 'Encounters'
};