import { HHTabData, HHTabs } from '@hinge-health/react-component-library';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useGenericMixpanelTrack } from '../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../lib/mixpanel/mixpanel-event-names';
import { DashboardTabSelectionType } from '../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { CoachDashboardFilter } from '../../../constants/coach-dashboard';
import { DEFAULT_COACH_DASHBOARD_TAB_NAME, LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from '../../../constants/strings/coach-dashboard-default';
import { selectedCoachDashboardFilters } from '../../../jotai/atoms';
import { CoachDashboardFilters } from '../../../types/coach';
export interface HealthLogNotesTabsProps {
  tableContent: JSX.Element;
}
export const HealthLogNotesTabs: React.FC<HealthLogNotesTabsProps> = ({
  tableContent
}) => {
  const [selectedDashboardFilter, setSelectedDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const mixpanelTrack = useGenericMixpanelTrack();
  const [tabIndex, setTabIndex] = useState(0);
  const invokeMixpanelEvent = async (event: string, tabname: string): Promise<void> => {
    mixpanelTrack<DashboardTabSelectionType>(event, {
      selectedTabName: tabname
    });
  };
  useEffect(() => {
    if (selectedDashboardFilter.filterTabName === DEFAULT_COACH_DASHBOARD_TAB_NAME) {
      invokeMixpanelEvent(MIXPANEL_EVENTS.ALL_INCOMING_HEALTH_LOG_NOTES_TAB_SELECTED, DEFAULT_COACH_DASHBOARD_TAB_NAME);
      setTabIndex(0);
    } else {
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboardFilter]);
  useEffect(() => {
    setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.MemberHealthLog]);
  }, [setSelectedDashboardFilter]);
  const handleTabChange = (event_0: number): void => {
    if (event_0 === 0) {
      setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.MemberHealthLog]);
    }
    if (event_0 === 1) {
      invokeMixpanelEvent(MIXPANEL_EVENTS.NO_RESPONSE_PREDICTED_HEALTH_LOG_NOTES_TAB_SELECTED, DEFAULT_COACH_DASHBOARD_TAB_NAME);
      setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.MemberHealthLogNoResponse]);
    }
  };
  const defaultTabData: HHTabData[] = [{
    tabContent: tableContent,
    tabLabel: DEFAULT_COACH_DASHBOARD_TAB_NAME,
    key: DEFAULT_COACH_DASHBOARD_TAB_NAME
  }, {
    icon: <AutoFixHigh />,
    iconPosition: 'start',
    tabContent: tableContent,
    tabLabel: LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME,
    key: LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME
  }];
  return <HHTabs layoutStyles={{
    margin: '2px'
  }} tabsData={defaultTabData} onTabChange={handleTabChange} defaultTabIndex={tabIndex} />;
};