import { c as _c } from "react-compiler-runtime";
import { HHBox, HHCircularProgress, useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import * as Sentry from '@sentry/react';
import { Provider } from 'jotai';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants';
import { PHYSICAL_THERAPIST } from '../constants/strings/physical-therapist';
import { AdminContext } from '../contexts/admin.context';
import { useSse } from '../hooks/use-sse';
import { DashboardPage } from '../modules/dashboard';
import { ShellProvider } from '../modules/dashboard/contexts/shell.context';
import { DashboardType } from '../modules/dashboard/custom-types';
import { Admin } from '../types';
import { OktaRole } from '../types/okta-roles';
import { genericMixpanelTrack, mixpanelTrack } from '../utils/mixpanel';
export const LOADER_ID = 'pt-page-loader';
const Error = () => {
  const $ = _c(1);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = <HHBox>{PHYSICAL_THERAPIST.ERROR}</HHBox>;
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  return t0;
};
export const PtPage = (): JSX.Element => {
  const [currentStoredAdmin, loading, adminError] = useContext(AdminContext);
  const hingeHealthAuthState = useHingeHealthSecurityContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (hingeHealthAuthState?.hingeHealthAuthState?.accessToken?.claims?.roles.includes(OktaRole.Coach)) {
      navigate(ROUTES.COACH, {});
    } else if (hingeHealthAuthState?.hingeHealthAuthState?.accessToken?.claims?.roles.includes(OktaRole.OnboardingSpecialist)) {
      navigate(ROUTES.OS, {});
    }
  }, [hingeHealthAuthState, navigate]);
  const shellProps = {
    loading,
    adminProfile: (currentStoredAdmin as Admin),
    mixpanelTrack,
    genericMixpanelTrack,
    useSse
  };
  if (loading) return <HHCircularProgress data-testid={LOADER_ID} />;
  return <Provider>
      <Sentry.ErrorBoundary fallback={<Error />}>
        {(adminError || !currentStoredAdmin) && <Error />}
        {currentStoredAdmin && <ShellProvider {...shellProps}>
            <DashboardPage loading={loading} dashboardType={DashboardType.PhysicalTherapist} />
          </ShellProvider>}
      </Sentry.ErrorBoundary>
    </Provider>;
};