import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { UpcomingEncounter, upcomingEncountersAtom } from '../../../state/queue-atoms';
export const useUpcomingEncounter = (): UpcomingEncounter | undefined => {
  const upcomingEncounters = useAtomValue(upcomingEncountersAtom);
  const [displayedUpcomingEncounter, setDisplayedUpcomingEncounter] = useState<UpcomingEncounter | undefined>(undefined);
  const determineUpcomingEncounterState = useCallback((): NodeJS.Timeout | undefined => {
    let newTimerId = undefined;
    const now = dayjs();
    const nextUpcomingEncounter = upcomingEncounters?.find(upcomingEncounter => dayjs(upcomingEncounter.callDate).isAfter(now));
    if (nextUpcomingEncounter) {
      const nextCallTime = dayjs(nextUpcomingEncounter.callDate);
      const startShowingTime = nextCallTime.subtract(30, 'minute');
      if (nextCallTime.isAfter(now) && startShowingTime.isSameOrBefore(now)) {
        setDisplayedUpcomingEncounter(nextUpcomingEncounter);
        newTimerId = setTimeout(determineUpcomingEncounterState, nextCallTime.diff(now) + 1000);
      } else {
        setDisplayedUpcomingEncounter(undefined);
        newTimerId = setTimeout(determineUpcomingEncounterState, startShowingTime.diff(now) + 1000);
      }
    } else {
      setDisplayedUpcomingEncounter(undefined);
    }
    return newTimerId;
  }, [upcomingEncounters]);
  useEffect(() => {
    const timerId = determineUpcomingEncounterState();
    return () => {
      clearTimeout(timerId);
    };
  }, [determineUpcomingEncounterState]);
  return displayedUpcomingEncounter;
};