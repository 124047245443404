import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import { functionalScaleNamespace, namespace as exercisesNamespace } from '../modules/member-360/components/care-plan/exercise-therapy/locales/namespaces';
import { isProduction } from '../utils/environment';
import { getAllTranslationFiles, getExerciseResourceByLanguage, getLocalizedTranslationFile, getPatientSpecificFunctionalScaleResource, SUPPORTED_LANGUAGES } from './utils';
type SupportedLanguage = typeof SUPPORTED_LANGUAGES[number];
const DEFAULT_LANGUAGE = 'en-US';
const generateTranslationsByLanguage = (sources: Array<string>, language: string): Resource => sources.filter((key: string) => key.includes(`/locales/${language}/`)).reduce((acc: object, file: string) => {
  const namespace = file.replace(/^\.\//, '').replace(/\/locales.+$/, '');
  const translationFile = getLocalizedTranslationFile(namespace, language);
  return {
    ...acc,
    [namespace]: translationFile
  };
}, {});
export const generateI18nResources = (): Resource => SUPPORTED_LANGUAGES.reduce((acc: Resource, language: SupportedLanguage) => {
  // Get all of our 'translations.json' files
  const translationFiles = getAllTranslationFiles();

  // Filter and generate an object of translation keys by language
  const translations = generateTranslationsByLanguage(translationFiles, language);

  // Return an object of all translation strings structured by namespace
  return {
    ...acc,
    [language]: translations
  };
}, ({} as Resource));
const handleParseMissingKey = (key: string, defaultValue?: string): string => {
  if (defaultValue) return i18n.t(defaultValue);
  Sentry.captureMessage(`react-i18next missing key: ${key}`);
  return isProduction ? '' : `Missing translation key: ${key}`;
};
export const getAllExerciseResources = async (): Promise<Resource> => {
  const resources = await Promise.all(SUPPORTED_LANGUAGES.map(async (lng): Promise<Resource> => getExerciseResourceByLanguage(lng)));
  return resources.reduce((acc: Resource, resource) => ({
    ...acc,
    ...resource
  }), ({} as Resource));
};
export const getAllPatientSpecificFunctionalResources = async (): Promise<Resource> => {
  const resources = await Promise.all(SUPPORTED_LANGUAGES.map(async (lng): Promise<Resource> => getPatientSpecificFunctionalScaleResource(lng)));
  return resources.reduce((acc: Resource, resource) => ({
    ...acc,
    ...resource
  }), ({} as Resource));
};
export const getCombinedResources = async (): Promise<Resource> => {
  const localResources = generateI18nResources();
  const exercisesResources = await getAllExerciseResources();
  const functionalScaleResources = await getAllPatientSpecificFunctionalResources();
  return SUPPORTED_LANGUAGES.reduce((acc, lng) => {
    const combinedResource = {
      ...localResources[lng],
      [exercisesNamespace]: exercisesResources[lng],
      [functionalScaleNamespace]: functionalScaleResources[lng]
    };
    return {
      ...acc,
      [lng]: combinedResource
    };
  }, ({} as Resource));
};
const initI18n = async (): Promise<void> => {
  const resources = await getCombinedResources();
  await i18n.use(LanguageDetector).use(initReactI18next).init({
    resources,
    supportedLngs: SUPPORTED_LANGUAGES,
    nsSeparator: false,
    // Use dot notation to indicate namespace hierarchy.
    fallbackLng: DEFAULT_LANGUAGE,
    debug: isProduction ? false : true,
    load: 'currentOnly',
    // Using 'currentOnly' prevents a "rejecting language code not found in supportedLngs: en" warning. Default value is 'all'.
    interpolation: {
      escapeValue: false // React is already safe from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    parseMissingKeyHandler: handleParseMissingKey
  }, err => {
    if (err) return console.error('something went wrong loading i18n', err);
  });
};
initI18n();
export default i18n;