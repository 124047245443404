export enum SseEvents {
  TaskUpdated = 'task-updated',
  WorkflowCreated = 'workflow-created',
  WorkflowTransitioned = 'workflow-transitioned',
  BulkWorkflowTransitionCompleted = 'bulk-workflow-transition-completed',
  BulkWorkflowTransitionFailed = 'bulk-workflow-transition-failed',
  CareCoordinationWorkflowCreated = 'care-coordination-workflow.update.succeeded',
  CareCoordinationWorkflowFailed = 'care-coordination-workflow.update.failed',
}
export const CONVERSATION_SELECTED_EVENT = 'conversationSelected';
export const RESTRICT_EXTERNAL_PANEL_ACTIONS_EVENT = 'restrictExternalPanelActions';