import { c as _c } from "react-compiler-runtime";
import { HHButton, HHDialogActions, HHDialogContent, HHDialogContentText, HHDialogTitle } from '@hinge-health/react-component-library';
export const DISABLE_PHI_MASK_DIALOG_TITLE = 'Disable PHI Mask?';
export const DISABLE_PHI_MASK_DIALOG_CONTENT = 'Protected health information will be visible after disabling PHI mask. Please confirm to continue.';
export const DISABLE_PHI_MASK_DIALOG_CONFIRM = 'Confirm';
export const DISABLE_PHI_MASK_DIALOG_CANCEL = 'Cancel';

// Test IDs
export const PHI_MASK_DISABLE_CONFIRM_DIALOG_TEST_ID = 'phi-mask-disable-confirm-dialog';
interface DisablePhiMaskingDialogProps {
  onConfirm: () => void;
  onClose: () => void;
}
export const DisablePHIMaskingDialogContent = t0 => {
  const $ = _c(11);
  const {
    onConfirm,
    onClose
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = <HHDialogTitle>{DISABLE_PHI_MASK_DIALOG_TITLE}</HHDialogTitle>;
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {
      pb: 0
    };
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = <HHDialogContent sx={t2} data-testid={PHI_MASK_DISABLE_CONFIRM_DIALOG_TEST_ID}><HHDialogContentText>{DISABLE_PHI_MASK_DIALOG_CONTENT}</HHDialogContentText></HHDialogContent>;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  let t4;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = {
      px: 6,
      py: 4
    };
    $[3] = t4;
  } else {
    t4 = $[3];
  }
  let t5;
  if ($[4] !== onClose) {
    t5 = <HHButton hhVariant="text" onClick={onClose}>{DISABLE_PHI_MASK_DIALOG_CANCEL}</HHButton>;
    $[4] = onClose;
    $[5] = t5;
  } else {
    t5 = $[5];
  }
  let t6;
  if ($[6] !== onConfirm) {
    t6 = <HHButton hhVariant="contained" onClick={onConfirm}>{DISABLE_PHI_MASK_DIALOG_CONFIRM}</HHButton>;
    $[6] = onConfirm;
    $[7] = t6;
  } else {
    t6 = $[7];
  }
  let t7;
  if ($[8] !== t5 || $[9] !== t6) {
    t7 = <>{t1}{t3}<HHDialogActions sx={t4}>{t5}{t6}</HHDialogActions></>;
    $[8] = t5;
    $[9] = t6;
    $[10] = t7;
  } else {
    t7 = $[10];
  }
  return t7;
};