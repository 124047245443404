import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox, HHTypography } from '@hinge-health/react-component-library';
import { COACH_WORKFLOWS_TABLE } from '../../../constants/strings/workflows-table';
const styles = {
  bodyTypography: {
    fontWeight: ctpTokens.fontWeights['roboto-1'],
    color: ctpTokens.palette.neutral[60],
    fontSize: ctpTokens.fontSize[4],
    lineHeight: ctpTokens.lineHeights[2],
    letterSpacing: ctpTokens.letterSpacing['0p3']
  },
  box: {
    display: 'flex',
    paddingTop: '48px',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
export const WorkflowsTableError = () => {
  const $ = _c(1);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = <HHBox sx={styles.box}><HHTypography hhVariant="variant-bypass" sx={styles.bodyTypography}>{COACH_WORKFLOWS_TABLE.ERROR}</HHTypography></HHBox>;
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  return t0;
};