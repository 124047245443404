import { c as _c } from "react-compiler-runtime";
import { gql, LazyQueryHookOptions, LazyQueryResultTuple, OperationVariables, useLazyQuery } from '@apollo/client';
export type UserCoverage = {
  role: string;
  uuid: string;
  name: string;
};
export type Coverage = {
  healthCoach: UserCoverage;
  physicalTherapist: UserCoverage;
};
export type GetUserCoveragesQuery = {
  getUserCoverages: {
    coverages: Coverage;
  };
};
export const GET_USER_COVERAGES_QUERY = gql`
  query getUserCoverages($userUuid: ID!) {
    getUserCoverages(userUuid: $userUuid) {
      coverages {
        healthCoach {
          role
          uuid
          name
        }
        physicalTherapist {
          role
          uuid
          name
        }
      }
    }
  }
`;
export const useGetUserCoverages = baseOptions => {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useLazyQuery(GET_USER_COVERAGES_QUERY, options);
};