import { c as _c } from "react-compiler-runtime";
import { AuthRedirectCallback as OktaAuthRedirectCallback } from '@hinge-health/react-component-library';
import { TEXT } from '../constants';
const errorComponent: React.FunctionComponent<{
  error: Error;
}> = _props => <p>{TEXT.LOGIN.LOGIN_FAILURE}</p>;
const loadingComponent: JSX.Element = <p>{TEXT.LOGIN.LOGIN_LOADING}</p>;
const AuthRedirectCallback = () => {
  const $ = _c(1);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = <OktaAuthRedirectCallback loadingComponent={loadingComponent} errorComponent={errorComponent} />;
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  return t0;
};
export default AuthRedirectCallback;