import { GetWorkflowDocument, Maybe, UpdateTasksMutation, UpdateTasksMutationFn } from '../types';
export const hasSelection = (selection?: Maybe<string> | undefined): boolean => selection !== null && selection !== '' && selection !== undefined;
export const asyncHandleTaskUpdate = async (updateTasksMutation: UpdateTasksMutationFn, taskId: number, selectionValue: string): Promise<UpdateTasksMutation> => (await updateTasksMutation({
  variables: {
    updateTasksDto: {
      taskIDs: [taskId],
      selection: selectionValue === '' ? null : selectionValue
    }
  },
  refetchQueries: [GetWorkflowDocument]
}) as UpdateTasksMutation);