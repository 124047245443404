import { c as _c } from "react-compiler-runtime";
import { useAtomValue } from 'jotai';
import { Dict } from 'mixpanel-browser';
import { useMemo } from 'react';
import { CommonAdminMixpanelProperties, CommonMemberMixpanelProperties, CommonWorkflowMixpanelProperties, MixpanelEventVersion, MixpanelPropertiesContext } from '../lib/mixpanel/types/generic-mixpanel-event-types';
import { getAdminMixpanelProperties, getMemberMixpanelProperties, getWorkflowMixpanelProperties } from '../lib/mixpanel/utils/generic-mixpanel-event-util';
import { currentWorkflowAtom } from '../modules/member-360/modules/panel-layouts/state/atoms';
import { currentUserAtom } from '../modules/member-360/state/atoms';
import { User, WorkflowPayload } from '../types';
import { mixpanelTrack } from '../utils/mixpanel';
import { useCurrentStoredAdminHook } from './use-current-stored-admin';

// Mapping context types to their respective property functions
const mixpanelContextPropertiesMap = new Map<MixpanelPropertiesContext, ((ctx: WorkflowPayload) => CommonWorkflowMixpanelProperties) | ((ctx: Partial<User>) => CommonMemberMixpanelProperties)>([[MixpanelPropertiesContext.WorkflowContext, getWorkflowMixpanelProperties], [MixpanelPropertiesContext.MemberContext, getMemberMixpanelProperties]]);
interface AdditionalProperties {
  eventVersion?: MixpanelEventVersion;
}

/**
 * Custom Hook for tracking Mixpanel events with context-specific properties.
 *
 * @template T - Type of common properties specific to a component
 * @param {Array<MixpanelPropertiesContext>} mixpanelContexts - Array of contexts to auto include common event properties
 * @returns {function(string, T & AdditionalProperties): void} mixpanelTrack - Function to track events mixpanel events
 *
 * @description
 * This hook automatically includes relevant user and workflow information in Mixpanel events.
 *
 * Key features:
 * - Event triggeres using this hook will always contain Admin properties. @refer - CommonAdminMixpanelProperties
 * - Can auto includes common properties according to the contexts provided. @refer - MixpanelPropertiesContext
 * - Enforces type safety for event-specific properties using generics (G)
 * - Supports common component specifc properties to be included in all component events. @refer - Example 4
 * - Supports mixpanel event versioning
 *------------------------------------------------------------------------------
 * @example1 : Tracking an from event from member page
 *
 * interface EditMemberAlertEventProps {
 *  memberAlertId: string;
 *  memberAlertCategory: string;
 * }
 *
 * const mixpanelTrack = useGenericMixpanelTrack([
 *   // Include common properties from Member context
 *   @refer - CommonMemberMixpanelProperties
 *   MixpanelPropertiesContext.MemberContext,
 * ]);
 *
 * mixpanelTrack<EditMemberAlertEventProps>('Member alert edited', { memberAlertId: '12345', memberAlertCategory : 'vacation' });
 *------------------------------------------------------------------------------
 * @example2 : Resolving a workflow from Workflow Panel in Member page
 *
 * interface WorkflowCompletionProps {
 *  worflowTransitionState: string;
 * }
 *
 * const mixpanelTrack = useGenericMixpanelTrack();
 *
 * mixpanelTrack<WorkflowCompletionProps>(
 *   'Workflow Completed',
 *   { workflowId: 'abc123', status: 'completed' }
 * );
 *
 *------------------------------------------------------------------------------
 * @example3 : Tracking a custom event with additional properties
 *
 * interface CustomEventProps {
 *  action: string;
 *  additionalInfo: string;
 * }
 *
 * // Can also invoke without passing any context if not required
 * const mixpanelTrack = useGenericMixpanelTrack()
 *
 * mixpanelTrack<CustomEventProps>('Custom Action', {
 *   action: 'clicked_button',
 *   additionalInfo: 'User clicked the submit button',
 * });
 *
 *------------------------------------------------------------------------------
 * @example4 : Tracking an event with component-level common properties
 *
 * interface ComponentLevelCommonProps {
 *   componentId: string; // Unique identifier for the component
 *   additionalInfo?: string; // Optional additional information about the event
 * }
 * const trackEvent = useGenericMixpanelTrack<ComponentLevelCommonProps>([
 *   MixpanelPropertiesContext.WorkflowContext,
 *   MixpanelPropertiesContext.MemberContext,
 * ]);
 *
 * trackEvent<ComponentLevelCommonProps>('Component Action', {
 *   action: 'button_click',
 *   componentId: 'submitButton',
 *   additionalInfo: 'User clicked the submit button in the form',
 */
export const useGenericMixpanelTrack = t0 => {
  const $ = _c(14);
  let t1;
  if ($[0] !== t0) {
    t1 = t0 === undefined ? [] : t0;
    $[0] = t0;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const mixpanelContexts = t1;
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const currentWorkflowData = useAtomValue(currentWorkflowAtom);
  const currentMember = useAtomValue(currentUserAtom);
  const t2 = currentWorkflowData?.currentWorkflow;
  let t3;
  let commonAdminMixpanelProperties;
  if ($[2] !== t2 || $[3] !== currentMember || $[4] !== currentStoredAdmin || $[5] !== mixpanelContexts) {
    const mixpanelContextValuesMap = new Map([[MixpanelPropertiesContext.WorkflowContext, t2], [MixpanelPropertiesContext.MemberContext, currentMember]]);
    let t4;
    let t5;
    if ($[8] !== currentStoredAdmin) {
      t5 = getAdminMixpanelProperties(currentStoredAdmin);
      $[8] = currentStoredAdmin;
      $[9] = t5;
    } else {
      t5 = $[9];
    }
    t4 = t5;
    commonAdminMixpanelProperties = t4;
    const mixpanelContextSpecificPropertiesArray = mixpanelContexts.map(context => {
      const getPropertiesFunction = mixpanelContextPropertiesMap.get(context);
      const propertiesValues = (mixpanelContextValuesMap.get(context) as WorkflowPayload & Partial<User>);
      let properties = {};
      if (getPropertiesFunction && propertiesValues) {
        properties = getPropertiesFunction(propertiesValues);
      }
      return properties;
    });
    let t6;
    if ($[10] === Symbol.for("react.memo_cache_sentinel")) {
      t6 = (acc, properties_0) => ({
        ...acc,
        ...properties_0
      });
      $[10] = t6;
    } else {
      t6 = $[10];
    }
    t3 = mixpanelContextSpecificPropertiesArray.reduce(t6, {});
    $[2] = t2;
    $[3] = currentMember;
    $[4] = currentStoredAdmin;
    $[5] = mixpanelContexts;
    $[6] = t3;
    $[7] = commonAdminMixpanelProperties;
  } else {
    t3 = $[6];
    commonAdminMixpanelProperties = $[7];
  }
  const mixpanelContextSpecificProperties = t3;
  let t4;
  if ($[11] !== mixpanelContextSpecificProperties || $[12] !== commonAdminMixpanelProperties) {
    t4 = (eventName, eventProperties) => {
      const mixpanelAccumulatedProperties = {
        ...eventProperties,
        ...mixpanelContextSpecificProperties,
        ...commonAdminMixpanelProperties
      };
      const allProperties = Object.entries(mixpanelAccumulatedProperties).reduce((acc_0, t5) => {
        const [key, value] = t5;
        if (value) {
          acc_0[key] = value;
        }
        return acc_0;
      }, ({} as Dict));
      mixpanelTrack?.(eventName, allProperties);
    };
    $[11] = mixpanelContextSpecificProperties;
    $[12] = commonAdminMixpanelProperties;
    $[13] = t4;
  } else {
    t4 = $[13];
  }
  const mixpanelTrackEvent = t4;
  return mixpanelTrackEvent;
};