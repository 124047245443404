export const RESOLVE_ACCORDION = {
  RESOLVE_ACTION: 'Resolve',
  WARNING: 'Make sure an action plan has been created before resolving this workflow.',
  MARK_COMPLETE: 'MARK COMPLETE',
  COMPLETE: 'COMPLETE',
  SEE_ALL_TASKS: 'See all your tasks'
};
export const WORKFLOW_SUBHEADERS = {
  MEMBER_NOTE: 'Member note',
  HEALTH_LOG_PAIN_UPTICK: 'Health log - pain uptick',
  PAIN_UPTICK: 'Pain uptick',
  BALANCE_ASSESSMENT: 'Balance assessment',
  SETUP_ACTION_PLAN: '6 ETs completed'
};
export const FILTER_TITLE = 'Your Dashboard';