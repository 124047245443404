import { c as _c } from "react-compiler-runtime";
import { HHChip } from '@hinge-health/react-component-library';
import { ACTIVE_CHIP_COLOR, CARE_PLAN_CHIPS } from '../constants/colors';
import { ACUTE_CARE_PLAN, ACUTE_CARE_PLAN_TEST_ID } from '../constants/strings/acute';
export interface AcuteCarePlanChipsProps {
  isConnectWithMemberTaskCompleted: boolean;
  isWithinTenDaysOfExpiration: boolean;
  isAfterExpiration: boolean;
  allTasksCompleted: boolean;
}
const pendingExtensionChipStyle = {
  color: CARE_PLAN_CHIPS.PENDING_EXTENSION,
  border: `1px solid ${CARE_PLAN_CHIPS.PENDING_EXTENSION_OUTLINE}`,
  backgroundColor: ACTIVE_CHIP_COLOR,
  height: 18,
  width: 120,
  fontSize: 13,
  padding: 3,
  '&.MuiChip-label,.MuiChip-labelMedium': {
    fontSize: 13,
    overflow: 'visible'
  }
};
const expiringChipStyle = {
  ...pendingExtensionChipStyle,
  color: CARE_PLAN_CHIPS.EXPIRING_SOON,
  border: `1px solid ${CARE_PLAN_CHIPS.EXPIRING_SOON_OUTLINE}`,
  width: 88
};
export const AcuteCarePlanChips = props => {
  const $ = _c(6);
  const {
    isConnectWithMemberTaskCompleted,
    isWithinTenDaysOfExpiration,
    isAfterExpiration,
    allTasksCompleted
  } = props;
  const isPendingExtension = isConnectWithMemberTaskCompleted || !isWithinTenDaysOfExpiration;
  let t0;
  if ($[0] !== allTasksCompleted || $[1] !== isAfterExpiration || $[2] !== isPendingExtension) {
    t0 = !allTasksCompleted && !isAfterExpiration && <HHChip hhVariant="variant-bypass" data-testid={isPendingExtension ? ACUTE_CARE_PLAN_TEST_ID.PENDING_EXTENSION_CHIP : ACUTE_CARE_PLAN_TEST_ID.EXPIRING_SOON_CHIP} label={isPendingExtension ? ACUTE_CARE_PLAN.PENDING_EXTENSION : ACUTE_CARE_PLAN.EXPIRING_SOON} sx={isPendingExtension ? pendingExtensionChipStyle : expiringChipStyle} />;
    $[0] = allTasksCompleted;
    $[1] = isAfterExpiration;
    $[2] = isPendingExtension;
    $[3] = t0;
  } else {
    t0 = $[3];
  }
  let t1;
  if ($[4] !== t0) {
    t1 = <>{t0}</>;
    $[4] = t0;
    $[5] = t1;
  } else {
    t1 = $[5];
  }
  return t1;
};