import { HHTabData, HHTabs } from '@hinge-health/react-component-library';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useGenericMixpanelTrack } from '../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../lib/mixpanel/mixpanel-event-names';
import { DashboardTabSelectionType } from '../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { CoachDashboardFilter } from '../../../constants/coach-dashboard';
import { COACH_VIDEO_VISIT_TAB_NAMES } from '../../../constants/strings/coach-layout';
import { selectAllWorkflowAtom, selectedCoachDashboardFilters, videoTabchanged } from '../../../jotai/atoms';
import { CoachDashboardFilters } from '../../../types/coach';
export interface VideoVisitTabsProps {
  tableContent: JSX.Element;
}
export const CoachVideoVisitTabs: React.FC<VideoVisitTabsProps> = ({
  tableContent
}) => {
  const [selectedDashboardFilter, setSelectedDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const setAllWorkflowFlag = useSetAtom(selectAllWorkflowAtom);
  const [tabIndex, setTabIndex] = useState(0);
  const mixPanelTrack = useGenericMixpanelTrack();
  const invokeMixpanelTrack = (event: string, selectedDashboardFilter_0: string): void => {
    mixPanelTrack<DashboardTabSelectionType>(event, {
      selectedTabName: selectedDashboardFilter_0
    });
  };
  const setVideoTabChanged = useSetAtom(videoTabchanged);
  useEffect(() => {
    if (selectedDashboardFilter.filterTabName === COACH_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS) {
      setTabIndex(0);
      invokeMixpanelTrack(MIXPANEL_EVENTS.VIDEO_VISITS_UPCOMING_VIDEO_VISITS_TAB_SELECTED, COACH_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS);
    } else {
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboardFilter]);
  const handleTabChange = (event_0: number): void => {
    if (event_0 === 0) {
      setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.CoachVideoVisitUpcoming]);
    }
    if (event_0 === 1) {
      setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.CoachVideoVisitIncomplete]);
      invokeMixpanelTrack(MIXPANEL_EVENTS.VIDEO_VISITS_POST_VIDEO_VISIT_TASKS_TAB_SELECTED, COACH_VIDEO_VISIT_TAB_NAMES.INCOMPLETE_VIDEO_VISITS);
    }
    setVideoTabChanged(true);
    setAllWorkflowFlag(false);
  };
  const defaultTabData: HHTabData[] = [{
    tabContent: tableContent,
    tabLabel: COACH_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS,
    key: COACH_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS
  }, {
    tabContent: tableContent,
    tabLabel: COACH_VIDEO_VISIT_TAB_NAMES.INCOMPLETE_VIDEO_VISITS,
    key: COACH_VIDEO_VISIT_TAB_NAMES.INCOMPLETE_VIDEO_VISITS
  }];
  return <HHTabs layoutStyles={{
    margin: '2px'
  }} tabsData={defaultTabData} onTabChange={handleTabChange} defaultTabIndex={tabIndex} />;
};