export enum NodeEnv {
  Development = 'development',
  Stage = 'stage',
  Scaling = 'scaling',
  Pentest = 'pentest',
  Production = 'production',
}
export enum AppEnv {
  Development = 'development',
  Stage = 'stage',
  Scaling = 'scaling',
  Pentest = 'pentest',
  Production = 'production',
}