import { c as _c } from "react-compiler-runtime";
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NAV_SPLIT_TREATMENTS, ON } from '../constants/strings/split';
import { baseCoachQueueWorkflowTypes, coachQueueHealthLogkWorkflowTypes, coachQueueMediumTierRetentionWorkflowTypes, CoachWorkflowTypes, OsWorkflowTypes, PtWorkflowTypes, WorkflowTypes } from '../custom-types';
import { SPLIT_TREATMENTS } from '../modules/dashboard/constants/splits';
import { defaultPtWorkflows } from '../modules/dashboard/types/pt';
import { OktaRole } from '../types/okta-roles';
import { useCurrentStoredAdminHook } from './use-current-stored-admin';
const getSplitTreatmentClient = (splitClient: SplitIO.IBrowserClient | null, adminUuid: string | undefined) => (treatment: string): boolean => {
  const splitTreatmentOn = splitClient?.getTreatment(treatment, {
    adminUuid: adminUuid || '*'
  }) === ON;
  return splitTreatmentOn;
};
export const useGetQueueWorkflowTypes = () => {
  const $ = _c(32);
  const splitClient = useClient();
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = [];
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const [workflowTypesToCount, setWorkflowTypesToCount] = useState(t0);
  const getSplitTreatmentOn = getSplitTreatmentClient(splitClient, currentStoredAdmin?.uuid);
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const healthLogPainUptickSplitOn = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.HEALTH_LOG_PAIN_UPTICKS);
  const reEngagerTreatment = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.REENGAGER_WORKFLOW_MIGRATION);
  const weeklyEnsoReviewTreatment = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.WEEKLY_ENSO_REVIEW_WORKFLOW_MIGRATION);
  const careCoordinationWorkflowTreatment = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT);
  const mediumTierRetentionWorkflowSplitOn = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.MEDIUM_TIER_RETENTION_WORKFLOWS);
  const coachCallsSplitOn = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.COACH_CALLS);
  const surgeryTrendSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.SURGERY_TREND_WORKFLOW_MIGRATION);
  const careCoordinationWorkflowDashboardSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT);
  const outreachNeededWorkflowTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.OUTREACH_NEEDED_WORKFLOW_MIGRATION);
  const aqRedFlagsSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.AQ_RED_FLAGS);
  const ptActionPlanReviewWorkflowSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.PT_ACTION_PLAN_REVIEW);
  const firstWkEnsoCheckInSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.FIRST_WEEK_ENSO_CHECK_IN_WORKFLOW_MIGRATION);
  const ptMemberAlertReminderSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.PT_MEMBER_ALERTS_REMINDERS);
  const setupActionPlanTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.SETUP_ACTION_PLAN_WORKFLOW);
  const coachWeeklyGoalExpiryTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.COACH_WEEKLY_GOAL_EXPIRY);
  const coachMemberUpdatedWeeklyGoalTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.COACH_MEMBER_UPDATED_WEEKLY_GOAL);
  const indicationUpdateTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.INDICATION_UPDATE_WORKFLOW);
  const coachVideoVisitTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.COACH_VIDEO_VISIT);
  let t1;
  if ($[1] !== healthLogPainUptickSplitOn || $[2] !== outreachNeededWorkflowTreatment || $[3] !== reEngagerTreatment || $[4] !== weeklyEnsoReviewTreatment || $[5] !== careCoordinationWorkflowTreatment || $[6] !== mediumTierRetentionWorkflowSplitOn || $[7] !== coachCallsSplitOn || $[8] !== coachWeeklyGoalExpiryTreatment || $[9] !== coachMemberUpdatedWeeklyGoalTreatment || $[10] !== coachVideoVisitTreatment) {
    t1 = () => {
      let coachWorkflowTypesList = [...baseCoachQueueWorkflowTypes];
      if (healthLogPainUptickSplitOn) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, ...coachQueueHealthLogkWorkflowTypes];
      }
      if (outreachNeededWorkflowTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.OutreachNeeded];
      }
      if (reEngagerTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.ReEngager];
      }
      if (weeklyEnsoReviewTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.WeeklyEnsoReview];
      }
      if (careCoordinationWorkflowTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachEscalation];
      }
      if (mediumTierRetentionWorkflowSplitOn) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, ...coachQueueMediumTierRetentionWorkflowTypes];
      }
      if (coachCallsSplitOn) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachCall];
      }
      if (coachWeeklyGoalExpiryTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachWeeklyGoalExpiry];
      }
      if (coachMemberUpdatedWeeklyGoalTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal];
      }
      if (coachVideoVisitTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachVideoVisit];
      }
      setWorkflowTypesToCount((coachWorkflowTypesList as T[]));
    };
    $[1] = healthLogPainUptickSplitOn;
    $[2] = outreachNeededWorkflowTreatment;
    $[3] = reEngagerTreatment;
    $[4] = weeklyEnsoReviewTreatment;
    $[5] = careCoordinationWorkflowTreatment;
    $[6] = mediumTierRetentionWorkflowSplitOn;
    $[7] = coachCallsSplitOn;
    $[8] = coachWeeklyGoalExpiryTreatment;
    $[9] = coachMemberUpdatedWeeklyGoalTreatment;
    $[10] = coachVideoVisitTreatment;
    $[11] = t1;
  } else {
    t1 = $[11];
  }
  const updateCoachWorkflowTypes = t1;
  let t2;
  if ($[12] !== surgeryTrendSplitOn || $[13] !== careCoordinationWorkflowDashboardSplitOn || $[14] !== aqRedFlagsSplitOn || $[15] !== ptActionPlanReviewWorkflowSplitOn || $[16] !== firstWkEnsoCheckInSplitOn || $[17] !== ptMemberAlertReminderSplitOn || $[18] !== setupActionPlanTreatment || $[19] !== indicationUpdateTreatment) {
    t2 = () => {
      let ptWorkflowTypesList = [...defaultPtWorkflows];
      if (surgeryTrendSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.SurgeryTrend];
      }
      if (careCoordinationWorkflowDashboardSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.EtModification, PtWorkflowTypes.ClinicalEscalation, PtWorkflowTypes.MedicalEmergencyFollowUp];
      }
      if (aqRedFlagsSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.AqRedFlags];
      }
      if (ptActionPlanReviewWorkflowSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.ActionPlanReview];
      }
      if (firstWkEnsoCheckInSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.FirstWeekEnsoCheckIn];
      }
      if (ptMemberAlertReminderSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.PtMemberAlertReminder];
      }
      if (setupActionPlanTreatment) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.SetupActionPlan];
      }
      if (indicationUpdateTreatment) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.IndicationUpdate];
      }
      setWorkflowTypesToCount((ptWorkflowTypesList as T[]));
    };
    $[12] = surgeryTrendSplitOn;
    $[13] = careCoordinationWorkflowDashboardSplitOn;
    $[14] = aqRedFlagsSplitOn;
    $[15] = ptActionPlanReviewWorkflowSplitOn;
    $[16] = firstWkEnsoCheckInSplitOn;
    $[17] = ptMemberAlertReminderSplitOn;
    $[18] = setupActionPlanTreatment;
    $[19] = indicationUpdateTreatment;
    $[20] = t2;
  } else {
    t2 = $[20];
  }
  const updatePtWorkflowTypes = t2;
  let t3;
  if ($[21] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = () => {
      const osWorkflowTypesList = [OsWorkflowTypes.UnreadMessages, OsWorkflowTypes.MemberAlertReminder, OsWorkflowTypes.ReEngager, OsWorkflowTypes.OutreachNeeded, OsWorkflowTypes.Nuto, OsWorkflowTypes.Ruto];
      setWorkflowTypesToCount(((osWorkflowTypesList as unknown) as T[]));
    };
    $[21] = t3;
  } else {
    t3 = $[21];
  }
  const updateOsWorkflowTypes = t3;
  const roles = hingeHealthAuthState?.accessToken?.claims.roles;
  let t4;
  let t5;
  if ($[22] !== roles) {
    t5 = roles?.includes(OktaRole.PhysicalTherapist);
    $[22] = roles;
    $[23] = t5;
  } else {
    t5 = $[23];
  }
  t4 = t5;
  const isPhysicalTherapist = t4;
  let t6;
  let t7;
  if ($[24] !== roles) {
    t7 = roles?.includes(OktaRole.OnboardingSpecialist);
    $[24] = roles;
    $[25] = t7;
  } else {
    t7 = $[25];
  }
  t6 = t7;
  const isOnboardingSpecialist = t6;
  let t8;
  let t9;
  if ($[26] !== isPhysicalTherapist || $[27] !== updatePtWorkflowTypes || $[28] !== isOnboardingSpecialist || $[29] !== updateCoachWorkflowTypes) {
    t8 = () => {
      if (isPhysicalTherapist) {
        updatePtWorkflowTypes();
      } else {
        if (isOnboardingSpecialist) {
          updateOsWorkflowTypes();
        } else {
          updateCoachWorkflowTypes();
        }
      }
    };
    t9 = [updateCoachWorkflowTypes, updatePtWorkflowTypes, isPhysicalTherapist, isOnboardingSpecialist, updateOsWorkflowTypes];
    $[26] = isPhysicalTherapist;
    $[27] = updatePtWorkflowTypes;
    $[28] = isOnboardingSpecialist;
    $[29] = updateCoachWorkflowTypes;
    $[30] = t8;
    $[31] = t9;
  } else {
    t8 = $[30];
    t9 = $[31];
  }
  useEffect(t8, t9);
  return workflowTypesToCount;
};