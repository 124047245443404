export const COACH_LAYOUT = {
  LOADER_ID: 'coach-layout-loader',
  LAYOUT_CONTAINER_ID: 'coach-layout',
  FILTER_CONTAINER_ID: 'coach-dashboard-filter',
  WORKFLOWS_TABLE_CONTAINER_ID: 'coach-workflows-table'
};
export const FIRST_ACTION_PLAN_LABEL = {
  NO_PLAN: 'No plan created',
  CREATE_BY_MEMBER: 'Member created plan',
  CREATE_BY_ADMIN: 'Care team created plan'
};
export const ACTION_PLAN_REVIEW_LABEL = {
  REVIEW_PLAN: 'Review plan',
  MEMBER_CREATED_PLAN: 'Member updated plan'
};
export const PANEL_CATEGORY = {
  UNREADS: 'Unreads',
  FIRST_WEEK_GOAL: 'First week goal',
  ACTION_PLAN: 'Action plan',
  ENSO: 'Enso',
  PELVIC_HEALTH: 'Pelvic health',
  OTHER_WORKFLOWS: 'Other workflows'
};
export const ENSO_WORKFLOW_LABEL = {
  NO_ENSO_DATA: 'No Enso data',
  SESSIONS_THIS_WEEK: 'sessions this week',
  LAST_WEEK: 'last week',
  NO_SESSIONS_IN_TWO_WEEKS: 'No sessions in two weeks',
  ET_ACTIVE: 'ET active'
};
export const MISSING_CUSTOM_FIELDS_SENTRY_ERROR = 'workflow missing customFields';
export const COACH_VIDEO_VISIT_TAB_NAMES = {
  INCOMPLETE_VIDEO_VISITS: 'Post Video Visit Tasks',
  UPCOMING_VIDEO_VISITS: 'Upcoming Video Visits'
};