import { HHBox, HHDialogContent, HHStack, HHTypography } from '@hinge-health/react-component-library';
import { HHLinearProgress } from '@hinge-health/react-component-library/dist/components/atoms/hh-linear-progress';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { PhiMaskingDialogProps } from '../..';
const CTT_BFF_GQL_URL = `${process.env.REACT_APP_BFF_URL}/graphql`;
const PROGRESS_BAR_INTERVAL = 1000;
const PROGRESS_COLOR = '#004A1F';
const PROGRESS_TRACK_COLOR = '#D9F2E4';
const PLEASE_WAIT_TEXT = 'PHI mask, please wait...';
export const ENABLING_PHI_MASK_TEXT = `Enabling ${PLEASE_WAIT_TEXT}`;
export const DISABLING_PHI_MASK_TEXT = `Disabling ${PLEASE_WAIT_TEXT}`;

// Test IDs
export const PHI_MASK_PROGRESS_DIALOG_TEST_ID = 'phi-mask-progress-dialog';
const linerProgressStyles = {
  width: '400px',
  height: '18px',
  borderRadius: '300px',
  backgroundColor: PROGRESS_TRACK_COLOR,
  '& .MuiLinearProgress-bar': {
    backgroundColor: PROGRESS_COLOR,
    borderRadius: '300px'
  }
};
interface PhiMaskingProgressDialogContentProps {
  phiMaskingEnabled: boolean;
  maskingProgress: number;
  setPhiMaskingDialogProps: Dispatch<SetStateAction<PhiMaskingDialogProps>>;
}
export const PHIMaskingProgressDialogContent = ({
  phiMaskingEnabled,
  maskingProgress,
  setPhiMaskingDialogProps
}: PhiMaskingProgressDialogContentProps): JSX.Element => {
  const handleProgressComplete = (): void => {
    setPhiMaskingDialogProps(prev => ({
      ...prev,
      maskingProgress: 100
    }));

    // Show the masking feedback dialog with some delay after the progress bar reaches 100%
    // This is to give the user a chance to see the progress bar at 100%
    const timeout = setTimeout((): void => {
      setPhiMaskingDialogProps(prev_0 => ({
        ...prev_0,
        dialogContent: 'masking-feedback'
      }));
      clearTimeout(timeout);
    }, 200);
  };
  useEffect(() => {
    let requestsInFlight = 0;
    let maxRequests = 0;

    // Override the fetch function to track the number of requests in flight
    // This will be used to show the progress of PHI masking
    const originalFetch = window.fetch;
    window.fetch = async (...args): Promise<Response> => {
      const requestUrl = (args[0] as string);
      const gqlRequestCount = requestUrl.startsWith(CTT_BFF_GQL_URL) ? 1 : 0;
      requestsInFlight += gqlRequestCount;
      maxRequests = Math.max(maxRequests, requestsInFlight);
      return originalFetch(...args).finally((): void => {
        requestsInFlight -= gqlRequestCount;
      });
    };
    const handleIncrementProgress = (): void => {
      const currentProgress = 100 * (1 - requestsInFlight / maxRequests);
      setPhiMaskingDialogProps(prev_1 => ({
        ...prev_1,
        maskingProgress: Math.max(currentProgress, prev_1.maskingProgress)
      }));
    };

    // Update the masking progress every second
    const maskingProgressInterval = setInterval(() => {
      const progressHandler = requestsInFlight ? handleIncrementProgress : handleProgressComplete;
      progressHandler();
    }, PROGRESS_BAR_INTERVAL);
    return () => {
      window.fetch = originalFetch;
      clearInterval(maskingProgressInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <HHDialogContent data-testid={PHI_MASK_PROGRESS_DIALOG_TEST_ID}>
      <HHStack height={'100%'} rowGap={8} justifyContent={'center'} alignItems={'center'}>
        <HHBox>
          <HHLinearProgress variant="determinate" value={maskingProgress} color="primary" sx={linerProgressStyles} />
        </HHBox>
        <HHTypography hhVariant="body1">
          {phiMaskingEnabled ? ENABLING_PHI_MASK_TEXT : DISABLING_PHI_MASK_TEXT}
        </HHTypography>
      </HHStack>
    </HHDialogContent>;
};