import { c as _c } from "react-compiler-runtime";
import { useLazyQuery } from '@apollo/client';
import { HHTableCell, HHTableRow } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CURRENT_TAB_CHANGED, SESSION_ID } from '../../../../../constants/strings/member';
import { acuteLayoutUrl, memberLayoutUrl, portalSidebarUrl } from '../../../../../constants/strings/workflow-urls';
import { acuteSidebarWorkflows, IamMessageSource, PtWorkflowTypes, threePanelLayoutWorkflows } from '../../../../../custom-types';
import { useGenericMixpanelTrack } from '../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../lib/mixpanel/mixpanel-event-names';
import { WorkflowSelectedFromDashboardProperties } from '../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { getLocalStorageItem } from '../../../../../utils/local-storage-utils';
import { SPLIT_TREATMENTS as PT_SPLIT_TREATEMENTS } from '../../../../acute-physical-therapy/constants/splits';
import { AcuteGroupType } from '../../../../acute-physical-therapy/custom-types';
import { isVideoVisitsWorkflowType } from '../../../../acute-physical-therapy/utils/video-visits';
import { SIMPLIFIED_WORKFLOWS } from '../../../../member-360/constants/splits';
import { GET_USER_INFO } from '../../../../member-360/queries/user.graphql';
import { ON, SPLIT_TREATMENTS } from '../../../constants/splits';
import { LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from '../../../constants/strings/coach-dashboard-default';
import { NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/pt-layout';
import { useShellContext } from '../../../contexts/shell.context';
import { completeWorkflowFilters, DashboardType, UnreadFilterType } from '../../../custom-types';
import { changePT, listOfIncompletedWorkflowsObject, listOfWorkflowsObject, listToMarkWorkflowComplete, listToMarkWorkflowPending, selectAllPTWorkflowAtom, selectAllWorkflowAtom, selectedCoachDashboardFilters, selectedDashboardType, selectedOsDashboardFilters, selectedPtDashboardFilters } from '../../../jotai/atoms';
import { WorkflowPayload } from '../../../types';
import { CoachWorkflowTypes } from '../../../types/coach';
import { ptCarePlanFilters, PtDashboardFilters } from '../../../types/pt';
import { getCompleteWorkflowFilters } from '../../../utils/get-complete-workflow-filters';
import { getPriorityString } from '../../../utils/get-workflow-priority';
import { WorkflowAssignCell } from './table-row-cells/workflow-assign';
import { WorkflowBulkMark } from './table-row-cells/workflow-bulk-mark';
import { WorkflowDateCell } from './table-row-cells/workflow-created-on';
import { WorkflowDueCell } from './table-row-cells/workflow-due';
import { WorkflowMarkCell } from './table-row-cells/workflow-mark';
import { WorkflowMemberCell } from './table-row-cells/workflow-member';
import { WorkflowMemberStateCell } from './table-row-cells/workflow-member-state';
import { WorkflowTypeCell } from './table-row-cells/workflow-type';
import WorkflowUnreadTypeCell from './table-row-cells/workflow-unread-type-cell';
export type CoachWorkflowsTableRowProps = {
  workflow: WorkflowPayload;
};
export const WorkflowsTableRow = t0 => {
  const $ = _c(95);
  const {
    workflow
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = [];
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const mixpanelTrack = useGenericMixpanelTrack(t1);
  const [selectedDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [selectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const {
    adminProfile
  } = useShellContext();
  const navigate = useNavigate();
  const splitClient = useClient();
  const threePanelLayoutTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.THREE_PANEL_LAYOUT, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const videoVisitDeprecationTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.CTT_DEPRECATION_VIDEO_VISIT, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const workflowName = workflow?.customFields?.source === IamMessageSource.HLN ? UnreadFilterType.HealthLog : UnreadFilterType.Messages;
  const ptPooledDashboardTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.PT_POOLED_DASHBOARD, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const ptPooledReassignementTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.PT_POOLED_REASSIGNEMNT, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const modularIamUnreadMessageTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.MODULAR_IAM_UNREAD_MESSAGE, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const [displayCompleteIcon, setDisplayCompleteIcon] = useState(false);
  const [displayAssignIcon, setDisplayAssignIcon] = useState(false);
  let t2;
  if ($[1] !== threePanelLayoutTreatment || $[2] !== workflow) {
    t2 = threePanelLayoutTreatment && threePanelLayoutWorkflows.includes((workflow.type as CoachWorkflowTypes));
    $[1] = threePanelLayoutTreatment;
    $[2] = workflow;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const navigateToThreePanelMemberView = t2;
  const [, setListToMarkComplete] = useAtom(listToMarkWorkflowComplete);
  const [, setListToMarkPending] = useAtom(listToMarkWorkflowPending);
  const [displayEncounterDate, setDisplayEncounterDate] = useState(workflow?.createdAt);
  const isCompleteWorkflowFilter = getCompleteWorkflowFilters([selectedDashboardFilter.filterLabel, selectedPtDashboardFilter.filterLabel, selectedOsDashboardFilter.filterLabel]);
  const [currentDashboard] = useAtom(selectedDashboardType);
  const [, setListOfSelectedWorkflows] = useAtom(listOfWorkflowsObject);
  const [, setListOfUnselectedWorkflows] = useAtom(listOfIncompletedWorkflowsObject);
  const allWorkflowFlag = useAtomValue(selectAllWorkflowAtom);
  const allPtWorkflowFlag = useAtomValue(selectAllPTWorkflowAtom);
  const [isChangePT] = useAtom(changePT);
  const [userInfoPrefetched, setUserInfoPrefetched] = useState(false);
  const [getUserInfo] = useLazyQuery(GET_USER_INFO);
  const isSimplifiedWorkflowsSplitOn = splitClient?.getTreatment(SIMPLIFIED_WORKFLOWS, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const t3 = (workflow?.priority as number);
  let t4;
  if ($[4] !== t3) {
    t4 = getPriorityString(t3);
    $[4] = t3;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  const priorityString = (t4 as string);
  let t5;
  if ($[6] !== mixpanelTrack || $[7] !== workflow || $[8] !== priorityString || $[9] !== selectedDashboardFilter.filterTabName || $[10] !== isSimplifiedWorkflowsSplitOn || $[11] !== navigate || $[12] !== videoVisitDeprecationTreatment || $[13] !== selectedPtDashboardFilter.filterLabel || $[14] !== navigateToThreePanelMemberView) {
    t5 = () => {
      localStorage.setItem(CURRENT_TAB_CHANGED, "");
      const sessionId = getLocalStorageItem(SESSION_ID) || null;
      mixpanelTrack(MIXPANEL_EVENTS.WORKFLOW_SELECTED_FROM_DASHBOARD, {
        selectedWorkflowID: workflow.id,
        selectedWorkflowName: workflow.type,
        selectedWorkflowState: workflow.state,
        selectedWorkflowCreatedAt: workflow.createdAt,
        selectedWorkflowMemberUuid: (workflow.patient?.uuid as string),
        selectedWorkflowPriority: priorityString,
        selectedWorkflowTabName: selectedDashboardFilter.filterTabName,
        timeStamp: Date.now().toString(),
        sessionId
      });
      if (isSimplifiedWorkflowsSplitOn) {
        navigate(memberLayoutUrl(workflow.id, workflow.type, workflow?.patient?.id, {
          isSimplifiedWorkflowsSplitOn
        }));
        return;
      }
      if (acuteSidebarWorkflows.includes((workflow?.type as PtWorkflowTypes)) && (!isVideoVisitsWorkflowType(workflow) || !videoVisitDeprecationTreatment)) {
        navigate(acuteLayoutUrl(workflow.id, workflow.type));
        return;
      }
      let vvGroupType = ("" as AcuteGroupType);
      if (selectedPtDashboardFilter.filterLabel === PtDashboardFilters.VideoVisitUpcoming) {
        vvGroupType = AcuteGroupType.Upcoming;
      } else {
        if (selectedPtDashboardFilter.filterLabel === PtDashboardFilters.VideoVisitIncomplete) {
          vvGroupType = AcuteGroupType.Incomplete;
        }
      }
      if (isVideoVisitsWorkflowType(workflow)) {
        localStorage.setItem("showMemberSidebar", "true");
      }
      navigateToThreePanelMemberView ? navigate(memberLayoutUrl(workflow.id, workflow.type, workflow?.patient?.id, {
        isSimplifiedWorkflowsSplitOn
      })) : navigate(portalSidebarUrl(workflow.id, workflow.type, vvGroupType));
    };
    $[6] = mixpanelTrack;
    $[7] = workflow;
    $[8] = priorityString;
    $[9] = selectedDashboardFilter.filterTabName;
    $[10] = isSimplifiedWorkflowsSplitOn;
    $[11] = navigate;
    $[12] = videoVisitDeprecationTreatment;
    $[13] = selectedPtDashboardFilter.filterLabel;
    $[14] = navigateToThreePanelMemberView;
    $[15] = t5;
  } else {
    t5 = $[15];
  }
  const navigateToMemberView = t5;
  let t6;
  if ($[16] !== selectedPtDashboardFilter.filterLabel) {
    t6 = ptCarePlanFilters.includes(selectedPtDashboardFilter.filterLabel);
    $[16] = selectedPtDashboardFilter.filterLabel;
    $[17] = t6;
  } else {
    t6 = $[17];
  }
  const carePlanWorkflow = t6;
  const displayMarkCell = selectedDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME;
  const displayOsMarkCell = selectedOsDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME;
  const displayAssignCell = currentDashboard === DashboardType.PhysicalTherapist && isChangePT;
  const showHeaderSelectionBox = currentDashboard === DashboardType.PhysicalTherapist;
  const displayPtMarkCell = selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME;
  const displayMemberStateCell = currentDashboard === DashboardType.PhysicalTherapist && ptPooledDashboardTreatment;
  const displayDueCell = !isCompleteWorkflowFilter && !carePlanWorkflow;
  const displayWorkflowUnreadTypeCell = currentDashboard === DashboardType.PhysicalTherapist && modularIamUnreadMessageTreatment && selectedPtDashboardFilter.filterLabel === PtDashboardFilters.UnreadMessage;
  let t7;
  if ($[18] !== workflow || $[19] !== carePlanWorkflow || $[20] !== selectedPtDashboardFilter.filterLabel || $[21] !== selectedDashboardFilter.filterLabel || $[22] !== selectedOsDashboardFilter.filterLabel) {
    t7 = () => {
      if (!workflow) {
        return;
      }
      const {
        customFields,
        carePlans
      } = workflow;
      let encounterDate = workflow.createdAt;
      if (customFields?.callDate) {
        encounterDate = customFields?.callDate;
      }
      if (customFields?.scheduledAt) {
        encounterDate = customFields?.scheduledAt;
      }
      if (carePlanWorkflow) {
        if (carePlans?.length && carePlans[0].expires) {
          encounterDate = carePlans[0].expires;
        } else {
          encounterDate = "";
        }
      }
      if (completeWorkflowFilters.includes(selectedPtDashboardFilter.filterLabel) || completeWorkflowFilters.includes(selectedDashboardFilter.filterLabel) || completeWorkflowFilters.includes(selectedOsDashboardFilter.filterLabel)) {
        encounterDate = workflow.lastCompletedAt || "";
      }
      setDisplayEncounterDate(encounterDate);
    };
    $[18] = workflow;
    $[19] = carePlanWorkflow;
    $[20] = selectedPtDashboardFilter.filterLabel;
    $[21] = selectedDashboardFilter.filterLabel;
    $[22] = selectedOsDashboardFilter.filterLabel;
    $[23] = t7;
  } else {
    t7 = $[23];
  }
  let t8;
  if ($[24] !== carePlanWorkflow || $[25] !== workflow || $[26] !== selectedDashboardFilter || $[27] !== selectedPtDashboardFilter || $[28] !== selectedOsDashboardFilter) {
    t8 = [carePlanWorkflow, workflow, selectedDashboardFilter, selectedPtDashboardFilter, selectedOsDashboardFilter];
    $[24] = carePlanWorkflow;
    $[25] = workflow;
    $[26] = selectedDashboardFilter;
    $[27] = selectedPtDashboardFilter;
    $[28] = selectedOsDashboardFilter;
    $[29] = t8;
  } else {
    t8 = $[29];
  }
  useEffect(t7, t8);
  let t9;
  if ($[30] !== setListToMarkComplete || $[31] !== setListOfSelectedWorkflows || $[32] !== workflow.type || $[33] !== setListToMarkPending || $[34] !== setListOfUnselectedWorkflows || $[35] !== allWorkflowFlag || $[36] !== allPtWorkflowFlag) {
    t9 = (checked, workflowId, workflowName_0) => {
      if (checked) {
        setListToMarkComplete(prevList => [...prevList, workflowId]);
        setListOfSelectedWorkflows(prevList_0 => [...prevList_0, {
          id: workflowId,
          name: workflowName_0,
          workflowType: workflow.type
        }]);
        setListToMarkPending(prevList_1 => prevList_1.filter(id => id !== workflowId));
        setListOfUnselectedWorkflows(prevList_2 => [...prevList_2, {
          id: workflowId,
          name: workflowName_0,
          workflowType: workflow.type
        }]);
      } else {
        const shouldAdd = allWorkflowFlag || allPtWorkflowFlag;
        setListOfUnselectedWorkflows(prev => shouldAdd ? [...prev, {
          id: workflowId,
          name: workflowName_0,
          workflowType: workflow.type
        }] : []);
        setListToMarkPending(prev_0 => shouldAdd ? [...prev_0, workflowId] : []);
        setListToMarkComplete(prevList_3 => prevList_3.filter(id_0 => id_0 !== workflowId));
        setListOfSelectedWorkflows(prevList_4 => prevList_4.filter(item => item.id !== workflowId));
      }
    };
    $[30] = setListToMarkComplete;
    $[31] = setListOfSelectedWorkflows;
    $[32] = workflow.type;
    $[33] = setListToMarkPending;
    $[34] = setListOfUnselectedWorkflows;
    $[35] = allWorkflowFlag;
    $[36] = allPtWorkflowFlag;
    $[37] = t9;
  } else {
    t9 = $[37];
  }
  const toggleIndividualWorkflows = t9;
  let t10;
  if ($[38] !== workflow.patient || $[39] !== userInfoPrefetched || $[40] !== getUserInfo) {
    t10 = () => {
      if (workflow.patient?.id && !userInfoPrefetched) {
        getUserInfo({
          variables: {
            uuid: workflow.patient.id
          }
        }).then(() => {
          setUserInfoPrefetched(true);
        });
      }
    };
    $[38] = workflow.patient;
    $[39] = userInfoPrefetched;
    $[40] = getUserInfo;
    $[41] = t10;
  } else {
    t10 = $[41];
  }
  const handlePrefetchUserInfo = t10;
  let t11;
  if ($[42] === Symbol.for("react.memo_cache_sentinel")) {
    t11 = {
      cursor: "pointer",
      borderBottom: "1px solid rgb(214, 214, 208)"
    };
    $[42] = t11;
  } else {
    t11 = $[42];
  }
  const t12 = `workflow-row-${workflow.id}`;
  let t13;
  if ($[43] !== handlePrefetchUserInfo) {
    t13 = () => {
      setDisplayCompleteIcon(true);
      setDisplayAssignIcon(true);
      handlePrefetchUserInfo();
    };
    $[43] = handlePrefetchUserInfo;
    $[44] = t13;
  } else {
    t13 = $[44];
  }
  let t14;
  if ($[45] === Symbol.for("react.memo_cache_sentinel")) {
    t14 = () => {
      setDisplayCompleteIcon(false);
      setDisplayAssignIcon(false);
    };
    $[45] = t14;
  } else {
    t14 = $[45];
  }
  let t15;
  if ($[46] !== displayMarkCell || $[47] !== displayPtMarkCell || $[48] !== displayOsMarkCell || $[49] !== showHeaderSelectionBox || $[50] !== isChangePT || $[51] !== ptPooledReassignementTreatment || $[52] !== workflow || $[53] !== toggleIndividualWorkflows || $[54] !== workflowName) {
    t15 = (displayMarkCell || displayPtMarkCell || displayOsMarkCell || showHeaderSelectionBox && isChangePT && ptPooledReassignementTreatment) && <HHTableCell style={{
      borderBottom: "1px solid #d6d6d0"
    }}><WorkflowBulkMark workflowId={workflow.id} markType="body" onSelect={toggleIndividualWorkflows} workflowName={workflowName} workflowCreatedAt={workflow.createdAt} workflowState={workflow.state} workflowMemberUuid={workflow.patient?.uuid} /></HHTableCell>;
    $[46] = displayMarkCell;
    $[47] = displayPtMarkCell;
    $[48] = displayOsMarkCell;
    $[49] = showHeaderSelectionBox;
    $[50] = isChangePT;
    $[51] = ptPooledReassignementTreatment;
    $[52] = workflow;
    $[53] = toggleIndividualWorkflows;
    $[54] = workflowName;
    $[55] = t15;
  } else {
    t15 = $[55];
  }
  let t16;
  if ($[56] !== workflow) {
    t16 = <WorkflowTypeCell workflow={workflow} />;
    $[56] = workflow;
    $[57] = t16;
  } else {
    t16 = $[57];
  }
  let t17;
  if ($[58] !== displayWorkflowUnreadTypeCell || $[59] !== workflow) {
    t17 = displayWorkflowUnreadTypeCell && <WorkflowUnreadTypeCell workflow={workflow} />;
    $[58] = displayWorkflowUnreadTypeCell;
    $[59] = workflow;
    $[60] = t17;
  } else {
    t17 = $[60];
  }
  let t18;
  if ($[61] !== workflow) {
    t18 = <WorkflowMemberCell workflow={workflow} />;
    $[61] = workflow;
    $[62] = t18;
  } else {
    t18 = $[62];
  }
  let t19;
  if ($[63] !== displayMemberStateCell || $[64] !== workflow) {
    t19 = displayMemberStateCell && <WorkflowMemberStateCell workflow={workflow} />;
    $[63] = displayMemberStateCell;
    $[64] = workflow;
    $[65] = t19;
  } else {
    t19 = $[65];
  }
  let t20;
  if ($[66] !== displayEncounterDate) {
    t20 = <WorkflowDateCell date={displayEncounterDate} />;
    $[66] = displayEncounterDate;
    $[67] = t20;
  } else {
    t20 = $[67];
  }
  let t21;
  if ($[68] !== displayDueCell || $[69] !== workflow) {
    t21 = displayDueCell && <WorkflowDueCell workflow={workflow} />;
    $[68] = displayDueCell;
    $[69] = workflow;
    $[70] = t21;
  } else {
    t21 = $[70];
  }
  let t22;
  if ($[71] !== displayMarkCell || $[72] !== displayPtMarkCell || $[73] !== displayOsMarkCell || $[74] !== workflow || $[75] !== displayCompleteIcon) {
    t22 = (displayMarkCell || displayPtMarkCell || displayOsMarkCell) && <WorkflowMarkCell workflow={workflow} displayCompleteIcon={displayCompleteIcon} />;
    $[71] = displayMarkCell;
    $[72] = displayPtMarkCell;
    $[73] = displayOsMarkCell;
    $[74] = workflow;
    $[75] = displayCompleteIcon;
    $[76] = t22;
  } else {
    t22 = $[76];
  }
  let t23;
  if ($[77] !== displayAssignCell || $[78] !== ptPooledReassignementTreatment || $[79] !== workflow || $[80] !== displayAssignIcon) {
    t23 = displayAssignCell && ptPooledReassignementTreatment && <WorkflowAssignCell workflow={workflow} displayAssignIcon={displayAssignIcon} />;
    $[77] = displayAssignCell;
    $[78] = ptPooledReassignementTreatment;
    $[79] = workflow;
    $[80] = displayAssignIcon;
    $[81] = t23;
  } else {
    t23 = $[81];
  }
  let t24;
  if ($[82] !== navigateToMemberView || $[83] !== t12 || $[84] !== t13 || $[85] !== t15 || $[86] !== t16 || $[87] !== t17 || $[88] !== t18 || $[89] !== t19 || $[90] !== t20 || $[91] !== t21 || $[92] !== t22 || $[93] !== t23) {
    t24 = <HHTableRow onClick={navigateToMemberView} sx={t11} hover={true} data-testid={t12} onMouseOver={t13} onMouseLeave={t14}>{t15}{t16}{t17}{t18}{t19}{t20}{t21}{t22}{t23}</HHTableRow>;
    $[82] = navigateToMemberView;
    $[83] = t12;
    $[84] = t13;
    $[85] = t15;
    $[86] = t16;
    $[87] = t17;
    $[88] = t18;
    $[89] = t19;
    $[90] = t20;
    $[91] = t21;
    $[92] = t22;
    $[93] = t23;
    $[94] = t24;
  } else {
    t24 = $[94];
  }
  return t24;
};