import { c as _c } from "react-compiler-runtime";
import { HHTablePagination } from '@hinge-health/react-component-library';
const styles = {
  pagination: {
    border: 'none',
    '.MuiTablePagination-toolbar': {
      marginTop: 4,
      '.MuiIconButton-root:not([disabled])': {
        color: 'inherit'
      }
    }
  }
};
export interface WorkflowPaginationProps {
  workflowCounts: number;
  pageNumber: number;
  onPageChange: (_event: unknown, newPage: number) => void;
}
const WorkflowPagination = t0 => {
  const $ = _c(5);
  const {
    workflowCounts,
    pageNumber,
    onPageChange: handleOnPageChange
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = [];
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] !== workflowCounts || $[2] !== pageNumber || $[3] !== handleOnPageChange) {
    t2 = <HHTablePagination count={workflowCounts} rowsPerPage={10} page={pageNumber} onPageChange={handleOnPageChange} showLastButton={true} showFirstButton={true} rowsPerPageOptions={t1} sx={styles.pagination} />;
    $[1] = workflowCounts;
    $[2] = pageNumber;
    $[3] = handleOnPageChange;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  return t2;
};
export default WorkflowPagination;