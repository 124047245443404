import { c as _c } from "react-compiler-runtime";
import { HHTabData, HHTabs } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { CoachDashboardFilter } from '../../../constants/coach-dashboard';
import { COACH_CALL_TAB_POST_CALL_TASKS, COACH_CALL_TAB_UPCOMING_COACH_CALLS } from '../../../constants/strings/coach-dashboard-default';
import { selectedCoachDashboardFilters } from '../../../jotai/atoms';
import { CoachDashboardFilters } from '../../../types/coach';
export interface CoachCallsTabsProps {
  tableContent: JSX.Element;
}
export const CoachCallsTabs: React.FC<CoachCallsTabsProps> = t0 => {
  const $ = _c(21);
  const {
    tableContent
  } = t0;
  const [selectedDashboardFilter, setSelectedDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [tabIndex, setTabIndex] = useState(0);
  let t1;
  if ($[0] !== selectedDashboardFilter.filterTabName) {
    t1 = () => {
      if (selectedDashboardFilter.filterTabName === COACH_CALL_TAB_UPCOMING_COACH_CALLS) {
        setTabIndex(0);
      } else {
        setTabIndex(1);
      }
    };
    $[0] = selectedDashboardFilter.filterTabName;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== selectedDashboardFilter) {
    t2 = [selectedDashboardFilter];
    $[2] = selectedDashboardFilter;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  useEffect(t1, t2);
  let t3;
  let t4;
  if ($[4] !== setSelectedDashboardFilter) {
    t3 = () => {
      setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.CoachCallsUpcomingCoachCalls]);
    };
    t4 = [setSelectedDashboardFilter];
    $[4] = setSelectedDashboardFilter;
    $[5] = t3;
    $[6] = t4;
  } else {
    t3 = $[5];
    t4 = $[6];
  }
  useEffect(t3, t4);
  let t5;
  if ($[7] !== setSelectedDashboardFilter) {
    t5 = event => {
      if (event === 1) {
        setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.CoachCallsPostCallTasks]);
      }
      if (event === 0) {
        setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.CoachCallsUpcomingCoachCalls]);
      }
    };
    $[7] = setSelectedDashboardFilter;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  const handleTabChange = t5;
  let t6;
  if ($[9] !== tableContent) {
    t6 = {
      tabContent: tableContent,
      tabLabel: COACH_CALL_TAB_UPCOMING_COACH_CALLS,
      key: COACH_CALL_TAB_UPCOMING_COACH_CALLS
    };
    $[9] = tableContent;
    $[10] = t6;
  } else {
    t6 = $[10];
  }
  let t7;
  if ($[11] !== tableContent) {
    t7 = {
      tabContent: tableContent,
      tabLabel: COACH_CALL_TAB_POST_CALL_TASKS,
      key: COACH_CALL_TAB_POST_CALL_TASKS
    };
    $[11] = tableContent;
    $[12] = t7;
  } else {
    t7 = $[12];
  }
  let t8;
  if ($[13] !== t6 || $[14] !== t7) {
    t8 = [t6, t7];
    $[13] = t6;
    $[14] = t7;
    $[15] = t8;
  } else {
    t8 = $[15];
  }
  const defaultTabData = t8;
  let t9;
  if ($[16] === Symbol.for("react.memo_cache_sentinel")) {
    t9 = {
      margin: "2px"
    };
    $[16] = t9;
  } else {
    t9 = $[16];
  }
  let t10;
  if ($[17] !== defaultTabData || $[18] !== handleTabChange || $[19] !== tabIndex) {
    t10 = <HHTabs layoutStyles={t9} tabsData={defaultTabData} onTabChange={handleTabChange} defaultTabIndex={tabIndex} />;
    $[17] = defaultTabData;
    $[18] = handleTabChange;
    $[19] = tabIndex;
    $[20] = t10;
  } else {
    t10 = $[20];
  }
  return t10;
};