import { HHTabData, HHTabs } from '@hinge-health/react-component-library';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useGenericMixpanelTrack } from '../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../lib/mixpanel/mixpanel-event-names';
import { DashboardTabSelectionType } from '../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { PtDashboardFilter } from '../../../constants/pt-dashboard';
import { DEFAULT_PT_DASHBOARD_TAB_NAME, NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/pt-layout';
import { selectedPtDashboardFilters } from '../../../jotai/atoms';
import { PtDashboardFilters } from '../../../types/pt';
export interface HealthLogNotesTabsProps {
  tableContent: JSX.Element;
}
export const PtHealthLogNotesTabs: React.FC<HealthLogNotesTabsProps> = ({
  tableContent
}) => {
  const [selectedDashboardFilter, setSelectedDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const mixpanelTrack = useGenericMixpanelTrack();
  const invokeMixpanelEvent = async (event: string, tabname: string): Promise<void> => {
    mixpanelTrack<DashboardTabSelectionType>(event, {
      selectedTabName: tabname
    });
  };
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    if (selectedDashboardFilter.filterTabName === DEFAULT_PT_DASHBOARD_TAB_NAME) {
      invokeMixpanelEvent(MIXPANEL_EVENTS.ALL_INCOMING_HEALTH_LOG_NOTES_TAB_SELECTED, DEFAULT_PT_DASHBOARD_TAB_NAME);
      setTabIndex(0);
    } else {
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboardFilter]);
  useEffect(() => {
    setSelectedDashboardFilter(PtDashboardFilter[PtDashboardFilters.HealthLogNotes]);
  }, [setSelectedDashboardFilter]);
  const handleTabChange = (event_0: number): void => {
    switch (event_0) {
      case 0:
        setSelectedDashboardFilter(PtDashboardFilter[PtDashboardFilters.HealthLogNotes]);
        break;
      case 1:
        setSelectedDashboardFilter(PtDashboardFilter[PtDashboardFilters.HealthLogNoResponse]);
        invokeMixpanelEvent(MIXPANEL_EVENTS.NO_RESPONSE_PREDICTED_HEALTH_LOG_NOTES_TAB_SELECTED, DEFAULT_PT_DASHBOARD_TAB_NAME);
        break;
      default:
        break;
    }
  };
  const defaultTabData: HHTabData[] = [{
    tabContent: tableContent,
    tabLabel: DEFAULT_PT_DASHBOARD_TAB_NAME,
    key: DEFAULT_PT_DASHBOARD_TAB_NAME
  }, {
    icon: <AutoFixHigh />,
    iconPosition: 'start',
    tabContent: tableContent,
    tabLabel: NO_RESPONSE_PREDICTED_TAB_NAME,
    key: NO_RESPONSE_PREDICTED_TAB_NAME
  }];
  return <HHTabs layoutStyles={{
    margin: 0.5
  }} tabsData={defaultTabData} onTabChange={handleTabChange} defaultTabIndex={tabIndex} />;
};