export class BaseEvent {
  eventSchemaVersion?: string;
  constructor(props: {
    eventSchemaVersion?: string;
  }) {
    this.eventSchemaVersion = props.eventSchemaVersion;
  }
  build(): Record<string, unknown> {
    return {
      eventSchemaVersion: this.eventSchemaVersion
    };
  }
}