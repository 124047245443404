import { HHDivider, HHSelect, HHSelectOption, HHStack, HHTextField } from '@hinge-health/react-component-library';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { PtWorkflowTypes } from '../../../../../../custom-types';
import { OktaRole } from '../../../../../../types/okta-roles';
import { currentUserAtom } from '../../../../state/atoms';
import { clinicalEscalationFormStateAtom, createCareCoordinationWorkflowAtom, workflowAssigneeUuidAtom } from '../../state/atoms';
import { CLINICAL_ESCALATION_FORM, COMMON_FORM, MAX_CHARACTERS } from '../constants/create-workflow-forms';
import { validateForm } from '../utils/create-workflow-form-helpers';
import { useCreateCareCoordinationWorkflow } from './hooks/use-create-care-coordination-workflow';
import { WorkflowFormActions } from './workflow-form-actions';
import { WorkflowFormHeader } from './workflow-form-header';
export const CLINICAL_ESCALATION_FORM_TEST_ID = 'clinical-escalation-form';
export const CLINICAL_REASON_ESCALATION_OPTIONS: HHSelectOption[] = [{
  value: 'out_of_scope',
  displayName: 'Out of scope'
}, {
  value: 'member_requested',
  displayName: 'Member requested'
}, {
  value: 'other',
  displayName: 'Other (specify)'
}];
export const ClinicalEscalationForm = (): JSX.Element => {
  const {
    TITLE,
    REASON_FOR_ESCALATION,
    ADDITIONAL_CONTEXT_HELPER
  } = CLINICAL_ESCALATION_FORM;
  const [CreateCareCoordinationWorkflow] = useCreateCareCoordinationWorkflow();
  const [currentUser] = useAtom(currentUserAtom);
  const [createCareCoordinationWorkflow] = useAtom(createCareCoordinationWorkflowAtom);
  const [clinicalEscalationFormState, setClinicalEscalationFormState] = useAtom(clinicalEscalationFormStateAtom);
  const [clinicalEscalationErrors, setClinicalEscalationErrors] = useState({
    reason: '',
    description: '',
    context: ''
  });
  const workflowAssigneeUuid = useAtomValue(workflowAssigneeUuidAtom);
  const onClinicalEscalationSubmit = useCallback((): void => {
    const {
      isValidated,
      errors: validationErrors
    } = validateForm({
      ...clinicalEscalationFormState,
      description: clinicalEscalationFormState.description.trim()
    }, ['context']);
    setClinicalEscalationFormState(prev => ({
      ...prev,
      description: clinicalEscalationFormState.description.trim()
    }));
    setClinicalEscalationErrors((validationErrors as Record<keyof typeof clinicalEscalationErrors, string>));
    if (!isValidated) {
      return;
    }
    CreateCareCoordinationWorkflow({
      workflowType: PtWorkflowTypes.ClinicalEscalation,
      workflowInputDto: {
        customFields: clinicalEscalationFormState,
        patientUuid: (currentUser?.uuid as string)
      },
      memberId: (currentUser?.id as string)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalEscalationFormState, currentUser]);
  const onClinicalEscalationFieldChange = (field: keyof typeof clinicalEscalationFormState, value: string): void => {
    if (['description', 'context'].includes(field) && value.length > MAX_CHARACTERS) {
      setClinicalEscalationErrors(prev_0 => ({
        ...prev_0,
        [field]: COMMON_FORM.MAX_CHARACTERS_ERROR
      }));
      return;
    }
    setClinicalEscalationFormState(prev_1 => ({
      ...prev_1,
      [field]: value
    }));
    setClinicalEscalationErrors(prev_2 => ({
      ...prev_2,
      [field]: ''
    }));
  };
  return <HHStack data-testid={CLINICAL_ESCALATION_FORM_TEST_ID} paddingY={4} paddingX={8} rowGap={4}>
      <WorkflowFormHeader title={TITLE} assigneeRole={OktaRole.PhysicalTherapist} />
      <HHDivider />
      <HHStack paddingTop={4} rowGap={4}>
        <HHSelect initialSelectValue={clinicalEscalationFormState.reason} selectOptions={CLINICAL_REASON_ESCALATION_OPTIONS} label={REASON_FOR_ESCALATION} readOnly={createCareCoordinationWorkflow.loading} error={Boolean(clinicalEscalationErrors.reason)} helperText={clinicalEscalationErrors.reason} onChange={(e): void => onClinicalEscalationFieldChange('reason', (e.target.value as string))} />
        <HHTextField hhVariant="outlined" label={COMMON_FORM.DESCRIPTOIN} InputProps={{
        readOnly: createCareCoordinationWorkflow.loading
      }} error={Boolean(clinicalEscalationErrors.description)} helperText={clinicalEscalationErrors.description} multiline maxRows={4} onChange={(e_0): void => onClinicalEscalationFieldChange('description', (e_0.target.value as string))} value={clinicalEscalationFormState.description} />
        <HHTextField hhVariant="outlined" label={COMMON_FORM.ADDITIONAL_CONTEXT} defaultValue={clinicalEscalationFormState.context} InputProps={{
        readOnly: createCareCoordinationWorkflow.loading
      }} error={Boolean(clinicalEscalationErrors.context)} helperText={clinicalEscalationErrors.context || ADDITIONAL_CONTEXT_HELPER} multiline maxRows={4} onChange={(e_1): void => onClinicalEscalationFieldChange('context', (e_1.target.value as string))} value={clinicalEscalationFormState.context} />
      </HHStack>
      <WorkflowFormActions loading={createCareCoordinationWorkflow.loading} onSubmit={onClinicalEscalationSubmit} disabled={!workflowAssigneeUuid} />
    </HHStack>;
};