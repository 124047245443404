import { c as _c } from "react-compiler-runtime";
import { HHBox, HHGrid, HHIconButton, HHTabs, HHTypography } from '@hinge-health/react-component-library';
import Close from '@mui/icons-material/Close';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom } from 'jotai';
import { NAV_SPLIT_TREATMENTS, ON } from '../../constants/strings/split';
import { QUEUE_SUBTITLE, QUEUE_TAB_LABELS, QUEUE_TITLE } from '../../constants/strings/workflows-queue';
import { QueueCategoryType } from '../../custom-types';
import { useCurrentStoredAdminHook } from '../../hooks/use-current-stored-admin';
import { useGetCompleteQueueCounts } from '../../hooks/use-get-complete-queue-counts';
import { currentQueueTabIndexAtom, queueComplete, queueDisplay } from '../../state/queue-atoms';
import { EncountersTab } from './encounters-tab';
import { useWorkflowsCompleted } from './hooks/use-workflows-completed';
import { UpcomingEncounterBox } from './upcoming-encounter-box';
import { WorkflowsAccordions } from './workflows-accordions';
const styles = {
  containerBox: {
    width: 480,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0
  },
  grid: {
    py: 5,
    alignItems: 'center',
    position: 'sticky',
    zIndex: 1,
    top: 0
  },
  titleBox: {
    marginLeft: 8
  },
  subtitleBox: {
    marginLeft: 8,
    marginTop: 2
  },
  iconContainer: {
    marginLeft: 4
  },
  accordionsBox: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden'
  },
  completedText: {
    marginTop: 16
  }
};
export const WorkflowsQueue = () => {
  const $ = _c(30);
  const [, setDisplayWorkflowQueue] = useAtom(queueDisplay);
  const [, setQueueComplete] = useAtom(queueComplete);
  const {
    totalCount: completedWorkflowsCount
  } = useGetCompleteQueueCounts(QueueCategoryType.Workflow, true);
  const {
    queueWorkflowsCompleted
  } = useWorkflowsCompleted(setQueueComplete);
  const splitClient = useClient();
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const [currentQueueTabIndex, setCurrentQueueTabIndex] = useAtom(currentQueueTabIndexAtom);
  const encountersTabTreatment = splitClient?.getTreatment(NAV_SPLIT_TREATMENTS.ENCOUNTERS_TAB, {
    adminUuid: currentStoredAdmin?.uuid || "*"
  }) === ON;
  let t0;
  if ($[0] !== queueWorkflowsCompleted) {
    t0 = <WorkflowsAccordions queueWorkflowsCompleted={queueWorkflowsCompleted} />;
    $[0] = queueWorkflowsCompleted;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  let t1;
  if ($[2] !== t0) {
    t1 = {
      tabContent: t0,
      tabLabel: QUEUE_TAB_LABELS.WORKFLOWS,
      keepRendered: true
    };
    $[2] = t0;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  let t2;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = <EncountersTab />;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  let t3;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      tabContent: t2,
      tabLabel: QUEUE_TAB_LABELS.ENCOUNTERS,
      keepRendered: true
    };
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  let t4;
  if ($[6] !== t1) {
    t4 = [t1, t3];
    $[6] = t1;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  const tabsData = t4;
  let t5;
  if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = {
      ...styles.grid,
      backgroundColor: theme => theme.palette.background.paper
    };
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  let t6;
  if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = <HHBox sx={styles.titleBox}><HHTypography hhVariant="h2">{QUEUE_TITLE}</HHTypography></HHBox>;
    $[9] = t6;
  } else {
    t6 = $[9];
  }
  const t7 = `${completedWorkflowsCount ?? "0"} ${QUEUE_SUBTITLE}`;
  let t8;
  if ($[10] !== t7) {
    t8 = <HHGrid item={true} xs={10}>{t6}<HHBox sx={styles.subtitleBox}><HHTypography hhVariant="body2">{t7}</HHTypography></HHBox></HHGrid>;
    $[10] = t7;
    $[11] = t8;
  } else {
    t8 = $[11];
  }
  let t9;
  if ($[12] !== setDisplayWorkflowQueue) {
    t9 = () => setDisplayWorkflowQueue(false);
    $[12] = setDisplayWorkflowQueue;
    $[13] = t9;
  } else {
    t9 = $[13];
  }
  let t10;
  if ($[14] === Symbol.for("react.memo_cache_sentinel")) {
    t10 = <Close fontSize="medium" color="inherit" />;
    $[14] = t10;
  } else {
    t10 = $[14];
  }
  let t11;
  if ($[15] !== t9) {
    t11 = <HHGrid item={true} xs={2}><HHBox sx={styles.iconContainer}><HHIconButton hhVariant="variant-bypass" onClick={t9}>{t10}</HHIconButton></HHBox></HHGrid>;
    $[15] = t9;
    $[16] = t11;
  } else {
    t11 = $[16];
  }
  let t12;
  if ($[17] !== t8 || $[18] !== t11) {
    t12 = <HHGrid container={true} sx={t5}>{t8}{t11}</HHGrid>;
    $[17] = t8;
    $[18] = t11;
    $[19] = t12;
  } else {
    t12 = $[19];
  }
  let t13;
  if ($[20] === Symbol.for("react.memo_cache_sentinel")) {
    t13 = <UpcomingEncounterBox />;
    $[20] = t13;
  } else {
    t13 = $[20];
  }
  let t14;
  if ($[21] !== encountersTabTreatment || $[22] !== currentQueueTabIndex || $[23] !== tabsData || $[24] !== setCurrentQueueTabIndex || $[25] !== queueWorkflowsCompleted) {
    t14 = encountersTabTreatment ? <HHTabs defaultTabIndex={currentQueueTabIndex} tabsData={tabsData} onTabChange={setCurrentQueueTabIndex} variant="fullWidth" layoutStyles={{
      height: "100%",
      overflow: "auto"
    }} dividerStyle={{
      position: "sticky",
      top: 0,
      zIndex: 1,
      backgroundColor: "white"
    }} /> : <WorkflowsAccordions queueWorkflowsCompleted={queueWorkflowsCompleted} />;
    $[21] = encountersTabTreatment;
    $[22] = currentQueueTabIndex;
    $[23] = tabsData;
    $[24] = setCurrentQueueTabIndex;
    $[25] = queueWorkflowsCompleted;
    $[26] = t14;
  } else {
    t14 = $[26];
  }
  let t15;
  if ($[27] !== t12 || $[28] !== t14) {
    t15 = <HHBox sx={styles.containerBox} data-testid="queue-panel">{t12}{t13}{t14}</HHBox>;
    $[27] = t12;
    $[28] = t14;
    $[29] = t15;
  } else {
    t15 = $[29];
  }
  return t15;
};