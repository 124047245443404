import { c as _c } from "react-compiler-runtime";
import { HHButton, HHDialog, HHDialogActions, HHDialogContent, HHDialogContentText, HHDialogTitle } from '@hinge-health/react-component-library';
import { useHHCreateLayoutTabContext } from '@hinge-health/react-component-library/dist/components/molecules/hh-layout-panel';
import { useAtom, useAtomValue } from 'jotai';
import { useContext } from 'react';
import { CareCoordinationWorkflowFormCancelled } from '../../../../../../lib/mixpanel/events/workflows';
import { trackEvent } from '../../../../../../utils/mixpanel';
import { CurrentMemberSessionIdContext } from '../../../../components/profile/session-manager';
import { currentAdminAtom, currentUserAtom } from '../../../../state/atoms';
import { currentWorkflowAtom, openCreateWorkflowFormAtom } from '../../state/atoms';
import { WorkflowPayload } from '../../types';
import { CANCEL_CONFIRMATION } from '../constants/create-workflow-forms';
export interface CancelConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onContinue?: () => void;
}
export const CANCEL_CONFIRMATION_DIALOG_TEST_ID = 'cancel-confirmation-dialog';
export const CancelConfirmationDialog = t0 => {
  const $ = _c(20);
  const {
    open,
    onCancel,
    onContinue
  } = t0;
  const currentAdmin = useAtomValue(currentAdminAtom);
  const currentUser = useAtomValue(currentUserAtom);
  const [t1] = useAtom(currentWorkflowAtom);
  const {
    currentWorkflow
  } = t1;
  const openCreateWorkflowForm = useAtomValue(openCreateWorkflowFormAtom);
  const {
    onClose
  } = useHHCreateLayoutTabContext();
  const {
    currentMemberSessionId
  } = useContext(CurrentMemberSessionIdContext);
  let t2;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = <HHDialogTitle>{CANCEL_CONFIRMATION.TITLE}</HHDialogTitle>;
    $[0] = t2;
  } else {
    t2 = $[0];
  }
  let t3;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = <HHDialogContent><HHDialogContentText width={CANCEL_CONFIRMATION.DIALOG_WIDTH}>{CANCEL_CONFIRMATION.CONTENT}</HHDialogContentText></HHDialogContent>;
    $[1] = t3;
  } else {
    t3 = $[1];
  }
  let t4;
  if ($[2] !== onCancel) {
    t4 = <HHButton hhVariant="text" color="secondary" onClick={onCancel}>{CANCEL_CONFIRMATION.CANCEL}</HHButton>;
    $[2] = onCancel;
    $[3] = t4;
  } else {
    t4 = $[3];
  }
  let t5;
  if ($[4] !== currentMemberSessionId || $[5] !== currentAdmin || $[6] !== currentUser || $[7] !== currentWorkflow || $[8] !== openCreateWorkflowForm.openCreateWorkflowType || $[9] !== onContinue || $[10] !== onClose) {
    t5 = () => {
      trackEvent(new CareCoordinationWorkflowFormCancelled({
        sessionId: currentMemberSessionId || "",
        adminCtx: currentAdmin,
        memberCtx: currentUser,
        workflowCtx: (currentWorkflow as WorkflowPayload),
        careCoordinationWorkflowName: openCreateWorkflowForm.openCreateWorkflowType
      }));
      onContinue && onContinue();
      onClose();
    };
    $[4] = currentMemberSessionId;
    $[5] = currentAdmin;
    $[6] = currentUser;
    $[7] = currentWorkflow;
    $[8] = openCreateWorkflowForm.openCreateWorkflowType;
    $[9] = onContinue;
    $[10] = onClose;
    $[11] = t5;
  } else {
    t5 = $[11];
  }
  let t6;
  if ($[12] !== t5) {
    t6 = <HHButton hhVariant="text" color="error" onClick={t5}>{CANCEL_CONFIRMATION.DISCARD}</HHButton>;
    $[12] = t5;
    $[13] = t6;
  } else {
    t6 = $[13];
  }
  let t7;
  if ($[14] !== t4 || $[15] !== t6) {
    t7 = <HHDialogActions>{t4}{t6}</HHDialogActions>;
    $[14] = t4;
    $[15] = t6;
    $[16] = t7;
  } else {
    t7 = $[16];
  }
  let t8;
  if ($[17] !== open || $[18] !== t7) {
    t8 = <HHDialog data-testid={CANCEL_CONFIRMATION_DIALOG_TEST_ID} open={open}>{t2}{t3}{t7}</HHDialog>;
    $[17] = open;
    $[18] = t7;
    $[19] = t8;
  } else {
    t8 = $[19];
  }
  return t8;
};