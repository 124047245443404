import { HHDivider, HHSelect, HHSelectOption, HHStack, HHTextField, useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { CoachWorkflowTypes } from '../../../../../../custom-types';
import { OktaRole } from '../../../../../../types/okta-roles';
import { currentUserAtom } from '../../../../state/atoms';
import { coachEscalationFormStateAtom, createCareCoordinationWorkflowAtom, workflowAssigneeUuidAtom } from '../../state/atoms';
import { COACH_ESCALATION_FORM, COMMON_FORM, MAX_CHARACTERS } from '../constants/create-workflow-forms';
import { validateForm } from '../utils/create-workflow-form-helpers';
import { useCreateCareCoordinationWorkflow } from './hooks/use-create-care-coordination-workflow';
import { WorkflowFormActions } from './workflow-form-actions';
import { WorkflowFormHeader } from './workflow-form-header';
export const COACH_ESCALATION_FORM_TEST_ID = 'coach-escalation-form';
export const COACH_REASON_ESCALATION_OPTIONS: HHSelectOption[] = [{
  value: 'coach_expertise',
  displayName: 'Coach expertise'
}, {
  value: 'member_requested',
  displayName: 'Member requested'
}, {
  value: 'other',
  displayName: 'Other (specify)'
}];
export const CoachEscalationForm = (): JSX.Element => {
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const [CreateCareCoordinationWorkflow] = useCreateCareCoordinationWorkflow();
  const [currentUser] = useAtom(currentUserAtom);
  const [createCareCoordinationWorkflow] = useAtom(createCareCoordinationWorkflowAtom);
  const workflowAssigneeUuid = useAtomValue(workflowAssigneeUuidAtom);
  const [coachEscalationFormState, setCoachEscalationFormState] = useAtom(coachEscalationFormStateAtom);
  const [coachEscalationErrors, setCoachEscalationErrors] = useState({
    reason: '',
    description: '',
    context: ''
  });
  const onCoachEscalationSubmit = useCallback((): void => {
    const {
      isValidated,
      errors: validationErrors
    } = validateForm({
      ...coachEscalationFormState,
      description: coachEscalationFormState.description.trim()
    }, ['context']);
    setCoachEscalationFormState(prev => ({
      ...prev,
      description: coachEscalationFormState.description.trim()
    }));
    setCoachEscalationErrors((validationErrors as Record<keyof typeof coachEscalationErrors, string>));
    if (!isValidated) {
      return;
    }
    CreateCareCoordinationWorkflow({
      workflowType: CoachWorkflowTypes.CoachEscalation,
      workflowInputDto: {
        customFields: coachEscalationFormState,
        patientUuid: (currentUser?.uuid as string)
      },
      memberId: (currentUser?.id as string)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachEscalationFormState, currentUser]);
  const onCoachEscalationFieldChange = (field: keyof typeof coachEscalationFormState, value: string): void => {
    if (['description', 'context'].includes(field) && value.length > MAX_CHARACTERS) {
      setCoachEscalationErrors(prev_0 => ({
        ...prev_0,
        [field]: COMMON_FORM.MAX_CHARACTERS_ERROR
      }));
      return;
    }
    setCoachEscalationFormState(prev_1 => ({
      ...prev_1,
      [field]: value
    }));
    setCoachEscalationErrors(prev_2 => ({
      ...prev_2,
      [field]: ''
    }));
  };
  const roles = hingeHealthAuthState?.accessToken?.claims?.roles;
  return <HHStack data-testid={COACH_ESCALATION_FORM_TEST_ID} paddingY={4} paddingX={8} rowGap={4}>
      <WorkflowFormHeader title={COACH_ESCALATION_FORM.TITLE} assigneeRole={OktaRole.Coach} />
      <HHDivider />
      <HHStack paddingTop={4} rowGap={4}>
        <HHSelect initialSelectValue={coachEscalationFormState.reason} onChange={(e): void => onCoachEscalationFieldChange('reason', (e.target.value as string))} readOnly={createCareCoordinationWorkflow.loading} error={Boolean(coachEscalationErrors.reason)} helperText={coachEscalationErrors.reason} selectOptions={COACH_REASON_ESCALATION_OPTIONS} label={COACH_ESCALATION_FORM.REASON_FOR_ESCALATION} />
        <HHTextField hhVariant="outlined" label={COMMON_FORM.DESCRIPTOIN} InputProps={{
        readOnly: createCareCoordinationWorkflow.loading
      }} error={Boolean(coachEscalationErrors.description)} helperText={coachEscalationErrors.description} multiline maxRows={4} onChange={(e_0): void => onCoachEscalationFieldChange('description', (e_0.target.value as string))} value={coachEscalationFormState.description} />
        <HHTextField hhVariant="outlined" label={COMMON_FORM.ADDITIONAL_CONTEXT} defaultValue={coachEscalationFormState.context} InputProps={{
        readOnly: createCareCoordinationWorkflow.loading
      }} error={Boolean(coachEscalationErrors.context)} helperText={coachEscalationErrors.context || COACH_ESCALATION_FORM.ADDITIONAL_CONTEXT_HELPER} multiline maxRows={4} onChange={(e_1): void => onCoachEscalationFieldChange('context', (e_1.target.value as string))} value={coachEscalationFormState.context} />
      </HHStack>
      <WorkflowFormActions loading={createCareCoordinationWorkflow.loading} onSubmit={onCoachEscalationSubmit} disabled={!workflowAssigneeUuid && !roles?.includes(OktaRole.OnboardingSpecialist)} />
    </HHStack>;
};