import { c as _c } from "react-compiler-runtime";
import { CtpThemeProvider } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { DashboardLayout } from './components/dashboard-layout';
import { DashboardType } from './custom-types';
import { selectedDashboardType } from './jotai/atoms';
export interface DashboardPageProps {
  loading: boolean;
  dashboardType: DashboardType;
}
export const DashboardPage = t0 => {
  const $ = _c(6);
  const {
    loading,
    dashboardType
  } = t0;
  const [, setSelectedDashboardType] = useAtom(selectedDashboardType);
  let t1;
  let t2;
  if ($[0] !== setSelectedDashboardType || $[1] !== dashboardType) {
    t1 = () => {
      setSelectedDashboardType(dashboardType);
    };
    t2 = [setSelectedDashboardType, dashboardType];
    $[0] = setSelectedDashboardType;
    $[1] = dashboardType;
    $[2] = t1;
    $[3] = t2;
  } else {
    t1 = $[2];
    t2 = $[3];
  }
  useEffect(t1, t2);
  let t3;
  if ($[4] !== loading) {
    t3 = <CtpThemeProvider><DashboardLayout loading={loading} /></CtpThemeProvider>;
    $[4] = loading;
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  return t3;
};