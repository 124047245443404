import { c as _c } from "react-compiler-runtime";
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { CURRENT_MEMBER_ID, SESSION_ID, SESSON_START_TIME, TIME_SPENT_ON_MEMBER } from '../../../../constants/strings/member';
import { getLocalStorageItem } from '../../../../utils/local-storage-utils';

// Generate a unique session ID
function generateSessionId(): string {
  return 'sess-' + uuidV4();
}
export const CurrentMemberSessionIdContext = createContext<{
  startSession: () => void;
  endSession: () => void;
  currentMemberSessionId: string | null;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  startSession: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  endSession: () => {},
  currentMemberSessionId: null
});
export const CurrentMemberSessionProvider = t0 => {
  const $ = _c(9);
  const {
    children
  } = t0;
  const [currentMemberSessionId, setCurrentMemberSessionId] = useState(null);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => {
      const sessionId = generateSessionId();
      const startTime = new Date().getTime();
      setCurrentMemberSessionId(sessionId);
      localStorage.setItem(SESSION_ID, sessionId);
      localStorage.setItem(SESSON_START_TIME, startTime.toString());
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const startSession = t1;
  let t2;
  if ($[1] !== currentMemberSessionId) {
    t2 = () => {
      const startTime_0 = getLocalStorageItem(SESSON_START_TIME);
      if (currentMemberSessionId && startTime_0) {
        const endTime = new Date().getTime();
        const timeSpent = (endTime - parseInt(startTime_0, 10)) / 1000;
        setCurrentMemberSessionId(null);
        localStorage.setItem(TIME_SPENT_ON_MEMBER, timeSpent.toString());
        localStorage.removeItem(SESSION_ID);
        localStorage.removeItem(SESSON_START_TIME);
        localStorage.removeItem(CURRENT_MEMBER_ID);
      }
    };
    $[1] = currentMemberSessionId;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  const endSession = t2;
  let t3;
  let t4;
  if ($[3] !== endSession || $[4] !== currentMemberSessionId) {
    t4 = {
      startSession,
      endSession,
      currentMemberSessionId
    };
    $[3] = endSession;
    $[4] = currentMemberSessionId;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  t3 = t4;
  const contextValue = t3;
  let t5;
  if ($[6] !== contextValue || $[7] !== children) {
    t5 = <CurrentMemberSessionIdContext.Provider value={contextValue}>{children}</CurrentMemberSessionIdContext.Provider>;
    $[6] = contextValue;
    $[7] = children;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  return t5;
};
export const useCurrentMemberSessionId = () => {
  return useContext(CurrentMemberSessionIdContext);
};