import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { LoginErrorStatus } from '../types/types';
export const defaultErrorHandler = (errorMessage: string): void => {
  Sentry.captureMessage(errorMessage);
};
export const authErrorHandler = (error: ApolloError, callback?: (error: LoginErrorStatus) => void): void => {
  if (error.message === 'Failed to fetch') {
    if (callback) {
      callback(LoginErrorStatus.SERVER_ERROR);
    }
  } else {
    error?.graphQLErrors?.forEach(error => {
      const code = error?.extensions?.code;
      if (code === 'UNAUTHENTICATED' || code === 'FORBIDDEN') {
        if (callback) {
          callback(LoginErrorStatus.AUTHENTICATION_ERROR);
        }
      }
    });
  }
};