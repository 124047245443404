import { c as _c } from "react-compiler-runtime";
import { useEffect, useRef } from 'react';
export const useInterval = (callback, delay) => {
  const $ = _c(10);
  const savedInterval = useRef();
  const savedCallback = useRef(callback);
  let t0;
  let t1;
  if ($[0] !== callback) {
    t0 = () => {
      savedCallback.current = callback;
    };
    t1 = [callback];
    $[0] = callback;
    $[1] = t0;
    $[2] = t1;
  } else {
    t0 = $[1];
    t1 = $[2];
  }
  useEffect(t0, t1);
  let t2;
  let t3;
  if ($[3] !== delay || $[4] !== callback) {
    t2 = () => {
      if (delay !== 0 && !savedInterval.current) {
        savedInterval.current = ((setInterval(callback, delay) as unknown) as number);
      }
    };
    t3 = [delay, callback];
    $[3] = delay;
    $[4] = callback;
    $[5] = t2;
    $[6] = t3;
  } else {
    t2 = $[5];
    t3 = $[6];
  }
  useEffect(t2, t3);
  let t4;
  if ($[7] !== savedCallback) {
    t4 = () => {
      savedCallback.current();
      return () => clearInterval(savedInterval.current);
    };
    $[7] = savedCallback;
    $[8] = t4;
  } else {
    t4 = $[8];
  }
  let t5;
  if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = [];
    $[9] = t5;
  } else {
    t5 = $[9];
  }
  useEffect(t4, t5);
};