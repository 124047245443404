import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import relativeTime from 'dayjs/plugin/relativeTime';
import { CARE_PLAN_TIME_STRINGS } from '../constants/strings/day-js-locales';
import { CarePlanGroup } from '../custom-types';
import { Maybe } from '../types';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
export const getCarePlanExpirationStatuses = (expirationDate: Maybe<string> | undefined, type: string): Record<string, string | boolean> => {
  const tenDays = dayjs().add(10, 'days');
  const isAfterExpiration = dayjs().isAfter(expirationDate, 'hour');
  const isSameOrAfterExpirationDate = dayjs().isSameOrAfter(expirationDate, 'day');
  const isWithinTenDays = dayjs(expirationDate).isSameOrBefore(tenDays, 'day') && !isAfterExpiration;
  let timeFromExpiration = dayjs(expirationDate).fromNow();
  if (dayjs(expirationDate).isTomorrow()) {
    timeFromExpiration = CARE_PLAN_TIME_STRINGS.IN_ONE_DAY;
  } else if (dayjs(expirationDate).isToday()) {
    timeFromExpiration = CARE_PLAN_TIME_STRINGS.TODAY;
  }
  const canShowCarePlans = isWithinTenDays && type === CarePlanGroup.Expiring || isSameOrAfterExpirationDate && type === CarePlanGroup.Closing;
  return {
    isWithinTenDaysOfExpiration: isWithinTenDays,
    isSameOrAfterExpirationDate,
    timeFromExpiration,
    isAfterExpiration,
    canShowCarePlans
  };
};