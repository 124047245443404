import { useAtom } from 'jotai';
import { useCallback, useContext } from 'react';
import { CareCoordinationWorkflowFormSubmitted } from '../../../../../../../lib/mixpanel/events/workflows';
import { trackEvent } from '../../../../../../../utils/mixpanel';
import { CurrentMemberSessionIdContext } from '../../../../../components/profile/session-manager';
import { CREATE_CARE_COORDINATION_WORKFLOW_ERROR } from '../../../../../constants/strings/member';
import { currentAdminAtom, currentUserAtom } from '../../../../../state/atoms';
import { createCareCoordinationWorkflowAtom, currentWorkflowAtom, workflowAssigneeUuidAtom } from '../../../state/atoms';
import { WorkflowPayload } from '../../../types';
import { CreateCareCoordinationWorkflowInputDto, useCreateCareCoordinationWorkflowMutation } from '../queries/create-care-coordination-workflow.mutation';
const WAIT_TIME_FOR_SSE = 1000 * 60;
interface CreateCareCoordinationWorkflowProps {
  workflowType: string;
  workflowInputDto: CreateCareCoordinationWorkflowInputDto;
  memberId: string;
}
export const useCreateCareCoordinationWorkflow = (): [({
  workflowType,
  workflowInputDto,
  memberId
}: CreateCareCoordinationWorkflowProps) => void] => {
  const [CreateCareCoordinationWorkflow] = useCreateCareCoordinationWorkflowMutation();
  const [, setCreateCareCoordinationWorkflow] = useAtom(createCareCoordinationWorkflowAtom);
  const {
    currentMemberSessionId
  } = useContext(CurrentMemberSessionIdContext);
  const [workflowAssigneeUuid] = useAtom(workflowAssigneeUuidAtom);
  const [currentAdmin] = useAtom(currentAdminAtom);
  const [{
    currentWorkflow
  }] = useAtom(currentWorkflowAtom);
  const [currentUser] = useAtom(currentUserAtom);
  const resetLoadingStateAfterDelay = (): void => {
    setTimeout(() => {
      setCreateCareCoordinationWorkflow({
        error: false,
        loading: false,
        success: false,
        errorMessage: undefined,
        memberId: undefined,
        workflowId: undefined,
        workflowType: undefined
      });
    }, WAIT_TIME_FOR_SSE);
  };
  const createAsyncWorkflow = useCallback(({
    workflowType,
    workflowInputDto,
    memberId
  }: {
    workflowType: string;
    memberId: string;
    workflowInputDto: CreateCareCoordinationWorkflowInputDto;
  }) => {
    trackEvent(new CareCoordinationWorkflowFormSubmitted({
      adminCtx: currentAdmin,
      workflowCtx: (currentWorkflow as WorkflowPayload),
      memberCtx: currentUser,
      careCoordinationWorkflowName: workflowType,
      sessionId: currentMemberSessionId || ''
    }));
    setCreateCareCoordinationWorkflow(prev => ({
      ...prev,
      loading: true,
      memberId
    }));
    CreateCareCoordinationWorkflow({
      variables: {
        createWorkflowInputDto: {
          ...workflowInputDto,
          createdByAdminUuid: currentAdmin?.uuid || '',
          ...(workflowAssigneeUuid && {
            adminUuids: [workflowAssigneeUuid]
          })
        },
        workflowType
      },
      onError: (): void => {
        setCreateCareCoordinationWorkflow({
          error: true,
          loading: false,
          success: false,
          memberId: undefined,
          workflowId: undefined,
          workflowType: undefined,
          errorMessage: CREATE_CARE_COORDINATION_WORKFLOW_ERROR
        });
      }
    });
    resetLoadingStateAfterDelay();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [workflowAssigneeUuid, currentAdmin]);
  return [createAsyncWorkflow];
};