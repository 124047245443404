import { PriorityStates } from '@hinge-health/react-component-library';
import { HouseCallTaskType } from './constants/strings/house-calls';
import { WorkflowPayload } from './types';
export type AcuteDecoratedWorkflow = WorkflowPayload & {
  dueState?: PriorityStates;
};
export enum AcuteWorkflowTypes {
  UnreadMessages = 'pt-unread-messages',
  VideoVisitsWorkflowGroup = 'video-visits-workflow-group',
  HealthLog = 'acute-health-log',
  PainUptick = 'acute-pain-uptick',
  CarePlanGroup = 'acute-care-plan-group',
}
export type AllAcuteWorkflowTypes = VideoVisitsWorkflows | VideoVisitsWithoutMedicalHistory | HouseCallsWorkflows | AcuteWorkflowTypes | CarePlanGroup;
export const encounterWorkflows: AcuteWorkflowTypes[] = [AcuteWorkflowTypes.PainUptick, AcuteWorkflowTypes.HealthLog];
export enum CarePlanGroup {
  Expiring = 'acute-expiring-care-plan',
  Closing = 'acute-care-plan-to-close',
}
export enum HouseCallsGroupType {
  UpcomingHouseCall = 'upcoming-house-call',
  OpenHouseCall = 'open-house-call',
}

// All of the In-Person workflow types
export enum HouseCallsWorkflows {
  HouseCall = 'house-call',
}
export enum VideoVisitsWorkflows {
  Acute = 'acute-video-visits',
  Chronic = 'chronic-video-visit',
  Surgery = 'surgery-video-visit',
  Coach = 'coach-video-visit',
}
export enum VideoVisitsWithoutMedicalHistory {
  FifteenMinute = '15-min-video-visits',
}
export type AllAcuteGroupTypes = AcuteGroupType | CarePlanGroup | HouseCallsGroupType;
export const ACUTE_WORKFLOW_LIST = [AcuteWorkflowTypes.VideoVisitsWorkflowGroup, AcuteWorkflowTypes.HealthLog, AcuteWorkflowTypes.PainUptick, AcuteWorkflowTypes.CarePlanGroup];
export const ALL_ACUTE_WORKFLOWS: string[] = [HouseCallsWorkflows.HouseCall, VideoVisitsWorkflows.Acute, VideoVisitsWorkflows.Chronic, VideoVisitsWorkflows.Surgery, VideoVisitsWithoutMedicalHistory.FifteenMinute, AcuteWorkflowTypes.HealthLog, AcuteWorkflowTypes.PainUptick, CarePlanGroup.Expiring, CarePlanGroup.Closing];
export const CARE_PLAN_WORKFLOWS = [CarePlanGroup.Expiring, CarePlanGroup.Closing];
export const VIDEO_VISIT_WORKFLOWS = [VideoVisitsWorkflows.Acute, VideoVisitsWorkflows.Chronic, VideoVisitsWorkflows.Surgery, VideoVisitsWithoutMedicalHistory.FifteenMinute, VideoVisitsWorkflows.Coach];
export enum AcuteGroupType {
  Upcoming = 'upcoming',
  Incomplete = 'incomplete',
  Title = 'title',
  Expiring = 'expiring',
  Closing = 'closing',
}
export enum CommonAcuteState {
  InactiveMember = 'inactive-member',
  AcuteComplete = 'acute-complete',
  VideoVisitComplete = 'complete',
  Dismiss = 'dismiss',
  AwaitingNew = 'awaiting-new',
}
export const PT_WORKFLOW_COMPLETED_STATES = [CommonAcuteState.AcuteComplete, CommonAcuteState.VideoVisitComplete, CommonAcuteState.Dismiss];
export enum VideoVisitsState {
  CancelVideoVisit = 'cancel-video-visit',
  CompleteVideoVisit = 'complete-video-visit',
  PerformVideoVisitFollowup = 'perform-video-visit-followup',
  PerformFollowUpVideoVisitFollowup = 'perform-followup-video-visit-followup',
}
export enum VideoVisitsActiveState {
  CompleteVideoVisit = 'complete-video-visit',
  PerformVideoVisitFollowup = 'perform-video-visit-followup',
  PerformFollowUpVideoVisitFollowup = 'perform-followup-video-visit-followup',
}
export const videoVisitFollowupStates: VideoVisitsState[] = [VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup];
export enum PrimaryCarePhysicianInfoState {
  ObtainMemberPcpInfo = 'obtain-member-pcp-info',
  InformPcp = 'inform-pcp',
}
export enum PainUptickState {
  PainUptick = 'pain-uptick',
}
export enum HealthLogState {
  NewHealthLogNote = 'new-health-log-note',
}
export enum ExpiringCarePlanState {
  ExpiringStart = 'expiring-start',
  ExpiringFull = 'expiring-full',
  ExpiringRestricted = 'expiring-restricted',
}
export enum CarePlanToCloseState {
  CarePlanNeedsClosing = 'care-plan-needs-closing',
}
export const AcuteState = {
  ...VideoVisitsActiveState,
  ...PainUptickState,
  ...HealthLogState,
  ...ExpiringCarePlanState,
  ...CarePlanToCloseState
};
export const ACUTE_STATES_LIST = Object.values(AcuteState).reduce((acc, curr) => `${acc.length ? `${acc},` : ''}${curr}`, '');
export enum VideoVisitStatus {
  Evaluation = 'Evaluation',
  FirstFollowup = '1st Follow up',
  SecondFollowup = '2nd Follow up',
  ThirdFollowup = '3rd Follow up',
  FourthFollowup = '4th Follow up',
  FifthFollowup = '5th Follow up',
  SixthFollowup = '6th Follow up',
}
export type AcuteState = CommonAcuteState | HealthLogState | PainUptickState | PrimaryCarePhysicianInfoState | VideoVisitsState;
export interface EncounterLinkDataType {
  name: string;
  url: string;
  showInIframe: boolean;
  className?: string;
}
export interface EncounterButtonDataType {
  name: string;
  className?: string;
  endIcon?: React.ReactNode;
}
export enum VideoVisitsTasksCategory {
  Asap = 'ASAP',
  WithinDay = 'Within A Day',
}
export enum HouseCallTasksCategory {
  CompleteVisit = 'Complete visit',
  CloseVisit = 'Close visit',
}
export enum AcuteExpiringTasksCategory {
  ConnectWithMember = 'Connect with member',
  ConnectWithProvider = 'Connect with provider',
  ConnectCarePlanExtension = 'Connect care plan extension',
}
export enum VideoVisitsTaskType {
  SendPostEvalEmail = 'send-post-eval-email',
  SendPostEvalInAppMessage = 'send-post-eval-in-app-message',
  CreateWeek1ExercisePlan = 'create-week-1-exercise-plan',
  RequestEquipment = 'request-equipment',
  FollowupRequestEquipment = 'followup-request-equipment',
  Complete6WeekExercisePlan = 'complete-6-week-exercise-plan',
  CompleteEvalNote = 'complete-eval-note',
  StartCarePlan = 'start-care-plan',
  UploadNoteToPhiDrive = 'upload-note-to-phi-drive',
  UpdateExercisePlan = 'update-exercise-plan',
  SendPostFollowUpEmail = 'send-post-follow-up-email',
  CompleteFollowUpNote = 'complete-follow-up-note',
  LogPtVisit = 'log-pt-visit',
  SurgeryUpdateExercisePlan = 'surgery-update-exercise-plan',
  CreateCarePlan = 'create-care-plan',
  CompleteDischargeNote = 'complete-discharge-note',
  ChronicUpdateExercisePlan = 'chronic-update-exercise-plan',
}
export enum AcuteExpiringTaskType {
  SendPlanExtensionEmail = 'send-plan-extension-email',
  DownloadEvaluation = 'download-evaluation',
  FaxEvaluation = 'fax-evaluation',
  ConfirmProviderInformed = 'confirm-provider-informed',
  ConfirmPocRxReceived = 'confirm-poc-rx-received',
  UploadSignedPocRx = 'upload-signed-poc-rx',
  SendPlanExtensionEmailRestricted = 'send-plan-extension-email-restricted',
  ConfirmPocRxReceivedRestricted = 'confirm-poc-rx-received-restricted',
  UploadSignedPocRxRestricted = 'upload-signed-poc-rx-restricted',
}
export enum CarePlanConnectWithMemberTaskType {
  ConnectWithMember = 'connect-with-member',
  RestrictedConnectWithMember = 'restricted-connect-with-member',
}
export enum VideoVisitsTab {
  Prepare = 'Prepare',
  Perform = 'Perform',
  Post = 'Post',
}
export enum AcuteExpiringTab {
  Tasks = 'Tasks',
  Resources = 'Resources',
}
export const VIDEO_VISITS_OPTIONAL_TASKS = [VideoVisitsTaskType.RequestEquipment, VideoVisitsTaskType.FollowupRequestEquipment, VideoVisitsTaskType.UploadNoteToPhiDrive, VideoVisitsTaskType.SurgeryUpdateExercisePlan, VideoVisitsTaskType.ChronicUpdateExercisePlan, VideoVisitsTaskType.CompleteDischargeNote, VideoVisitsTaskType.UpdateExercisePlan];
export const HouseCallTasksByCategory: Partial<Record<HouseCallTaskType, HouseCallTasksCategory>> = {
  [HouseCallTaskType.CompleteSoapNote]: HouseCallTasksCategory.CloseVisit,
  [HouseCallTaskType.UpdateCarePlan]: HouseCallTasksCategory.CloseVisit,
  [HouseCallTaskType.SendSummaryEmail]: HouseCallTasksCategory.CloseVisit,
  [HouseCallTaskType.SubmitTravelReimbursement]: HouseCallTasksCategory.CloseVisit,
  [HouseCallTaskType.UpdateExercisePlan]: HouseCallTasksCategory.CloseVisit,
  [HouseCallTaskType.UploadNoteTo]: HouseCallTasksCategory.CloseVisit,
  [HouseCallTaskType.CompleteDischargeNote]: HouseCallTasksCategory.CloseVisit
};
export const HOUSE_CALL_OPTIONAL_TASKS = [HouseCallTaskType.UpdateExercisePlan, HouseCallTaskType.UploadNoteTo, HouseCallTaskType.CompleteDischargeNote];
export const VideoVisitsTasksByCategory: Partial<Record<VideoVisitsTaskType, VideoVisitsTasksCategory>> = {
  [VideoVisitsTaskType.SendPostEvalEmail]: VideoVisitsTasksCategory.Asap,
  [VideoVisitsTaskType.CreateWeek1ExercisePlan]: VideoVisitsTasksCategory.Asap,
  [VideoVisitsTaskType.RequestEquipment]: VideoVisitsTasksCategory.Asap,
  [VideoVisitsTaskType.SurgeryUpdateExercisePlan]: VideoVisitsTasksCategory.Asap,
  [VideoVisitsTaskType.ChronicUpdateExercisePlan]: VideoVisitsTasksCategory.Asap,
  [VideoVisitsTaskType.UpdateExercisePlan]: VideoVisitsTasksCategory.Asap,
  [VideoVisitsTaskType.Complete6WeekExercisePlan]: VideoVisitsTasksCategory.WithinDay,
  [VideoVisitsTaskType.CompleteEvalNote]: VideoVisitsTasksCategory.WithinDay,
  [VideoVisitsTaskType.StartCarePlan]: VideoVisitsTasksCategory.WithinDay,
  [VideoVisitsTaskType.CreateCarePlan]: VideoVisitsTasksCategory.WithinDay,
  [VideoVisitsTaskType.UploadNoteToPhiDrive]: VideoVisitsTasksCategory.WithinDay,
  [VideoVisitsTaskType.CompleteDischargeNote]: VideoVisitsTasksCategory.WithinDay
};
export const FollowupVideoVisitsTasksByCategory: Partial<Record<VideoVisitsTaskType, VideoVisitsTasksCategory>> = {
  [VideoVisitsTaskType.UpdateExercisePlan]: VideoVisitsTasksCategory.Asap,
  [VideoVisitsTaskType.SendPostFollowUpEmail]: VideoVisitsTasksCategory.Asap,
  [VideoVisitsTaskType.FollowupRequestEquipment]: VideoVisitsTasksCategory.Asap,
  [VideoVisitsTaskType.CompleteFollowUpNote]: VideoVisitsTasksCategory.WithinDay,
  [VideoVisitsTaskType.LogPtVisit]: VideoVisitsTasksCategory.WithinDay
};
export const AcuteExpiringTasksByCategory: Partial<Record<AcuteExpiringTaskType, AcuteExpiringTasksCategory>> = {
  [AcuteExpiringTaskType.SendPlanExtensionEmail]: AcuteExpiringTasksCategory.ConnectWithMember,
  [AcuteExpiringTaskType.DownloadEvaluation]: AcuteExpiringTasksCategory.ConnectWithProvider,
  [AcuteExpiringTaskType.FaxEvaluation]: AcuteExpiringTasksCategory.ConnectWithProvider,
  [AcuteExpiringTaskType.ConfirmProviderInformed]: AcuteExpiringTasksCategory.ConnectWithProvider,
  [AcuteExpiringTaskType.ConfirmPocRxReceived]: AcuteExpiringTasksCategory.ConnectCarePlanExtension,
  [AcuteExpiringTaskType.UploadSignedPocRx]: AcuteExpiringTasksCategory.ConnectCarePlanExtension,
  [AcuteExpiringTaskType.SendPlanExtensionEmailRestricted]: AcuteExpiringTasksCategory.ConnectWithMember,
  [AcuteExpiringTaskType.ConfirmPocRxReceivedRestricted]: AcuteExpiringTasksCategory.ConnectCarePlanExtension,
  [AcuteExpiringTaskType.UploadSignedPocRxRestricted]: AcuteExpiringTasksCategory.ConnectCarePlanExtension
};
export enum ClosingActions {
  EmailSentToMember,
  DismissNoReply,
}
export const CLOSING_ACTIONS_LIST = [ClosingActions.EmailSentToMember, ClosingActions.DismissNoReply];
export enum ExpiringCarePlanActions {
  CarePlanExtended,
  MarkToClosePlan,
}
export const EXPIRING_CARE_PLAN_ACTIONS_LIST = [ExpiringCarePlanActions.CarePlanExtended, ExpiringCarePlanActions.MarkToClosePlan];
export enum CarePlanToCloseTaskType {
  DischargeAndClosePlan = 'discharge-and-close-plan',
  CompleteDischargeNote = 'complete-discharge-note',
}
export enum UnreadMessagesActions {
  RequiredReply,
  DidNotRequireReply,
}