import { HHDivider, HHSelect, HHStack, HHTextField } from '@hinge-health/react-component-library';
import Autocomplete from '@mui/material/Autocomplete';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PtWorkflowTypes } from '../../../../../../custom-types';
import { OktaRole } from '../../../../../../types/okta-roles';
import { currentUserAtom } from '../../../../state/atoms';
import { createCareCoordinationWorkflowAtom, etModificationFormStateAtom, workflowAssigneeUuidAtom } from '../../state/atoms';
import { COMMON_FORM, ET_MODIFICATION_FORM, MAX_CHARACTERS } from '../constants/create-workflow-forms';
import { AutocompleteOption, getAutocompleteOptions, getSelectOptions, validateForm } from '../utils/create-workflow-form-helpers';
import { useCreateCareCoordinationWorkflow } from './hooks/use-create-care-coordination-workflow';
import { useGetAllBodyParts } from './queries/get-all-body-parts.query';
import { WorkflowFormActions } from './workflow-form-actions';
import { WorkflowFormHeader } from './workflow-form-header';
export const ET_MODIFICATION_FORM_TEST_ID = 'et-modification-form';
export const BODY_PART_AUTOCOMPLETE_TEST_ID = 'body-part-autocomplete';
export const ETModificationForm = (): JSX.Element => {
  const [GetAllBodyParts, {
    data: bodyPartsData,
    loading: bodyPartsDataLoading
  }] = useGetAllBodyParts();
  const [CreateCareCoordinationWorkflow] = useCreateCareCoordinationWorkflow();
  const [currentUser] = useAtom(currentUserAtom);
  const [createCareCoordinationWorkflow] = useAtom(createCareCoordinationWorkflowAtom);
  const [etModificationFormState, setETModificationFormState] = useAtom(etModificationFormStateAtom);
  const workflowAssigneeUuid = useAtomValue(workflowAssigneeUuidAtom);
  const [etModificationErrors, setETModificationErrors] = useState({
    bodyPart: '',
    side: '',
    description: '',
    context: ''
  });
  const [selectedBodyPartOption, setSelectedBodyPartOption] = useState<AutocompleteOption | undefined>();
  const bodyPartOptions = useMemo(() => getAutocompleteOptions(bodyPartsData?.getAllBodyParts, true), [bodyPartsData]);
  useEffect(() => {
    if (bodyPartOptions.length > 0 && etModificationFormState.bodyPart) {
      const bodyPartOption = bodyPartOptions.find(option => option.value === etModificationFormState.bodyPart);
      setSelectedBodyPartOption(bodyPartOption);
    }
  }, [bodyPartOptions, etModificationFormState.bodyPart]);
  useEffect(() => {
    GetAllBodyParts({
      fetchPolicy: 'cache-and-network'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onETModificationSubmit = useCallback((): void => {
    const {
      isValidated,
      errors: validationErrors
    } = validateForm({
      ...etModificationFormState,
      description: etModificationFormState.description.trim()
    }, ['context']);
    setETModificationFormState(prev => ({
      ...prev,
      description: etModificationFormState.description.trim()
    }));
    setETModificationErrors((validationErrors as Record<keyof typeof etModificationErrors, string>));
    if (!isValidated) {
      return;
    }
    CreateCareCoordinationWorkflow({
      workflowType: PtWorkflowTypes.EtModification,
      workflowInputDto: {
        customFields: etModificationFormState,
        patientUuid: (currentUser?.uuid as string)
      },
      memberId: (currentUser?.id as string)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [etModificationFormState, currentUser]);
  const onETModificationFieldChange = (field: keyof typeof etModificationFormState, value: string): void => {
    if (['description', 'context'].includes(field) && value.length > MAX_CHARACTERS) {
      setETModificationErrors(prev_0 => ({
        ...prev_0,
        [field]: COMMON_FORM.MAX_CHARACTERS_ERROR
      }));
      return;
    }
    setETModificationFormState(prev_1 => ({
      ...prev_1,
      [field]: value
    }));
    setETModificationErrors(prev_2 => ({
      ...prev_2,
      [field]: ''
    }));
  };
  return <HHStack data-testid={ET_MODIFICATION_FORM_TEST_ID} paddingY={4} paddingX={8} rowGap={4}>
      <WorkflowFormHeader title={ET_MODIFICATION_FORM.TITLE} assigneeRole={OktaRole.PhysicalTherapist} />
      <HHDivider />
      <HHStack paddingTop={4} rowGap={4}>
        <Autocomplete key={selectedBodyPartOption?.value} data-testid={BODY_PART_AUTOCOMPLETE_TEST_ID} options={bodyPartOptions} loading={bodyPartsDataLoading} readOnly={createCareCoordinationWorkflow.loading} disableClearable onChange={(_e, option_0): void => {
        onETModificationFieldChange('bodyPart', (option_0?.value as string));
        setSelectedBodyPartOption(option_0);
      }} isOptionEqualToValue={(option_1, value_0): boolean => option_1.value === value_0.value} defaultValue={selectedBodyPartOption} renderInput={(params): React.ReactNode => <HHTextField hhVariant="outlined" {...params} label={ET_MODIFICATION_FORM.BODY_PART} error={Boolean(etModificationErrors.bodyPart)} helperText={etModificationErrors.bodyPart} />} />
        <HHSelect selectOptions={getSelectOptions(ET_MODIFICATION_FORM.SIDE_OF_BODY_OPTIONS)} initialSelectValue={etModificationFormState.side} readOnly={createCareCoordinationWorkflow.loading} label={ET_MODIFICATION_FORM.SIDE_OF_BODY} error={Boolean(etModificationErrors.side)} onChange={(e): void => onETModificationFieldChange('side', (e.target.value as string))} helperText={etModificationErrors.side} />
        <HHTextField hhVariant="outlined" label={COMMON_FORM.DESCRIPTOIN} InputProps={{
        readOnly: createCareCoordinationWorkflow.loading
      }} error={Boolean(etModificationErrors.description)} multiline maxRows={4} onChange={(e_0): void => onETModificationFieldChange('description', (e_0.target.value as string))} helperText={etModificationErrors.description} value={etModificationFormState.description} />
        <HHTextField hhVariant="outlined" label={COMMON_FORM.ADDITIONAL_CONTEXT} defaultValue={etModificationFormState.context} InputProps={{
        readOnly: createCareCoordinationWorkflow.loading
      }} error={Boolean(etModificationErrors.context)} helperText={etModificationErrors.context || ET_MODIFICATION_FORM.ADDITIONAL_CONTEXT_HELPER} multiline maxRows={4} onChange={(e_1): void => onETModificationFieldChange('context', (e_1.target.value as string))} value={etModificationFormState.context} />
      </HHStack>
      <WorkflowFormActions loading={createCareCoordinationWorkflow.loading} onSubmit={onETModificationSubmit} disabled={!workflowAssigneeUuid} />
    </HHStack>;
};