import { c as _c } from "react-compiler-runtime";
import { HHBox, HHTableCell, HHTypography } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { IamMessageSource } from '../../../../../../custom-types';
import { State } from '../../../../../../test-utils/split';
import { SPLIT_TREATMENTS as PT_SPLIT_TREATEMENTS } from '../../../../../acute-physical-therapy/constants/splits';
import { HLN_UNREAD, HL_PAIN_UPTICK, MESSAGE } from '../../../../../member-360/modules/panel-layouts/constants/panel';
import { useShellContext } from '../../../../contexts/shell.context';
import { WorkflowPayload, WorkflowTypeCustomFieldsActionPlansFragment } from '../../../../types';
import { isSignificantPainChange } from '../../../../utils/get-workflow-subtitle';
import { styles } from './style';
interface WorkflowTypeCellProps {
  workflow: WorkflowTypeCustomFieldsActionPlansFragment;
}
const WorkflowUnreadTypeCell = t0 => {
  const $ = _c(8);
  const {
    workflow
  } = t0;
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const modularIamPainInsightsTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.MODULAR_IAM_PAIN_INSIGHTS, {
    adminUuid: adminProfile.uuid || "*"
  }) === State.On;
  let t1;
  if ($[0] !== workflow || $[1] !== modularIamPainInsightsTreatment) {
    t1 = () => {
      if (workflow?.customFields?.source === IamMessageSource.HLN) {
        return modularIamPainInsightsTreatment ? isSignificantPainChange((workflow as WorkflowPayload)) ? HL_PAIN_UPTICK : HLN_UNREAD : HLN_UNREAD;
      }
      return MESSAGE;
    };
    $[0] = workflow;
    $[1] = modularIamPainInsightsTreatment;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  const getWorkflowTypeLabel = t1;
  let t2;
  if ($[3] !== getWorkflowTypeLabel) {
    t2 = getWorkflowTypeLabel();
    $[3] = getWorkflowTypeLabel;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  const workflowTypeLabel = t2;
  let t3;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      display: "flex",
      flexDirection: "column"
    };
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  let t4;
  if ($[6] !== workflowTypeLabel) {
    t4 = <HHTableCell sx={styles.cell}><HHBox sx={t3} data-testid="workflow-unread-type-cell"><HHTypography hhVariant="body">{workflowTypeLabel}</HHTypography></HHBox></HHTableCell>;
    $[6] = workflowTypeLabel;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  return t4;
};
export default WorkflowUnreadTypeCell;