import { HHSelectOption } from '@hinge-health/react-component-library';
import { COMMON_FORM, MAX_CHARACTERS } from '../constants/create-workflow-forms';
export const getSelectOptions = (values: string[]): HHSelectOption[] => values.map(value => ({
  value,
  displayName: value
}));
export const getInitials = (name: string): string => {
  const names = name.split(' ');
  return `${names[0][0]}${names?.[1]?.[0]}`;
};
export type AutocompleteOption = {
  value: string;
  label: string;
};
export const getAutocompleteOptions = (values?: string[], includeOtherOption = false): AutocompleteOption[] => {
  const tempValues = values && values.length ? [...values] : [];
  const options: AutocompleteOption[] = [];
  options.push(...tempValues.map(value => ({
    value,
    label: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  })));
  if (values && values.length && includeOtherOption) {
    options.push({
      value: 'other',
      label: 'Other (specify)'
    });
  }
  return options;
};
export const getUniquIndex = (key: string, index: number): string => `${key.toLowerCase().split(' ').join('')}-${index}`;
export const validateForm = <T extends Record<string, string | number>,>(formState: T, optionalFields: Array<keyof typeof formState>): {
  isValidated: boolean;
  errors: Partial<Record<keyof typeof formState, string>>;
} => {
  const errors: Partial<Record<keyof typeof formState, string>> = {};
  Object.keys(formState).forEach(key => {
    if (!formState[key] && !optionalFields.includes(key)) {
      errors[(key as keyof typeof formState)] = COMMON_FORM.REQUIRED_FIELD_ERROR;
    } else {
      errors[(key as keyof typeof formState)] = '';
      if ((key === 'description' || key === 'context') && formState[key].toString().length > MAX_CHARACTERS) {
        errors[(key as keyof typeof formState)] = COMMON_FORM.MAX_CHARACTERS_ERROR;
      }
    }
  });
  return {
    isValidated: Object.values(errors).every(error => !error),
    errors
  };
};