import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox, HHTypography } from '@hinge-health/react-component-library';
import { useAtomValue, useSetAtom, WritableAtom } from 'jotai';
import { NO_ENCOUNTERS } from '../../constants/strings/workflows-queue';
import { QueueCategoryType } from '../../custom-types';
import { encounterCategoryAtoms, FetchMoreWorkflowsAtom, queueComplete } from '../../state/queue-atoms';
import { ConditionalQueueAccordion } from '../workflows-queue-components/conditional-queue-accordion';
import { useWorkflowsCompleted } from './hooks/use-workflows-completed';
const styles = {
  accordionsBox: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden'
  },
  completedText: {
    marginTop: 16
  }
};
export const EncountersTab = () => {
  const $ = _c(3);
  const encounterCategories = (useAtomValue(encounterCategoryAtoms) as WritableAtom<FetchMoreWorkflowsAtom, unknown[], void>[]);
  const setQueueComplete = useSetAtom(queueComplete);
  const {
    encounterWorkflowsCompleted
  } = useWorkflowsCompleted(setQueueComplete);
  let t0;
  if ($[0] !== encounterWorkflowsCompleted || $[1] !== encounterCategories) {
    t0 = <HHBox>{encounterWorkflowsCompleted ? <HHBox><HHTypography hhVariant="h2" layoutStyles={styles.completedText} align="center" color={ctpTokens.palette.neutral[40]}>{NO_ENCOUNTERS}</HHTypography></HHBox> : <HHBox sx={styles.accordionsBox} data-testid="conditional-queue-accordion">{encounterCategories.map((workflowCategoryAtom, index) => <ConditionalQueueAccordion workflowCategoryAtom={workflowCategoryAtom} type={QueueCategoryType.Encounter} key={index} />)}</HHBox>}</HHBox>;
    $[0] = encounterWorkflowsCompleted;
    $[1] = encounterCategories;
    $[2] = t0;
  } else {
    t0 = $[2];
  }
  return t0;
};