import { HHDivider, HHFormControlLabel, HHLink, HHRadio, HHRadioGroup, HHStack, HHTextField, HHTypography } from '@hinge-health/react-component-library';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { PtWorkflowTypes } from '../../../../../../custom-types';
import { OktaRole } from '../../../../../../types/okta-roles';
import { currentUserAtom } from '../../../../state/atoms';
import { createCareCoordinationWorkflowAtom, medicalEmergencyFormStateAtom, workflowAssigneeUuidAtom } from '../../state/atoms';
import { COMMON_FORM, MAX_CHARACTERS, MEDICAL_EMERGENCY_FORM } from '../constants/create-workflow-forms';
import { getUniquIndex, validateForm } from '../utils/create-workflow-form-helpers';
import { useCreateCareCoordinationWorkflow } from './hooks/use-create-care-coordination-workflow';
import { WorkflowFormActions } from './workflow-form-actions';
import { WorkflowFormHeader } from './workflow-form-header';
export const MEDICAL_EMERGENCY_FORM_TEST_ID = 'medical-emergency-form';
export const MedicalEmergencyForm = (): JSX.Element => {
  const [CreateCareCoordinationWorkflow] = useCreateCareCoordinationWorkflow();
  const [currentUser] = useAtom(currentUserAtom);
  const [createCareCoordinationWorkflow] = useAtom(createCareCoordinationWorkflowAtom);
  const [medicalEmergencyFormState, setMedicalEmergencyFormState] = useAtom(medicalEmergencyFormStateAtom);
  const [medicalEmergencyErrors, setMedicalEmergencyErrors] = useState({
    description: '',
    context: ''
  });
  const workflowAssigneeUuid = useAtomValue(workflowAssigneeUuidAtom);
  const onMedicalEmergencySubmit = useCallback((): void => {
    const {
      isValidated,
      errors: validationErrors
    } = validateForm({
      ...medicalEmergencyFormState,
      description: medicalEmergencyFormState.description.trim()
    }, ['context', 'level']);
    setMedicalEmergencyFormState(prev => ({
      ...prev,
      description: medicalEmergencyFormState.description.trim()
    }));
    setMedicalEmergencyErrors((validationErrors as Record<keyof typeof medicalEmergencyErrors, string>));
    if (!isValidated) {
      return;
    }
    CreateCareCoordinationWorkflow({
      workflowType: PtWorkflowTypes.MedicalEmergencyFollowUp,
      workflowInputDto: {
        customFields: {
          ...medicalEmergencyFormState,
          level: medicalEmergencyFormState.level
        },
        patientUuid: (currentUser?.uuid as string)
      },
      memberId: (currentUser?.id as string)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalEmergencyFormState, currentUser]);
  const onMedicalEmergencyFieldChange = (field: keyof typeof medicalEmergencyFormState, value: string | number): void => {
    if (['description', 'context'].includes(field) && value.toString().length > MAX_CHARACTERS) {
      setMedicalEmergencyErrors(prev_0 => ({
        ...prev_0,
        [field]: COMMON_FORM.MAX_CHARACTERS_ERROR
      }));
      return;
    }
    setMedicalEmergencyFormState(prev_1 => ({
      ...prev_1,
      [field]: value
    }));
    setMedicalEmergencyErrors(prev_2 => ({
      ...prev_2,
      [field]: ''
    }));
  };
  return <HHStack data-testid={MEDICAL_EMERGENCY_FORM_TEST_ID} paddingY={4} paddingX={8} rowGap={4}>
      <WorkflowFormHeader title={MEDICAL_EMERGENCY_FORM.TITLE} assigneeRole={OktaRole.PhysicalTherapist} />
      <HHDivider />
      <HHStack paddingTop={4} rowGap={4}>
        <HHStack>
          <HHTypography hhVariant="body1">
            {MEDICAL_EMERGENCY_FORM.LEVEL_OF_EMERGENCY} (
            <HHLink href={MEDICAL_EMERGENCY_FORM.GUIDELINES_LINK} target="_blank">
              {MEDICAL_EMERGENCY_FORM.GUIDELINES_LINK_TEXT}
            </HHLink>
            )
          </HHTypography>
          <HHRadioGroup defaultValue={medicalEmergencyFormState.level} value={medicalEmergencyFormState.level} onChange={(e): void => onMedicalEmergencyFieldChange('level', parseInt(e.target.value))}>
            {MEDICAL_EMERGENCY_FORM.LEVEL_OF_EMERGENCY_OPTIONS.map((label, index) => <HHFormControlLabel label={label} key={getUniquIndex(label, index)} disabled={createCareCoordinationWorkflow.loading} value={index + 1} control={<HHRadio hhVariant="primary" />} />)}
          </HHRadioGroup>
        </HHStack>

        <HHTextField hhVariant="outlined" label={COMMON_FORM.DESCRIPTOIN} error={Boolean(medicalEmergencyErrors.description)} helperText={medicalEmergencyErrors.description} InputProps={{
        readOnly: createCareCoordinationWorkflow.loading
      }} multiline maxRows={4} onChange={(e_0): void => onMedicalEmergencyFieldChange('description', (e_0.target.value as string))} value={medicalEmergencyFormState.description} />
        <HHTextField hhVariant="outlined" label={COMMON_FORM.ADDITIONAL_CONTEXT} defaultValue={medicalEmergencyFormState.context} InputProps={{
        readOnly: createCareCoordinationWorkflow.loading
      }} error={Boolean(medicalEmergencyErrors.context)} helperText={medicalEmergencyErrors.context || MEDICAL_EMERGENCY_FORM.ADDITIONAL_CONTEXT_HELPER} multiline maxRows={4} onChange={(e_1): void => onMedicalEmergencyFieldChange('context', (e_1.target.value as string))} value={medicalEmergencyFormState.context} />
      </HHStack>
      <WorkflowFormActions loading={createCareCoordinationWorkflow.loading} onSubmit={onMedicalEmergencySubmit} disabled={!workflowAssigneeUuid} />
    </HHStack>;
};