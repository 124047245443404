import { HHBox, HHTableCell } from '@hinge-health/react-component-library';
import { MarkChatRead } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { PtWorkflowTypes } from '../../../../../../custom-types';
import { useGenericMixpanelTrack } from '../../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../../lib/mixpanel/mixpanel-event-names';
import { WorkflowResolutionProperties } from '../../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { CommonAcuteState } from '../../../../../acute-physical-therapy/custom-types';
import { useCreateTransitionMutation, WorkflowPayload } from '../../../../../member-360/modules/panel-layouts/types';
import { MARK_COMPLETE_MESSAGE, MARK_READ } from '../../../../constants/strings/workflows-table';
import { DashboardType } from '../../../../custom-types';
import { currentSelectedWorkflowTypeAtom, listToMarkWorkflowComplete, listToMarkWorkflowPending, markCompleteAlertMessage, markCompleteAlertType, markedWorkflowIds, selectAllPTWorkflowAtom, selectAllWorkflowAtom, selectedDashboardType, showMarkCompleteAlert } from '../../../../jotai/atoms';
import { CommonCoachState } from '../../../../types/coach';
import { defaultErrorHandler } from '../../../../utils/sentry-error-handler';
import { styles } from './style';
interface WorkflowMarkCellProps {
  workflow: WorkflowPayload;
  displayCompleteIcon: boolean;
}
export const tooltipStyles = {
  tooltip: {
    position: 'absolute',
    color: 'white',
    marginTop: '-3vh',
    marginLeft: '-0.5vw',
    backgroundColor: 'rgb(85, 85, 85)',
    opacity: 1,
    transition: 'opacity 0.3s ease 0s',
    width: '2.7vw',
    textAlign: 'center',
    borderRadius: '4px',
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: 'rgb(85, 85, 85) transparent transparent'
  }
};
const markAsReadTooltipStyles = {
  width: 'fit-content',
  padding: '0px 3px',
  marginLeft: '-1.5vw',
  marginTop: '-2.5vh'
};
export const WorkflowMarkCell = ({
  workflow,
  displayCompleteIcon
}: WorkflowMarkCellProps): JSX.Element => {
  const [createTransitionMutation] = useCreateTransitionMutation();
  const mixpanelTrack = useGenericMixpanelTrack();
  const [, setAlertType] = useAtom(markCompleteAlertType);
  const [, setMessage] = useAtom(markCompleteAlertMessage);
  const [, setShowAlert] = useAtom(showMarkCompleteAlert);
  const [workflowIds, setWorkflowIds] = useAtom(markedWorkflowIds);
  const [showTooltip, setShowTooltip] = useState(false);
  const [, setAllWorkflowFlag] = useAtom(selectAllWorkflowAtom);
  const [, setAllPTWorkflowFlag] = useAtom(selectAllPTWorkflowAtom);
  const [, setListToMarkComplete] = useAtom(listToMarkWorkflowComplete);
  const [, setListToMarkPending] = useAtom(listToMarkWorkflowPending);
  const [currrentDashboardType] = useAtom(selectedDashboardType);
  const [, setCurrentWorkflowType] = useAtom(currentSelectedWorkflowTypeAtom);
  useEffect(() => {
    if (workflow) {
      setCurrentWorkflowType(workflow.type);
    }
  }, [setCurrentWorkflowType, workflow]);
  const statusChangeHandler = async (): Promise<void> => {
    if (workflowIds.includes(workflow.id)) {
      return;
    }
    setWorkflowIds([...workflowIds, workflow.id]);
    const state = currrentDashboardType === DashboardType.PhysicalTherapist && workflow.type == PtWorkflowTypes.HealthLog ? CommonAcuteState.AcuteComplete : CommonCoachState.CoachComplete;
    try {
      const {
        data: transitionData
      } = await createTransitionMutation({
        variables: {
          transition: {
            id: workflow.id,
            toState: state
          },
          workflowType: workflow.type
        }
      });
      if (transitionData) {
        mixpanelTrack<WorkflowResolutionProperties>(MIXPANEL_EVENTS.WORKFLOW_MANUALLY_RESOLVED_VIA_MARK_AS_READ, {
          workflowId: workflow.id,
          workflowTransitionState: transitionData.createTransition?.state,
          workflowInitialState: workflow.state,
          workflowName: workflow.state,
          workflowCreatedAt: workflow.createdAt,
          memberUuid: (workflow.patient?.uuid as string)
        });
      }
    } catch (err) {
      setAlertType('error');
      setShowAlert(true);
      setMessage(MARK_COMPLETE_MESSAGE.ERROR);
      setMessage(MARK_COMPLETE_MESSAGE.ERROR);
      defaultErrorHandler(MARK_COMPLETE_MESSAGE.ERROR);
      setAllWorkflowFlag(false);
      setAllPTWorkflowFlag(false);
      setListToMarkComplete([]);
      setListToMarkPending([]);
    }
  };
  return <HHTableCell sx={styles.cell} id={`${workflow.id}`} data-testid={'workflow-row-mark'} onClick={(event): void => {
    statusChangeHandler();
    event.stopPropagation();
  }}>
      <HHBox sx={{
      display: 'flex',
      flexDirection: 'row',
      width: 60
    }}>
        {displayCompleteIcon && <>
            <HHBox onMouseEnter={(): void => setShowTooltip(true)} onMouseLeave={(): void => setShowTooltip(false)}>
              <MarkChatRead sx={{
            ...(workflowIds.includes(workflow.id) && {
              opacity: 0.5,
              pointerEvents: 'none'
            }),
            cursor: 'pointer'
          }} />
            </HHBox>
            {showTooltip && <span style={{
          ...(tooltipStyles.tooltip as React.CSSProperties),
          ...markAsReadTooltipStyles
        }}>
                {MARK_READ}
              </span>}
          </>}
      </HHBox>
    </HHTableCell>;
};