import { useClient } from '@splitsoftware/splitio-react';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { NAV_SPLIT_TREATMENTS, ON } from '../constants/strings/split';
import { MIXPANEL_EVENTS } from '../lib/mixpanel/mixpanel-event-names';
import { CLICK_TRACKING_KEYS } from '../lib/mixpanel/strings';
export const useGlobalClickTracking = (adminUUID: string | undefined): void => {
  const location = useLocation();
  const splitClient = useClient();
  const mixpanelClickTrackingTreatment = splitClient?.getTreatment(NAV_SPLIT_TREATMENTS.MIXPANEL_CLICK_TRACKING, {
    adminUuid: adminUUID || '*'
  }) === ON;
  useEffect(() => {
    if (!mixpanelClickTrackingTreatment) {
      return;
    }
    const handleClick = (event: MouseEvent): void => {
      const {
        clientX,
        clientY
      } = event;
      const targetElement = (event.target as HTMLElement);
      if (targetElement) {
        mixpanel.track(MIXPANEL_EVENTS.ELEMENT_CLICKED, {
          [CLICK_TRACKING_KEYS.DATA_TEST_ID]: targetElement.getAttribute('data-testid'),
          [CLICK_TRACKING_KEYS.X_COORDINATE]: clientX,
          [CLICK_TRACKING_KEYS.Y_COORDINATE]: clientY,
          [CLICK_TRACKING_KEYS.PAGE]: location.pathname
        });
      }
    };
    document.addEventListener('click', handleClick);
    return (): void => {
      document.removeEventListener('click', handleClick);
    };
  }, [location.pathname, mixpanelClickTrackingTreatment]);
};