import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHChip, HHCircularProgress } from '@hinge-health/react-component-library';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { NAV_QUEUE_COUNTS_LOADING_ID } from '../../constants/strings/navigation-items';
import { useGetIncompleteQueueCounts } from '../../hooks/use-get-incomplete-queue-counts';
const styles = {
  chip: {
    backgroundColor: ctpTokens.palette.lime[50],
    cursor: 'pointer'
  }
};
export const QueueCountNavIcon = () => {
  const $ = _c(4);
  const {
    totalCount,
    errorInCountFetch,
    loading
  } = useGetIncompleteQueueCounts();
  if (loading) {
    let t0;
    if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <HHCircularProgress data-testid={NAV_QUEUE_COUNTS_LOADING_ID} size={24} />;
      $[0] = t0;
    } else {
      t0 = $[0];
    }
    return t0;
  }
  if (errorInCountFetch) {
    let t0;
    if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <ErrorOutline />;
      $[1] = t0;
    } else {
      t0 = $[1];
    }
    return t0;
  }
  const t0 = !loading && totalCount || 0;
  let t1;
  if ($[2] !== t0) {
    t1 = <HHChip data-testid="nav-count-chip" hhVariant="variant-bypass" label={t0} size="small" sx={styles.chip} />;
    $[2] = t0;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  return t1;
};