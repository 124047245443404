import { ctpTokens, HHButton, HHDialogActions, HHDialogContent, HHDialogTitle, HHStack, HHTypography } from '@hinge-health/react-component-library';
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import WarningIcon from '@mui/icons-material/Warning';
import React, { useEffect } from 'react';

// Constants for PHI masking
export const PHI_MASK_ENABLED_MESSAGE = 'Protected health information is now hidden.';
export const PHI_MASK_DISABLED_MESSAGE = 'Protected health information is now visible.';
export const CLOSE_BUTTON_TEXT = 'Close';
const PHI_MASK_IS = 'PHI mask is';
export const PHI_MASK_ENABLED = `${PHI_MASK_IS} enabled`;
export const PHI_MASK_DISABLED = `${PHI_MASK_IS} disabled`;
const CLOSE_DIALOG_TIMEOUT = 3000;

// Test IDs
export const PHI_MASK_FEEDBACK_DIALOG_TEST_ID = 'phi-mask-feedback-dialog';
const commonIconStyles: React.CSSProperties = {
  height: '22px',
  width: '22px'
};
interface PhiMaskingFeedbackDialogContentProps {
  phiMaskingEnabled: boolean;
  onClose: () => void;
}
export const PHIMaskingFeedbackDialogContent = ({
  phiMaskingEnabled,
  onClose
}: PhiMaskingFeedbackDialogContentProps): JSX.Element => {
  useEffect(() => {
    // Auto close feedback dialog after 3 seconds
    const closeDialogTimeout = setTimeout(() => {
      onClose();
      clearTimeout(closeDialogTimeout);
    }, CLOSE_DIALOG_TIMEOUT);
    return () => clearTimeout(closeDialogTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const titleIcon = phiMaskingEnabled ? <CheckCircleRounded sx={{
    ...commonIconStyles,
    color: ctpTokens.palette.green[50]
  }} /> : <WarningIcon sx={{
    ...commonIconStyles,
    color: ctpTokens.palette.yellow[30]
  }} />;
  const titleText = phiMaskingEnabled ? PHI_MASK_ENABLED : PHI_MASK_DISABLED;
  return <>
      <HHDialogTitle>
        <HHStack flexDirection={'row'} columnGap={2.5} alignItems={'center'}>
          {titleIcon}
          <HHTypography hhVariant="h1">{titleText}</HHTypography>
        </HHStack>
      </HHDialogTitle>
      <HHDialogContent sx={{
      paddingBottom: 0
    }} data-testid={PHI_MASK_FEEDBACK_DIALOG_TEST_ID}>
        <HHStack justifyContent={'center'} height={'100%'}>
          <HHTypography hhVariant="body1">
            {phiMaskingEnabled ? PHI_MASK_ENABLED_MESSAGE : PHI_MASK_DISABLED_MESSAGE}
          </HHTypography>
        </HHStack>
      </HHDialogContent>
      <HHDialogActions sx={{
      px: 6,
      pt: 4,
      pb: 6
    }}>
        <HHButton hhVariant="contained" onClick={onClose}>
          {CLOSE_BUTTON_TEXT}
        </HHButton>
      </HHDialogActions>
    </>;
};