import { c as _c } from "react-compiler-runtime";
import { gql, MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { CreateWorkflowInputDto, Scalars } from '../../../types';
export type CreateCareCoordinationWorkflowMutation = {
  createAndTransitionCareCoordinationWorkflows: {
    response: string;
  };
};
export type CreateCareCoordinationWorkflowInputDto = CreateWorkflowInputDto & {
  /** The uuid of the admin who created the workflow */
  createdByAdminUuid?: Scalars['ID'];
};
export const CREATE_CARE_COORDINATION_WORKFLOW_MUTATION = gql`
  mutation CreateCareCoordinationWorkflow(
    $createWorkflowInputDto: [CreateWorkflowInputDto!]!
    $workflowType: String!
  ) {
    createAndTransitionCareCoordinationWorkflows(
      createWorkflowInputDto: $createWorkflowInputDto
      workflowType: $workflowType
    ) {
      response
    }
  }
`;
export type CreateCareCoordinationWorkflowVariables = {
  createWorkflowInputDto: CreateCareCoordinationWorkflowInputDto;
  workflowType: string;
};
export function useCreateCareCoordinationWorkflowMutation(baseOptions) {
  const $ = _c(2);
  let t0;
  if ($[0] !== baseOptions) {
    t0 = {
      ...baseOptions
    };
    $[0] = baseOptions;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const options = t0;
  return useMutation(CREATE_CARE_COORDINATION_WORKFLOW_MUTATION, options);
}